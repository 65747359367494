import React from 'react';
import { Helmet } from 'react-helmet';
import serializeForm from 'form-serialize';
import { Header } from '../../components/DashboardHeader';
import { Sidebar } from '../../components/DashboardSidebar';
import Gap from '../../components/Gap';
import { Input } from '../../components/html/Input';
import { SettingsSidebar } from '../../components/SettingsSidebar';
import { toast } from 'react-toastify';
import * as userApi from '../../../api/user';
import { handleRequestErrors } from '../../../utils';
import { Button } from '../../components/html/Button';
import { Modal } from '../../components/Modal';
import { Security2faDialog } from '../../components/2FADialog';
import '../settings.scss';

export const PasswordSettings = () => {
  const changePassword = (currentPassword: string, password: string) =>
    Modal.Open(
      <Security2faDialog
        operation="PASSWORD_UPDATE"
        handleSecurity={async (security, callback) => {
          userApi.changePassword(currentPassword, password, security).catch((err) => {
            callback(null, err);
            handleRequestErrors(err, 'An unexptected error occurred. We could not change your password.');
          });
        }}
      />,
      {
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'modal-mobile-fullscreen',
          popup: 'max-w-350x',
        },
      },
    );

  return (
    <>
      <Helmet title="Settings | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="settings-page-wrap">
                  <SettingsSidebar />

                  <div className="settings-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => history.back()}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Password Update</h6>
                      </div>
                    </div>

                    <Gap v={2} />

                    <div className="page-content-inner">
                      <form
                        onSubmit={(ev) => {
                          ev.preventDefault();

                          const form = ev.target as any;
                          const { currentPassword, password, confirmPassword } = serializeForm(form, { hash: true });

                          if (password !== confirmPassword) {
                            return toast.error('Your passwords do not match. Please check and try again.');
                          }

                          if (currentPassword === password) {
                            return toast.error('You cannot change your password to your current password.');
                          }

                          changePassword(currentPassword, password);
                        }}
                      >
                        <Input required type="password" name="currentPassword" label="Old Password" gapbottom={1} />

                        <Input required type="password" name="password" label="New Password" gapbottom={1} />

                        <Input required type="password" name="confirmPassword" label="Confirm Password" gapbottom={1} />

                        <Button type="submit" className="btn btn-primary btn-default" text="Change Password" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
