import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ISearchResults, searchResults } from '../../../search';
import './styles.scss';

export const GlobalSearchForm = () => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState<ISearchResults[]>([]);

  useEffect(() => {
    const filteredResults = searchResults.filter((result) =>
      // prettier-ignore
      searchText ? (result.title.toLowerCase().includes(searchText.toLowerCase()) || result.description.toLowerCase().includes(searchText.toLowerCase())) : true,
    );

    setResults(searchText === '' ? [] : filteredResults);
  }, [searchText]);

  return (
    <div className="global-search-help">
      <form onSubmit={(ev) => ev.preventDefault()}>
        <input
          ref={inputRef}
          className="form-control search-input"
          placeholder="Search here..."
          name="search"
          autoComplete="off"
          onChange={(ev) => setSearchText(ev.target.value.trim())}
        />
      </form>

      <div className="search-results">
        <ul>
          {results.map((result) => (
            <li
              key={result.id}
              onClick={() => {
                const $el = document.getElementsByClassName('dashboard-wrapper')[0];
                const $scrollElem = document.getElementsByClassName('dashboard-content-wrap')[0];
                $el && $el.classList.remove('left-sidebar-open', 'right-sidebar-open');
                $scrollElem && $scrollElem.classList.remove('no-scroll');

                setResults([]);
                (inputRef.current as any).value = '';

                if (result.location) {
                  navigate(result.location);
                } else if (result.onClick) {
                  result.onClick();
                }
              }}
            >
              <h6>{result.title}</h6>
              <p>{result.description}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
