import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { TbArrowsRandom } from 'react-icons/tb';
import { FiArrowUpRight } from 'react-icons/fi';
import 'rc-menu/assets/index.css';
import 'rc-dropdown/assets/index.css';
import { ReducerStates } from '../../../typings/reducer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { route } from '../../../routes';
import { Modal } from '../Modal';
import './header.scss';
import { useDispatch } from 'react-redux';
import { GlobalSearchForm } from '../GlobalSearchForm';
import { TickerBar } from '../TickerBar';
import { ExchangeDialog } from '../ExchangeDialog';
import { TransferDialog } from '../TransferDialog';
import logoMobile from '../../../assets/logo-mobile.svg';

interface HeaderProps {
  withTicker?: boolean;
  isOnboarding?: boolean;
  containerClass?: string;
  onLeftMenuClick?(): void;
}

export const Header = (props: HeaderProps) => {
  const { onLeftMenuClick = () => {}, withTicker } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state: ReducerStates) => state);
  const userInitials = `${user.firstName} ${user.lastName}`
    .trim()
    .match(/(\b\S)?/g)!
    .join('')!
    .match(/(^\S|\S$)?/g)!
    .join('')
    .toUpperCase();

  const wrapperClassName = props.isOnboarding ? 'onboarding-wrapper' : 'dashboard-wrapper';
  const $scrollContainer = props.isOnboarding ? window : document.getElementsByClassName('dashboard-content-wrap')[0];

  const closeSidebars = () => {
    const $el = document.getElementsByClassName(wrapperClassName)[0];
    const $scrollElem = props.isOnboarding ? document.getElementsByTagName('body')[0] : ($scrollContainer as Element);

    $el && $el.classList.remove('left-sidebar-open', 'right-sidebar-open');
    $scrollElem && $scrollElem.classList.remove('no-scroll');
  };

  const toggleSidebar = (type: 'left' | 'right') => {
    const $el = document.getElementsByClassName(wrapperClassName)[0];
    $scrollContainer && $scrollContainer.scrollTo({ top: 0, behavior: 'auto' });

    if (type === 'left') {
      $el && $el.classList.remove('right-sidebar-open');
      $el && $el.classList.toggle('left-sidebar-open');
    } else {
      $el && $el.classList.remove('left-sidebar-open');
      $el && $el.classList.toggle('right-sidebar-open');
    }

    const $scrollElem = props.isOnboarding ? document.getElementsByTagName('body')[0] : ($scrollContainer as Element);
    if (($el && $el.classList.contains('left-sidebar-open')) || ($el && $el.classList.contains('right-sidebar-open'))) {
      $scrollElem && $scrollElem.classList.add('no-scroll');
    } else {
      $scrollElem && $scrollElem.classList.remove('no-scroll');
    }

    Modal.CloseAll();
    onLeftMenuClick();
  };

  const doLogout = () => {
    closeSidebars();

    Modal.Confirm('Confirm logout?', <></>).then((b) => {
      if (b === true) {
        dispatch({ type: 'RESET_STATES' });
        navigate(route.authentication.signin, { replace: true });
      }
    });
  };

  useEffect(() => {
    closeSidebars();
  }, [location]);

  return (
    <>
      <div className={`dashboard-header ${props.containerClass || ''}`}>
        <div className="row">
          <div className="col-auto" data-vertical_center>
            <div className="title"></div>
            <span className="mobile-menu visible-1200px" onClick={() => toggleSidebar('left')}></span>
            <span
              className="hidden-1200px cursor-pointer font-weight-bold"
              onClick={() =>
                Modal.Open(<ExchangeDialog />, {
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  customClass: {
                    container: 'modal-mobile-fullscreen',
                    popup: 'max-w-500x',
                  },
                })
              }
            >
              <TbArrowsRandom
                className="d-inline-block"
                style={{
                  fontSize: '22px',
                  verticalAlign: 'top',
                  color: '#6932d4',
                  marginTop: '2px',
                }}
              />
              &nbsp;{' '}
              <span className="d-inline-block" style={{ color: '#6932d4', fontWeight: 'bold' }}>
                Exchange
              </span>
            </span>
          </div>

          <div className="col-auto" data-vertical_center>
            <span
              className="hidden-1200px cursor-pointer font-weight-bold"
              onClick={() =>
                Modal.Open(<TransferDialog />, {
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  customClass: {
                    container: 'modal-mobile-fullscreen',
                    popup: 'max-w-400x',
                  },
                })
              }
            >
              <FiArrowUpRight className="d-inline-block" style={{ fontSize: '24px', verticalAlign: 'top', color: '#6932d4' }} />{' '}
              <span className="d-inline-block" style={{ color: '#6932d4', fontWeight: 'bold' }}>
                Transfer
              </span>
            </span>
          </div>

          <div className="col" data-vertical_center>
            <div className="text-center">
              <Link to="/" className="logo visible-1200px">
                <img src={logoMobile} alt="logo" />
              </Link>
            </div>
          </div>

          <div className="col-auto text-end" data-vertical_center>
            <div className="right-content-wrap">
              <div className="row">
                <div className="col col-search hidden-1200px">
                  <GlobalSearchForm />
                </div>

                <div className="col-auto">
                  <div>
                    <div className="notifications-wrap d-inline-block hidden-1200px hidden">
                      <span className="handle has-.update"></span>
                    </div>

                    <div className="user-wrap d-inline-block">
                      <Dropdown>
                        <Dropdown.Toggle as="span" className="handle hidden-1200px" data-initials={userInitials} />

                        <Dropdown.Menu className="dropdown-custom">
                          <ul>
                            <li>
                              <Link to={route.dashboard.settings}>Account</Link>
                            </li>
                            <li className="visible-1200px">
                              <Link to={route.dashboard.notifications}>Notifications</Link>
                            </li>
                            <li>
                              <a onClick={() => doLogout()}>Logout</a>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>

                      <span className="handle visible-1200px" data-initials={userInitials} onClick={() => toggleSidebar('right')}></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right-sidebar visible-1200px">
          <ul>
            <li>
              <Link to={route.dashboard.settings}>Account</Link>
            </li>
            <li className="hidden">
              <Link to={route.dashboard.notifications}>Notifications</Link>
            </li>
            <li>
              <a className="alt" onClick={() => doLogout()}>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="sidebar-backdrop visible-1200px" onClick={() => closeSidebars()}></div>

      {withTicker ? <TickerBar /> : null}
    </>
  );
};
