import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import { parsePhoneNumber } from 'libphonenumber-js';
import serializeForm from 'form-serialize';
import Dropdown from 'rc-dropdown';
import Bluebird from 'bluebird';
import Menu, { Item as MenuItem } from 'rc-menu';
import 'rc-dropdown/assets/index.css';
import { Input } from '../components/html/Input';
import { Sidebar } from '../components/OnboardingSidebar';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Gap from '../components/Gap';
import { route } from '../../routes';
import { KycDocSubType, KycDocType, ReducerStates, UploadFile } from '../../typings';
import { cleanPhoneNumber, generateUniqueFileIdentifier, handleRequestErrors } from '../../utils';
import { setIndividualKycInfo } from '../../actions/kyc';
import { fromLocationValue, LocationSelect } from '../components/LocationSelect';
import { parseLocationData } from '../../utils/user';
import { meansOfIdDocsOptions, proofOfAddressDocsOptions } from '../../constants';
import { FileDisplay } from '../components/html/FileDisplay';
import * as kycApi from '../../api/kyc';
import * as filesApi from '../../api/files';
import './onboarding.scss';
import { Button } from '../components/html/Button';
import { Header } from '../components/DashboardHeader';
import { PhoneCodeSelect } from '../components/html/PhoneCodeSelect';

type NavTypes = 'BASIC' | 'LOCATION' | 'DOCS';

const acceptedFiles = '.png, .jpg, .jpeg, .pdf';
const maxSizePerFile = 5242880;

export const OnboardingProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const { user, kyc } = useSelector((state: ReducerStates) => state);
  const [loading, setLoading] = useState(false);
  const [locationData, setLocationData] = useState<any>();
  const [currentContent, setCurrentContent] = useState<NavTypes>('BASIC');
  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
  const [category, setCategory] = useState<KycDocType | null>();
  const [subCategory, setSubCategory] = useState<KycDocSubType | null>();

  const addFile = (type: KycDocType, subType?: KycDocSubType) => {
    setCategory(type);
    setSubCategory(subType);
    (inputFile.current as any).click();
  };

  const removeFile = (id: string) => {
    const file = uploadFiles.find((f) => f.id === id);
    file?.cancelToken && file.cancelToken();

    if (file?.uploaded) {
      // remove file from server
      kycApi
        .removeFile(file.id, 'PROFILE')
        .then(() => setUploadFiles(uploadFiles.filter((f) => f.id !== id)))
        .catch((err) => handleRequestErrors(err));
    } else {
      setUploadFiles(uploadFiles.filter((f) => f.id !== id));
    }
  };

  const updateFile = (id: string, data: Partial<UploadFile>) => {
    setUploadFiles(
      uploadFiles.map((file) => {
        if (file.id !== id) return file;

        for (const [k, v] of Object.entries(data)) {
          file[k] = v;
        }

        return file;
      }),
    );
  };

  useEffect(() => {
    if (kyc.individual?.location) {
      setLocationData({
        country: parseLocationData(kyc.individual?.location.country),
        state: parseLocationData(kyc.individual?.location.state),
        city: parseLocationData(kyc.individual?.location.suburb),
      });
    }
  }, [kyc.individual?.location]);

  useEffect(() => {
    setUploadFiles([
      ...uploadFiles,
      ...(kyc.individual?.docs || []).map(
        (doc) =>
          ({
            id: doc.id,
            status: doc.status,
            uploaded: true,
            uploading: false,
            extension: doc.originalFileName.split('.').pop(),
            category: doc.type,
            subCategory: doc.subType,
            data: {
              fileKey: doc.fileKey,
              fileUrl: doc.url,
              fileName: doc.originalFileName,
            },
          } as any),
      ),
    ]);
  }, [kyc.individual?.docs]);

  useEffect(() => {
    Bluebird.Promise.each(uploadFiles, async (file) => {
      if (file.uploading === true || file.uploaded === true) return;

      file.uploading = true;

      updateFile(file.id, {
        uploading: true,
      });

      const presign = await filesApi.generatePresignedUrl(file.type, 'VERIFICATIONS');

      // Prevent upload incase file already removed before presign operation finished
      const exstFile = uploadFiles.find((f) => f.id === file.id);
      if (!exstFile) return;

      await filesApi
        .uploadFile({
          signedUrl: presign.signedUrl,
          file,
          getCancelToken(cancelToken) {
            file.cancelToken = cancelToken;

            updateFile(file.id, {
              cancelToken,
            });
          },
          onUploadProgress(progress) {
            file.progress = progress;

            updateFile(file.id, {
              progress,
            });
          },
        })
        .then(() => {
          file.uploaded = true;
          file.data = {
            fileName: file.name,
            fileUrl: presign.fileUrl,
            fileKey: presign.fileKey,
          };

          updateFile(file.id, {
            uploaded: true,
            data: file.data,
          });
        })
        .catch((err) => handleRequestErrors(err, 'An error occurred. Your file could not be uploaded'))
        .finally(async () => {
          file.uploading = false;

          updateFile(file.id, {
            uploading: false,
          });

          if (file.uploaded) {
            kycApi
              .addFile({
                mode: 'PROFILE',
                type: file.category,
                subType: file.subCategory,
                fileKey: file.data.fileKey,
                originalFileName: file.name,
                url: file.data.fileUrl,
              })
              .then((addedFile) => {
                const { id, status, type, subType, fileKey, originalFileName, url } = addedFile;

                updateFile(file.id, {
                  id,
                  status,
                  category: type,
                  subCategory: subType,
                  data: {
                    fileKey,
                    fileUrl: url,
                    fileName: originalFileName,
                  },
                });
              })
              .catch((err) => handleRequestErrors(err));
          }
        });
    });
  }, [uploadFiles]);

  return (
    <>
      <Helmet title="Host Capital" />

      <div className="onboarding-wrapper">
        <Sidebar />

        <div className="onboarding-content-wrap in-page">
          <Header isOnboarding onLeftMenuClick={() => {}} />

          <div className="content-inner">
            <div className="in-page-nav">
              <ul>
                <li>
                  <a className={`${currentContent === 'BASIC' ? 'current' : ''}`} onClick={() => setCurrentContent('BASIC')}>
                    Basic
                  </a>
                </li>
                <li>
                  <a className={`${currentContent === 'LOCATION' ? 'current' : ''}`} onClick={() => setCurrentContent('LOCATION')}>
                    Location
                  </a>
                </li>
                <li>
                  <a className={`${currentContent === 'DOCS' ? 'current' : ''}`} onClick={() => setCurrentContent('DOCS')}>
                    Documents
                  </a>
                </li>
              </ul>
            </div>

            <div className="in-page-content">
              <div
                style={{
                  display: currentContent === 'BASIC' ? 'block' : 'none',
                }}
              >
                <form
                  onSubmit={(ev) => {
                    ev.preventDefault();

                    const formData = serializeForm(ev.target, { hash: true });
                    const { firstName, lastName, primaryPhoneCode, secondaryPhoneCode, nationality, occupation } = formData;
                    // prettier-ignore
                    const primaryPhone = formData.primaryPhone ? primaryPhoneCode + formData.primaryPhone.replace(/^0+/, '').replace(/\s/g, '') : '';

                    let secondaryPhone;

                    if (formData.secondaryPhone) {
                      // prettier-ignore
                      secondaryPhone = formData.secondaryPhone ? secondaryPhoneCode + formData.secondaryPhone.replace(/^0+/, '').replace(/\s/g, '') : '';
                    }

                    setLoading(true);
                    kycApi
                      .updatePersonalInfo({
                        firstName,
                        lastName,
                        primaryPhone,
                        secondaryPhone,
                        nationality,
                        occupation,
                      })
                      .then((data) => {
                        dispatch(setIndividualKycInfo(data));
                        setCurrentContent('LOCATION');
                      })
                      .catch(handleRequestErrors)
                      .finally(() => setLoading(false));
                  }}
                >
                  <h3>Let us know more about you</h3>
                  <Gap v={1} />

                  <div className="row">
                    <div className="col">
                      <Input
                        required
                        type="text"
                        label="First Name"
                        id="first-name"
                        placeholder="Ex. John"
                        name="firstName"
                        defaultValue={kyc.individual?.firstName}
                        gapbottom={1}
                        minLength={3}
                        maxLength={40}
                      />
                    </div>

                    <div className="col">
                      <Input
                        required
                        type="text"
                        label="Last Name"
                        id="last-name"
                        placeholder="Ex. Doe"
                        name="lastName"
                        defaultValue={kyc.individual?.lastName}
                        gapbottom={1}
                        minLength={3}
                        maxLength={40}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="input-pri-phone" className="mb-2">
                        Primary Phone Number <span>*</span>
                      </label>
                    </div>

                    <div className="col-4 pr-2">
                      <PhoneCodeSelect
                        name="primaryPhoneCode"
                        value={
                          // prettier-ignore
                          kyc.individual?.primaryPhone ? `+${parsePhoneNumber(cleanPhoneNumber(kyc.individual.primaryPhone)).countryCallingCode as string}` : ''
                        }
                      />
                    </div>

                    <div className="col-8 pl-0">
                      <div className="form-group">
                        <Cleave
                          required
                          name="primaryPhone"
                          autoComplete="off"
                          id="input-pri-phone"
                          className="form-control"
                          placeholder="E.g 8051235678"
                          value={
                            // prettier-ignore
                            kyc.individual?.primaryPhone ? (parsePhoneNumber(cleanPhoneNumber(kyc.individual.primaryPhone as string)).nationalNumber as string) : ''
                          }
                          options={{
                            phone: true,
                            phoneRegionCode: 'any',
                          }}
                        />
                        <Gap v={1} />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="input-sec-phone" className="mb-2">
                        Secondary Phone Number
                      </label>
                    </div>

                    <div className="col-4 pr-2">
                      <PhoneCodeSelect
                        name="secondaryPhoneCode"
                        value={
                          // prettier-ignore
                          kyc.individual?.secondaryPhone ? `+${parsePhoneNumber(cleanPhoneNumber(kyc.individual.secondaryPhone)).countryCallingCode as string}` : ''
                        }
                      />
                    </div>

                    <div className="col-8 pl-0">
                      <div className="form-group">
                        <Cleave
                          name="secondaryPhone"
                          autoComplete="off"
                          id="input-sec-phone"
                          className="form-control"
                          placeholder="E.g 8051235678"
                          value={
                            // prettier-ignore
                            kyc.individual?.secondaryPhone ? (parsePhoneNumber(cleanPhoneNumber(kyc.individual.secondaryPhone as string)).nationalNumber as string) : ''
                          }
                          options={{
                            phone: true,
                            phoneRegionCode: 'any',
                          }}
                        />

                        <Gap v={1} />
                      </div>
                    </div>
                  </div>

                  <Input
                    required
                    type="text"
                    label="Nationality"
                    id="nationality"
                    name="nationality"
                    placeholder="Ex. Nigerian"
                    defaultValue={kyc.individual?.nationality}
                    gapbottom={1}
                    minLength={3}
                    maxLength={40}
                  />

                  <Input
                    required
                    type="text"
                    label="Occupation (If you're a business owner, what are the business activities?)"
                    id="occupation"
                    name="occupation"
                    defaultValue={kyc.individual?.occupation}
                    gapbottom={1}
                    minLength={3}
                    maxLength={40}
                  />

                  <div className="button-set text-end mt-4">
                    <Button
                      loading={loading}
                      disabled={loading}
                      type="submit"
                      className="btn btn-primary btn-full-width-mobile"
                      text="Save & Continue"
                    />
                  </div>

                  <Gap v={2} />
                </form>
              </div>

              <div
                style={{
                  display: currentContent === 'LOCATION' ? 'block' : 'none',
                }}
              >
                <form
                  onSubmit={(ev) => {
                    ev.preventDefault();

                    const formData = serializeForm(ev.target, { hash: true });
                    const { country, state, city, address, postCode } = formData;

                    setLoading(true);
                    kycApi
                      .updatePersonalInfo({
                        location: {
                          // prettier-ignore
                          country: country ? Object.entries(fromLocationValue(country)!)
                            .map((entry) => entry[1])
                            .filter(Boolean)
                            .join('::') : undefined,
                          // prettier-ignore
                          state: state ? Object.entries(fromLocationValue(state)!)
                            .map((entry) => entry[1])
                            .filter(Boolean)
                            .join('::') : undefined,
                          // prettier-ignore
                          suburb: city ? Object.entries(fromLocationValue(city)!)
                            .map((entry) => entry[1])
                            .filter(Boolean)
                            .join('::') : undefined,
                          address,
                          postCode,
                        },
                      })
                      .then((data) => {
                        dispatch(setIndividualKycInfo(data));
                        setCurrentContent('DOCS');
                      })
                      .catch(handleRequestErrors)
                      .finally(() => setLoading(false));
                  }}
                >
                  <h3>Location Information</h3>
                  <Gap v={1} />

                  <LocationSelect gapBottom={1} values={locationData} />

                  <div className="row">
                    <div className="col-md-9">
                      <Input
                        required
                        type="text"
                        label="Your address"
                        id="input-addr"
                        placeholder="Ex. 123, Darwin Street, Mars"
                        name="address"
                        defaultValue={kyc.individual?.location.address}
                        gapbottom={1}
                      />
                    </div>

                    <div className="col-md-3">
                      <Input
                        required
                        type="text"
                        label="Postal Code"
                        id="input-post-code"
                        placeholder="Ex. 23401"
                        name="postCode"
                        defaultValue={kyc.individual?.location.postCode}
                        gapbottom={1}
                      />
                    </div>
                  </div>

                  <div className="button-set text-end mt-4">
                    <Button
                      loading={loading}
                      disabled={loading}
                      type="submit"
                      className="btn btn-primary btn-full-width-mobile"
                      text="Save & Continue"
                    />
                  </div>

                  <Gap v={2} />
                </form>
              </div>

              <div
                style={{
                  display: currentContent === 'DOCS' ? 'block' : 'none',
                }}
              >
                <div className="max-w-500x">
                  <h3>Identification Documents</h3>
                  <Gap v={1} />

                  <ul className="p-0 ps-3 m-0">
                    <li>Please provide all Identification documents</li>
                    <li>
                      If documents are in any language other than English, please ensure they are accompanied by a certified Engligh
                      translation.
                    </li>
                  </ul>

                  <Gap v={2} />

                  <div className="id-files-section">
                    <div className="section">
                      <h6>
                        Proof of Identity - Passport or Drivers Licence (If you do not hold a Nigerian Passport, please provide a clear
                        selfie with the proof of identity provided)
                      </h6>

                      <div className="id-files">
                        {uploadFiles
                          .filter((file) => meansOfIdDocsOptions.map((id) => id.value).includes(file.category))
                          .map((file) => (
                            <div
                              key={file.id}
                              className="file"
                              style={{
                                marginBottom: '45px',
                              }}
                            >
                              <FileDisplay
                                preview={file}
                                src={file.data.fileUrl}
                                fileName={file.name || file.data.fileName}
                                onRemove={() => removeFile(file.id)}
                              />

                              <span
                                style={{
                                  width: '80px',
                                  display: 'block',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {file.name || file.data.fileName}
                              </span>
                            </div>
                          ))}

                        <Dropdown
                          trigger={['click']}
                          overlay={
                            <Menu>
                              {meansOfIdDocsOptions.map((id) => (
                                <MenuItem key={id.value} onClick={() => addFile(id.value as any)}>
                                  {id.label}
                                </MenuItem>
                              ))}
                            </Menu>
                          }
                          alignPoint
                        >
                          <a className="control">Add File</a>
                        </Dropdown>
                      </div>
                    </div>

                    <div className="section">
                      <h6>Proof of Address (Utility Bill or Bank Statement)</h6>

                      <div className="id-files">
                        {uploadFiles
                          .filter((file) => proofOfAddressDocsOptions.map((id) => id.value).includes(file.subCategory))
                          .map((file) => (
                            <div
                              key={file.id}
                              className="file"
                              style={{
                                marginBottom: '45px',
                              }}
                            >
                              <FileDisplay
                                preview={file}
                                src={file.data.fileUrl}
                                fileName={file.name || file.data.fileName}
                                onRemove={() => removeFile(file.id)}
                              />

                              <span
                                style={{
                                  width: '80px',
                                  display: 'block',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {file.name || file.data.fileName}
                              </span>
                            </div>
                          ))}

                        <Dropdown
                          trigger={['click']}
                          overlay={
                            <Menu>
                              {proofOfAddressDocsOptions.map((id) => (
                                <MenuItem key={id.value} onClick={() => addFile('PROOF_OF_ADDRESS', id.value as any)}>
                                  {id.label}
                                </MenuItem>
                              ))}
                            </Menu>
                          }
                          alignPoint
                        >
                          <a className="control">Add File</a>
                        </Dropdown>
                      </div>
                    </div>

                    <input
                      multiple
                      type="file"
                      ref={inputFile}
                      accept={acceptedFiles}
                      style={{
                        opacity: 0,
                        display: 'none',
                      }}
                      onChange={(ev) => {
                        const files = Array.from(ev.target.files || [])
                          .filter((file) => !uploadFiles.find((f) => f.name === file.name))
                          .filter((file) => {
                            const ext = file.name.split('.').pop();
                            const allowedExts = acceptedFiles
                              .split(',')
                              .map((ext) => ext.trim())
                              .filter(Boolean);
                            const sizeError = file.size >= maxSizePerFile!;

                            return allowedExts.includes(`.${ext!.toLowerCase()}`) && !sizeError;
                          })
                          .map((file: any) => {
                            file.id = generateUniqueFileIdentifier(file);
                            file.extension = file.name.split('.').pop();
                            file.progress = 0;
                            file.uploading = false;
                            file.uploaded = false;
                            file.category = category;
                            file.subCategory = subCategory;
                            file.data = {};

                            return file;
                          });

                        setUploadFiles([...files, ...uploadFiles]);
                        setCategory(null);
                        setSubCategory(null);

                        ev.target.value = '';
                      }}
                    />
                  </div>

                  <Gap v={2} />

                  <div>
                    <p>
                      <b>IMPORTANT</b>: It is an offence under the Anti-Money Laundering and Counter Terrorism Financing Act 2006
                      Requirements to knowingly provide false or misleading information or knowingly produce a false or misleading document.
                    </p>
                    <p>By completing and signing the form I declare that the information I have provided is true and correct.</p>
                  </div>

                  <Button
                    disabled={!uploadFiles.every((f) => f.uploaded === true)}
                    type="submit"
                    className="btn btn-primary full-width-mobile"
                    onClick={() => navigate(user.type !== 'INDIVIDUAL' ? route.onboarding.corporate : route.onboarding.sourceOfFunds)}
                    text="Continue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
