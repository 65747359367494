import Request from '../helpers/request';
import { StatusDto } from '../typings';
import { TokenDto, LoginSource, UserDto, AccessRequestDto } from '../typings/user';

export const requestAccess = async (data: AccessRequestDto): Promise<StatusDto> => {
  return await Request.post<StatusDto>(`${process.env.REACT_APP_API_URL}/user/auth/request-access`, {
    data,
  });
};

export const login = async (email: string, password: string): Promise<TokenDto> => {
  return await Request.post<TokenDto>(`${process.env.REACT_APP_API_URL}/user/auth/login`, {
    data: {
      email,
      password,
    },
  });
};

export const loginConfirm = async (pin: string, token: string): Promise<TokenDto> => {
  return await Request.post<TokenDto>(`${process.env.REACT_APP_API_URL}/user/auth/login/confirm`, {
    data: {
      pin,
    },
    headers: {
      'x-auth-token': token,
    },
  });
};

export const handleMultiLogon = async (accountId: string, token: string): Promise<TokenDto> => {
  return await Request.post<TokenDto>(`${process.env.REACT_APP_API_URL}/user/auth/login/account-token`, {
    data: {
      accountId,
    },
    headers: {
      'x-auth-token': token,
    },
  });
};

export const authExternal = async (token: string, source: LoginSource): Promise<TokenDto> => {
  return await Request.post<TokenDto>(`${process.env.REACT_APP_API_URL}/user/auth/external`, {
    data: {
      token,
      source,
    },
  });
};

export const signup = async (data: Partial<UserDto>): Promise<StatusDto> => {
  const { type, firstName, middleName, lastName, email, primaryPhone, password } = data;

  return await Request.post<StatusDto>(`${process.env.REACT_APP_API_URL}/user/auth/signup`, {
    data: {
      type,
      firstName,
      middleName,
      lastName,
      email,
      phoneNumber: primaryPhone,
      password,
    },
  });
};

export const emailConfirm = async (token: string): Promise<StatusDto> => {
  return await Request.post<StatusDto>(`${process.env.REACT_APP_API_URL}/user/auth/email/confirm`, {
    headers: {
      'x-auth-token': token,
    },
  });
};

export const passwordReset = async (email: string): Promise<StatusDto> => {
  return await Request.post<StatusDto>(`${process.env.REACT_APP_API_URL}/user/auth/password/reset`, {
    data: {
      email,
    },
  });
};

export const passwordChange = async (token: string, password: string) => {
  return await Request.post<StatusDto>(`${process.env.REACT_APP_API_URL}/user/auth/password/change`, {
    data: {
      password,
    },
    headers: {
      'x-auth-token': token,
    },
  });
};
