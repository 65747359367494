import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { route } from '../../../routes';
import { ReducerStates, WalletAssetTransaction } from '../../../typings';
import { ConditionalElement } from '../ConditionalElement';
import * as walletsApi from '../../../api/wallets';
import { formatCurrency, handleRequestErrors } from '../../../utils';
import { useSelector } from 'react-redux';
import { Modal } from '../Modal';
import { WalletTransactionInfoDialog } from '../TransactionInfoDialog/WalletTransactionInfo';
import chevronDownInv from '../../../assets/chevron-down-inv.svg';
import filterIcon from '../../../assets/filter.svg';
import sortIcon from '../../../assets/sort.svg';
import arrowPrimaryRight from '../../../assets/arrow-primary-right.svg';
import { SearchFilterConfig } from '../SearchFilterConfig';
import { FilterTransactionModal, TransactionsFilterData } from './Modals';
import Gap from '../Gap';
import './styles.scss';

interface OrdersListProps {
  title?: string;
  limit?: number;
  filters?: boolean;
  paginated?: {
    size: number;
    totalItems: number;
  };
}

interface OrderRowProps {
  transaction: WalletAssetTransaction;
}

const OrderRow = (props: OrderRowProps) => {
  const { rates } = useSelector((state: ReducerStates) => state);
  const { reference, status, amount, feeAmount, category, createdAt } = props.transaction;
  const currency = rates.currencies.find((curr) => curr.code === amount.currency);

  const showTxInfoDialog = () => {
    Modal.Open(<WalletTransactionInfoDialog transaction={props.transaction} />, {
      customClass: {
        popup: 'transaction-info-dialog-popup',
        htmlContainer: 'transaction-info-dialog-container',
      },
    });
  };

  return (
    <>
      <tr onClick={() => showTxInfoDialog()}>
        <td className="detail-pointer">
          <div className="illus-wrap">
            <div className="illus">
              <img
                src={`https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/currencies/${currency?.code.toLocaleLowerCase()}.svg`}
                alt=""
              />
            </div>

            <div className="info">
              <div className="fw-bold hidden-768px">
                <span className="truncate-text">{currency?.name || <i>Delisted</i>}</span>
              </div>
              <div className="visible-768px">
                <div className="fw-bold truncate-text">{category}</div>
                <div>
                  <small className="text-muted">{moment(new Date(createdAt)).format('MMM DD, yyyy hh:mm a')}</small>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td className="text-end hidden-768px">
          <span className="truncate-text">{reference}</span>
        </td>
        <td className="text-end hidden-768px">{category}</td>
        <td className="text-end hidden-768px">{status}</td>
        <td
          className="text-end detail-pointer"
          onClick={() => {
            // setShowDetails(!showDetails)
          }}
        >
          <div className="hidden-768px">{amount.currency}</div>
          <div className="visible-768px">
            <div>{currency ? formatCurrency(currency?.type!, amount.value, currency?.code!, currency?.decimals) : '-'}</div>
            <small>{status}</small>
          </div>
        </td>
        <td className="text-end hidden-768px">{amount.value}</td>
        <td className="text-end hidden-768px">{feeAmount.value}</td>
        <td className="text-end hidden-768px" style={{ width: '200px' }}>
          {moment(new Date(createdAt)).format('MMM DD, yyyy hh:mm a')}
        </td>
      </tr>

      {/* <ConditionalElement
        condition={showDetails}
        element={
          <tr className="detailed-view">
            <td colSpan={8}>
              <div className="order-details">
                <div className="detail">
                  <div className="left">Asset:</div>
                  <div className="right">{currency?.name}</div>
                </div>

                <div className="detail">
                  <div className="left">Reference:</div>
                  <div className="right">
                    <div
                      style={{
                        display: 'inline-block',
                        maxWidth: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        textAlign: 'right',
                      }}
                    >
                      <CopiableText text={reference} />
                    </div>
                  </div>
                </div>

                <div className="detail">
                  <div className="left">Fee:</div>
                  <div className="right">
                    {currency ? formatCurrency(feeCurrency?.type!, feeAmount.value, feeCurrency?.code!, feeCurrency?.decimals) : '-'}
                  </div>
                </div>

                <div className="detail">
                  <div className="left">Type:</div>
                  <div className="right">{category}</div>
                </div>
              </div>
            </td>
          </tr>
        }
      /> */}
    </>
  );
};

const OrderRowLoading = () => {
  return (
    <>
      {Array(5)
        .fill(1)
        .map((value, index) => (
          <tr key={`${value}_${index}`}>
            <td className="detail-pointer is-placeholder">
              <div className="illus-wrap">
                <div className="illus">
                  <div className="custom-placeholder"></div>
                </div>

                <div className="info">
                  <div className="fw-bold hidden-768px custom-placeholder"></div>
                  <div className="visible-768px">
                    <div className="fw-bold truncate-text custom-placeholder"></div>
                    <div className="custom-placeholder" style={{ maxWidth: 80 }}></div>
                  </div>
                </div>
              </div>
            </td>
            <td className="text-end hidden-768px is-placeholder">
              <div className="custom-placeholder"></div>
            </td>
            <td className="text-end hidden-768px is-placeholder">
              <div className="custom-placeholder"></div>
            </td>
            <td className="text-end hidden-768px is-placeholder">
              <div className="custom-placeholder"></div>
            </td>
            <td className="text-end detail-pointer is-placeholder">
              <div className="hidden-768px custom-placeholder"></div>
              <div className="visible-768px">
                <div className="custom-placeholder mb-1"></div>
                <div className="custom-placeholder"></div>
              </div>
            </td>
            <td className="text-end hidden-768px is-placeholder">
              <div className="custom-placeholder"></div>
            </td>
            <td className="text-end hidden-768px is-placeholder">
              <div className="custom-placeholder"></div>
            </td>
            <td className="text-end hidden-768px is-placeholder" style={{ width: '200px' }}>
              <div className="custom-placeholder"></div>
            </td>
          </tr>
        ))}
    </>
  );
};

export const OrdersList = (props: OrdersListProps) => {
  const { title, filters = true } = props;
  const [loading, setLoading] = useState(false);
  const [loadingPosition, setLoadingPosition] = useState<'LEFT' | 'RIGHT'>();
  const [page, setPage] = useState(1);
  const [size] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [transactionFilters, setTransactionFilters] = useState<Partial<TransactionsFilterData>>({});
  const [transactions, setTransactions] = useState<WalletAssetTransaction[]>([]);
  const [searchTs, setSearchTs] = useState<NodeJS.Timeout>();

  const pageTotal = size * page > totalItems ? totalItems : size * page;

  useEffect(() => {
    setLoading(true);

    if (loading) {
      return;
    }

    setTransactions([]);

    walletsApi
      .getTransactions({
        page,
        size,
        ...transactionFilters,
      })
      .then((res) => {
        setTransactions(res.records);
        setTotalItems(res.totalItems);
      })
      .catch(handleRequestErrors)
      .finally(() => {
        setLoading(false);
        setLoadingPosition(undefined);
      });
  }, [page, size, transactionFilters]);

  return (
    <div className="table-advanced">
      <div className="row">
        <div className="col" data-vertical_center>
          <div className="col" data-vertical_center>
            <div className="row">
              <div className="col-auto" data-vertical_center>
                <h5>{title || 'Order History'}</h5>
              </div>

              <div className="col"></div>

              <div className="col-md-6">
                <Gap v={1} className="visible-768px" />

                {filters ? (
                  <>
                    <SearchFilterConfig
                      search={{
                        name: 'search',
                        placeholder: 'Search reference, memo, service reference',
                        onChange: (reference) => {
                          clearTimeout(searchTs);
                          setSearchTs(
                            setTimeout(() => {
                              setTransactionFilters({ ...transactionFilters, reference });
                            }, 1000),
                          );
                        },
                      }}
                      filter={{
                        visible: true,
                        buttonText: 'Filter Transactions',
                        onClick: () =>
                          Modal.Open(<FilterTransactionModal filters={transactionFilters} onFilter={setTransactionFilters} />, {
                            customClass: {
                              container: 'modal-mobile-fullscreen',
                              popup: 'max-w-400x',
                            },
                          }),
                      }}
                    />

                    <div className="btn-filters-set hidden">
                      <button className="btn btn-primary btn-outline sort hidden-768px hidden">
                        <img src={sortIcon} alt="sort" /> Sort by
                      </button>

                      <button className="btn btn-primary btn-outline filter hidden-768px hidden">
                        <img src={filterIcon} alt="filter" /> Filter
                      </button>

                      <button className="btn btn-primary report hidden-768px hidden">
                        Download Report <img src={chevronDownInv} alt="report" />
                      </button>
                    </div>
                  </>
                ) : (
                  <Link to={route.dashboard.orderHistory} className="more cursor-pointer">
                    All orders &nbsp;
                    <img src={arrowPrimaryRight} alt="arr" />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Gap v={1} />
      <Gap v={1} className="visible-768px" />

      <div className="table-wrapper history-table">
        <table>
          <thead>
            <tr>
              <th>Asset</th>
              <th className="text-end">Reference</th>
              <th className="text-end">Type</th>
              <th className="text-end">Status</th>
              <th className="text-end">Currency</th>
              <th className="text-end">Amount</th>
              <th className="text-end">Fee</th>
              <th className="text-end">Date</th>
            </tr>
          </thead>

          <tbody>
            <ConditionalElement condition={loading} element={<OrderRowLoading />} />

            {transactions.map((transaction) => (
              <OrderRow key={transaction.reference} transaction={transaction} />
            ))}
          </tbody>
        </table>

        <ConditionalElement
          condition={loading === false && transactions.length === 0}
          element={
            <div className="no-result">
              <h5>You have not yet made any transactions</h5>
            </div>
          }
        />
      </div>

      <Gap v={1} />

      <div className="table-pagination">
        <div className="pagination-wrap">
          <div className="item-total hidden">
            <span className="color-4F4F4F">Rows per page: &nbsp;&nbsp;</span> 8
          </div>

          <ConditionalElement
            condition={totalItems > size}
            element={
              <div className="item-nav">
                <span className="color-4F4F4F">
                  {(page - 1) * size + 1} - {pageTotal} of {totalItems.toLocaleString('en')}
                </span>
                <Gap h={1} />

                <a
                  className={`prev ${loading && loadingPosition === 'LEFT' ? 'loading' : ''}`}
                  onClick={() => {
                    if (loading) {
                      return;
                    }

                    const prevPage = page - 1;

                    if (prevPage <= 0) {
                      return;
                    }

                    setPage(prevPage);
                    setLoadingPosition('LEFT');
                  }}
                />
                <a
                  className={`next ${loading && loadingPosition === 'RIGHT' ? 'loading' : ''}`}
                  onClick={() => {
                    if (loading) {
                      return;
                    }

                    const nextPage = page + 1;

                    if (nextPage > Math.ceil(totalItems / size)) {
                      return;
                    }

                    setPage(nextPage);
                    setLoadingPosition('RIGHT');
                  }}
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};
