import React from 'react';
import { Link } from 'react-router-dom';
import './errors.scss';

export const CrashPage = () => (
  <div className="error-container">
    <div className="centered">
      <div className="centered-content">
        <div className="inner-content">
          <h3>Sadly, this page just crashed</h3>
          <p>We're not sure why yet, but we've been notified and we will fix this as soon as possible.</p>
          <Link to="/" className="btn btn-primary">
            Take me back home
          </Link>
        </div>
      </div>
    </div>
  </div>
);
