import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import serializeForm from 'form-serialize';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import { Header } from '../../components/DashboardHeader';
import { Sidebar } from '../../components/DashboardSidebar';
import { Input } from '../../components/html/Input';
import { SettingsSidebar } from '../../components/SettingsSidebar';
import { useSelector } from 'react-redux';
import { ReducerStates } from '../../../typings/reducer';
import { handleRequestErrors } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as userApi from '../../../api/user';
import * as businessActions from '../../../actions/business';
import Gap from '../../components/Gap';
import '../settings.scss';
import { useDispatch } from 'react-redux';

export const BusinessSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { business } = useSelector((state: ReducerStates) => state);
  const [isSaving, setIsSaving] = useState(false);

  return (
    <>
      <Helmet title="Settings | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="settings-page-wrap">
                  <SettingsSidebar />

                  <div className="settings-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Business</h6>
                      </div>
                    </div>

                    <Gap v={2} className="hidden-768px" />

                    <div className="page-content-inner">
                      <form
                        onSubmit={async (ev) => {
                          ev.preventDefault();

                          const { alias, website } = serializeForm(ev.target, { hash: true });

                          setIsSaving(true);

                          try {
                            const businessInfo = await userApi.updateBusiness({ alias, website });

                            dispatch(businessActions.setBusinessInfo(businessInfo));

                            toast.success('Business was updated successfully.');
                          } catch (err) {
                            handleRequestErrors(err);
                          } finally {
                            setIsSaving(false);
                          }
                        }}
                      >
                        <Input
                          type="text"
                          label="Alias"
                          placeholder="Ex. companycorpllc"
                          name="alias"
                          min={3}
                          maxLength={40}
                          defaultValue={business?.alias}
                        />
                        <small className="text-muted">Will be used in place of your business name.</small>
                        <Gap v={1} />

                        <Input
                          type="url"
                          label="Website"
                          placeholder="Ex. https://example.com"
                          name="website"
                          defaultValue={business?.website}
                          gapbottom={1}
                        />

                        <button disabled={isSaving} type="submit" className="btn btn-primary btn-width-150px btn-full-width-mobile">
                          {isSaving ? 'Please wait...' : 'Update'}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
