import { NotificationAction, NotificationState } from '../typings';

const initialState: NotificationState = {
  settings: {},
};

export default (state = initialState, action: NotificationAction): Partial<NotificationState> => {
  switch (action.type) {
    case 'SET_SETTINGS':
      return {
        ...state,
        settings: action.settings!,
      };
    default:
      return state;
  }
};
