import { WithdrawalAccountDto, WithdrawalAction } from '../typings';

export const setAccounts = (accounts: WithdrawalAccountDto[]): WithdrawalAction => ({
  type: 'SET_ACCOUNTS',
  accounts,
});

export const addAccount = (account: WithdrawalAccountDto): WithdrawalAction => ({
  type: 'ADD_ACCOUNT',
  account,
});

export const removeAccount = (id: string): WithdrawalAction => ({
  type: 'REMOVE_ACCOUNT',
  id,
});
