import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import serializeForm from 'form-serialize';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import config from '../../config';
import { route } from '../../routes';
import Gap from '../components/Gap';
import { Input } from '../components/html/Input';
import { TextArea } from '../components/html/TextArea';
import { handleRequestErrors } from '../../utils';
import { toast } from 'react-toastify';
import * as authApi from '../../api/auth';
import logo from '../../assets/logo-primary.svg';
import './styles.scss';
import { PhoneCodeSelect } from '../components/html/PhoneCodeSelect';

export const RequestAccess = () => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Helmet title="Request Access | Host Capital" />

      <div className="auth-ra-container">
        <div className="centered">
          <div className="centered-content full-width">
            <div className="inner-content">
              <div className="ra-wrap">
                <div className="ra-box">
                  <a href="" className="logo">
                    <img src={logo} alt="logo" />
                  </a>

                  <Gap v={2} />

                  <div className="heading">
                    <h3>Request Access</h3>
                    <p>
                      To learn more about Host Capital, work with us, or explore investment opportunities, please contact us or complete
                      this form.
                    </p>
                  </div>

                  <Gap v={1} />

                  <form
                    onSubmit={(ev) => {
                      ev.preventDefault();

                      const form = ev.target as any;
                      const { firstName, lastName, email, businessName, phoneCode, phone, additionalInfo } = serializeForm(form, {
                        hash: true,
                      });

                      const phoneNumber = phoneCode + phone.replace(/^0+/, '').replace(/\s/g, '');

                      setLoading(true);
                      authApi
                        .requestAccess({ firstName, lastName, email, businessName, phoneNumber, additionalInfo })
                        .then(() => {
                          toast.success(
                            'Your request was sent successfully. We will be in touch via your temporary asset manager to find out what your requirements are.',
                          );
                          form.reset();
                        })
                        .catch((err) => handleRequestErrors(err))
                        .finally(() => setLoading(false));
                    }}
                  >
                    <div className="row">
                      <div className="col">
                        <Input required type="text" name="firstName" placeholder="First Name" minLength={3} maxLength={40} gapbottom={1} />
                      </div>
                      <div className="col">
                        <Input required type="text" name="lastName" placeholder="Last Name" minLength={3} maxLength={40} gapbottom={1} />
                      </div>
                    </div>

                    <Input required type="email" name="email" placeholder="Email" minLength={5} maxLength={80} gapbottom={1} />

                    <div className="row">
                      <div className="col-4">
                        <PhoneCodeSelect name="phoneCode" />
                      </div>

                      <div className="col-8 ps-0">
                        <div className="form-group">
                          <Cleave
                            required
                            name="phone"
                            autoComplete="off"
                            id="input-pri-phone"
                            className="form-control"
                            placeholder="E.g 8051235678"
                            options={{
                              phone: true,
                              phoneRegionCode: 'any',
                            }}
                          />
                          <Gap v={1} />
                        </div>
                      </div>
                    </div>

                    <Input
                      type="text"
                      name="businessName"
                      placeholder="Ex. Company Ltd. (Optional)"
                      minLength={3}
                      maxLength={40}
                      gapbottom={1}
                    />

                    <TextArea name="additionalInfo" placeholder="Please let us know how we can help (Optional)" gapbottom={1}></TextArea>

                    <div>
                      <label>
                        <input required type="checkbox" name="accept_agreement" />
                        &nbsp; I agree to the <a href={config.web.terms}>terms</a> & <a href={config.web.privacy}>conditions</a>
                      </label>
                    </div>

                    <button disabled={loading} type="submit" className="btn btn-primary mt-4 mb-2 btn-submit">
                      {loading ? 'Please wait...' : 'Request access'}
                    </button>
                    <Gap v={2} />

                    <div className="text-center">
                      <span className="text-muted">Already have access?</span> <Link to={route.authentication.signup}>Sign Up</Link>
                    </div>
                  </form>
                </div>

                <div className="illus">
                  <h4>Trusted by 100+ clients</h4>
                  <Gap v={1} />
                  <img src={require('../../assets/person-1.png')} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
