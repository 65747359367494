import { P2pActions, P2pState } from '../typings/p2p';

const initialState: P2pState = {
  profile: undefined,
  requests: [],
  transactions: [],
  disputes: [],
};

export default (state = initialState, action: P2pActions): P2pState => {
  switch (action.type) {
    case 'SET_PROFILE':
      return {
        ...state,
        profile: action.profile,
      };
    case 'SET_REQUESTS':
      return {
        ...state,
        requests: action.requests,
      };
    case 'SET_TRANSACTIONS':
      return {
        ...state,
        transactions: action.transactions,
      };
    case 'ADD_TRANSACTION':
      const newTx = action.transaction!;

      return {
        ...state,
        transactions: [newTx, ...state.transactions!.filter((tx) => tx.id !== newTx.id)],
      };
    case 'SET_DISPUTES':
      return {
        ...state,
        disputes: action.disputes,
      };
    default:
      return state;
  }
};
