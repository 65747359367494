import { route } from '../routes';
import { BusinessKycDetailsDto, LocationData, ProfileKycDetailsDto } from '../typings';

export const profileInfoComplete = (info: ProfileKycDetailsDto): boolean => {
  const { firstName, lastName, primaryPhone, nationality, occupation, location, docs = [] } = info;

  if (!firstName || !lastName || !primaryPhone || !nationality || !occupation) {
    return false;
  }

  if (!(location && location.country && location.address && location.state && location.postCode)) {
    return false;
  }

  if (docs.length === 0) {
    return false;
  }

  return true;
};

export const sourceOfFundsInfoComplete = (info: ProfileKycDetailsDto | BusinessKycDetailsDto) => {
  if (!info?.funds?.sourceOfFunds || !info?.funds?.sourceOfWealth) {
    return false;
  }

  return true;
};

export const tradingActInfoComplete = (info: ProfileKycDetailsDto | BusinessKycDetailsDto) => {
  return !info?.trading?.purposeOfExchange ? false : true;
};

export const businessInfoComplete = (info: BusinessKycDetailsDto) => {
  const { type, name, regNumber, country, email, primaryPhone, location, jurisdictions, docs, stakeholders } = info;

  if (!(location && location.country && location.address && location.state && location.postCode)) {
    return false;
  }

  if (!type || !name || !regNumber || !country || !email || !primaryPhone || jurisdictions.length === 0) {
    return false;
  }

  if (docs.length === 0 || stakeholders.length === 0) {
    return false;
  }

  return true;
};

export const isOnboardingComplete = (type: string, profile: ProfileKycDetailsDto, business?: BusinessKycDetailsDto) => {
  if (!profileInfoComplete(profile)) {
    return false;
  }

  const kycInfo = (type === 'INDIVIDUAL' ? profile : business) as any;

  if (!sourceOfFundsInfoComplete(kycInfo)) {
    return false;
  }

  if (!tradingActInfoComplete(kycInfo)) {
    return false;
  }

  if (type !== 'INDIVIDUAL' && !businessInfoComplete(business!)) {
    return false;
  }

  return true;
};

export const getOnboardingStage = (type: string, profile: ProfileKycDetailsDto, business?: BusinessKycDetailsDto) => {
  if (!type) {
    return route.onboarding.type;
  } else if (type === 'INDIVIDUAL' && !profileInfoComplete(profile)) {
    return route.onboarding.profile;
  } else if ((type === 'CORPORATE' || type === 'TRUST' || type === 'SMSF') && !businessInfoComplete(business!)) {
    return route.onboarding.corporate;
  } else if (!sourceOfFundsInfoComplete((type === 'INDIVIDUAL' ? profile : business) as any)) {
    return route.onboarding.sourceOfFunds;
  } else if (!tradingActInfoComplete((type === 'INDIVIDUAL' ? profile : business) as any)) {
    return route.onboarding.tradingActivities;
  } else {
    return null;
  }
};

export const parseLocationData = (str = ''): LocationData | undefined => {
  const [id, name, iso] = (str || '')
    .split('::')
    .filter(Boolean)
    .map((s) => s.trim());

  if (!id || !name) {
    return undefined;
  }

  const data: any = {
    id: Number(id),
    name,
  };

  if (iso) {
    data['iso'] = iso;
  }

  return data;
};
