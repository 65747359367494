import moment from 'moment';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { BsFillCreditCard2FrontFill, BsThreeDotsVertical } from 'react-icons/bs';
import Menu, { Item as MenuItem } from 'rc-menu';
import RcDropdown from 'rc-dropdown';
import { Helmet } from 'react-helmet';
import { Header } from '../components/DashboardHeader';
import { Sidebar } from '../components/DashboardSidebar';
import { OnboardingNote } from '../components/OnboardingNote';
import { HiPlusSm } from 'react-icons/hi';
import { Modal } from '../components/Modal';
import { CardDto } from '../../typings/cards';
import {
  CardDetailsModel,
  CardTransactionModal,
  CardsFilterData,
  CreateCardModal,
  FilterCardsModal,
  FundCardModal,
  WithdrawCardModal,
} from './Modals';
import { formatCurrency, handleRequestErrors } from '../../utils';
import { ConditionalElement } from '../components/ConditionalElement';
import { Security2faDialog } from '../components/2FADialog';
import { SearchFilterConfig } from '../components/SearchFilterConfig';
import { ReducerStates } from '../../typings';
import * as cardApi from '../../api/cards';
import Gap from '../components/Gap';
import './styles.scss';

interface AssetRowProps {
  card: CardDto;
  onUpdate(data: Partial<CardDto>): void;
}

const CardRow = (props: AssetRowProps) => {
  const { card } = props;
  const { rates } = useSelector((state: ReducerStates) => state);

  const currencyDef = rates.currencies.find((currency) => currency.code === card.currency);

  const freezeCard = () =>
    Modal.Open(
      <Security2faDialog
        operation="CARDS_FREEZE"
        handleSecurity={async (security, callback) => {
          cardApi
            .freezeCard(card.id, security)
            .then(() => {
              props.onUpdate({ status: 'FROZEN' });
              Modal.CloseAll();
            })
            .catch((err) => {
              callback(null, err);
              handleRequestErrors(err);
            });
        }}
      />,
      {
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'modal-mobile-fullscreen',
          popup: 'max-w-350x',
        },
      },
    );

  const unfreezeCard = () =>
    Modal.Open(
      <Security2faDialog
        operation="CARDS_UNFREEZE"
        handleSecurity={async (security, callback) => {
          cardApi
            .unfreezeCard(card.id, security)
            .then(() => {
              props.onUpdate({ status: 'ACTIVE' });
              Modal.CloseAll();
            })
            .catch((err) => {
              callback(null, err);
              handleRequestErrors(err);
            });
        }}
      />,
      {
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'modal-mobile-fullscreen',
          popup: 'max-w-350x',
        },
      },
    );

  const terminateCard = () =>
    Modal.Open(
      <Security2faDialog
        operation="CARDS_TERMINATE"
        handleSecurity={async (security, callback) => {
          cardApi
            .terminateCard(card.id, security)
            .then(() => {
              props.onUpdate({ status: 'TERMINATED' });
              Modal.CloseAll();
            })
            .catch((err) => {
              callback(null, err);
              handleRequestErrors(err);
            });
        }}
      />,
      {
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'modal-mobile-fullscreen',
          popup: 'max-w-350x',
        },
      },
    );

  const getCardById = (cardId: string) => cardApi.getCardById(cardId).then((data) => props.onUpdate(data));

  const balanceStr = formatCurrency(currencyDef?.type!, card.balance, card.currency, currencyDef?.decimals!);

  return (
    <tr
      onClick={() =>
        // prettier-ignore
        !['TERMINATED', 'CREATING'].includes(card.status) ? Modal.Open(<CardDetailsModel card={card} />, {
          customClass: {
            container: 'modal-mobile-fullscreen modal-card-display',
            popup: 'max-w-400x',
          },
        }) : void 0
      }
    >
      <td>
        <BsFillCreditCard2FrontFill
          style={{ fontSize: '1.5em', display: 'inline-block', verticalAlign: 'top', marginTop: '1px', color: '#6932d4' }}
        />{' '}
        &nbsp;{' '}
        <b>
          {card.holder.firstName} {card.holder.lastName}
        </b>
        <div className="color-r7-g12-b66-a60" style={{ paddingLeft: '38px' }}>
          {
            // prettier-ignore
            card.label ? `${card.label} ${card.pan ? `(••${card.pan?.slice(-4)})` : ''}` : card.pan ? `•••• •••• •••• ${card.pan?.slice(-4)}` : ''
          }
          <br />
          <small className="visible-768px">Available balance: {balanceStr}</small>
        </div>
      </td>
      <td className="text-end hidden-768px">{card.status}</td>
      <td className="text-end hidden-768px">
        {card.pan?.slice(0, 6)}••{card.pan?.slice(-4)}
      </td>
      <td className="text-end hidden-768px">{card.scheme}</td>
      <td className="text-end hidden-768px">{balanceStr}</td>
      <td className="text-end hidden-768px">{moment.utc(card.createdAt).format('MMM DD, yyyy hh:mm a')}</td>
      <td className="text-end" onClick={(ev) => ev.stopPropagation()}>
        {!['TERMINATED', 'CREATING'].includes(card.status) ? (
          <RcDropdown
            trigger={['click']}
            overlay={
              <Menu>
                {card.status === 'ACTIVE' ? (
                  <MenuItem
                    key={`card-${card.id}-fund`}
                    onClick={() =>
                      Modal.Open(<FundCardModal card={card} onUpdate={getCardById} />, {
                        customClass: {
                          container: 'modal-mobile-fullscreen',
                          popup: 'max-w-400x card-txn-modal-popup',
                          htmlContainer: 'card-txn-modal-container',
                        },
                      })
                    }
                  >
                    Fund
                  </MenuItem>
                ) : null}

                {card.status === 'ACTIVE' && card.balance > 0 ? (
                  <MenuItem
                    key={`card-${card.id}-withdraw`}
                    onClick={() =>
                      Modal.Open(<WithdrawCardModal card={card} onUpdate={getCardById} />, {
                        customClass: {
                          container: 'modal-mobile-fullscreen',
                          popup: 'max-w-400x',
                        },
                      })
                    }
                  >
                    Withdraw
                  </MenuItem>
                ) : null}

                {card.status === 'ACTIVE' ? (
                  <MenuItem
                    key={`card-${card.id}-freeze`}
                    onClick={() =>
                      Modal.Confirm(
                        'Freeze card',
                        <>
                          <br />
                          Are you sure you want to freeze this card? Once frozen, payments charged to your card will be automatically
                          declined.
                          <br /> Continue?
                        </>,
                      ).then((b) => (b ? freezeCard() : void 0))
                    }
                  >
                    Freeze
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={`card-${card.id}-unfreeze`}
                    onClick={() =>
                      Modal.Confirm(
                        'Unfreeze card',
                        <>
                          <br />
                          Are you sure you want to unfreeze this card?.
                          <br /> Continue?
                        </>,
                      ).then((b) => (b ? unfreezeCard() : void 0))
                    }
                  >
                    Unfreeze
                  </MenuItem>
                )}

                <MenuItem
                  className=""
                  key={`card-${card.id}-transaction`}
                  onClick={() => {
                    Modal.Open(<CardTransactionModal card={card} />, {
                      customClass: {
                        container: 'modal-mobile-fullscreen',
                        popup: 'max-w-500x',
                      },
                    });
                  }}
                >
                  Transactions
                </MenuItem>

                {card.status === 'ACTIVE' ? (
                  <MenuItem
                    key={`card-${card.id}-terminate`}
                    onClick={() =>
                      Modal.Confirm(
                        'Terminate card',
                        <>
                          <br />
                          <p>Are you sure you want to terminate this card?.</p>
                          <p>Please make sure to withdraw your funds from this card before terminating.</p>
                          <p>Note that this action cannot be undone.</p>
                          <p>Continue?</p>
                        </>,
                      ).then((b) => (b ? terminateCard() : void 0))
                    }
                  >
                    Terminate
                  </MenuItem>
                ) : null}
              </Menu>
            }
            alignPoint
          >
            <a style={{ display: 'inline-block', width: 30, height: 30, cursor: 'pointer', textAlign: 'center' }}>
              <BsThreeDotsVertical />
            </a>
          </RcDropdown>
        ) : null}
      </td>
    </tr>
  );
};

const CardRowLoading = () => {
  return (
    <>
      {Array(5)
        .fill(1)
        .map((value, index) => (
          <tr key={`card_dummy_${value}_${index}`}>
            <td className="is-placeholder">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
          </tr>
        ))}
    </>
  );
};

export const Cards = () => {
  const [loading, setLoading] = useState(false);
  const [loadingPosition, setLoadingPosition] = useState<'LEFT' | 'RIGHT'>();
  const [page, setPage] = useState(1);
  const [size] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [cardFilters, setCardFilters] = useState<Partial<CardsFilterData>>({});
  const [cards, setCards] = useState<CardDto[]>([]);
  const [searchTs, setSearchTs] = useState<NodeJS.Timeout>();

  const pageTotal = size * page > totalItems ? totalItems : size * page;

  useEffect(() => {
    setLoading(true);

    if (loading) {
      return;
    }

    setCards([]);

    cardApi
      .getCards({
        page,
        size,
        ...cardFilters,
      })
      .then((res) => {
        setCards(res.records);
        setTotalItems(res.totalItems);
      })
      .catch(handleRequestErrors)
      .finally(() => {
        setLoading(false);
        setLoadingPosition(undefined);
      });
  }, [page, size, cardFilters]);

  return (
    <>
      <Helmet title="Cards | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap v-cards-dashboard">
              <div className="content-inner">
                <Header withTicker={false} />

                <Gap v={2} />

                <div className="v-cards-page-wrap">
                  <OnboardingNote gapBottom={2} />

                  <div className="row">
                    <div className="col-auto" data-vertical_center>
                      <h3 className="p-0 m-0">Cards</h3>
                      <Gap v={2} className="visible-768px" />
                    </div>

                    <div className="col-auto">
                      <button
                        className="btn btn-primary  btn-float-mobile"
                        onClick={() =>
                          Modal.Open(<CreateCardModal onCreated={(card) => setCards([card, ...cards])} />, {
                            customClass: {
                              container: 'modal-mobile-fullscreen',
                              popup: 'max-w-400x',
                            },
                          })
                        }
                      >
                        <span className="text hidden-768px">Create a card</span>
                        <span className="icon visible-768px">
                          <HiPlusSm />
                        </span>
                      </button>
                    </div>

                    <div className="col"></div>

                    <div className="col-md-6">
                      <SearchFilterConfig
                        search={{
                          name: 'search',
                          placeholder: 'Search card Id, reference, service reference',
                          onChange: (reference) => {
                            clearTimeout(searchTs);
                            setSearchTs(
                              setTimeout(() => {
                                setCardFilters({ ...cardFilters, reference });
                              }, 1000),
                            );
                          },
                        }}
                        filter={{
                          visible: false,
                          buttonText: 'Filter Cards',
                          onClick: () =>
                            Modal.Open(<FilterCardsModal filters={cardFilters} onFilter={setCardFilters} />, {
                              customClass: {
                                container: 'modal-mobile-fullscreen',
                                popup: 'max-w-400x',
                              },
                            }),
                        }}
                      />
                    </div>
                  </div>

                  <Gap v={2} className="hidden-768px" />
                  <Gap v={1} className="visible-768px" />

                  <div className="table-advanced">
                    <div className="table-wrapper table-wrapper-cards">
                      <table className="has-details">
                        <thead>
                          <tr>
                            <th>Cardholder</th>
                            <th className="text-end">Status</th>
                            <th className="text-end">Pan</th>
                            <th className="text-end">Type</th>
                            <th className="text-end">Balance</th>
                            <th className="text-end">Date created</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody>
                          <ConditionalElement condition={loading} element={<CardRowLoading />} />

                          {cards.map((card) => (
                            <CardRow
                              key={card.id}
                              card={card}
                              onUpdate={(data) =>
                                setCards(
                                  cards.map((cardData) => {
                                    if (cardData.id === card.id) {
                                      return {
                                        ...cardData,
                                        ...data,
                                      };
                                    }

                                    return cardData;
                                  }),
                                )
                              }
                            />
                          ))}
                        </tbody>
                      </table>

                      <ConditionalElement
                        condition={!loading && cards.length === 0}
                        element={
                          <div className="no-result">
                            <h5>You haven't created any cards yet</h5>
                          </div>
                        }
                      />
                    </div>

                    <Gap v={1} />

                    <div className="table-pagination">
                      <div className="pagination-wrap">
                        <div className="item-total hidden">
                          <span className="color-4F4F4F">Rows per page: &nbsp;&nbsp;</span> 8
                        </div>

                        <ConditionalElement
                          condition={totalItems > size}
                          element={
                            <div className="item-nav">
                              <span className="color-4F4F4F">
                                {(page - 1) * size + 1} - {pageTotal} of {totalItems.toLocaleString('en')}
                              </span>
                              <Gap h={1} />

                              <a
                                className={`prev ${loading && loadingPosition === 'LEFT' ? 'loading' : ''}`}
                                onClick={() => {
                                  if (loading) {
                                    return;
                                  }

                                  const prevPage = page - 1;

                                  if (prevPage <= 0) {
                                    return;
                                  }

                                  setPage(prevPage);
                                  setLoadingPosition('LEFT');
                                }}
                              />
                              <a
                                className={`next ${loading && loadingPosition === 'RIGHT' ? 'loading' : ''}`}
                                onClick={() => {
                                  if (loading) {
                                    return;
                                  }

                                  const nextPage = page + 1;

                                  if (nextPage > Math.ceil(totalItems / size)) {
                                    return;
                                  }

                                  setPage(nextPage);
                                  setLoadingPosition('RIGHT');
                                }}
                              />
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
