import React from 'react';
import { useSelector } from 'react-redux';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { displayCurrencies, USD, USDT, UseLocalCurrency } from '../../../constants';
import { ReducerStates } from '../../../typings';
import { formatCurrency } from '../../../utils';
import { Modal } from '../Modal';
import './styles.scss';
import { CreateAssetDialog } from '../CreateAssetDialog';

export const CryptoCards = () => {
  const { kyc, rates, wallets } = useSelector((state: ReducerStates) => state);
  const localCurrency = kyc.business?.currency || kyc.individual?.currency;
  const defaultCurrency = UseLocalCurrency ? localCurrency : rates.currencies.find((currencyDef) => currencyDef.code === USD);
  const useDisplayCurrencies = [...displayCurrencies];

  return (
    <div className="price-cards">
      <div className="inner">
        {[localCurrency?.code, ...useDisplayCurrencies]
          .map((dispCurrency) => wallets.find(({ currency }) => currency.code === dispCurrency)!)
          .filter(Boolean)
          .map(({ currency }) => {
            // prettier-ignore
            const conversion = currency.type === 'CRYPTO' ? rates.conversions.find((conv) => conv.fromCurrency === USDT && conv.toCurrency === currency.code) : rates.conversions.find((conv) => conv.fromCurrency === currency.code && conv.toCurrency === USDT);

            const percentageChange = conversion ? conversion.percentageChange : '0';
            const isNegativeChange = conversion?.percentageChange.toString().startsWith('-');
            const isNoChange = conversion?.percentageChange === 0;

            // prettier-ignore
            const price = conversion ? formatCurrency(defaultCurrency!.type, 1/conversion.rate, currency.type === 'FIAT' ? currency.code : defaultCurrency!.code, currency.type === 'FIAT' ? currency.decimals : defaultCurrency?.decimals) : <span className='custom-placeholder'></span>;

            if (!conversion) {
              return null;
            }

            return (
              <div key={currency.code} className="card-wrap">
                <div className="illus">
                  <img
                    src={`https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/currencies/${currency.code.toLocaleLowerCase()}.svg`}
                    alt=""
                  />
                </div>
                <div className="info">
                  <div className="pair">
                    <span className="a">{currency.code}</span>
                    <span className="i"></span>
                    <span className="b">{USDT}</span>
                  </div>
                  <div className="price">{price}</div>
                  <div className="change">
                    {conversion ? (
                      <span className={isNegativeChange ? 'negative-change' : isNoChange ? '' : 'positive-change'}>
                        {isNegativeChange || isNoChange ? '' : '+'}
                        {percentageChange}%
                      </span>
                    ) : (
                      <span className="c-info custom-placeholder"></span>
                    )}{' '}
                    <span className="c-info">Last 24H</span>
                  </div>
                </div>
              </div>
            );
          })}

        <div
          className="card-wrap card-add-asset"
          onClick={() =>
            Modal.Open(<CreateAssetDialog />, {
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: 'modal-mobile-fullscreen',
                popup: 'max-w-400x',
              },
            })
          }
        >
          <div>
            <IoIosAddCircleOutline className="icon" />
            <p>Add Asset</p>
          </div>
        </div>
      </div>
    </div>
  );
};
