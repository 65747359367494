import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { AiTwotoneCopy } from 'react-icons/ai';
import { BsLink45Deg, BsPrinter } from 'react-icons/bs';
import { formatCurrency, handleRequestErrors } from '../../../utils';
import { WalletAssetTransaction, WalletTransactionCategory, WalletAssetTransactionType } from '../../../typings';
import { baseStore } from '../../../store';
import { ConditionalElement } from '../ConditionalElement';
import { CopiableText } from '../CopiableText';
import { Button } from '../html/Button';
import { CardDto } from '../../../typings/cards';
import * as cardsApi from '../../../api/cards';
import Gap from '../Gap';
import './styles.scss';

interface Props {
  transaction: WalletAssetTransaction;
}

const catogoryToText = (category: WalletTransactionCategory, reference: string, type: WalletAssetTransactionType): string => {
  switch (category) {
    case 'CASH_DEPOSIT':
      return 'Cash Deposit';
    case 'CASH_WITHDRAWAL':
      return 'Cash Withdrawal';
    case 'COIN_SWAP':
      return 'Crypto Swap';
    case 'CRYPTO_DEPOSIT':
      return 'Crypto Deposit';
    case 'CRYPTO_WITHDRAWAL':
      return 'Crypto Withdrawal';
    case 'TRANSFER_RECEIVED':
      return 'Transfer Received';
    case 'TRANSFER_SENT':
      return 'Transfer Sent';
    case 'DISTRIBUTION':
      return type === 'CREDIT' ? 'Credit Distribution' : 'Debit Distribution';
    case 'P2P_TRANSFER':
      return type === 'CREDIT' ? 'P2P Credit' : 'P2P Debit';
    case 'SWAP':
      return 'Swap Transaction';
    default:
      return reference.startsWith('REVSL') ? 'Reversal Transaction' : 'Transaction';
  }
};

export const WalletTransactionInfoDialog = ({ transaction }: Props) => {
  const { reference, category, type, status, createdAt, amount, feeAmount, memo, serviceReference } = transaction;
  const { rates } = baseStore.getState();
  const [cardInfo, setCardInfo] = useState<CardDto>();

  const currency = rates?.currencies.find((curr) => amount.currency === curr.code);

  const fromMetadata = (keyName: string): string => transaction.metadata.find(({ name }) => name === keyName)?.value!;

  const getExplorerUrl = () => {
    if (!(category === 'CRYPTO_DEPOSIT' || category === 'CRYPTO_WITHDRAWAL')) return '';

    const network = fromMetadata('network');
    const txId = fromMetadata('transactionId');

    const urlVar =
      currency?.network === network ? currency.explorerUrl : currency?.networks.find((n) => n.network === network)?.explorerUrl;

    if (!urlVar) return '';

    return urlVar.replace('__TX_HASH__', txId);
  };

  useEffect(() => {
    const cardId = fromMetadata('cardId');

    if (cardId && !cardInfo) {
      cardsApi.getCardById(cardId).then(setCardInfo).catch(handleRequestErrors);
    }
  }, [transaction.metadata]);

  return (
    <>
      <div className="head">
        <div className="illus">
          <img
            src={`https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/currencies/${currency?.code.toLocaleLowerCase()}.svg`}
            alt=""
          />
        </div>
        <h4 className={status.toLowerCase()}>{catogoryToText(category, reference, type)}</h4>
      </div>

      <Gap v={1} />

      <div className="content">
        <div className="info-row">
          <div className="left">
            <div className="info-title">Reference</div>
          </div>
          <div className="right">
            <div className="info-content">
              <CopiableText text={reference} />
            </div>
          </div>
        </div>

        <div className="info-row">
          <div className="left">
            <div className="info-title">Date</div>
          </div>
          <div className="right">
            <div className="info-content">{moment(createdAt).format('MMM DD, yyyy hh:mm a')}</div>
          </div>
        </div>

        <div className="info-row">
          <div className="left">
            <div className="info-title">Type</div>
            <div className="info-content">{type}</div>
          </div>
          <div className="right">
            <div className="info-title">Status</div>
            <div className={`info-content ${status.toLowerCase()}`}>{status}</div>
          </div>
        </div>

        <div className="info-row">
          <div className="left">
            <div className="info-title">Amount</div>
            <div className="info-content">
              {formatCurrency(currency?.type || 'FIAT', amount.value, amount.currency, currency?.decimals)}
            </div>
          </div>
          <div className="right">
            <div className="info-title">Fee</div>
            <div className="info-content">
              {formatCurrency(currency?.type || 'FIAT', feeAmount.value, feeAmount.currency, currency?.decimals)}
            </div>
          </div>
        </div>

        <ConditionalElement
          condition={category === 'CRYPTO_WITHDRAWAL'}
          element={
            <div className="info-row">
              <div className="left">
                <div className="info-title">Network</div>
              </div>
              <div className="right">
                <div className="info-content">{fromMetadata('network')}</div>
              </div>
            </div>
          }
        />

        <ConditionalElement
          condition={category === 'CRYPTO_WITHDRAWAL' || category === 'CRYPTO_DEPOSIT'}
          element={
            <div className="info-row">
              <div className="left">
                <div className="info-title">Address</div>
              </div>
              <div className="right">
                <div className="info-content">
                  <CopiableText text={fromMetadata('address')} /> <AiTwotoneCopy />
                </div>
              </div>
            </div>
          }
        />

        <ConditionalElement
          condition={!!memo}
          element={
            <div className="info-row">
              <div className="left">
                <div className="info-title">Memo</div>
              </div>
              <div className="right">
                <div className="info-content">{memo}</div>
              </div>
            </div>
          }
        />

        <ConditionalElement
          condition={(category === 'CRYPTO_WITHDRAWAL' || category === 'CRYPTO_DEPOSIT') && !!fromMetadata('transactionId')}
          element={
            <div className="info-row">
              <div className="left">
                <div className="info-title">Transaction ID</div>
              </div>
              <div className="right">
                <div className="info-content">
                  <a rel="noopener noreferrer" target="_blank" href={getExplorerUrl()}>
                    {fromMetadata('transactionId')}
                  </a>{' '}
                  <BsLink45Deg />
                </div>
              </div>
            </div>
          }
        />

        <ConditionalElement
          condition={!!fromMetadata('recipient_bank_name')}
          element={
            <>
              <div className="info-row">
                <div className="left">
                  <div className="info-title">Recipient</div>
                </div>
                <div className="right">
                  <b>{fromMetadata('recipient_account_name')}</b>
                </div>
              </div>

              <div className="info-row">
                <div className="left">
                  <div className="info-title">Bank</div>
                </div>
                <div className="right">{fromMetadata('recipient_bank_name')}</div>
              </div>

              <div className="info-row">
                <div className="left">
                  <div className="info-title">Account No.</div>
                </div>
                <div className="right">{fromMetadata('recipient_account_number')}</div>
              </div>
            </>
          }
        />

        <ConditionalElement
          condition={category === 'TRANSFER_SENT' && !!fromMetadata('recipient')}
          element={
            <div className="info-row">
              <div className="left">
                <div className="info-title">Recipient</div>
              </div>
              <div className="right">
                <div className="info-content">{fromMetadata('recipient')}</div>
              </div>
            </div>
          }
        />

        <ConditionalElement
          condition={category === 'TRANSFER_RECEIVED' && !!fromMetadata('sender')}
          element={
            <div className="info-row">
              <div className="left">
                <div className="info-title">Sender</div>
              </div>
              <div className="right">
                <div className="info-content">{fromMetadata('sender')}</div>
              </div>
            </div>
          }
        />

        <ConditionalElement
          condition={!!serviceReference}
          element={
            <div className="info-row block">
              <div className="left">
                <div className="info-title">Service Reference</div>
              </div>
              <div className="right">
                <div className="info-content">
                  <CopiableText text={serviceReference} />
                </div>
              </div>
            </div>
          }
        />

        <ConditionalElement
          condition={!!cardInfo}
          element={
            <div className="info-row block">
              <div className="left">
                <div className="info-title">Card</div>
              </div>

              <div className="right">
                <div className="info-content">
                  <CopiableText text={cardInfo?.id!} />{' '}
                  <b>
                    ({cardInfo?.pan.slice(0, 6)}******{cardInfo?.pan.slice(-4)})
                  </b>
                </div>
              </div>
            </div>
          }
        />
      </div>

      <div className="buttons hidden">
        <Button
          className="btn btn-primary full-width"
          text={
            <>
              <span>
                Print &nbsp;
                <BsPrinter />
              </span>
            </>
          }
          onClick={() => window.print()}
        />
      </div>
    </>
  );
};
