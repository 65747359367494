/* eslint-disable new-cap */
/* eslint-disable-next-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { CloseAll, DialogButtons } from './index';

interface ConfirmModalProps {
  type: 'normal' | 'delete';
  title?: string;
  message?: string | JSX.Element | null;
  buttons?: DialogButtons;
  onCancel(b: boolean): void;
  onAccept(b: boolean): void;
}

export const ConfirmModal = (props: ConfirmModalProps) => {
  const {
    type,
    title,
    message,
    onCancel,
    onAccept,
    buttons = {
      accept: {
        text: '',
        type: 'normal',
      },
      cancel: {
        text: '',
        type: 'normal',
      },
    },
  } = props;

  return (
    <>
      <div className="text-left">
        {title && (
          <>
            <h4>{title}</h4>
          </>
        )}

        {message && (
          <>
            <p>{message}</p>
          </>
        )}

        <div className="row">
          {buttons.accept && (
            <div className="col-auto">
              <a
                className={`btn ${(buttons.accept.type || type) === 'delete' ? 'btn-danger' : 'btn-primary'}`}
                onClick={() => {
                  CloseAll();
                  onAccept(true);
                }}
                style={{ paddingLeft: '25px', paddingRight: '25px' }}
              >
                {buttons.accept.text ? buttons.accept.text : buttons.accept.type === 'delete' ? 'Yes, Delete' : 'Yes, Continue'}
              </a>
            </div>
          )}

          {buttons.cancel && (
            <div className="col-auto ps-0">
              <a
                className={`btn btn-default-type ${(buttons.cancel.type || type) === 'delete' ? 'btn-danger' : 'btn-grey'}`}
                onClick={() => {
                  CloseAll();
                  onCancel(false);
                }}
                style={{ paddingLeft: '25px', paddingRight: '25px' }}
              >
                {buttons.cancel.text ? buttons.cancel.text : 'Cancel'}
              </a>
            </div>
          )}

          <div className="col"></div>
        </div>
      </div>
    </>
  );
};
