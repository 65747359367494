import config from '../config';
import Request from '../helpers/request';
import { StatusDto, WithSecurity } from '../typings';
import { DepositAccountDto, DepositAccountsDto, DepositChannelDto, DepositMethodType, DepositTransactionDto } from '../typings/deposit';

export const prepare = async (
  assetId: string,
  amount: number,
  currency: string,
  methodId: DepositMethodType,
): Promise<DepositTransactionDto> => {
  return await Request.postWithUserToken<DepositTransactionDto>(`${process.env.REACT_APP_API_URL}/deposit/prepare`, {
    data: {
      assetId,
      amount,
      currency,
      methodId,
    },
  });
};

export const getChannel = async (assetId: string, currency: string): Promise<DepositChannelDto> => {
  return await Request.postWithUserToken<DepositChannelDto>(`${process.env.REACT_APP_API_URL}/deposit/me/channel`, {
    data: {
      assetId,
      currency,
    },
  });
};

export const verify = async (reference: string): Promise<StatusDto> => {
  return await Request.postWithUserToken<StatusDto>(`${process.env.REACT_APP_API_URL}/deposit/verify`, {
    data: {
      reference,
    },
  });
};

export const getAccounts = async (): Promise<DepositAccountsDto> => {
  return await Request.getWithUserToken<DepositAccountsDto>(`${config.api.deposit}/me/accounts`);
};

export const addAccount = async (data: WithSecurity<Partial<DepositAccountDto>>): Promise<DepositAccountDto> => {
  return await Request.postWithUserToken<DepositAccountDto>(`${config.api.deposit}/me/accounts`, {
    data,
  });
};

export const removeAccount = async (id: string, security: string): Promise<StatusDto> => {
  return await Request.deleteWithUserToken<StatusDto>(`${config.api.deposit}/me/accounts/${id}`, {
    data: {
      security,
    },
  });
};
