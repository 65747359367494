import React from 'react';
import copy from 'clipboard-copy';
import { toast } from 'react-toastify';

interface CopiableTextProps {
  text: string;
  altText?: string;
  className?: string;
}

export const CopiableText = ({ text, altText, className }: CopiableTextProps) => {
  const copyText = altText || text;

  return (
    <span
      style={{
        borderBottom: '1px dotted black',
        cursor: 'pointer',
      }}
      className={`cursor-pointer ${className || ''}`}
      onClick={() => {
        copy(copyText);
        toast.info(`${copyText} was copied to your cliboard`);
      }}
      title="Click to copy"
    >
      {text}
    </span>
  );
};
