import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { setCities, setStates } from '../../actions/lists';
import { LocationData } from '../../typings';
import { handleRequestErrors } from '../../utils';
import * as listsApi from '../../api/lists';
import Gap from './Gap';
import { baseStore } from '../../store';

interface LocationSelectValue {
  id: number;
  label: string;
  value: string;
}

interface LocationSelectValues {
  country?: LocationData & { iso?: string };
  state?: LocationData;
  city?: LocationData;
}

interface LocationSelectProps {
  disabled?: boolean;
  select?: ('countries' | 'states' | 'cities')[];
  names?: {
    country?: string;
    state?: string;
    city?: string;
  };
  labels?: {
    country?: string;
    state?: string;
    city?: string;
  };
  onlySupported?: boolean;
  onChange?(data: LocationSelectValues): void;
  values?: LocationSelectValues;
  gapBottom?: number;
}

export const fromLocationValue = (value: string): (LocationData & { iso?: string }) | undefined => {
  if (!value) {
    return;
  }

  const [id, name, iso] = value.trim().split(',').filter(Boolean);

  return {
    id: Number(id),
    name,
    iso,
  };
};

export const getLocationValue = (value: string): LocationSelectValue | undefined => {
  if (!value) return undefined;

  const data = fromLocationValue(value)!;

  return {
    id: data.id,
    label: data.name,
    value: Object.entries(data)
      .map((entry) => entry[1])
      .filter(Boolean)
      .join(','),
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const LocationSelect = (props: LocationSelectProps) => {
  const { lists } = baseStore.getState();
  const { values, select, onChange = () => {}, names = {}, labels = {}, disabled } = props;

  const [country, setCountry] = useState<string>();
  const [state, setState] = useState<string>();
  const [city, setCity] = useState<string>();
  const [stateOptions, setStateOptions] = useState<LocationSelectValue[]>([]);
  const [cityOptions, setCityOptions] = useState<LocationSelectValue[]>([]);

  const showCountries = !select || select.includes('countries');
  const showStates = !select || select.includes('states');
  const showCities = !select || select.includes('cities');

  const getData = (_country?: string, _state?: string, _city?: string): LocationSelectValues => {
    return {
      country: fromLocationValue(_country! || country!),
      state: fromLocationValue(_state! || state!),
      city: fromLocationValue(_city! || city!),
    };
  };

  useEffect(() => {
    if (!values?.country) return;

    setCountry(
      Object.entries(values.country)
        .map((entry) => entry[1])
        .filter(Boolean)
        .join(','),
    );
  }, [values?.country]);

  useEffect(() => {
    if (!values?.state) return;

    setState(
      Object.entries(values.state)
        .map((entry) => entry[1])
        .filter(Boolean)
        .join(','),
    );
  }, [values?.state]);

  useEffect(() => {
    if (!values?.city) return;

    setCity(
      Object.entries(values.city)
        .map((entry) => entry[1])
        .filter(Boolean)
        .join(','),
    );
  }, [values?.city]);

  useEffect(() => {
    const id = fromLocationValue(country!)?.id!;

    if (!id) return;

    setStateOptions(
      (lists.states![id] || []).map(({ id, name }) => ({
        id,
        label: name,
        value: id + ',' + name,
      })),
    );
  }, [country, lists.states]);

  useEffect(() => {
    const id = fromLocationValue(state!)?.id!;

    if (!id) return;

    setCityOptions(
      (lists.cities![id] || []).map(({ id, name }) => ({
        id,
        label: name,
        value: id + ',' + name,
      })),
    );
  }, [state, lists.cities]);

  return (
    <>
      {showCountries && (
        <div className="form-group">
          <label htmlFor="sel-dob color-032940" className="mb-2">
            {labels.country || 'Country'} <span className="color-e25757">*</span>
          </label>

          <Select
            isDisabled={disabled}
            id="sel-country"
            name={names.country || 'country'}
            placeholder="Select a country"
            options={lists
              .countries!.filter((c) => (props.onlySupported ? c.isSupported === props.onlySupported : true))
              .map(({ id, name, isoCode }) => ({
                id,
                label: name,
                value: id + ',' + name + ',' + isoCode,
              }))}
            value={getLocationValue(country!)}
            onChange={(ev: any) => {
              setCountry(ev.value);
              setState('');
              setCity('');

              onChange(getData(ev.value));

              if (showStates) {
                listsApi
                  .getStates(ev.id)
                  .then((data) => baseStore.dispatch(setStates(ev.id, data.states || [])))
                  .catch((err) => handleRequestErrors(err, 'Some error occurred. Could not fetch states'));
              }
            }}
          />

          <Gap v={props.gapBottom || 0} />
        </div>
      )}

      <div className="row">
        <div className="col-md">
          {showStates && (
            <>
              <div className="form-group">
                <label htmlFor="input-state color-032940" className="mb-2">
                  State <span className="color-e25757">*</span>
                </label>

                <Select
                  isDisabled={disabled}
                  id="sel-state"
                  name={names.state || 'state'}
                  placeholder="Select a state"
                  options={stateOptions}
                  value={getLocationValue(state!)}
                  onMenuOpen={() => {
                    if (values?.country && !lists?.states![values?.country?.id]) {
                      const { id } = values?.country;

                      listsApi
                        .getStates(id)
                        .then((data) => baseStore.dispatch(setStates(id, data.states)))
                        .catch((err) => handleRequestErrors(err, 'Some error occurred. Could not fetch states'));
                    }
                  }}
                  onChange={(ev: any) => {
                    setState(ev.value), setCity('');
                    onChange(getData(ev.value));

                    if (showCities) {
                      listsApi
                        .getCities(ev.id)
                        .then((data) => baseStore.dispatch(setCities(ev.id, data.cities)))
                        .catch((err) => handleRequestErrors(err, 'Some error occurred. Could not fetch cities'));
                    }
                  }}
                />
              </div>

              <Gap v={props.gapBottom || 0} />
            </>
          )}
        </div>

        <div className="col-md">
          {showCities && (
            <>
              <div className="form-group">
                <label htmlFor="sel-cty color-032940" className="mb-2">
                  City
                </label>

                <Select
                  isClearable
                  isDisabled={disabled}
                  id="sel-city"
                  name={names.city || 'city'}
                  placeholder="Select a city"
                  options={cityOptions}
                  value={getLocationValue(city!)}
                  onMenuOpen={() => {
                    if (values?.state && !lists?.cities![values.state.id]) {
                      const { id } = values.state;

                      listsApi
                        .getCities(id)
                        .then((data) => baseStore.dispatch(setCities(id, data.cities)))
                        .catch((err) => handleRequestErrors(err, 'Some error occurred. Could not fetch cities'));
                    }
                  }}
                  onChange={(ev: any) => {
                    setCity(ev.value);
                    onChange(getData(ev.value));
                  }}
                />
              </div>

              <Gap v={props.gapBottom || 0} />
            </>
          )}
        </div>
      </div>
    </>
  );
};
