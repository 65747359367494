import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { route } from '../../routes';
import { Header } from '../components/DashboardHeader';
import Gap from '../components/Gap';
import { Modal } from '../components/Modal';
import { Sidebar } from '../components/OnboardingSidebar';
import * as kycApi from '../../api/kyc';
import { handleRequestErrors } from '../../utils';

export const OnboardingTermsOfService = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet title="HostFi Services Ltd" />

      <div className="onboarding-wrapper">
        <Sidebar />

        <div className="onboarding-content-wrap">
          <Header isOnboarding onLeftMenuClick={() => {}} />

          <div className="content-inner">
            <div className="centered">
              <div className="centered-content">
                <Gap v={1} />
                <h3>Terms of Service</h3>
                <Gap v={1} />

                <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                  <li>
                    <p>
                      By accessing the websites at https://*.hostcap.io/ or any of its subdomains (the "Sites"), you are agreeing to be
                      bound by these terms of service, all applicable laws, and regulations, and agree that you are responsible for
                      compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using
                      or accessing this site. The materials contained in the Sites are protected by applicable copyright and trademark law.
                    </p>
                  </li>

                  <li className="pt-4">
                    <h3>Use License</h3>
                    <p>
                      Permission is granted to temporarily download one copy of the materials (information or software) on the HostFi
                      Services Ltd Sites for personal, non-commercial transitory viewing only. This is the grant of a license, not a
                      transfer of title, and under this license, you may not:
                    </p>

                    <ul>
                      <li>modify or copy the materials;</li>
                      <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                      <li>attempt to decompile or reverse engineer any software contained on HostFi Services Ltd's website;</li>
                      <li>remove any copyright or other proprietary notations from the materials; or</li>
                      <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                    </ul>

                    <p className="pt-3">
                      This license shall automatically terminate if you violate any of these restrictions and may be terminated by Host
                      Capital at any time. Upon terminating your viewing of these materials or upon the termination of this license, you
                      must destroy any downloaded materials in your possession whether in electronic or printed format.
                    </p>
                  </li>

                  <li className="pt-4">
                    <h3>Disclaimer</h3>
                    <p>
                      The materials on the HostFi Services Ltd Sites are provided on an 'as is' basis. HostFi Services Ltd makes no
                      warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation,
                      implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of
                      intellectual property or other violation of rights.
                    </p>
                    <p>
                      Further, HostFi Services Ltd does not warrant or make any representations concerning the accuracy, likely results, or
                      reliability of the use of the materials on its Sites or otherwise relating to such materials or on any sites linked to
                      the Sites.
                    </p>
                  </li>

                  <li className="pt-4">
                    <h3>Limitations</h3>
                    <p>
                      In no event shall HostFi Services Ltd or its suppliers be liable for any damages (including, without limitation,
                      damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the
                      materials on the HostFi Services Ltd Sites, even if HostFi Services Ltd or a HostFi Services Ltd authorized
                      representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do
                      not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages,
                      these limitations may not apply to you.
                    </p>
                  </li>

                  <li className="pt-4">
                    <h3>Accuracy of Materials</h3>
                    <p>
                      The materials appearing on the HostFi Services Ltd Sites could include technical, typographical, or photographic
                      errors. HostFi Services Ltd does not warrant that any of the materials on its website are accurate, complete or
                      current. HostFi Services Ltd may make changes to the materials contained on its Sites at any time without notice.
                      However, HostFi Services Ltd does not make any commitment to update the materials.
                    </p>
                  </li>

                  <li className="pt-4">
                    <h3>Links</h3>
                    <p>
                      HostFi Services Ltd has not reviewed all of the sites linked to its Sites and is not responsible for the contents of
                      any such linked site. The inclusion of any link does not imply endorsement by HostFi Services Ltd of the site. Use of
                      any such linked website is at the user's own risk.
                    </p>
                  </li>

                  <li className="pt-4">
                    <h3>Modifications</h3>
                    <p>
                      HostFi Services Ltd may revise these terms of service for its Sites at any time without notice. By using the Sites you
                      are agreeing to be bound by the then current version of these terms of service.
                    </p>
                  </li>

                  <li className="pt-4">
                    <h3>Governing Law</h3>
                    <p>
                      These terms and conditions are governed by and construed in accordance with the laws of the Federal Republic of
                      Nigeria and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
                    </p>
                  </li>
                </ul>

                <Gap v={1} />

                <div className="button-set text-end mt-5">
                  <div className="d-inline-block">
                    <button onClick={() => navigate(route.onboarding.tradingActivities)} className="btn btn-primary btn-outline">
                      Back
                    </button>
                    <Gap h={1} />
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        Modal.Confirm('Submit KYC?', 'Please ensure that all of the details you have provided are correct.').then((b) => {
                          if (b === true) {
                            kycApi.submit().catch(handleRequestErrors);
                            navigate(route.dashboard.home);
                          }
                        })
                      }
                    >
                      Accept & Continue
                    </button>
                  </div>
                </div>

                <Gap v={2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
