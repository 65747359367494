import React from 'react';

interface ICheckboxProps {
  id?: string;
  inputClass?: string;
  textClass?: string;
  label?: string;
}

export const CheckBox = (
  props: ICheckboxProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
) => {
  const { id, inputClass, textClass, label, ...restProps } = props;

  return (
    <div className="default-checkbox">
      <input
        id={id}
        type="checkbox"
        className={inputClass}
        {...restProps}
        style={{ display: 'inline-block', verticalAlign: 'top', marginTop: 2 }}
      />
      &nbsp;&nbsp;{' '}
      <label htmlFor={id} className={`${textClass || ''}`}>
        {label}
      </label>
    </div>
  );
};

export const CheckBox2 = (
  props: ICheckboxProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
) => {
  const { id, inputClass, textClass, label, ...restProps } = props;

  return (
    <label className="custom-checkbox no-select" htmlFor={id}>
      <input type="checkbox" className={inputClass} {...restProps} />
      <span className="chkr bg-props-before"></span>&nbsp;&nbsp; <span className={`${textClass || ''}`}>{label}</span>
    </label>
  );
};
