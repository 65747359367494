import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locale/en.locale.json';

const getTranslations = (translation: any) => ({
  translation,
});

const configure = () => {
  i18n.use(initReactI18next).init({
    resources: {
      en: getTranslations(en),
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
};

export default { configure };
