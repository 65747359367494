import { BusinessAction, BusinessDto } from '../typings/business';

const initialState: Partial<BusinessDto> = {};

export default (state = initialState, action: BusinessAction): Partial<BusinessDto> => {
  switch (action.type) {
    case 'SET_BUSINESS':
      return action.business;
    case 'UPDATE_BUSINESS':
      return {
        ...state,
        ...action.business,
      };
    default:
      return state;
  }
};
