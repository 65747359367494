import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/DashboardHeader';
import { Sidebar } from '../components/DashboardSidebar';
import { OrdersList } from '../components/OrdersList';
import Gap from '../components/Gap';
import './styles.scss';
import { OnboardingNote } from '../components/OnboardingNote';

export const OrderHistory = () => {
  return (
    <>
      <Helmet title="Orders | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap orders-dashboard">
              <div className="content-inner">
                <Header withTicker={false} />

                <Gap v={2} />

                <div className="orders-page-wrap">
                  <OnboardingNote gapBottom={2} />

                  <OrdersList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
