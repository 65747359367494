import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { UserType } from '../../typings';
import Gap from '../components/Gap';
import * as userApi from '../../api/user';
import { handleRequestErrors } from '../../utils';
import { saveState } from '../../localstorage';
import { LOCAL_APP_TOKEN } from '../../constants';
import { route } from '../../routes';
import { Button } from '../components/html/Button';
import { useDispatch } from 'react-redux';
import { updateMiscSettings } from '../../actions/misc';
import { getExpiryFromNow } from '../../utils/math';
import logoPrimary from '../../assets/logo-primary.svg';
import './onboarding.scss';

export const OnboardingAccountType = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token }: any = useParams();
  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState<UserType>();

  return (
    <>
      <Helmet title="Host Capital" />

      <div className="onboarding-account-type">
        <div className="centered">
          <div className="centered-content full-width">
            <div className="inner-content">
              <div className="content-box">
                <a href="" className="logo">
                  <img src={logoPrimary} alt="logo" />
                </a>

                <Gap v={2} />

                <div className="heading">
                  <h4>Choose an Account Type</h4>
                  <p>Please select an account type that best suits your intended mode of operation.</p>
                </div>
                <Gap v={1} />

                <ul className="account-types">
                  <li className={`individual ${accountType === 'INDIVIDUAL' ? 'selected' : ''}`}>
                    <a onClick={() => setAccountType('INDIVIDUAL')}>
                      <h6>Individual</h6>
                      <span>Perfect for individual funds and portfolios</span>
                    </a>
                  </li>

                  <li className={`corporate ${accountType === 'CORPORATE' ? 'selected' : ''}`}>
                    <a onClick={() => setAccountType('CORPORATE')}>
                      <h6>Corporate</h6>
                      <span>For registered businesses and corporations</span>
                    </a>
                  </li>

                  <li className={`trust ${accountType === 'TRUST' ? 'selected' : ''}`}>
                    <a onClick={() => setAccountType('TRUST')}>
                      <h6>Trust</h6>
                      <span>For trusts</span>
                    </a>
                  </li>
                </ul>

                <Gap v={1} />

                <div className="text-end">
                  <Button
                    loading={loading}
                    disabled={loading}
                    className="btn btn-primary btn-full-width-mobile"
                    style={{ minWidth: '150px' }}
                    onClick={() => {
                      if (!accountType) {
                        return toast.error('Please choose an account type');
                      }

                      setLoading(true);
                      userApi
                        .updateOnboarding(token, { type: accountType })
                        .then((response) => {
                          saveState(LOCAL_APP_TOKEN, {
                            ...response,
                            expiresIn: getExpiryFromNow(response.expiresIn),
                          });
                          navigate(route.onboarding.profile, { replace: true });
                          dispatch(updateMiscSettings({ token: response }));
                        })
                        .catch(handleRequestErrors)
                        .finally(() => setLoading(false));
                    }}
                    text="Continue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
