import config from '../config';
import Request from '../helpers/request';
import { StatusDto } from '../typings';
import {
  P2pAccountDto,
  P2pAccountListDto,
  P2pAdDto,
  P2pAdListDto,
  P2pAdStatusDto,
  P2pAdTypeDto,
  P2pCurrencyPairs,
  P2pDisputeDto,
  P2pDisputeListDto,
  P2pDisputeStatusDto,
  P2pMethodIdDto,
  P2pProfileDto,
  P2pRequestDto,
  P2pRequestListDto,
  P2pRequestStatusDto,
  P2pTransactionDto,
  P2pTransactionListDto,
  P2pTransactionStatusDto,
} from '../typings/p2p';
import { getQueryParams } from '../utils';

export class P2pApi {
  static async getSupportedPairs(countryCode = 'NG'): Promise<P2pCurrencyPairs> {
    return await Request.getWithUserToken<P2pCurrencyPairs>(`${config.api.p2p}/pairs/${countryCode}`);
  }

  static async getProfile(): Promise<P2pProfileDto> {
    return await Request.getWithUserToken<P2pProfileDto>(`${config.api.p2p}/me`);
  }

  static async updateProfile(data: Partial<P2pProfileDto>): Promise<P2pProfileDto> {
    return await Request.patchWithUserToken<P2pProfileDto>(`${config.api.p2p}/me`, {
      data,
    });
  }

  static async getAccounts(methodId: P2pMethodIdDto): Promise<P2pAccountListDto> {
    return await Request.getWithUserToken<P2pAccountListDto>(`${config.api.p2p}/me${getQueryParams({ methodId })}`);
  }

  static async addAccount(data: Partial<P2pAccountDto>): Promise<P2pAccountDto> {
    return await Request.postWithUserToken<P2pAccountDto>(`${config.api.p2p}/me/accounts`, {
      data,
    });
  }

  static async removeAccount(id: string): Promise<StatusDto> {
    return await Request.deleteWithUserToken<StatusDto>(`${config.api.p2p}/me/accounts/${id}`);
  }

  static async getMyAds(
    page = 1,
    size = 20,
    fromCurrency?: string,
    toCurrency?: string,
    status?: P2pAdStatusDto,
    type?: P2pAdTypeDto,
  ): Promise<P2pAdListDto> {
    return await Request.getWithUserToken<P2pAdListDto>(
      `${config.api.p2p}/me/ads${getQueryParams({
        fromCurrency,
        toCurrency,
        page,
        size,
        status,
        type,
      })}`,
    );
  }

  static async createAd(data: Partial<P2pAdDto>): Promise<P2pAdDto> {
    return await Request.postWithUserToken<P2pAdDto>(`${config.api.p2p}/me/ads`, { data });
  }

  static async getMyAd(id: string): Promise<P2pAdDto> {
    return await Request.getWithUserToken<P2pAdDto>(`${config.api.p2p}/me/ads/${id}`);
  }

  static async updateMyAd(id: string, data: Partial<P2pAdDto>): Promise<P2pAdDto> {
    return await Request.patchWithUserToken<P2pAdDto>(`${config.api.p2p}/me/ads/${id}`, { data });
  }

  static async deleteMyAd(id: string): Promise<StatusDto> {
    return await Request.deleteWithUserToken<StatusDto>(`${config.api.p2p}/me/ads/${id}`);
  }

  static async getRequests(status?: P2pRequestStatusDto, page = 1, size = 20): Promise<P2pRequestListDto> {
    return await Request.getWithUserToken<P2pRequestListDto>(
      `${config.api.p2p}/me/requests${getQueryParams({
        status,
        page,
        size,
        includePartnerReqs: true,
      })}`,
    );
  }

  static async getRequest(id: string): Promise<P2pRequestDto> {
    return await Request.getWithUserToken<P2pRequestDto>(`${config.api.p2p}/me/requests/${id}`);
  }

  static async updateRequest(id: string, status: 'ACCEPT' | 'REJECT' | 'CANCEL'): Promise<P2pRequestDto> {
    return await Request.patchWithUserToken<P2pRequestDto>(`${config.api.p2p}/me/requests/${id}`, {
      data: {
        status,
      },
    });
  }

  static async getTransactions(status?: P2pTransactionStatusDto, page = 1, size = 20): Promise<P2pTransactionListDto> {
    return await Request.getWithUserToken<P2pTransactionListDto>(
      `${config.api.p2p}/me/transactions${getQueryParams({
        status,
        page,
        size,
        includePartnerTxns: true,
      })}`,
    );
  }

  static async getTransaction(id: string): Promise<P2pTransactionDto> {
    return await Request.getWithUserToken<P2pTransactionDto>(`${config.api.p2p}/transactions/${id}`);
  }

  static async updateTransaction(id: string, status: 'PAID' | 'RECEIVED' | 'CANCEL', security: string): Promise<P2pTransactionDto> {
    return await Request.postWithUserToken<P2pTransactionDto>(`${config.api.p2p}/transactions/${id}`, {
      data: {
        status,
        security,
      },
    });
  }

  static async createDispute(transactionId: string, reason?: string): Promise<P2pDisputeDto> {
    return await Request.postWithUserToken<P2pDisputeDto>(`${config.api.p2p}/disputes`, {
      data: {
        transactionId,
        reason,
      },
    });
  }

  static async getDisputes(status?: P2pDisputeStatusDto, page = 1, size = 20): Promise<P2pDisputeListDto> {
    return await Request.getWithUserToken<P2pDisputeListDto>(
      `${config.api.p2p}/me/disputes${getQueryParams({
        status,
        page,
        size,
      })}`,
    );
  }

  static async getDispute(id: string): Promise<P2pDisputeDto> {
    return await Request.getWithUserToken<P2pDisputeDto>(`${config.api.p2p}/disputes/${id}`);
  }

  static async updateDispute(id: string, status: 'CANCEL', cancelReason?: string): Promise<P2pDisputeDto> {
    return await Request.postWithUserToken<P2pDisputeDto>(`${config.api.p2p}/disputes/${id}`, {
      data: {
        status,
        reason: cancelReason,
      },
    });
  }
}
