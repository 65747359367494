import Request from '../helpers/request';
import { CurrencyType, CurrencyListResponse, CurrencyPairsListResponse, ExchangeRateList, ExchangeRate, FeeDto } from '../typings/rates';
import { getQueryParams } from '../utils';

export const getCurrencies = async (type?: CurrencyType, isSupported = true): Promise<CurrencyListResponse> => {
  const obj = { assetType: type, isSupported };

  return await Request.getWithUserToken<CurrencyListResponse>(`${process.env.REACT_APP_API_URL}/rates/currencies${getQueryParams(obj)}`);
};

export const getSwapPairs = async (currency: string): Promise<CurrencyPairsListResponse> => {
  return await Request.getWithUserToken<CurrencyPairsListResponse>(`${process.env.REACT_APP_API_URL}/rates/currencies/${currency}/pairs`);
};

export const getConversions = async (obj: { fromCurrency?: string; toCurrency?: string }): Promise<ExchangeRateList> => {
  return await Request.getWithUserToken<ExchangeRateList>(`${process.env.REACT_APP_API_URL}/rates/conversions${getQueryParams(obj || {})}`);
};

export const getSwapConversion = async (obj: { fromCurrency: string; toCurrency: string }): Promise<ExchangeRate> => {
  return await Request.getWithUserToken<ExchangeRate>(`${process.env.REACT_APP_API_URL}/rates/conversions${getQueryParams(obj || {})}`);
};

export const getFee = async (
  type: 'exchange' | 'withdrawal',
  sourceCurrency: string,
  targetCurrency: string,
): Promise<{ fees: FeeDto[] }> => {
  return await Request.getWithUserToken<{ fees: FeeDto[] }>(
    `${process.env.REACT_APP_API_URL}/rates/fees${getQueryParams({
      type,
      sourceCurrency,
      targetCurrency,
    })}`,
  );
};
