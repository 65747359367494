import { ListsState, ListsAction } from '../typings/lists';

const defaultState: ListsState = {
  countries: [],
  states: {},
  cities: {},
};

export default (state = defaultState, action: ListsAction): ListsState => {
  switch (action.type) {
    case 'SET_COUNTRIES':
      return {
        ...state,
        countries: action.countries,
      };
    case 'SET_STATES':
      const { countryId } = action;

      if (!countryId) {
        return state;
      }

      return {
        ...state,
        states: {
          ...state.states,
          [countryId]: action.data!,
        },
      };
    case 'SET_CITIES':
      const { stateId } = action;

      if (!stateId) {
        return state;
      }

      return {
        ...state,
        cities: {
          ...state.cities,
          [stateId]: action.data!,
        },
      };
    default:
      return state;
  }
};
