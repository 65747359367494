import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging } from 'firebase/messaging';
import config from '../config';

// prettier-ignore
const app = initializeApp(config.variant === 'PRODUCTION' ? {
  apiKey: 'AIzaSyCCY5cP57wGyCnN7dg39hut9ygqcYywDJo',
  authDomain: 'hostfiprod.firebaseapp.com',
  projectId: 'hostfiprod',
  storageBucket: 'hostfiprod.appspot.com',
  messagingSenderId: '735139506395',
  appId: '1:735139506395:web:f05d51587367ff91ae04fe',
  measurementId: 'G-KNT4PCB9QE',
} : {
  apiKey: 'AIzaSyBSXr0GwM59AiI_39mq2UrJYr_2J3oqEdw',
  authDomain: 'hostfidev.firebaseapp.com',
  projectId: 'hostfidev',
  storageBucket: 'hostfidev.appspot.com',
  messagingSenderId: '143556218074',
  appId: '1:143556218074:web:60a03cbdf557a48488e9e3',
  measurementId: 'G-ERZXPGR13N',
});

const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export { analytics, messaging };
