/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import OtpInput from 'react18-input-otp';
import { BsChevronRight } from 'react-icons/bs';
import { AiOutlineLoading } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import serializeForm from 'form-serialize';
import { Link, useNavigate } from 'react-router-dom';
import Gap from '../components/Gap';
import { Input } from '../components/html/Input';
import { login, loginConfirm } from '../../api/auth';
import { handleRequestErrors } from '../../utils';
import { toast } from 'react-toastify';
import { saveState } from '../../localstorage';
import { LOCAL_APP_TOKEN } from '../../constants';
import { route } from '../../routes';
import { ManagedAccountDto, TokenDto } from '../../typings';
import * as authApi from '../../api/auth';
import { useDispatch } from 'react-redux';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { updateMiscSettings } from '../../actions/misc';
import { getExpiryFromNow } from '../../utils/math';
import { ConditionalElement } from '../components/ConditionalElement';
import logo from '../../assets/logo-primary.svg';
import './signin.scss';

interface SigninFormProps {
  setOtpAuth(b: boolean): void;
  setOtpToken(token: TokenDto): void;
  onMultiAccounts(accounts: ManagedAccountDto[], token: string): void;
}

interface Signin2FAProps {
  otpToken: TokenDto;
  onMultiAccounts(accounts: ManagedAccountDto[], token: string): void;
}

const SigninForm = (props: SigninFormProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const handleGoogleLogin = (token: string) => {
    setLoading(true);

    authApi
      .authExternal(token, 'GMAIL')
      .then((response) => {
        if (response.accounts && response.accounts.length > 0) {
          props.onMultiAccounts(response.accounts, response.token);
        } else if (response.useAuth) {
          props.setOtpAuth(true);
          props.setOtpToken(response);
        } else if (response.redirect === 'ONBOARDING_0') {
          navigate(route.onboarding.type.replace(':token', response.token), { replace: true });
        } else {
          saveState(LOCAL_APP_TOKEN, {
            ...response,
            expiresIn: getExpiryFromNow(response.expiresIn),
          });
          navigate(route.dashboard.home, { replace: true });
          dispatch(updateMiscSettings({ token: response }));
        }
      })
      .catch((err) => handleRequestErrors(err))
      .finally(() => setLoading(false));
  };

  const googleLogin = useGoogleLogin({
    prompt: 'consent',
    onSuccess: ({ access_token: token }) => handleGoogleLogin(token),
    onError: (err) => console.log('LOGIN_FAILED: ', err),
  });

  return (
    <div className="inner-content">
      <div className="signin-wrap">
        <div className="signin-box">
          <a href="" className="logo">
            <img src={logo} alt="logo" />
          </a>

          <Gap v={2} />

          <div className="heading">
            <h3>Sign In</h3>
            <p>Welcome back to your account.</p>
          </div>

          <form
            onSubmit={(ev) => {
              ev.preventDefault();

              const data = serializeForm(ev.target, { hash: true });
              const { email, password } = data;

              setLoading(true);
              login(email, password)
                .then((response) => {
                  if (response.accounts && response.accounts.length > 0) {
                    props.onMultiAccounts(response.accounts, response.token);
                  } else {
                    props.setOtpAuth(true);
                    props.setOtpToken(response);
                  }
                })
                .catch((err) => handleRequestErrors(err, 'Some error occurred. Authentication failed.'))
                .finally(() => setLoading(false));
            }}
          >
            <Input required type="email" name="email" placeholder={t('text.email')} gapbottom={1} />

            <Input required type="password" name="password" placeholder="Password" gapbottom={1} />

            <div className="row">
              <div className="col"></div>

              <div className="col-auto text-end">
                <Link to="/forgot-password" className="forgot-pass">
                  Forgot Password?
                </Link>
              </div>
            </div>

            <button disabled={loading} type="submit" className="btn btn-primary mt-4 mb-2 btn-submit">
              {loading ? 'Please wait...' : 'Login'}
            </button>
            <Gap v={1} />

            <div className="text-uppercase text-center divider-text">
              <span>or</span>
            </div>
            <Gap v={1} />

            <div className="text-center">
              <a className="btn btn-google-login" onClick={() => googleLogin()}>
                Sign In with Google
              </a>
            </div>
            <Gap v={2} />

            <div className="text-center">
              <span className="text-muted">New here?</span> <Link to={route.authentication.requstAccess}>Request access</Link>
            </div>
          </form>
        </div>

        <div className="illus">
          <h4>Manage your assets</h4>
          <Gap v={1} />
          <img src={require('../../assets/person-2.png')} alt="" />
        </div>
      </div>
    </div>
  );
};

const Signin2FA = (props: Signin2FAProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [otpCode, setOtpCode] = useState('');

  const otpLength = props.otpToken.useAuth === 'PIN_OTP' ? 4 : 6;

  return (
    <div className="inner-content">
      <div className="signin-box">
        <a href="" className="logo">
          <img src={logo} alt="logo" />
        </a>

        <Gap v={2} />

        <div className="heading">
          <h4>
            {
              // prettier-ignore
              props.otpToken.useAuth === 'AUTHENTICATOR_OTP' ? 'Authenticator' :
                props.otpToken.useAuth === 'MOBILE_OTP' ? 'SMS' :
                  props.otpToken.useAuth === 'PIN_OTP' ? 'PIN' : 'Email'
            }{' '}
            Verification
          </h4>
          <p>
            {
              // prettier-ignore
              props.otpToken.useAuth === 'MOBILE_OTP' ?
                // eslint-disable-next-line quotes
                "Enter the OTP code that was sent to your phone number so we know that it's you." :
                props.otpToken.useAuth === 'AUTHENTICATOR_OTP' ?
                  // eslint-disable-next-line quotes
                  "Enter your authenticator OTP so we know that it's you." :
                  props.otpToken.useAuth === 'PIN_OTP' ?
                    // eslint-disable-next-line quotes
                    "Please enter your account PIN so we know that it's you." :
                    // eslint-disable-next-line quotes
                    "Enter the OTP code that was sent to your email so we know that it's you."
            }
          </p>
        </div>

        <form
          onSubmit={(ev) => {
            ev.preventDefault();

            if (otpCode.length < otpLength) {
              return toast.error('Please provide a valid OTP');
            }

            setLoading(true);
            loginConfirm(otpCode, props.otpToken.token)
              .then((response) => {
                if (response.accounts && response.accounts.length > 0) {
                  props.onMultiAccounts(response.accounts, response.token);
                } else if (response.redirect === 'ONBOARDING_0') {
                  navigate(route.onboarding.type.replace(':token', response.token), { replace: true });
                } else {
                  saveState(LOCAL_APP_TOKEN, {
                    ...response,
                    expiresIn: getExpiryFromNow(response.expiresIn),
                  });
                  navigate(route.dashboard.home, { replace: true });
                  dispatch(updateMiscSettings({ token: response }));
                }
              })
              .catch((err) => handleRequestErrors(err, 'Some error occurred. Authentication failed.'))
              .finally(() => setLoading(false));
          }}
        >
          <div className="form-group mb-2">
            <OtpInput
              value={otpCode}
              numInputs={otpLength}
              shouldAutoFocus={true}
              placeholder={'*'.repeat(otpLength)}
              onChange={(otp: string) => setOtpCode(otp)}
              containerStyle="otp-input-container"
              inputStyle="form-control"
              isInputNum={true}
            />
          </div>

          <button disabled={loading} type="submit" className="btn btn-primary mt-4 mb-2 btn-submit">
            {loading ? 'Please wait...' : 'Login'}
          </button>
          <Gap v={1} />

          <ConditionalElement
            condition={['EMAIL_OTP', 'MOBILE_OTP'].includes(props.otpToken.useAuth)}
            element={
              <div className="text-center">
                <span className="text-muted">Didn't get it?</span> <Link to="/signup">Resend OTP</Link>
              </div>
            }
          />
        </form>
      </div>
    </div>
  );
};

interface MultiAccountSelectProps {
  token: string;
  accounts: ManagedAccountDto[];
}

const MultiAccountSelect = ({ accounts, token }: MultiAccountSelectProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<string>();

  return (
    <div className="inner-content">
      <div className="signin-box multi-account-box">
        <a href="" className="logo">
          <img src={logo} alt="logo" />
        </a>

        <Gap v={2} />

        <div className="heading">
          <h4>Choose Account</h4>
          <p>Select any account below to continue signing in</p>
        </div>

        <ul className="account-choose">
          {accounts.map(({ id, name }) => (
            <li
              key={id}
              onClick={() => {
                if (loading) {
                  return;
                }

                setLoading(true);
                setSelected(id);
                authApi
                  .handleMultiLogon(id, token)
                  .then((response) => {
                    saveState(LOCAL_APP_TOKEN, {
                      ...response,
                      expiresIn: getExpiryFromNow(response.expiresIn),
                    });
                    navigate(route.dashboard.home, { replace: true });
                    dispatch(updateMiscSettings({ token: response }));
                  })
                  .catch(handleRequestErrors)
                  .finally(() => {
                    setLoading(false);
                    setSelected('');
                  });
              }}
            >
              {name || <>&nbsp;</>} {loading && selected === id ? <AiOutlineLoading className="spinner-animation" /> : <BsChevronRight />}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const Signin = () => {
  const { t } = useTranslation();

  const [otpAuth, setOtpAuth] = useState(false);
  const [otpToken, setOtpToken] = useState<TokenDto>();
  const [accounts, setAccounts] = useState<ManagedAccountDto[]>([]);
  const [logonToken, setLogonToken] = useState<string>('');

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string}>
      <Helmet title={t('text.signin') + ' | Host Capital'} />

      <div className={`auth-signin-container ${otpAuth || accounts.length > 0 ? 'auth-centered' : ''}`}>
        <div className="centered">
          <div className="centered-content full-width">
            {accounts.length > 0 ? (
              <MultiAccountSelect token={logonToken} accounts={accounts} />
            ) : otpAuth ? (
              <Signin2FA
                otpToken={otpToken!}
                onMultiAccounts={(accounts, token) => {
                  setAccounts(accounts);
                  setLogonToken(token);
                }}
              />
            ) : (
              <SigninForm
                setOtpAuth={setOtpAuth}
                setOtpToken={setOtpToken}
                onMultiAccounts={(accounts, token) => {
                  setAccounts(accounts);
                  setLogonToken(token);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};
