import React, { useEffect, useState } from 'react';

interface ToggleSwitchProps {
  on?: boolean;
  disabled?: boolean;
  onSwitch?(on: boolean): void;
}

export const ToggleSwitch = ({ on = false, disabled, onSwitch = () => {} }: ToggleSwitchProps) => {
  const [isOn, setIsOn] = useState(false);

  useEffect(() => setIsOn(on), [on]);

  return (
    <div
      className={`custom-toogle-switch ${isOn ? 'switch-on' : ''}`}
      onClick={() => {
        if (disabled) {
          return;
        }

        const state = !isOn;

        setIsOn(state);
        onSwitch(state);
      }}
    />
  );
};
