import { BigNumber } from 'bignumber.js';
import { normaliseAmount } from './index';
import { USD, USDT } from '../constants';
import { AmountFormat, CurrencyDefinition, ExchangeRate, FeeDto, WalletAsset } from '../typings';

export const shortId = () => Math.round(Date.now() * Math.random()).toString();

export const computeLocalBalance = (
  localCurrency: CurrencyDefinition,
  assets: WalletAsset[],
  conversions: ExchangeRate[],
): AmountFormat => {
  if (!localCurrency) {
    return {
      value: 0,
      currency: USD,
    };
  }

  const balance = assets.reduce((accum, asset) => {
    if (asset.currency.code === localCurrency.code) {
      return accum + asset.balance;
    }

    // prettier-ignore
    const conversion = conversions.find(
      (conv) =>
        conv.fromCurrency === asset.currency.code &&
        conv.toCurrency === (localCurrency.code === USD ? (asset.currency.code === USDT ? USD : USDT) : localCurrency.code),
    );

    return accum + normaliseAmount(2, conversion ? asset.balance / (1 / conversion.rate) : 0);
  }, 0);

  return {
    value: balance,
    currency: localCurrency.code,
  };
};

export const convertAssetBalance = (asset: WalletAsset, conversion: ExchangeRate) => {
  return conversion ? asset.balance * (conversion.rate || 0) : 0;
};

// eslint-disable-next-line valid-jsdoc
/**
 * Get expiry time in milliseconds
 * @param {number} expiresIn In seconds
 * @returns
 */
export const getExpiryFromNow = (expiresIn: number): number => {
  return new Date().getTime() + expiresIn * 1000;
};

export const applyFee = (amount: number, fee: FeeDto): number => {
  const BN_ZERO = new BigNumber(0);
  const BN_AMOUNT = new BigNumber(amount);
  const BN_FEE_MIN = new BigNumber(fee.min);
  const BN_FEE_MAX = new BigNumber(fee.max);

  let feeAMount: BigNumber = BN_ZERO;

  if (fee.percent !== null && fee.percent !== 0) {
    feeAMount = BN_AMOUNT.multipliedBy(fee.percent / 100);
  }

  if (fee.flat !== null && fee.flat !== 0) {
    feeAMount = feeAMount.plus(fee.flat);
  }

  if (fee.min !== null && fee.min !== 0 && BN_FEE_MIN.comparedTo(BN_ZERO) > 0) {
    feeAMount = feeAMount.comparedTo(fee.min) < 0 ? BN_FEE_MIN : feeAMount;
  }

  if (fee.max !== null && fee.max !== 0 && BN_FEE_MAX.comparedTo(BN_ZERO) > 0) {
    feeAMount = feeAMount.comparedTo(fee.max) > 0 ? BN_FEE_MAX : feeAMount;
  }

  return Number(feeAMount.toPrecision(BigNumber.ROUND_HALF_UP));
};
