import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReducerStates } from '../../typings';
import { getOnboardingStage } from '../../utils/user';
import { ConditionalElement } from './ConditionalElement';
import { Note } from './Note';
import Gap from './Gap';

type OnboardingNoteProps = {
  gapBottom?: number;
};

export const OnboardingNote = ({ gapBottom }: OnboardingNoteProps) => {
  const { user, kyc } = useSelector((state: ReducerStates) => state);
  const [stage, setStage] = useState<string | null>();

  useEffect(() => {
    if (kyc.individual && (user.type !== 'INDIVIDUAL' ? !!kyc.business : true)) {
      setStage(getOnboardingStage(user.type, kyc.individual, kyc.business));
    }
  }, [user, kyc]);

  return (
    <>
      <ConditionalElement
        condition={!!stage}
        element={
          <Note>
            <>
              Your account is not yet fully set up. You will not be able to make deposits, create wallets and trade until this is done.
              Please&nbsp;
              <Link to={stage!} className="fw-bold">
                click here
              </Link>{' '}
              to finish setting up your account.
            </>
          </Note>
        }
      />

      <Gap v={gapBottom || 0} />
    </>
  );
};
