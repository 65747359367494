import React, { useEffect, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { BsChevronRight } from 'react-icons/bs';
import OtpInput from 'react18-input-otp';
import { baseStore } from '../../../store';
import { OtpOperationTypes, Security2faTypes } from '../../../typings';
import { Button } from '../html/Button';
import { ConditionalElement } from '../ConditionalElement';
import * as userApi from '../../../api/user';
import Gap from '../Gap';
import './styles.scss';

type Security2faDialogProps = {
  title?: string;
  message?: string;
  type?: Security2faTypes;
  operation: OtpOperationTypes;
  handleSecurity?(pin: string, fn: (data: any, err: any) => any): void;
};

interface PinEntryDialogProps extends Security2faDialogProps {
  goBack(): void;
}

export const PinEntryDialog = (props: PinEntryDialogProps) => {
  const { type = 'PIN', title, message, handleSecurity = () => {}, goBack } = props;
  const { user } = baseStore.getState();
  const [otpCode, setOtpCode] = useState('');
  const [loading, setLoading] = useState(false);

  const otpLength = type === 'PIN' ? 4 : 6;

  return (
    <div className="mfa-dialog-content">
      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          if (loading) {
            return;
          }

          setLoading(true);

          handleSecurity(`${type}:${otpCode}`, (data, err) => {
            if (err) {
              return setLoading(false);
            }
          });
        }}
      >
        <div className="head">
          <a className="back-button" onClick={() => goBack()}>
            <BiArrowBack />
          </a>
          <h4>{title || 'Security Verification'}</h4>
        </div>

        <Gap v={2} />

        <div className="note-type-1 caution">
          <span>
            {message || type === 'SMS' ? (
              <>
                An authentication code has been sent via SMS to your phone number: <b>{user.primaryPhone}</b>.
              </>
            ) : type === 'AUTHENTICATOR' ? (
              'Enter your authenticator OTP to continue.'
            ) : type === 'EMAIL' ? (
              'Enter the OTP code that was sent to your email to continue.'
            ) : (
              'Please enter your account PIN to continue.'
            )}
          </span>
        </div>

        <Gap v={2} />

        <span className="fw-bold">Verification code</span>
        <Gap v={0.5} />
        <OtpInput
          value={otpCode}
          numInputs={otpLength}
          shouldAutoFocus={true}
          placeholder={'*'.repeat(otpLength)}
          onChange={(otp: string) => setOtpCode(otp)}
          containerStyle="otp-input-container"
          inputStyle="form-control"
          isInputNum={true}
        />

        <Gap v={1} />

        <Button loading={loading} disabled={otpCode.length < otpLength || loading} className="btn btn-primary full-width" text="Next" />
      </form>
    </div>
  );
};

export const Security2faDialog = (props: Security2faDialogProps) => {
  const { operation } = props;
  const { user } = baseStore.getState();
  const [pinType, setPinType] = useState<Security2faTypes | undefined>(props.type);

  useEffect(() => {
    if (pinType === 'EMAIL' || pinType === 'SMS') {
      userApi.requestOtp(pinType, operation!).catch(() => {});
    }
  }, [pinType]);

  if (pinType) {
    return <PinEntryDialog {...props} type={pinType} goBack={() => setPinType(undefined)} />;
  } else {
    return (
      <div className="mfa-dialog-content">
        <h4>Security Verification</h4>
        <p>Choose a security verification method to continue.</p>

        <Gap v={0.5} />

        <ul className="otp-type-chooser">
          <ConditionalElement
            condition={user.security?.email.set! && user.security.email.enabled!}
            element={
              <li onClick={() => setPinType('EMAIL')}>
                <span className="text">Email</span>
                <span className="arrow">
                  <BsChevronRight />
                </span>
              </li>
            }
          />

          <ConditionalElement
            condition={user.security?.sms.set! && user.security.sms.enabled!}
            element={
              <li onClick={() => setPinType('SMS')}>
                <span className="text">SMS</span>
                <span className="arrow">
                  <BsChevronRight />
                </span>
              </li>
            }
          />

          <ConditionalElement
            condition={user.security?.authenticator.set! && user.security.authenticator.enabled!}
            element={
              <li onClick={() => setPinType('AUTHENTICATOR')}>
                <span className="text">Authenticator</span>
                <span className="arrow">
                  <BsChevronRight />
                </span>
              </li>
            }
          />

          <ConditionalElement
            condition={user.security?.pin.set! && user.security.pin.enabled!}
            element={
              <li onClick={() => setPinType('PIN')}>
                <span className="text">PIN</span>
                <span className="arrow">
                  <BsChevronRight />
                </span>
              </li>
            }
          />
        </ul>
      </div>
    );
  }
};
