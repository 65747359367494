import React from 'react';
import config from '../../../config';
import { CurrencyDefinition } from '../../../typings';
import { DepositDialog } from '../DepositDialog';
import { ExchangeDialog } from '../ExchangeDialog';
import { TransferDialog } from '../TransferDialog';
import { WithdrawalDialog } from '../WithdrawalDialog';
import { Modal } from '../Modal';
import Gap from '../Gap';
import './styles.scss';

interface Props {
  assetId: string;
  currency: CurrencyDefinition;
}

export const CurrencyActivityDialog = ({ currency, assetId }: Props) => {
  const buyActivity = currency.activities.includes('BUY');
  const sellActivity = currency.activities.includes('SELL');
  const transferActivity = currency.activities.includes('TRANSFER');

  let depositActivity =
    currency.activities?.includes('DEPOSIT') || currency.networks?.some((network) => network.activities.includes('DEPOSIT'));
  let withdrawalActivity =
    currency.activities?.includes('WITHDRAW') || currency.networks?.some((network) => network.activities.includes('WITHDRAW'));

  if (config.enabledCryptoDeposits === 'false') {
    depositActivity = false;
    withdrawalActivity = false;
  }

  const openDepositDialog = (activity: boolean, assetId: string, currency: CurrencyDefinition) => {
    if (!activity) {
      Modal.Alert('Deposit Disabled', `${currency.code} deposits are currently disabled.`);
    }

    Modal.Open(<DepositDialog assetId={assetId} currency={currency} />, {
      customClass: {
        container: 'modal-mobile-fullscreen',
        popup: `deposit-dialog-popup ${currency.type === 'CRYPTO' ? 'max-w-400x' : ''}`,
        htmlContainer: 'deposit-dialog-container',
      },
    });
  };

  const openWithdrawalDialog = (activity: boolean, assetId: string, currency: CurrencyDefinition) => {
    if (!activity) {
      Modal.Alert('Withdrawal Disabled', `${currency.code} withdrawals are currently disabled.`);
    }

    Modal.Open(<WithdrawalDialog assetId={assetId} currency={currency} />, {
      customClass: {
        container: 'modal-mobile-fullscreen',
        popup: 'max-w-400x withdrawal-dialog-popup',
        htmlContainer: 'withdrawal-dialog-container',
      },
    });
  };

  const openExchangeDialog = (activity: boolean, currency: CurrencyDefinition) => {
    if (!activity) {
      Modal.Alert('Exchange Disabled', `${currency.code} exchange is currently disabled.`);
    }

    Modal.Open(<ExchangeDialog currency={currency} />, {
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        container: 'modal-mobile-fullscreen',
        popup: 'max-w-500x',
      },
    });
  };

  const openTransferDialog = (activity: boolean, currency: CurrencyDefinition) => {
    if (!activity) {
      Modal.Alert('Transfer Disabled', `${currency.code} transfer is currently disabled.`);
    }

    Modal.Open(<TransferDialog currency={currency} />, {
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        container: 'modal-mobile-fullscreen',
        popup: 'max-w-400x',
      },
    });
  };

  return (
    <div className="currency-activity-chooser">
      <h4>What would you like to do?</h4>

      <Gap v={0.5} />

      <ul>
        {depositActivity ? (
          <li key={`asset-${assetId}-deposit`} onClick={() => openDepositDialog(depositActivity, assetId, currency)}>
            Deposit
          </li>
        ) : null}

        {withdrawalActivity ? (
          <li key={`asset-${assetId}-withdraw`} onClick={() => openWithdrawalDialog(withdrawalActivity, assetId, currency)}>
            Withdraw
          </li>
        ) : null}

        {buyActivity || sellActivity ? (
          <li key={`asset-${assetId}-exchange`} onClick={() => openExchangeDialog(buyActivity || sellActivity, currency)}>
            Exchange
          </li>
        ) : null}

        <li key={`asset-${assetId}-transfer`} onClick={() => openTransferDialog(transferActivity, currency)}>
          Transfer
        </li>
      </ul>

      <Gap v={0.5} />
    </div>
  );
};
