import React, { useEffect } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { HiPlusSm } from 'react-icons/hi';
import { TiDocumentText } from 'react-icons/ti';
import { Header } from '../../components/DashboardHeader';
import { Sidebar } from '../../components/DashboardSidebar';
import Gap from '../../components/Gap';
import { Modal } from '../../components/Modal';
import { CreateKeyModal } from './Modals';
import { SettingsSidebar } from '../../components/SettingsSidebar';
import { useNavigate } from 'react-router-dom';
import * as developerApi from '../../../api/developer';
import * as developerAction from '../../../actions/developer';
import { useDispatch } from 'react-redux';
import { handleRequestErrors } from '../../../utils';
import { useSelector } from 'react-redux';
import { ReducerStates } from '../../../typings';
import { ConditionalElement } from '../../components/ConditionalElement';
import { Security2faDialog } from '../../components/2FADialog';
import '../settings.scss';
import './styles.scss';

export const DeveloperKeys = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { developer } = useSelector((state: ReducerStates) => state);

  useEffect(() => {
    developerApi
      .getKeys()
      .then((data) => dispatch(developerAction.setKeys(data.keys)))
      .catch((err) => handleRequestErrors(err));
  }, []);

  return (
    <>
      <Helmet title="Settings | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="settings-page-wrap">
                  <SettingsSidebar />

                  <div className="settings-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Developer Keys</h6>
                      </div>

                      <div className="col-auto">
                        <button
                          className="btn btn-primary  btn-float-mobile"
                          onClick={() =>
                            Modal.Open(<CreateKeyModal />, {
                              customClass: {
                                container: 'modal-mobile-fullscreen',
                                popup: 'max-w-400x',
                              },
                            })
                          }
                        >
                          <span className="text hidden-768px">Create new key</span>
                          <span className="icon visible-768px">
                            <HiPlusSm />
                          </span>
                        </button>
                      </div>
                    </div>

                    <Gap v={2} className="hidden-768px" />

                    <div className="table-basic table-dev-keys">
                      <table>
                        <thead className="hidden-768px">
                          <tr>
                            <th>Label</th>
                            <th>Active</th>
                            <th>Client ID</th>
                            <th>Allowed IPs</th>
                            <th>Date Created</th>
                            <th>Last Used</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody>
                          {developer.keys.map((key) => (
                            <tr key={key.id}>
                              <td>
                                <div>{key?.label}</div>
                                <small className="visible-768px text-muted">{key.clientId}</small>
                                <small className="visible-768px text-muted">{key.whiteListedIPs?.join(',')}</small>
                              </td>
                              <td className="hidden-768px">
                                <div>{key.active ? 'Yes' : 'No'}</div>
                              </td>
                              <td className="hidden-768px">
                                <div>{key.clientId}</div>
                              </td>
                              <td className="hidden-768px">
                                <div>{key.whiteListedIPs?.join(',')}</div>
                              </td>
                              <td className="hidden-768px">{moment(key.createdAt).format('MMM DD, yyyy hh:mm a')}</td>
                              <td className="hidden-768px">
                                {key.lastUsedAt ? moment(key.lastUsedAt).format('MMM DD, yyyy hh:mm a') : '-'}
                              </td>
                              <td>
                                <span
                                  className="btn btn-danger delete"
                                  onClick={() => {
                                    Modal.Confirm(`Remove ${key?.label}`, 'Are you sure you want to remove this key?').then((value) => {
                                      if (value === true) {
                                        Modal.Open(
                                          <Security2faDialog
                                            operation="DEVELOPER_KEY_REMOVE"
                                            handleSecurity={async (security, callback) => {
                                              developerApi
                                                .removeKey(key.id, security)
                                                .then(() => {
                                                  Modal.CloseAll();
                                                  dispatch(developerAction.removeKey(key.id));
                                                })
                                                .catch((err) => {
                                                  callback(null, err);
                                                  handleRequestErrors(err);
                                                });
                                            }}
                                          />,
                                          {
                                            allowOutsideClick: false,
                                            allowEscapeKey: false,
                                            customClass: {
                                              container: 'modal-mobile-fullscreen',
                                              popup: 'max-w-350x',
                                            },
                                          },
                                        );
                                      }
                                    });
                                  }}
                                >
                                  ✕
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <ConditionalElement
                        condition={developer.keys.length === 0}
                        element={
                          <div className="no-result">
                            <h6>You don't have any keys</h6>
                          </div>
                        }
                      />
                    </div>

                    <div className="text-end">
                      <Gap v={1} />
                      <a href="https://hostfi.readme.io" target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                        View documentation <TiDocumentText />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
