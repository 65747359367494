import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { HiPlusSm } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import serializeForm from 'form-serialize';
import { parsePhoneNumber } from 'libphonenumber-js';
import Select from 'react-select';
import Dropdown from 'rc-dropdown';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import 'rc-menu/assets/index.css';
import 'rc-dropdown/assets/index.css';
import Gap from '../components/Gap';
import { Input } from '../components/html/Input';
import { Sidebar } from '../components/OnboardingSidebar';
import { TextArea } from '../components/html/TextArea';
import { KycDocSubType, KycDocType, ReducerStates, UploadFile } from '../../typings';
import { addStakeholder, removeStakeholder, setBusinessKycInfo } from '../../actions/kyc';
import { route } from '../../routes';
import { cleanPhoneNumber, generateUniqueFileIdentifier, handleRequestErrors } from '../../utils';
import * as kycApi from '../../api/kyc';
import * as filesApi from '../../api/files';
import { Select2 } from '../components/html/Select';
import { businessKycDocsOptions, BusinessTypeOptions, meansOfIdDocsOptions, proofOfAddressDocsOptions } from '../../constants';
import { LocationSelect, fromLocationValue, getLocationValue } from '../components/LocationSelect';
import { parseLocationData } from '../../utils/user';
import { AddStakeholderModal } from './Modals';
import { Modal } from '../components/Modal';
import { FileDisplay } from '../components/html/FileDisplay';
import Bluebird from 'bluebird';
import { Button } from '../components/html/Button';
import { Header } from '../components/DashboardHeader';
import { PhoneCodeSelect } from '../components/html/PhoneCodeSelect';

type NavTypes = 'BASIC' | 'CONTACT' | 'LOCATION' | 'STAKEHOLDERS' | 'DOCS';

const acceptedFiles = '.png, .jpg, .jpeg, .pdf';
const maxSizePerFile = 5242880;

export const OnboardingCorporate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const { kyc, lists } = useSelector((state: ReducerStates) => state);
  const [loading, setLoading] = useState(false);
  const [isTrust, setIsTrust] = useState(kyc.business?.type === 'TRUST');
  const [locationData, setLocationData] = useState<any>();
  const [currentContent, setCurrentContent] = useState<NavTypes>('BASIC');
  const [jurisdictions, setJurisdictions] = useState<string[]>([]);
  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
  const [category, setCategory] = useState<KycDocType | null>();
  const [subCategory, setSubCategory] = useState<KycDocSubType | null>();

  const addFile = (type: KycDocType, subType?: KycDocSubType) => {
    setCategory(type);
    setSubCategory(subType);
    (inputFile.current as any).click();
  };

  const removeFile = (id: string) => {
    const file = uploadFiles.find((f) => f.id === id);
    file?.cancelToken && file.cancelToken();

    if (file?.uploaded) {
      // remove file from server
      kycApi
        .removeFile(file.id, 'BUSINESS')
        .then(() => setUploadFiles(uploadFiles.filter((f) => f.id !== id)))
        .catch((err) => handleRequestErrors(err));
    } else {
      setUploadFiles(uploadFiles.filter((f) => f.id !== id));
    }
  };

  const updateFile = (id: string, data: Partial<UploadFile>) => {
    setUploadFiles(
      uploadFiles.map((file) => {
        if (file.id !== id) return file;

        for (const [k, v] of Object.entries(data)) {
          file[k] = v;
        }

        return file;
      }),
    );
  };

  useEffect(() => {
    if (!kyc.business?.location) return;

    setLocationData({
      country: parseLocationData(kyc.business?.location.country),
      state: parseLocationData(kyc.business?.location.state),
      city: parseLocationData(kyc.business?.location.suburb),
    });
  }, [kyc.business?.location]);

  useEffect(() => {
    setJurisdictions((kyc.business?.jurisdictions || []).map((juris) => juris.replaceAll('::', ',')));
  }, [kyc.business?.jurisdictions]);

  useEffect(() => {
    setUploadFiles([
      ...uploadFiles,
      ...(kyc.business?.docs || []).map(
        (doc) =>
          ({
            id: doc.id,
            status: doc.status,
            uploaded: true,
            uploading: false,
            extension: doc.originalFileName.split('.').pop(),
            category: doc.type,
            subCategory: doc.subType,
            data: {
              fileKey: doc.fileKey,
              fileUrl: doc.url,
              fileName: doc.originalFileName,
            },
          } as any),
      ),
    ]);
  }, [kyc.business?.docs]);

  useEffect(() => {
    Bluebird.Promise.each(uploadFiles, async (file) => {
      if (file.uploading === true || file.uploaded === true) return;

      file.uploading = true;

      updateFile(file.id, {
        uploading: true,
      });

      const presign = await filesApi.generatePresignedUrl(file.type, 'VERIFICATIONS');

      // Prevent upload incase file already removed before presign operation finished
      const exstFile = uploadFiles.find((f) => f.id === file.id);
      if (!exstFile) return;

      await filesApi
        .uploadFile({
          signedUrl: presign.signedUrl,
          file,
          getCancelToken(cancelToken) {
            file.cancelToken = cancelToken;

            updateFile(file.id, {
              cancelToken,
            });
          },
          onUploadProgress(progress) {
            file.progress = progress;

            updateFile(file.id, {
              progress,
            });
          },
        })
        .then(() => {
          file.uploaded = true;
          file.data = {
            fileName: file.name,
            fileUrl: presign.fileUrl,
            fileKey: presign.fileKey,
          };

          updateFile(file.id, {
            uploaded: true,
            data: file.data,
          });
        })
        .catch((err) => handleRequestErrors(err, 'An error occurred. Your file could not be uploaded'))
        .finally(async () => {
          file.uploading = false;

          updateFile(file.id, {
            uploading: false,
          });

          if (file.uploaded) {
            kycApi
              .addFile({
                mode: 'BUSINESS',
                type: file.category,
                subType: file.subCategory,
                fileKey: file.data.fileKey,
                originalFileName: file.name,
                url: file.data.fileUrl,
              })
              .then((addedFile) => {
                const { id, status, type, subType, fileKey, originalFileName, url } = addedFile;

                updateFile(file.id, {
                  id,
                  status,
                  category: type,
                  subCategory: subType,
                  data: {
                    fileKey,
                    fileUrl: url,
                    fileName: originalFileName,
                  },
                });
              })
              .catch((err) => handleRequestErrors(err));
          }
        });
    });
  }, [uploadFiles]);

  return (
    <>
      <Helmet title="Host Capital" />

      <div className="onboarding-wrapper">
        <Sidebar />

        <div className="onboarding-content-wrap in-page">
          <Header isOnboarding onLeftMenuClick={() => {}} />

          <div className="content-inner">
            <div className="in-page-nav">
              <ul>
                <li>
                  <a className={`${currentContent === 'BASIC' ? 'current' : ''}`} onClick={() => setCurrentContent('BASIC')}>
                    Basic
                  </a>
                </li>
                <li>
                  <a className={`${currentContent === 'CONTACT' ? 'current' : ''}`} onClick={() => setCurrentContent('CONTACT')}>
                    Contact
                  </a>
                </li>
                <li>
                  <a className={`${currentContent === 'LOCATION' ? 'current' : ''}`} onClick={() => setCurrentContent('LOCATION')}>
                    Location
                  </a>
                </li>
                <li>
                  <a className={`${currentContent === 'STAKEHOLDERS' ? 'current' : ''}`} onClick={() => setCurrentContent('STAKEHOLDERS')}>
                    Stakeholders
                  </a>
                </li>
                <li>
                  <a className={`${currentContent === 'DOCS' ? 'current' : ''}`} onClick={() => setCurrentContent('DOCS')}>
                    Documents
                  </a>
                </li>
              </ul>
            </div>

            <div className="in-page-content">
              <div
                style={{
                  display: currentContent === 'BASIC' ? 'block' : 'none',
                }}
              >
                <h3>Let us know more about your business</h3>
                <Gap v={1} />

                <form
                  onSubmit={(ev) => {
                    ev.preventDefault();

                    const formData = serializeForm(ev.target, { hash: true });
                    const { type, name, regCountry, regNumber, website, natureOfBusiness, trustType } = formData;

                    setLoading(true);
                    kycApi
                      .updateBusinessInfo({
                        type,
                        name,
                        website,
                        country: Object.entries(fromLocationValue(regCountry)!)
                          .map((entry) => entry[1])
                          .filter(Boolean)
                          .join('::'),
                        regNumber,
                        jurisdictions: jurisdictions.map((country: string) =>
                          Object.entries(fromLocationValue(country)!)
                            .map((entry) => entry[1])
                            .filter(Boolean)
                            .join('::'),
                        ),
                        natureOfBusiness,
                        trustType: type === 'TRUST' ? trustType : '',
                      })
                      .then((data) => {
                        dispatch(setBusinessKycInfo(data));
                        setCurrentContent('CONTACT');
                      })
                      .catch(handleRequestErrors)
                      .finally(() => setLoading(false));
                  }}
                >
                  <Input
                    required
                    type="text"
                    label="Entity Name"
                    id="entity-name"
                    name="name"
                    placeholder="Ex. Host Capital LLC"
                    defaultValue={kyc.business?.name}
                    gapbottom={1}
                    minLength={3}
                    maxLength={40}
                  />

                  <LocationSelect
                    gapBottom={1}
                    values={{ country: parseLocationData(kyc.business?.country) }}
                    select={['countries']}
                    labels={{ country: 'Registration country' }}
                    names={{ country: 'regCountry' }}
                  />

                  <div className="form-group">
                    <label htmlFor="sel-juris" className="mb-2 color-032940">
                      Declaration of Jurisdiction (In which countries do you conduct business)
                    </label>
                    <Select
                      isMulti
                      id="sel-juris"
                      name="jurisdictions"
                      options={lists.countries?.map(({ id, name, isoCode }) => ({
                        id,
                        label: name,
                        value: id + ',' + name + ',' + isoCode,
                      }))}
                      value={jurisdictions.map((juris) => getLocationValue(juris))}
                      onChange={(values: any) => setJurisdictions((values || []).map((v: any) => v.value))}
                    />
                    <Gap v={1} />
                  </div>

                  <Input
                    required
                    type="text"
                    label="Registration Number"
                    id="reg-number"
                    name="regNumber"
                    placeholder="Ex. 1234567"
                    defaultValue={kyc.business?.regNumber}
                    gapbottom={1}
                    minLength={3}
                    maxLength={40}
                  />

                  <Input
                    type="text"
                    label="Website"
                    id="website"
                    name="website"
                    placeholder="Ex. https://example.com"
                    defaultValue={kyc.business?.website}
                    gapbottom={1}
                    minLength={3}
                    maxLength={40}
                  />

                  <TextArea
                    required
                    id="nature-of-business"
                    name="natureOfBusiness"
                    label="Nature of Business (Please describe your business activities)"
                    defaultValue={kyc.business?.natureOfBusiness}
                    placeholder=""
                    gapbottom={1}
                  />

                  <Select2
                    required
                    label="Select your business type"
                    name="type"
                    defaultValue={kyc.business?.type}
                    options={BusinessTypeOptions}
                    onChange={(ev) => setIsTrust(ev.target.value === 'TRUST')}
                    gapbottom={1}
                  />

                  <Input
                    required={isTrust}
                    type="text"
                    label="Trust Type (Only if registered as a trust)"
                    id="trust-type"
                    name="trustType"
                    defaultValue={kyc.business?.trustType}
                    gapbottom={1}
                  />

                  <div className="button-set text-end mt-4">
                    <Button
                      loading={loading}
                      disabled={loading}
                      type="submit"
                      className="btn btn-primary btn-full-width-mobile"
                      text="Save & Continue"
                    />
                  </div>

                  <Gap v={2} />
                </form>
              </div>

              <div
                style={{
                  display: currentContent === 'CONTACT' ? 'block' : 'none',
                }}
              >
                <h3>Contact Information</h3>
                <Gap v={1} />

                <form
                  onSubmit={(ev) => {
                    ev.preventDefault();

                    const formData = serializeForm(ev.target, { hash: true });
                    const { email, primaryPhoneCode, secondaryPhoneCode } = formData;

                    // prettier-ignore
                    const primaryPhone = formData.primaryPhone ? primaryPhoneCode + formData.primaryPhone.replace(/^0+/, '').replace(/\s/g, '') : '';

                    let secondaryPhone;

                    if (formData.secondaryPhone) {
                      // prettier-ignore
                      secondaryPhone = formData.secondaryPhone ? secondaryPhoneCode + formData.secondaryPhone.replace(/^0+/, '').replace(/\s/g, '') : '';
                    }

                    setLoading(true);
                    kycApi
                      .updateBusinessInfo({
                        email,
                        primaryPhone,
                        secondaryPhone,
                      })
                      .then((data) => {
                        dispatch(setBusinessKycInfo(data));
                        setCurrentContent('LOCATION');
                      })
                      .catch(handleRequestErrors)
                      .finally(() => setLoading(false));
                  }}
                >
                  <Input
                    required
                    type="text"
                    label="Contact Email Address"
                    id="contact-email"
                    name="email"
                    placeholder="Ex. support@example.com"
                    defaultValue={kyc.business?.email}
                    gapbottom={1}
                    minLength={5}
                    maxLength={80}
                  />

                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="input-pri-phone" className="mb-2">
                        Primary Phone Number <span>*</span>
                      </label>
                    </div>

                    <div className="col-4 pr-2">
                      <PhoneCodeSelect
                        name="primaryPhoneCode"
                        value={
                          // prettier-ignore
                          kyc.business?.primaryPhone ? `+${parsePhoneNumber(cleanPhoneNumber(kyc.business.primaryPhone)).countryCallingCode as string}` : ''
                        }
                      />
                    </div>

                    <div className="col-8 pl-0">
                      <div className="form-group">
                        <Cleave
                          required
                          name="primaryPhone"
                          autoComplete="off"
                          id="input-pri-phone"
                          className="form-control"
                          placeholder="E.g 8051235678"
                          value={
                            // prettier-ignore
                            kyc.business?.primaryPhone ? (parsePhoneNumber(cleanPhoneNumber(kyc.business.primaryPhone as string)).nationalNumber as string) : ''
                          }
                          options={{
                            phone: true,
                            phoneRegionCode: 'any',
                          }}
                        />
                        <Gap v={1} />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="input-sec-phone" className="mb-2">
                        Secondary Phone Number
                      </label>
                    </div>

                    <div className="col-4 pr-2">
                      <PhoneCodeSelect name="secondaryPhoneCode" />
                    </div>

                    <div className="col-8 pl-0">
                      <div className="form-group">
                        <Cleave
                          name="secondaryPhone"
                          autoComplete="off"
                          id="input-sec-phone"
                          className="form-control"
                          placeholder="E.g 8051235678"
                          value={
                            // prettier-ignore
                            kyc.business?.secondaryPhone ? (parsePhoneNumber(cleanPhoneNumber(kyc.business.secondaryPhone as string), 'NG').nationalNumber as string) : ''
                          }
                          options={{
                            phone: true,
                            phoneRegionCode: 'any',
                          }}
                        />
                        <Gap v={1} />
                      </div>
                    </div>
                  </div>

                  <div className="button-set text-end mt-4">
                    <Button
                      loading={loading}
                      disabled={loading}
                      type="submit"
                      className="btn btn-primary btn-full-width-mobile"
                      text="Save & Continue"
                    />
                  </div>

                  <Gap v={2} />
                </form>
              </div>

              <div
                style={{
                  display: currentContent === 'LOCATION' ? 'block' : 'none',
                }}
              >
                <h3>Location Information</h3>
                <Gap v={1} />

                <form
                  onSubmit={(ev) => {
                    ev.preventDefault();

                    const formData = serializeForm(ev.target, { hash: true });
                    const { address, country, state, city, postCode } = formData;

                    setLoading(true);
                    kycApi
                      .updateBusinessInfo({
                        location: {
                          country: Object.entries(fromLocationValue(country)!)
                            .map((entry) => entry[1])
                            .filter(Boolean)
                            .join('::'),
                          state: Object.entries(fromLocationValue(state)!)
                            .map((entry) => entry[1])
                            .filter(Boolean)
                            .join('::'),
                          // prettier-ignore
                          suburb: city ? Object.entries(fromLocationValue(city)!)
                            .map((entry) => entry[1])
                            .filter(Boolean)
                            .join('::') : undefined,
                          address,
                          postCode,
                        },
                      })
                      .then((data) => {
                        dispatch(setBusinessKycInfo(data));
                        setCurrentContent('STAKEHOLDERS');
                      })
                      .catch(handleRequestErrors)
                      .finally(() => setLoading(false));
                  }}
                >
                  <LocationSelect onlySupported gapBottom={1} values={locationData} />

                  <div className="row">
                    <div className="col-md-9">
                      <Input
                        required
                        type="text"
                        label="Your address"
                        id="input-addr"
                        placeholder="Ex. 123, Darwin Street, Mars"
                        name="address"
                        defaultValue={kyc.business?.location.address}
                        gapbottom={1}
                      />
                    </div>

                    <div className="col-md-3">
                      <Input
                        required
                        type="text"
                        label="Postal Code"
                        id="input-post-code"
                        placeholder="Ex. 23401"
                        name="postCode"
                        defaultValue={kyc.business?.location.postCode}
                      />
                    </div>
                  </div>

                  <div className="button-set text-end mt-4">
                    <Button
                      loading={loading}
                      disabled={loading}
                      type="submit"
                      className="btn btn-primary btn-full-width-mobile"
                      text="Save & Continue"
                    />
                  </div>

                  <Gap v={2} />
                </form>
              </div>

              <div
                style={{
                  display: currentContent === 'STAKEHOLDERS' ? 'block' : 'none',
                }}
              >
                <div className="head">
                  <div className="max-w-500x full-width-mobile">
                    <div className="row">
                      <div className="col" data-vertical_center>
                        <h3>Corporate Details</h3>
                      </div>

                      <div className="col-auto" data-vertical_center>
                        <button
                          className="btn btn-primary btn-float-mobile"
                          onClick={() => {
                            Modal.Open(<AddStakeholderModal onSuccess={(stakeholder) => dispatch(addStakeholder(stakeholder))} />, {
                              customClass: {
                                container: 'modal-mobile-fullscreen',
                                popup: 'max-w-400x',
                              },
                            });
                          }}
                        >
                          <span className="text hidden-768px">Add Stakeholder</span>
                          <span className="icon visible-768px">
                            <HiPlusSm />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <Gap v={2} />

                <div className="kyc-stakeholders-list">
                  {kyc.business?.stakeholders && kyc.business?.stakeholders.length > 0 ? (
                    <ul>
                      {kyc.business?.stakeholders.map((stk) => (
                        <li key={stk.id}>
                          <div className="row">
                            <div className="col">
                              <a>
                                <small>
                                  {stk.email ? (
                                    <>
                                      <span>{stk.email}</span>&nbsp;<b>&middot;</b>&nbsp;
                                    </>
                                  ) : null}

                                  <b>
                                    <i>{stk.type}</i>
                                  </b>
                                </small>
                                <Gap v={0.2} />
                                <h6>{stk.name}</h6>
                              </a>
                            </div>

                            <div className="col-auto" data-vertical_center>
                              <a
                                className="remove btn btn-danger"
                                onClick={() =>
                                  Modal.Confirm(
                                    'Remove Stakeholder?',
                                    <>
                                      Are you sure your want to remove <b>{stk.name}</b> from your stakeholders list?
                                    </>,
                                  ).then((b) => {
                                    if (b !== true) return;

                                    kycApi
                                      .removeStakeHolder(stk.id)
                                      .then(() => dispatch(removeStakeholder(stk.id)))
                                      .catch(handleRequestErrors);
                                  })
                                }
                              >
                                Remove&nbsp;&nbsp;✕
                              </a>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <h6 className="text-center">You haven't added any stakeholder(s)</h6>
                  )}
                </div>

                <Gap v={1} />

                <Button
                  type="submit"
                  className="btn btn-primary btn-full-width-mobile"
                  onClick={() => setCurrentContent('DOCS')}
                  text="Continue"
                />
              </div>

              <div
                style={{
                  display: currentContent === 'DOCS' ? 'block' : 'none',
                }}
              >
                <div className="max-w-500x full-width-mobile">
                  <h3>Corporate/Trust Formation Documents</h3>
                  <Gap v={1} />

                  <ul className="p-0 ps-3 m-0">
                    <li>Please provide all Identification documents</li>
                    <li>
                      If documents are in any language other than English, please ensure they are accompanied by a certified Engligh
                      translation.
                    </li>
                  </ul>

                  <Gap v={2} />

                  <div className="id-files-section">
                    <div className="section">
                      <h6>For all shareholders that own over 25% of the entity, or are directors of the company:</h6>
                      <Gap v={1} />

                      <ul>
                        <li>
                          <p>
                            Proof of Identity - Passport or Drivers Licence (If they do not hold a Nigerian Passport, please provide a clear
                            selfie with the proof of identity provided)
                          </p>

                          <div className="id-files">
                            {uploadFiles
                              .filter((file) => meansOfIdDocsOptions.map((id) => id.value).includes(file.category))
                              .map((file) => (
                                <div
                                  key={file.id}
                                  className="file"
                                  style={{
                                    marginBottom: '45px',
                                  }}
                                >
                                  <FileDisplay
                                    preview={file}
                                    src={file.data.fileUrl}
                                    fileName={file.name || file.data.fileName}
                                    onRemove={() => removeFile(file.id)}
                                  />

                                  <span
                                    style={{
                                      width: '80px',
                                      display: 'block',
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {file.name || file.data.fileName}
                                  </span>
                                </div>
                              ))}

                            <Dropdown
                              trigger={['click']}
                              overlay={
                                <Menu>
                                  {meansOfIdDocsOptions.map((id) => (
                                    <MenuItem key={id.value} onClick={() => addFile(id.value as any)}>
                                      {id.label}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              }
                              alignPoint
                            >
                              <a className="control">Add File</a>
                            </Dropdown>
                          </div>
                        </li>

                        <li>
                          <p>Proof of Address (Utility Bill or Bank Statement)</p>

                          <div className="id-files">
                            {uploadFiles
                              .filter((file) => {
                                const opts = proofOfAddressDocsOptions.map((id) => id.value);
                                return ['PROOF_OF_ADDRESS'].includes(file.category) && opts.includes(file.subCategory);
                              })
                              .map((file) => (
                                <div
                                  key={file.id}
                                  className="file"
                                  style={{
                                    marginBottom: '45px',
                                  }}
                                >
                                  <FileDisplay
                                    preview={file}
                                    src={file.data.fileUrl}
                                    fileName={file.name || file.data.fileName}
                                    onRemove={() => removeFile(file.id)}
                                  />

                                  <span
                                    style={{
                                      width: '80px',
                                      display: 'block',
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {file.name || file.data.fileName}
                                  </span>
                                </div>
                              ))}

                            <Dropdown
                              trigger={['click']}
                              overlay={
                                <Menu>
                                  {proofOfAddressDocsOptions.map((id) => (
                                    <MenuItem key={id.value} onClick={() => addFile('PROOF_OF_ADDRESS', id.value as any)}>
                                      {id.label}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              }
                              alignPoint
                            >
                              <a className="control">Add File</a>
                            </Dropdown>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="section">
                      <h6>In addition, please provide:</h6>

                      <ul>
                        <li>Certificate of Registration / Incorporation / Trust Deed</li>
                        <li>Register of Directors</li>
                        <li>
                          Register of Shareholders showing the Ultimate Beneficial Owners, the individual(s) with 25% or more interest /
                          Beneficiaries
                        </li>
                        <li>Register of Authorised Signatories (if there are other auth representative other than the directors)</li>
                        <li>Proof of Address (Utility Bill or Bank Statement)</li>
                      </ul>

                      <div className="id-files">
                        {uploadFiles
                          .filter((file) => businessKycDocsOptions.map((id) => id.value).includes(file.category))
                          .map((file) => (
                            <div
                              key={file.id}
                              className="file"
                              style={{
                                marginBottom: '45px',
                              }}
                            >
                              <FileDisplay
                                preview={file}
                                src={file.data.fileUrl}
                                fileName={file.name || file.data.fileName}
                                onRemove={() => removeFile(file.id)}
                              />

                              <span
                                style={{
                                  width: '80px',
                                  display: 'block',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {file.name || file.data.fileName}
                              </span>
                            </div>
                          ))}

                        <Dropdown
                          trigger={['click']}
                          overlay={
                            <Menu>
                              {businessKycDocsOptions.map((id) => {
                                if (id.options) {
                                  return (
                                    <SubMenu title={id.label} key={id.value}>
                                      {id.options.map((option) => (
                                        <MenuItem
                                          key={`${id.value}-${option.value}`}
                                          onClick={() => addFile(id.value as any, option.value as any)}
                                        >
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </SubMenu>
                                  );
                                }

                                return (
                                  <MenuItem key={id.value} onClick={() => addFile(id.value as any)}>
                                    {id.label}
                                  </MenuItem>
                                );
                              })}
                            </Menu>
                          }
                          alignPoint
                        >
                          <a className="control">Add File</a>
                        </Dropdown>
                      </div>
                    </div>

                    <input
                      multiple
                      type="file"
                      ref={inputFile}
                      accept={acceptedFiles}
                      style={{
                        opacity: 0,
                        display: 'none',
                      }}
                      onChange={(ev) => {
                        const files = Array.from(ev.target.files || [])
                          .filter((file) => !uploadFiles.find((f) => f.name === file.name))
                          .filter((file) => {
                            const ext = file.name.split('.').pop();
                            const allowedExts = acceptedFiles
                              .split(',')
                              .map((ext) => ext.trim())
                              .filter(Boolean);
                            const sizeError = file.size >= maxSizePerFile!;
                            return allowedExts.includes(`.${ext!.toLowerCase()}`) && !sizeError;
                          })
                          .map((file: any) => {
                            file.id = generateUniqueFileIdentifier(file);
                            file.extension = file.name.split('.').pop();
                            file.progress = 0;
                            file.uploading = false;
                            file.uploaded = false;
                            file.category = category;
                            file.subCategory = subCategory;
                            file.data = {};

                            return file;
                          });

                        setUploadFiles([...files, ...uploadFiles]);
                        setCategory(null);
                        setSubCategory(null);

                        ev.target.value = '';
                      }}
                    />
                  </div>

                  <Gap v={2} />

                  <div>
                    <p>
                      <b>IMPORTANT</b>: It is an offence under the Anti-Money Laundering and Counter Terrorism Financing Act 2006
                      Requirements to knowingly provide false or misleading information or knowingly produce a false or misleading document.
                    </p>
                    <p>By completing and signing the form I declare that the information I have provided is true and correct.</p>
                  </div>

                  <Button
                    disabled={!uploadFiles.every((f) => f.uploaded === true)}
                    type="submit"
                    className="btn btn-primary btn-full-width-mobile"
                    onClick={() => navigate(route.onboarding.sourceOfFunds)}
                    text="Continue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
