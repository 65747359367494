import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import serializeForm from 'form-serialize';
import { BankDto, DepositAccountDto, WithdrawalAccountDto } from '../../../typings';
import Gap from '../../components/Gap';
import { Select2 } from '../../components/html/Select';
import { LocationSelect } from '../../components/LocationSelect';
import * as depositApi from '../../../api/deposit';
import * as withdrawalApi from '../../../api/withdrawal';
import { handleRequestErrors } from '../../../utils';
import { Input } from '../../components/html/Input';
import { Button } from '../../components/html/Button';
import { Modal } from '../../components/Modal';
import { ConditionalElement } from '../../components/ConditionalElement';
import { Security2faDialog } from '../../components/2FADialog';
import { countriesWithBankList, supportedCountryCurrencies } from '../../../constants';
import { baseStore } from '../../../store';

type AddType = 'DEPOSIT' | 'WITHDRAWAL';

type AddBankAccountModal = {
  type?: AddType;
  onAccountAdd?(type: AddType, account: DepositAccountDto | WithdrawalAccountDto): void;
};

export const AddBankAccountModal = (props: AddBankAccountModal) => {
  const { wallets } = baseStore.getState();
  const [countryCode, setCountryCode] = useState<string>();
  const [bankList, setBankList] = useState<BankDto[]>([]);
  const [currency, setCurrency] = useState<{ label: string; value: string } | null>();
  const [currencyOptions, setCurrencyOptions] = useState<any[]>([]);

  const { onAccountAdd = () => {} } = props;

  useEffect(() => {
    setCurrency(null);

    if (countryCode) {
      setCurrencyOptions(
        wallets!
          .filter(({ currency }) => currency.type === 'FIAT')
          .filter(({ currency }) => (supportedCountryCurrencies as any)[countryCode].includes(currency.code))
          .map((wallet) => ({ label: wallet.currency.name, value: wallet.currency.code })),
      );
    }
  }, [wallets, countryCode]);

  return (
    <>
      <h4>Add Account</h4>
      <p>Add a bank account</p>

      <Gap v={0.5} />

      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          const form = ev.target as any;
          const { type, bankId = '', bankName, accountNumber, accountName, routingNumber, currency } = serializeForm(form, { hash: true });
          const useType = type || props.type;

          Modal.Open(
            <Security2faDialog
              operation={useType === 'DEPOSIT' ? 'DEPOSIT_ACCOUNT_ADD' : 'WITHDRAWAL_ACCOUNT_ADD'}
              handleSecurity={async (security, callback) => {
                try {
                  if (useType === 'DEPOSIT') {
                    await depositApi
                      .addAccount({
                        type: 'BANK_TRANSFER',
                        accountNumber,
                        bankId,
                        country: countryCode,
                        currency,
                        security,
                      })
                      .then((account) => onAccountAdd('DEPOSIT', account));
                  } else {
                    await withdrawalApi
                      .addAccount({
                        type: 'BANK',
                        accountNumber,
                        bankId,
                        bankName,
                        accountName,
                        routingNumber,
                        country: countryCode,
                        currency,
                        security,
                      })
                      .then((account) => onAccountAdd('WITHDRAWAL', account));
                  }

                  Modal.CloseAll();
                } catch (err) {
                  callback(null, err);
                  handleRequestErrors(err);
                }
              }}
            />,
            {
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: 'modal-mobile-fullscreen',
                popup: 'max-w-350x',
              },
            },
          );
        }}
      >
        <ConditionalElement
          condition={!props.type}
          element={
            <Select2
              required
              label="Type"
              value={props.type}
              options={[
                { label: 'Deposit Account', value: 'DEPOSIT' },
                { label: 'Withdrawal Account', value: 'WITHDRAWAL' },
              ]}
              name="type"
              placeholder="Select type"
              gapbottom={1}
            />
          }
        />

        <LocationSelect
          onlySupported
          select={['countries']}
          onChange={(ev) => {
            setCountryCode(ev.country?.iso);

            if (countriesWithBankList.includes(ev.country?.iso!)) {
              withdrawalApi
                .getBankList(ev.country?.iso!)
                .then(({ banks }) => setBankList(banks))
                .catch(handleRequestErrors);
            }
          }}
          gapBottom={1}
        />

        <label htmlFor="w-currency">Currency</label>
        <Gap v={0.5} />
        <Select
          id="w-currency"
          name="currency"
          options={currencyOptions}
          onChange={(ev: any) => setCurrency(ev)}
          value={currency}
          classNamePrefix="custom-select"
        />

        <Gap v={1} />

        {countriesWithBankList.includes(countryCode!) ? (
          <Select2
            required
            label="Select bank"
            options={bankList.map(({ bankId, bankName }) => ({
              label: bankName,
              value: bankId,
            }))}
            name="bankId"
            placeholder="Select bank"
            gapbottom={1}
          />
        ) : (
          <>
            <input type="hidden" name="bankId" value={`${countryCode}::000`} />

            <Input required label="Account Name" name="accountName" placeholder="Eg. Company Corp." gapbottom={1} />

            <Input required label="Bank name" name="bankName" placeholder="Eg. Standard Trust Bank" gapbottom={1} />

            <Input required label="Routing number" name="routingNumber" placeholder="Eg. 1100223476" gapbottom={1} />
          </>
        )}

        <Input label="Account number" name="accountNumber" placeholder="2004567891" gapbottom={2} />

        <Button className="btn btn-primary full-width" text={props.type === 'DEPOSIT' ? 'Register account' : 'Add account'} />
      </form>
    </>
  );
};
