import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { route } from '../../../routes';
import { ReducerStates } from '../../../typings';
import './styles.scss';

export const SettingsSidebar = () => {
  const { user } = useSelector((state: ReducerStates) => state);

  return (
    <div className="settings-navi">
      <div className="navi-wrap">
        <ul>
          <li>
            <NavLink to={route.dashboard.settings} className={({ isActive }) => `profile ${isActive ? 'current' : ''}`} end>
              Account
            </NavLink>
          </li>
          {user.type !== 'INDIVIDUAL' ? (
            <li>
              <NavLink to={route.settings.business} className={({ isActive }) => `business ${isActive ? 'current' : ''}`} end>
                Business
              </NavLink>
            </li>
          ) : null}
          <li>
            <NavLink to={route.settings.security} className={({ isActive }) => `security ${isActive ? 'current' : ''}`}>
              Security
            </NavLink>
          </li>
          <li>
            <NavLink to={route.settings.bankAccounts} className={({ isActive }) => `accounts ${isActive ? 'current' : ''}`}>
              Bank Accounts
            </NavLink>
          </li>
          <li>
            <NavLink to={route.settings.cryptoAddresses} className={({ isActive }) => `addresses ${isActive ? 'current' : ''}`}>
              Crypto Addresses
            </NavLink>
          </li>
          <li>
            <NavLink to={route.settings.team} className={({ isActive }) => `team ${isActive ? 'current' : ''}`}>
              Team
            </NavLink>
          </li>
          {/* <li>
            <NavLink to={route.settings.compliance} className={({ isActive }) => `compliance ${isActive ? 'current' : ''}`}>
              Compliance
            </NavLink>
          </li> */}
          <li>
            <NavLink to={route.settings.developers} className={({ isActive }) => `developers ${isActive ? 'current' : ''}`}>
              Developers
            </NavLink>
          </li>
          <li>
            <NavLink to={route.settings.notifications} className={({ isActive }) => `notifications ${isActive ? 'current' : ''}`}>
              Notifications
            </NavLink>
          </li>
          {/* <li>
            <NavLink to={route.settings.system} className={({ isActive }) => `system ${isActive ? 'current' : ''}`}>
              System
            </NavLink>
          </li> */}
        </ul>
      </div>
    </div>
  );
};
