import { WalletAction, WalletAsset } from '../typings/wallets';

const initialState: WalletAsset[] = [];

export default (state = initialState, action: WalletAction) => {
  switch (action.type) {
    case 'SET_WALLET_ASSETS':
      return action.assets;
    default:
      return state;
  }
};
