import Request from '../helpers/request';
import { StatusDto, WithSecurity } from '../typings';
import { DeveloperKeyDto, DeveloperKeysDto, WebhookSubscriptionDto, WebhookSubscriptionsDto } from '../typings/developer';

export const getKeys = async (): Promise<DeveloperKeysDto> => {
  return await Request.getWithUserToken<DeveloperKeysDto>(`${process.env.REACT_APP_API_URL}/developer/me/keys`);
};

export const createKey = async (data: WithSecurity<Partial<DeveloperKeyDto>>): Promise<DeveloperKeyDto> => {
  const { label, whiteListedIPs, permissions, security } = data;

  return await Request.postWithUserToken<DeveloperKeyDto>(`${process.env.REACT_APP_API_URL}/developer/me/keys`, {
    data: {
      label,
      whiteListedIPs,
      permissions,
      security,
    },
  });
};

export const removeKey = async (id: string, security: string): Promise<StatusDto> => {
  return await Request.deleteWithUserToken<StatusDto>(`${process.env.REACT_APP_API_URL}/developer/me/keys/${id}`, {
    data: {
      security,
    },
  });
};

export const getWebhookSubscriptions = async (): Promise<WebhookSubscriptionsDto> => {
  return await Request.getWithUserToken<WebhookSubscriptionsDto>(`${process.env.REACT_APP_API_URL}/developer/me/webhook/subscriptions`);
};

export const createWebhookSubscription = async (data: WithSecurity<Partial<WebhookSubscriptionDto>>): Promise<WebhookSubscriptionDto> => {
  const { url, events, security, secret } = data;

  return await Request.putWithUserToken<WebhookSubscriptionDto>(`${process.env.REACT_APP_API_URL}/developer/me/webhook/subscriptions`, {
    data: {
      url,
      events,
      secret,
      security,
    },
  });
};

export const removeWebhookSubscription = async (id: string, security: string): Promise<StatusDto> => {
  return await Request.deleteWithUserToken<StatusDto>(`${process.env.REACT_APP_API_URL}/developer/me/webhook/subscriptions/${id}`, {
    data: {
      security,
    },
  });
};
