import Request from '../helpers/request';
import { StatusDto } from '../typings';
import { BusinessKycDetailsDto, KycDocDto, ProfileKycDetailsDto, StakeholderKycDto } from '../typings/kyc';

export const getPersonalInfo = async (): Promise<ProfileKycDetailsDto> => {
  return await Request.getWithUserToken<ProfileKycDetailsDto>(`${process.env.REACT_APP_API_URL}/kyc/me`);
};

export const updatePersonalInfo = async (data: Partial<ProfileKycDetailsDto>): Promise<ProfileKycDetailsDto> => {
  return await Request.postWithUserToken<ProfileKycDetailsDto>(`${process.env.REACT_APP_API_URL}/kyc/me`, {
    data,
  });
};

export const getBusinessInfo = async (): Promise<BusinessKycDetailsDto> => {
  return await Request.getWithUserToken<BusinessKycDetailsDto>(`${process.env.REACT_APP_API_URL}/kyc/me/business`);
};

export const updateBusinessInfo = async (data: Partial<BusinessKycDetailsDto>): Promise<BusinessKycDetailsDto> => {
  return await Request.postWithUserToken<BusinessKycDetailsDto>(`${process.env.REACT_APP_API_URL}/kyc/me/business`, {
    data,
  });
};

export const addStakeHolder = async (data: Partial<StakeholderKycDto>): Promise<StakeholderKycDto> => {
  return await Request.putWithUserToken<StakeholderKycDto>(`${process.env.REACT_APP_API_URL}/kyc/me/business/stakeholders`, {
    data,
  });
};

export const removeStakeHolder = async (id: string): Promise<StatusDto> => {
  return await Request.deleteWithUserToken<StatusDto>(`${process.env.REACT_APP_API_URL}/kyc/me/business/stakeholders/${id}`);
};

export const addFile = async (data: Partial<KycDocDto>): Promise<KycDocDto> => {
  return await Request.putWithUserToken<KycDocDto>(`${process.env.REACT_APP_API_URL}/kyc/me/docs`, {
    data,
  });
};

export const removeFile = async (id: string, mode: 'PROFILE' | 'BUSINESS'): Promise<StatusDto> => {
  return await Request.deleteWithUserToken<StatusDto>(`${process.env.REACT_APP_API_URL}/kyc/me/docs/${id}?mode=${mode}`);
};

export const submit = async (): Promise<StatusDto> => {
  return await Request.postWithUserToken<StatusDto>(`${process.env.REACT_APP_API_URL}/kyc/me/submit`);
};
