import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { route } from '../../../routes';
import { ReducerStates } from '../../../typings';
import { businessInfoComplete, profileInfoComplete, sourceOfFundsInfoComplete, tradingActInfoComplete } from '../../../utils/user';
import { ConditionalElement } from '../ConditionalElement';
import logoSecondary from '../../../assets/logo-secondary.svg';
import Gap from '../Gap';
import './sidebar.scss';

export const Sidebar = () => {
  const { user, kyc } = useSelector((state: ReducerStates) => state);
  const kycDetails = user.type === 'INDIVIDUAL' ? kyc.individual : kyc.business;

  return (
    <div className="onboarding-sidebar">
      <div className="text-white onboarding-nav-content-wrap">
        <div className="nav-logo hidden-1200px">
          <a href="" className="logo">
            <img src={logoSecondary} alt="logo" />
          </a>
        </div>

        <div className="centered">
          <div className="centered-content">
            <div className="nav-content">
              <Gap v={5} className="hidden-768px" />
              <Gap v={3} />

              <h6 className="title">Setup your Host Capital account</h6>
              <Gap v={1} />

              <ul className="nav nav-pills">
                <li>
                  <NavLink
                    to={route.onboarding.profile}
                    className={({ isActive }) => `${isActive ? 'current' : ''} ${profileInfoComplete(kyc.individual! || {}) ? 'done' : ''}`}
                  >
                    <h6>Profile Information</h6>
                    <span>You'll need this to enjoy our services</span>
                  </NavLink>
                </li>

                {
                  <ConditionalElement
                    condition={user.type !== 'INDIVIDUAL'}
                    element={
                      <li>
                        <NavLink
                          to={route.onboarding.corporate}
                          className={({ isActive }) =>
                            `${isActive ? 'current' : ''} ${businessInfoComplete(kyc.business! || {}) ? 'done' : ''}`
                          }
                        >
                          <h6>Business information</h6>
                          <span>We'll need to verify your business</span>
                        </NavLink>
                      </li>
                    }
                  />
                }

                <li>
                  <NavLink
                    to={route.onboarding.sourceOfFunds}
                    className={({ isActive }) => ` ${isActive ? 'current' : ''} ${sourceOfFundsInfoComplete(kycDetails!) ? 'done' : ''}`}
                  >
                    <h6>Source of funds</h6>
                    <span>Delare your funding source</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to={route.onboarding.tradingActivities}
                    className={({ isActive }) => ` ${isActive ? 'current' : ''} ${tradingActInfoComplete(kycDetails!) ? 'done' : ''}`}
                  >
                    <h6>Trading activities</h6>
                    <span>What type of trading will you do</span>
                  </NavLink>
                </li>
              </ul>

              <Gap v={3} />

              {/* <Link to={route.onboarding.termsOfService} className="legal">
                Legal agreement
              </Link>
              <Gap v={3} /> */}
            </div>
          </div>
        </div>

        <div className="pb-4">{/* <span className="d-none d-sm-inline mx-1">Holla</span> */}</div>
      </div>
    </div>
  );
};
