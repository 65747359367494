import Request from '../helpers/request';
import { Security2faTypes, StatusDto, OtpOperationTypes, WithSecurity } from '../typings';
import { BusinessDto } from '../typings/business';
import { UserDto, OnboardingUpdateDto, TokenDto, Update2faRequestDto, TeamMemberListDto, TeamMemberDto } from '../typings/user';

export const getActiveUser = async (): Promise<UserDto> => {
  return await Request.getWithUserToken<UserDto>(`${process.env.REACT_APP_API_URL}/user/me`);
};

export const updateUser = async (data: Partial<UserDto>) => {
  return await Request.patchWithUserToken<UserDto>(`${process.env.REACT_APP_API_URL}/user/me`, {
    data,
  });
};

export const getActiveBusiness = async (): Promise<BusinessDto> => {
  return await Request.getWithUserToken<BusinessDto>(`${process.env.REACT_APP_API_URL}/user/me/business`);
};

export const updateBusiness = async (data: Partial<BusinessDto>) => {
  return await Request.patchWithUserToken<BusinessDto>(`${process.env.REACT_APP_API_URL}/user/me/business`, {
    data,
  });
};

export const setup2Fa = async (type: Security2faTypes, otp?: string): Promise<StatusDto> => {
  return await Request.postWithUserToken<StatusDto>(`${process.env.REACT_APP_API_URL}/user/me/2fa/${type}/setup`, {
    data: {
      pin: otp,
    },
  });
};

export const verify2Fa = async (type: Security2faTypes, otp: string): Promise<StatusDto> => {
  return await Request.postWithUserToken<StatusDto>(`${process.env.REACT_APP_API_URL}/user/me/2fa/${type}/verify`, {
    data: {
      pin: otp,
    },
  });
};

export const update2Fa = async (type: Security2faTypes, data: WithSecurity<Update2faRequestDto>) => {
  return await Request.postWithUserToken(`${process.env.REACT_APP_API_URL}/user/me/2fa/${type}/update`, {
    data,
  });
};

export const changePassword = async (currentPassword: string, newPassword: string, security: string): Promise<StatusDto> => {
  return await Request.postWithUserToken<StatusDto>(`${process.env.REACT_APP_API_URL}/user/me/password`, {
    data: {
      currentPassword,
      newPassword,
      security,
    },
  });
};

export const updateOnboarding = async (token: string, data: OnboardingUpdateDto): Promise<TokenDto> => {
  return await Request.post<TokenDto>(`${process.env.REACT_APP_API_URL}/user/me/onboarding/update`, {
    data,
    headers: {
      'x-auth-token': token,
    },
  });
};

export const requestOtp = async (type: Security2faTypes, operation: OtpOperationTypes): Promise<StatusDto> => {
  return await Request.postWithUserToken<StatusDto>(`${process.env.REACT_APP_API_URL}/user/me/security/otp/request`, {
    data: {
      type,
      operation,
    },
  });
};

export const getTeamMembers = async (): Promise<TeamMemberListDto> => {
  return await Request.getWithUserToken<TeamMemberListDto>(`${process.env.REACT_APP_API_URL}/user/teams`);
};

export const addTeamMember = async (data: WithSecurity<TeamMemberDto>): Promise<TeamMemberDto> => {
  return await Request.postWithUserToken<TeamMemberDto>(`${process.env.REACT_APP_API_URL}/user/teams`, {
    data,
  });
};

export const removeTeamMember = async (id: string, security: string): Promise<StatusDto> => {
  return await Request.postWithUserToken<StatusDto>(`${process.env.REACT_APP_API_URL}/user/teams/${id}`, {
    data: {
      security,
    },
  });
};

export const updateTeamMember = async (id: string, data: WithSecurity<Partial<TeamMemberDto>>): Promise<TeamMemberDto> => {
  return await Request.patchWithUserToken<TeamMemberDto>(`${process.env.REACT_APP_API_URL}/user/teams/${id}`, {
    data,
  });
};

export const respondToTeamInvite = async (response: 'ACCEPT' | 'REJECT', token: string) => {
  return await Request.post<TokenDto>(`${process.env.REACT_APP_API_URL}/user/auth/team/invite/respond`, {
    data: {
      response,
    },
    headers: {
      'x-auth-token': token,
    },
  });
};
