import Axios from 'axios';
import Request from '../helpers/request';
import { FileUploadType, PresignedUrlGeneratedDto } from '../typings/files';

export const generatePresignedUrl = async (contentType: string, uploadType: FileUploadType): Promise<PresignedUrlGeneratedDto> => {
  return await Request.postWithUserToken<PresignedUrlGeneratedDto>(
    `${process.env.REACT_APP_API_URL}/files/me/upload/generate-presigned-url`,
    {
      data: {
        contentType,
        uploadType,
      },
    },
  );
};

interface UploadOptions {
  signedUrl: string;
  file: File;
  onUploadProgress?(progress: number): void;
  getCancelToken?(cancelToken: any): void;
}

/**
 * Upload a file
 * @param {UploadOptions} options
 */
export const uploadFile = async (options: UploadOptions): Promise<any> => {
  const { signedUrl, file, onUploadProgress = () => {}, getCancelToken = () => {} } = options;

  return await Request.put(signedUrl, {
    data: file,
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      onUploadProgress!((loaded / total) * 100);
    },
    cancelToken: new Axios.CancelToken((c) => getCancelToken(c)),
  });
};
