import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { WalletAssetAddress } from '../../typings';
import { Header } from '../components/DashboardHeader';
import { Sidebar } from '../components/DashboardSidebar';
import * as walletsApi from '../../api/wallets';
import filterIcon from '../../assets/filter.svg';
import Gap from '../components/Gap';
import { handleRequestErrors } from '../../utils';
import { OnboardingNote } from '../components/OnboardingNote';
import { ConditionalElement } from '../components/ConditionalElement';
import { CopiableText } from '../components/CopiableText';
import './styles.scss';

export const Addresses = () => {
  const [loading, setLoading] = useState(false);
  const [loadingPosition, setLoadingPosition] = useState<'LEFT' | 'RIGHT'>();
  const [page, setPage] = useState(1);
  const [size] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [addresses, setAddresses] = useState<WalletAssetAddress[]>([]);

  const pageTotal = size * page > totalItems ? totalItems : size * page;

  useEffect(() => {
    setLoading(true);

    if (loading) {
      return;
    }

    walletsApi
      .getWalletSubAddresses({
        page,
        size,
      })
      .then((res) => {
        setAddresses(res.records);
        setTotalItems(res.totalItems);
      })
      .catch(handleRequestErrors)
      .finally(() => {
        setLoading(false);
        setLoadingPosition(undefined);
      });
  }, [page, size]);

  return (
    <>
      <Helmet title="Addresses | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap addresses-dashboard">
              <div className="content-inner">
                <Header withTicker={false} />

                <Gap v={2} />

                <div className="orders-page-wrap">
                  <OnboardingNote gapBottom={2} />

                  <div className="table-advanced">
                    <div className="row">
                      <div className="col" data-vertical_center>
                        <div className="row">
                          <div className="col">
                            <h5>Addresses</h5>
                          </div>
                          <div className="col"></div>
                        </div>
                      </div>

                      <div className="col-auto" data-vertical_center>
                        <div className="btn-filters-set">
                          <button className="btn btn-primary btn-outline filter hidden-768px hidden">
                            <img src={filterIcon} alt="filter" /> Filter
                          </button>
                        </div>
                      </div>
                    </div>

                    <Gap v={1} />
                    <Gap v={1} className="visible-768px" />

                    <div className="table-wrapper addresses-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Asset</th>
                            <th>Network</th>
                            <th className="text-end">Identifier</th>
                            <th className="text-start ps-5">Address</th>
                            <th className="text-end">Date Created</th>
                          </tr>
                        </thead>

                        <tbody>
                          {addresses.map(({ id, currency, network, address, identifier, createdAt }) => (
                            <tr key={id}>
                              <td>
                                <div className="illus-wrap">
                                  <div className="illus">
                                    <img
                                      src={`https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/currencies/${currency.toLocaleLowerCase()}.svg`}
                                      alt=""
                                    />
                                  </div>

                                  <div className="info">
                                    <div className="h fw-bold">
                                      <div className="truncate-text">
                                        {currency} <span className="visible-768px visible-inline fw-normal">({network})</span>
                                      </div>
                                      <small className="text-muted visible-768px">
                                        {moment(new Date(createdAt)).format('MMM DD, yyyy hh:mm a')}
                                      </small>
                                    </div>

                                    <CopiableText text={address} className="truncate-text addr visible-768px" />
                                  </div>
                                </div>
                              </td>
                              <td className="hidden-768px">{network}</td>
                              <td className="text-end hidden-768px">{identifier}</td>
                              <td className="text-start ps-5 hidden-768px">
                                <CopiableText text={address} />
                              </td>
                              <td className="text-end hidden-768px">{moment(new Date(createdAt)).format('MMM DD, yyyy hh:mm a')}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <ConditionalElement
                        condition={loading === false && addresses.length === 0}
                        element={
                          <div className="no-result">
                            <h5>You don't have any additional addresses</h5>
                          </div>
                        }
                      />
                    </div>

                    <Gap v={1} />

                    <div className="table-pagination">
                      <div className="pagination-wrap">
                        <div className="item-total hidden">
                          <span className="color-4F4F4F">Rows per page: &nbsp;&nbsp;</span> 8
                        </div>

                        <ConditionalElement
                          condition={totalItems > size}
                          element={
                            <div className="item-nav">
                              <span className="color-4F4F4F">
                                {(page - 1) * size + 1} - {pageTotal} of {totalItems.toLocaleString('en')}
                              </span>
                              <Gap h={1} />
                              <a
                                className={`prev ${loading && loadingPosition === 'LEFT' ? 'loading' : ''}`}
                                onClick={() => {
                                  if (loading) {
                                    return;
                                  }

                                  const prevPage = page - 1;

                                  if (prevPage <= 0) {
                                    return;
                                  }

                                  setPage(prevPage);
                                  setLoadingPosition('LEFT');
                                }}
                              />
                              <a
                                className={`next ${loading && loadingPosition === 'RIGHT' ? 'loading' : ''}`}
                                onClick={() => {
                                  if (loading) {
                                    return;
                                  }

                                  const nextPage = page + 1;

                                  if (nextPage > Math.ceil(totalItems / size)) {
                                    return;
                                  }

                                  setPage(nextPage);
                                  setLoadingPosition('RIGHT');
                                }}
                              />
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
