import { KycAction, KycState, ProfileKycDetailsDto, BusinessKycDetailsDto } from '../typings/kyc';

const initialState: KycState = {};

export default (state = initialState, action: KycAction): Partial<KycState> => {
  switch (action.type) {
    case 'SET_INDIVIDUAL_KYC':
      return {
        ...state,
        individual: action.details as ProfileKycDetailsDto,
      };
    case 'SET_BUSINESS_KYC':
      return {
        ...state,
        business: action.details as BusinessKycDetailsDto,
      };
    case 'ADD_STAKEHOLDER':
      return {
        ...state,
        business: {
          ...state.business!,
          stakeholders: [action.stakeholder!, ...state.business?.stakeholders!],
        },
      };
    case 'REMOVE_STAKEHOLDER':
      return {
        ...state,
        business: {
          ...state.business!,
          stakeholders: state.business?.stakeholders.filter((stk) => stk.id !== action.id)!,
        },
      };
    default:
      return state;
  }
};
