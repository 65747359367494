import moment from 'moment';
import config from '../config';
import Request from '../helpers/request';
import { Paging, StatusDto, WithSecurity } from '../typings';
import { CurrencyType } from '../typings/rates';
import {
  WalletAsset,
  WalletAssetAddress,
  WalletAssetList,
  WalletAssetSwapRequest,
  WalletAssetTransactionsList,
  WalletAssetTransferRequest,
  WalletAssetTransferTransactions,
  WalletAssetTransactionType,
  WalletTransactionCategory,
} from '../typings/wallets';
import { getQueryParams } from '../utils';

export const getWallets = async (): Promise<WalletAssetList> => {
  return await Request.getWithUserToken<WalletAssetList>(`${config.api.wallets}/me/assets`);
};

export const createWallet = async (currency: string, type: CurrencyType, security: string): Promise<WalletAsset> => {
  return await Request.postWithUserToken<WalletAsset>(`${config.api.wallets}/me/assets`, {
    data: {
      currency,
      type,
      security,
    },
  });
};

export const getWalletById = async (id: string): Promise<WalletAsset> => {
  return await Request.getWithUserToken<WalletAsset>(`${config.api.wallets}/me/assets/${id}`);
};

export const getWalletAddress = async (id: string, network: string): Promise<WalletAssetAddress> => {
  return await Request.getWithUserToken<WalletAssetAddress>(`${config.api.wallets}/me/assets/${id}/address?network=${network}`);
};

export const getWalletSubAddresses = async (data: {
  identifier?: string;
  currency?: string;
  network?: string;
  assetId?: string;
  page?: number;
  size?: number;
}): Promise<Paging<WalletAssetAddress[]>> => {
  const { identifier, currency, network, assetId, page = 1, size = 20 } = data;
  return await Request.getWithUserToken<Paging<WalletAssetAddress[]>>(
    `${config.api.wallets}/me/assets/sub-addresses${getQueryParams({
      identifier,
      currency,
      network,
      assetId,
      page,
      size,
    })}`,
  );
};

export const getFavorites = async (): Promise<WalletAssetList> => {
  return await Request.getWithUserToken<WalletAssetList>(`${config.api.wallets}/me/assets/favorites`);
};

export const setUnsetFavorite = async (id: string): Promise<StatusDto> => {
  return await Request.postWithUserToken<StatusDto>(`${config.api.wallets}/me/assets/favorites?id=${id}`);
};

export const getTransactions = async (data: {
  page: number;
  size: number;
  status?: string;
  assetId?: string;
  type?: WalletAssetTransactionType;
  category?: WalletTransactionCategory;
  currency?: string;
  reference?: string;
  identifier?: string;
  fromDate?: Date;
  toDate?: Date;
  fromAmount?: number;
  toAmount?: number;
  includeSubTransactions?: boolean;
  onlySubTransactions?: boolean;
}): Promise<WalletAssetTransactionsList> => {
  const {
    page = 1,
    size = 20,
    type,
    assetId,
    status,
    currency,
    category,
    reference,
    identifier,
    fromAmount,
    toAmount,
    includeSubTransactions,
    onlySubTransactions,
    fromDate,
    toDate,
  } = data;

  return await Request.getWithUserToken<WalletAssetTransactionsList>(
    `${config.api.wallets}/me/assets/transactions${getQueryParams({
      page,
      size,
      type,
      status,
      assetId,
      category,
      currency,
      reference,
      identifier,
      fromAmount,
      toAmount,
      onlySubTransactions,
      includeSubTransactions,
      fromDate: fromDate ? moment(fromDate, 'ddd MMM D Y HH:mm:s').format('yyyy-MM-DD') : undefined,
      toDate: toDate ? moment(toDate, 'ddd MMM D Y HH:mm:s').format('yyyy-MM-DD') : undefined,
    })}`,
  );
};

export const getWalletTransactions = async (
  id: string,
  page = 1,
  size = 20,
  type?: WalletAssetTransactionType,
): Promise<WalletAssetTransactionsList> => {
  return await Request.getWithUserToken<WalletAssetTransactionsList>(
    `${config.api.wallets}/me/assets/${id}/transactions${getQueryParams({ page, size, type })}`,
  );
};

export const swap = async (data: WithSecurity<WalletAssetSwapRequest>): Promise<WalletAssetTransferTransactions> => {
  return await Request.postWithUserToken<WalletAssetTransferTransactions>(`${config.api.wallets}/me/assets/swap`, {
    data,
  });
};

export const transfer = async (data: WithSecurity<WalletAssetTransferRequest>): Promise<WalletAssetTransferTransactions> => {
  return await Request.postWithUserToken<WalletAssetTransferTransactions>(`${config.api.wallets}/me/assets/transfer`, {
    data,
  });
};

export const getTrendingAssets = async (): Promise<WalletAssetList> => {
  return await Request.getWithUserToken<WalletAssetList>(`${config.api.wallets}/me/assets/trending`);
};

export const getDefaultAssets = async (): Promise<WalletAssetList> => {
  return await Request.getWithUserToken<WalletAssetList>(`${config.api.wallets}/me/assets/default`);
};
