import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Cleave from 'cleave.js/react';
import { ConditionalElement } from '../ConditionalElement';
import { formatCurrency, handleRequestErrors } from '../../../utils';
import { CurrencyDefinition, ExchangeRate, FeeDto, WalletAsset } from '../../../typings';
import { Button } from '../html/Button';
import { baseStore } from '../../../store';
import * as ratesApi from '../../../api/rates';
import * as walletApi from '../../../api/wallets';
import { Modal } from '../Modal';
import { setWalletAssets } from '../../../actions/wallets';
import { Security2faDialog } from '../2FADialog';
import { applyFee } from '../../../utils/math';
import { Note } from '../Note';
import Gap from '../Gap';
import './styles.scss';

interface ExchangeDialogProps {
  currency?: CurrencyDefinition;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ExchangeDialog = (props: ExchangeDialogProps) => {
  const { wallets, kyc } = baseStore.getState();
  const [preview, setPreview] = useState(false);
  const [pairAssets, setPairAssets] = useState<{ [x: string]: WalletAsset[] }>({});
  const [sourceCurrency, setSourceCurrency] = useState<{ label: string; value: string }>();
  const [targetCurrency, setTargetCurrency] = useState<{ label: string; value: string }>();
  const [sourceQuantity, setSourceQuantity] = useState<number>(0);
  const [targetQuantity, setTargetQuantity] = useState<number>(0);
  const [sourceWallet, setSourceWallet] = useState<WalletAsset>();
  const [targetWallet, setTargetWallet] = useState<WalletAsset>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [exchangeFees, setExchangeFees] = useState<FeeDto[]>([]);
  const [conversion, setConversion] = useState<ExchangeRate>();

  const sourceCurrencyOptions = wallets?.map(({ currency }) => ({ label: currency.name, value: currency.code, type: currency.type }));
  const targetCurrencyOptions = pairAssets[sourceCurrency?.value!]?.map(({ currency }) => ({
    label: currency.name,
    value: currency.code,
    type: currency.type,
  }));

  useEffect(() => {
    const useCurrency = sourceCurrency?.value;

    if (!useCurrency || pairAssets[useCurrency]) {
      return;
    }

    ratesApi
      .getSwapPairs(useCurrency)
      .then(({ pairs }) => {
        setPairAssets({
          ...pairAssets,
          [useCurrency]: wallets!.filter((asset) => pairs.map((pair) => pair.code).includes(asset.currency.code)),
        });
      })
      .catch((err) => handleRequestErrors(err, `Unable to get swap pairs for ${useCurrency}`));
  }, [sourceCurrency]);

  useEffect(() => {
    if (sourceCurrency?.value === targetCurrency?.value) {
      return;
    }

    const fee = exchangeFees.find((eFee) => eFee.sourceCurrency === sourceCurrency?.value && eFee.targetCurrency === targetCurrency?.value);

    if (!fee && sourceCurrency?.value && targetCurrency?.value) {
      ratesApi
        .getFee('exchange', sourceCurrency.value, targetCurrency.value)
        .then((eFee) => setExchangeFees([...exchangeFees, ...eFee.fees]))
        .catch((err) => handleRequestErrors(err, `Unable to get exchange fees for ${sourceCurrency.value} to ${targetCurrency.value}`));
    }

    if (sourceCurrency?.value && targetCurrency?.value) {
      setConversion(undefined);

      ratesApi
        .getSwapConversion({ fromCurrency: sourceCurrency.value, toCurrency: targetCurrency.value })
        .then((conv) => setConversion(conv))
        .catch((err) =>
          handleRequestErrors(err, `Unable to get conversion rate for conversion from ${sourceCurrency.value} to ${targetCurrency.value}`),
        );
    }
  }, [sourceCurrency, targetCurrency]);

  const reset = () => {
    setTargetQuantity(0);
    setSourceQuantity(0);
  };

  useEffect(() => {
    const kycData = kyc.business || kyc.individual;
    const localCurrency = kycData?.currency;

    if (!localCurrency || sourceCurrency) {
      return;
    }

    reset();

    setSourceCurrency(sourceCurrencyOptions?.find((option) => option.value === localCurrency?.code));
    setSourceWallet(wallets?.find((wallet) => wallet.currency.code === localCurrency?.code));
  }, [kyc.individual, kyc.business]);

  useEffect(() => {
    if (!props.currency) {
      return;
    }

    reset();

    setTargetCurrency(targetCurrencyOptions?.find((option) => option.value === props?.currency?.code));
    setTargetWallet(wallets?.find((wallet) => wallet.currency.code === props.currency?.code));
  }, [props.currency, pairAssets]);

  const conversionRate = conversion ? 1 / conversion.rate : 0;

  const sourceQuantityChange = (value: number) => {
    setSourceQuantity(value);
    setErrorMessage('');

    if (!targetCurrency || !sourceCurrency) {
      return;
    }

    if (sourceWallet?.balance! < value) {
      setErrorMessage('You do not have sufficient balance for this transaction.');
    }

    if (!conversionRate) {
      return;
    }

    setTargetQuantity(value / conversionRate);
  };

  const targetQuantityChange = (value: number) => {
    setTargetQuantity(value);
    setErrorMessage('');

    if (!targetCurrency || !sourceCurrency) {
      return;
    }

    if (!conversionRate) {
      return;
    }

    const sourceValue = value * conversionRate;

    if (sourceWallet?.balance! < sourceValue) {
      setErrorMessage('You do not have sufficient balance for this transaction.');
    }

    setSourceQuantity(sourceValue);
  };

  const executeTrade = async () => {
    Modal.Open(
      <Security2faDialog
        operation="ASSET_SWAP"
        handleSecurity={async (security, callback) => {
          walletApi
            .swap({
              source: {
                assetId: sourceWallet?.id,
                currency: sourceWallet?.currency.code!,
              },
              target: {
                assetId: targetWallet?.id,
                currency: targetWallet?.currency.code!,
              },
              amount: {
                value: sourceQuantity,
                currency: sourceWallet?.currency.code!,
              },
              security,
            })
            .then(({ transactions }) => {
              if (transactions.every((tx) => tx.status === 'SUCCESS')) {
                Modal.Alert(
                  'Exchange Success',
                  `You successfully exchanged your ${sourceWallet?.currency.code} for some ${targetWallet?.currency.code}`,
                );
                setTimeout(() => walletApi.getWallets().then((res) => baseStore.dispatch(setWalletAssets(res.assets))), 1500);
              } else {
                Modal.Alert(
                  'Exchange Failed',
                  `An unexpected error occurred. Your request to exchange ${sourceWallet?.currency.code} for ${targetWallet?.currency.code} failed and your funds have been reversed to your wallet.`,
                );
              }
            })
            .catch((err) => {
              callback(null, err);
              handleRequestErrors(err);
            });
        }}
      />,
      {
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'modal-mobile-fullscreen',
          popup: 'max-w-350x',
        },
      },
    );
  };

  const getFee = () => {
    const fee = exchangeFees.find((eFee) => eFee.sourceCurrency === sourceCurrency?.value && eFee.targetCurrency === targetCurrency?.value);

    return fee ? applyFee(targetQuantity, fee) : 0;
  };

  const tradeDisabled = !sourceWallet || !targetWallet || sourceQuantity === 0 || !!errorMessage;

  return preview ? (
    <>
      <div className="head">
        <h4>
          Exchange {sourceWallet?.currency.code} for {targetWallet?.currency.code}
        </h4>
      </div>

      <Gap v={1} />

      <div className="buy-trade-preview">
        <div className="info">
          <div className="left">Pay With</div>
          <div className="right">
            {formatCurrency(sourceWallet?.type || 'CRYPTO', sourceQuantity, sourceCurrency?.value!, sourceWallet?.currency.decimals)}
          </div>
        </div>

        <div className="info">
          <div className="left">Receive</div>
          <div className="right">
            {formatCurrency(targetWallet?.type || 'CRYPTO', targetQuantity, targetCurrency?.value!, targetWallet?.currency.orderDecimals)}
          </div>
        </div>

        <div className="info">
          <div className="left">Exchange Rate</div>
          <div className="right">
            1 {targetCurrency?.value} ={' '}
            {formatCurrency(sourceWallet?.type || 'FIAT', conversionRate, sourceCurrency?.value!, sourceWallet?.currency.decimals)}
          </div>
        </div>

        <div className="info">
          <div className="left">Fee</div>
          <div className="right">
            {formatCurrency(targetWallet?.type || 'CRYPTO', getFee(), targetCurrency?.value!, targetWallet?.currency.decimals)}
          </div>
        </div>

        <div className="info">
          <div className="left">Operation Time</div>
          <div className="right">Instant</div>
        </div>
      </div>

      <Gap v={1} />

      <Button
        disabled={tradeDisabled}
        className="btn btn-primary full-width"
        text={`Exchange ${sourceCurrency?.value} for ${targetCurrency?.value}`}
        onClick={() => executeTrade()}
      />
    </>
  ) : (
    <>
      <div className="head">
        <h4>
          Exchange {sourceWallet?.currency.code} for {targetWallet?.currency.code}
        </h4>
      </div>

      <Note>Swap between multiple assets at current market rate. No hidden charges.</Note>

      <Gap v={1} />

      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          setPreview(true);
        }}
      >
        <div className="row">
          <div className="col-12">
            <label className="mb-2">
              Pay With <span>*</span>
            </label>
          </div>

          <div className="col-4">
            <Select
              name="sourceCurrency"
              options={[
                {
                  label: 'Fiat',
                  options: (sourceCurrencyOptions || []).filter((option) => option.type === 'FIAT'),
                },
                {
                  label: 'Crypto',
                  options: (sourceCurrencyOptions || []).filter((option) => option.type === 'CRYPTO'),
                },
              ]}
              onChange={(ev: any) => {
                reset();
                setSourceCurrency(ev);
                setSourceWallet(wallets?.find((wallet) => wallet.currency.code === ev.value));
              }}
              value={sourceCurrency}
              classNamePrefix="custom-select"
            />
          </div>

          <div className="col-8 ps-0">
            <Cleave
              key={`${sourceWallet?.currency.code}-select-pay`}
              required
              id="input-text"
              name="sourceValue"
              className="form-control"
              placeholder={`${formatCurrency(
                sourceWallet?.currency.type!,
                0,
                sourceWallet?.currency.code!,
                sourceWallet?.currency.decimals,
              )}`}
              autoComplete="off"
              options={{
                numeral: true,
                numeralDecimalScale: sourceWallet?.currency.orderDecimals,
                stripLeadingZeroes: false,
                numeralThousandsGroupStyle: 'thousand',
              }}
              value={sourceQuantity}
              onChange={(ev) => sourceQuantityChange(Number(ev.target.rawValue || 0))}
            />
          </div>
        </div>

        <ConditionalElement
          condition={!!sourceCurrency}
          element={
            <small className="text-muted">
              Available:{' '}
              {formatCurrency(
                sourceWallet?.type || 'CRYPTO',
                sourceWallet?.balance || 0,
                sourceCurrency?.value!,
                sourceWallet?.currency.decimals,
              )}{' '}
            </small>
          }
        />

        <Gap v={1} />

        <div className="row">
          <div className="col-12">
            <label className="mb-2">
              Receive <span>*</span>
            </label>
          </div>

          <div className="col-4">
            <Select
              name="targetCurrency"
              options={[
                {
                  label: 'Fiat',
                  options: (targetCurrencyOptions || []).filter((option) => option.type === 'FIAT'),
                },
                {
                  label: 'Crypto',
                  options: (targetCurrencyOptions || []).filter((option) => option.type === 'CRYPTO'),
                },
              ]}
              onChange={(ev: any) => {
                reset();
                setTargetCurrency(ev);
                setTargetWallet(wallets?.find((wallet) => wallet.currency.code === ev.value));
              }}
              value={targetCurrency}
              classNamePrefix="custom-select"
            />
          </div>

          <div className="col-8 ps-0">
            <Cleave
              key={`${targetWallet?.currency.code}-select-receive`}
              required
              id="input-text"
              name="targetValue"
              className="form-control"
              placeholder={`${formatCurrency(
                targetWallet?.currency.type!,
                0,
                targetWallet?.currency.code!,
                targetWallet?.currency.decimals,
              )}`}
              autoComplete="off"
              options={{
                numeral: true,
                numeralDecimalScale: sourceWallet?.currency.orderDecimals,
                stripLeadingZeroes: false,
                numeralThousandsGroupStyle: 'thousand',
              }}
              value={targetQuantity}
              onChange={(ev) => targetQuantityChange(Number(ev.target.rawValue || 0))}
            />
          </div>
        </div>

        <ConditionalElement
          condition={!!targetCurrency}
          element={
            <small className="text-muted">
              Available:{' '}
              {formatCurrency(
                targetWallet?.type || 'CRYPTO',
                targetWallet?.balance || 0,
                targetCurrency?.value!,
                targetWallet?.currency.decimals,
              )}{' '}
            </small>
          }
        />

        <Gap v={1} />

        <ConditionalElement condition={!!errorMessage} element={<div className="color-fd0000">{errorMessage}</div>} />

        <Gap v={1} />

        <Button type="submit" disabled={tradeDisabled} className="btn btn-primary full-width" text="Preview Exchange" />

        <ConditionalElement
          condition={!!(sourceCurrency?.value && targetCurrency?.value && conversionRate)}
          element={
            <>
              <Gap v={1} />

              <div className="text-center">
                {
                  // prettier-ignore
                  (conversionRate || 0).toString().startsWith('0.0') ?
                    `1 ${sourceCurrency?.value} = ${formatCurrency(targetWallet?.type || 'FIAT', 1/conversionRate, targetWallet?.currency.code!, targetWallet?.currency.decimals)}` :
                    `1 ${targetCurrency?.value} = ${formatCurrency(sourceWallet?.type || 'FIAT', conversionRate, sourceWallet?.currency.code!, sourceWallet?.currency.decimals)}`
                }
              </div>
            </>
          }
        />
      </form>
    </>
  );
};
