import React, { useEffect, useState } from 'react';
import * as listsApi from '../../../api/lists';

export const PhoneCodeSelect = (props: React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>) => {
  const [phoneCodes, setPhoneCodes] = useState<string[]>([]);
  const [defaultValue, setDefaultValue] = useState<string>('');

  const { className, required = false, value, ...restProps } = props;

  useEffect(() => {
    listsApi.getCountries().then((data) => {
      const codes = data.countries
        .map(({ phoneCode }) => phoneCode)
        .sort()
        .map((code) => `+${code}`);
      setPhoneCodes(codes);
    });
  }, []);

  useEffect(() => {
    setDefaultValue(value as string);
  }, [value]);

  return (
    <div className="form-group">
      <select
        required={required}
        className={`form-control ${className}`}
        value={defaultValue}
        onChange={(ev) => {
          setDefaultValue(ev.target.value);
        }}
        {...restProps}
      >
        {[...new Set(phoneCodes)].map((phoneCode) => (
          <option key={phoneCode}>{phoneCode}</option>
        ))}
      </select>
    </div>
  );
};
