import React, { useEffect, useState } from 'react';
import { imageFileToDataUrl } from '../../../utils/index';

interface IFileDisplay2Props {
  fileName?: string;
  onRemove?(ev: React.MouseEvent): void;
  imgStyle?: { [x: string]: any };
  src?: string;
  preview?: any;
}

const imageExtensions = ['JPG', 'PNG', 'JPEG'];

export const FileDisplay = (props: IFileDisplay2Props) => {
  const { fileName, onRemove, imgStyle = {} } = props;
  const [imageSrc, setImageSrc] = useState<string>();

  useEffect(() => {
    if (props.preview && props.preview.name && imageExtensions.includes(props.preview.extension.toUpperCase())) {
      imageFileToDataUrl(props.preview, (err, res) => {
        if (err) {
          return;
        }

        setImageSrc(res as any);
      });
    }
  }, [props.preview]);

  useEffect(() => {
    if (!props.src) return;
    setImageSrc(props.src);
  }, [props.src]);

  return (
    <div className="file-grid">
      <div className="display" title={fileName}>
        {imageExtensions.includes(props.preview.extension.toUpperCase()) ? (
          <img alt="" src={imageSrc} style={imgStyle} />
        ) : (
          <img alt="" style={imgStyle} src={require(`../../../assets/${props.preview.extension.toLowerCase()}.png`)} />
        )}

        {onRemove && (
          <a
            className="cursor-pointer font-weight-500"
            style={{
              top: 3,
              right: 5,
              color: '#ffffff',
              fontFamily: 'arial',
              position: 'absolute',
              textShadow: '0 0 2px #000',
            }}
            onClick={onRemove || (() => {})}
          >
            ✕
          </a>
        )}
      </div>

      {typeof props.preview.progress === 'number' ? (
        <div className="file-progress" style={{ width: `${props.preview.progress}%` }}></div>
      ) : null}
    </div>
  );
};
