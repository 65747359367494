import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Cleave from 'cleave.js/react';
import serializeForm from 'form-serialize';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Header } from '../../components/DashboardHeader';
import { Sidebar } from '../../components/DashboardSidebar';
import { Input } from '../../components/html/Input';
import { SettingsSidebar } from '../../components/SettingsSidebar';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStates } from '../../../typings/reducer';
import * as userApi from '../../../api/user';
import * as ratesApi from '../../../api/rates';
import * as userActions from '../../../actions/user';
import { cleanPhoneNumber, handleRequestErrors } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { Select2 } from '../../components/html/Select';
import { toast } from 'react-toastify';
import { addConversion } from '../../../actions/rates';
import { USD } from '../../../constants';
import { Note } from '../../components/Note';
import { PhoneCodeSelect } from '../../components/html/PhoneCodeSelect';
import Gap from '../../components/Gap';
import '../settings.scss';

export const ProfileSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, rates, wallets } = useSelector((state: ReducerStates) => state);
  const [isSaving, setIsSaving] = useState(false);

  return (
    <>
      <Helmet title="Settings | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="settings-page-wrap">
                  <SettingsSidebar />

                  <div className="settings-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Account</h6>
                      </div>
                    </div>

                    <Gap v={2} className="hidden-768px" />

                    <div className="page-content-inner">
                      {user.type !== 'INDIVIDUAL' ? (
                        <Note>
                          <>Please note that these changes apply only to your personal user account.</>
                        </Note>
                      ) : null}

                      <Gap v={2} />

                      <form
                        onSubmit={async (ev) => {
                          ev.preventDefault();

                          const data = serializeForm(ev.target, { hash: true });

                          let primaryPhone;
                          if (data.primaryPhone) {
                            const { primaryPhoneCode } = data;
                            primaryPhone = primaryPhoneCode + data.primaryPhone.replace(/^0+/, '').replace(/\s/g, '');
                          }

                          let secondaryPhone;
                          if (data.secondaryPhone) {
                            const { secondaryPhoneCode } = data;
                            secondaryPhone = secondaryPhoneCode + data.secondaryPhone.replace(/^0+/, '').replace(/\s/g, '');
                          }

                          setIsSaving(true);

                          try {
                            const userResponse = await userApi.updateUser({
                              alias: data.alias,
                              primaryPhone,
                              secondaryPhone,
                              preferredCurrency: data.preferredCurrency,
                            });

                            dispatch(userActions.updateUser(userResponse));

                            if (userResponse.preferredCurrency) {
                              const ratesResponse = await ratesApi.getConversions({ toCurrency: data.preferredCurrency });
                              ratesResponse.rates.forEach((rate) => dispatch(addConversion(rate)));
                            }

                            toast.success('Your account was updated successfully.');
                          } catch (err) {
                            handleRequestErrors(err);
                          } finally {
                            setIsSaving(false);
                          }
                        }}
                      >
                        <Input
                          type="text"
                          label="Alias"
                          placeholder="Ex. username"
                          name="alias"
                          min={3}
                          maxLength={40}
                          defaultValue={user.alias}
                        />
                        <small className="text-muted">Will be used in place of your full name.</small>
                        <Gap v={1} />

                        <div className="row">
                          <div className="col-12">
                            <label htmlFor="input-pri-phone" className="mb-2">
                              Primary Phone Number
                            </label>
                          </div>

                          <div className="col-4 pr-2">
                            <PhoneCodeSelect
                              name="primaryPhoneCode"
                              value={
                                // prettier-ignore
                                user.primaryPhone ? `+${parsePhoneNumber(cleanPhoneNumber(user.primaryPhone)).countryCallingCode as string}` : ''
                              }
                            />
                          </div>

                          <div className="col-8 pl-0">
                            <div className="form-group">
                              <Cleave
                                name="primaryPhone"
                                autoComplete="off"
                                id="input-pri-phone"
                                className="form-control"
                                placeholder="E.g 8051235678"
                                options={{
                                  phone: true,
                                  phoneRegionCode: 'any',
                                }}
                                value={
                                  // prettier-ignore
                                  user.primaryPhone ? (parsePhoneNumber(cleanPhoneNumber(user.primaryPhone)).nationalNumber as string) : ''
                                }
                              />
                              <Gap v={1} />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <label htmlFor="input-sec-phone" className="mb-2">
                              Secondary Phone Number
                            </label>
                          </div>

                          <div className="col-4 pr-2">
                            <PhoneCodeSelect
                              name="secondaryPhoneCode"
                              value={
                                // prettier-ignore
                                user.secondaryPhone ? `+${parsePhoneNumber(cleanPhoneNumber(user.secondaryPhone)).countryCallingCode as string}` : ''
                              }
                            />
                          </div>

                          <div className="col-8 pl-0">
                            <div className="form-group">
                              <Cleave
                                name="secondaryPhone"
                                autoComplete="off"
                                id="input-sec-phone"
                                className="form-control"
                                placeholder="E.g 8051235678"
                                options={{
                                  phone: true,
                                  phoneRegionCode: 'any',
                                }}
                                value={
                                  // prettier-ignore
                                  user.secondaryPhone ? (parsePhoneNumber(cleanPhoneNumber(user.secondaryPhone)).nationalNumber as string) : ''
                                }
                              />

                              <Gap v={1} />
                            </div>
                          </div>
                        </div>

                        <Select2
                          defaultValue={user.preferredCurrency || undefined}
                          name="preferredCurrency"
                          label="Preferred Currency"
                          placeholder="Select Currency"
                          options={[
                            ...rates.currencies
                              .filter((currency) => currency.code === USD)
                              .map(({ name, code }) => ({ label: name, value: code })),
                            ...wallets
                              .filter((wallet) => wallet.currency.type === 'FIAT' && wallet.currency.code !== USD)
                              .map(({ currency: { name, code } }) => ({ label: name, value: code })),
                          ]}
                        />
                        <small className="text-muted">Your balances, asset prices will be shown in this currency</small>

                        <Gap v={1} />

                        <button disabled={isSaving} type="submit" className="btn btn-primary btn-width-150px btn-full-width-mobile">
                          {isSaving ? 'Please wait...' : 'Update'}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
