/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import serializeForm from 'form-serialize';
import Gap from '../components/Gap';
import * as authApi from '../../api/auth';
import { handleRequestErrors } from '../../utils';
import { Input } from '../components/html/Input';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { route } from '../../routes';
import logoPrimary from '../../assets/logo-primary.svg';
import checkTickIcon from '../../assets/check-tick.svg';
import './forgotpassword.scss';

interface ResetFormProps {
  onRequest(): void;
}

const ResetForm = ({ onRequest = () => {} }: ResetFormProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="inner-content">
      <div className="auth-box">
        <a href="" className="logo">
          <img src={logoPrimary} alt="logo" />
        </a>

        <Gap v={2} />

        <div className="heading">
          <h3>Reset Your Password</h3>
          <p>Enter your email address and we'll send you a password reset link.</p>
        </div>

        <form
          onSubmit={(ev) => {
            ev.preventDefault();

            const form = ev.target as any;
            const { email } = serializeForm(form, { hash: true });

            setLoading(true);
            authApi
              .passwordReset(email)
              .then(() => onRequest())
              .catch((err) => {
                handleRequestErrors(err);
                form.reset();
              })
              .finally(() => setLoading(false));
          }}
        >
          <div className="form-group mb-1">
            <input required type="email" name="email" placeholder="your@email.com" className="form-control" />
          </div>

          <button disabled={loading} type="submit" className="btn btn-primary mt-3 mb-2 btn-submit">
            {loading ? 'Please wait...' : 'Reset Password'}
          </button>
        </form>
      </div>
    </div>
  );
};

const ResetSent = () => (
  <div className="inner-content">
    <a href="" className="logo">
      <img src={logoPrimary} alt="logo" />
    </a>
    <Gap v={1} />

    <div className="auth-box">
      <img src={checkTickIcon} alt="check" />

      <Gap v={1} />

      <div className="heading">
        <h4>Reset Your Password</h4>
        <div>You will receive a password reset email soon. Follow the link in the email to reset your password.</div>
      </div>
      <Gap v={2} />
    </div>
  </div>
);

export const ForgotPassword = () => {
  const [resetSent, setResetSent] = useState(false);

  return (
    <>
      <Helmet title="Reset Password | Host Capital" />

      <div className={`auth-reset-pass-container ${resetSent ? 'auth-reset-sent' : ''}`}>
        <div className="centered">
          <div className="centered-content full-width">
            {resetSent ? <ResetSent /> : <ResetForm onRequest={() => setResetSent(true)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export const ForgotPasswordChange = () => {
  const navigate = useNavigate();
  const { token }: any = useParams();
  const [loading, setLoading] = useState(false);

  return (
    <div className="auth-reset-pass-container">
      <div className="centered">
        <div className="centered-content full-width">
          <div className="inner-content">
            <div className="auth-box">
              <a href="" className="logo">
                <img src={logoPrimary} alt="logo" />
              </a>

              <Gap v={2} />

              <div className="heading">
                <h3>Change Your Password</h3>
              </div>

              <Gap v={1} />

              <form
                onSubmit={(ev) => {
                  ev.preventDefault();

                  const form = ev.target as any;
                  const { password, passwordConfirm } = serializeForm(form, { hash: true });

                  if (password !== passwordConfirm) {
                    return toast.error('Your passwords do not match');
                  }

                  setLoading(true);
                  authApi
                    .passwordChange(token, password)
                    .then(() => {
                      form.reset();
                      toast.success('Your password was reset successfully');
                      navigate(route.authentication.signin, { replace: true });
                    })
                    .catch((err) => handleRequestErrors(err))
                    .finally(() => setLoading(false));
                }}
              >
                <Input required type="password" name="password" placeholder="New Password" gapbottom={1} />

                <Input required type="password" name="passwordConfirm" placeholder="Confirm Password" />

                <button disabled={loading} type="submit" className="btn btn-primary mt-3 mb-2 btn-submit">
                  {loading ? 'Please wait...' : 'Reset Password'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
