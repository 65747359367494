import React from 'react';
import { Link } from 'react-router-dom';
import './errors.scss';

export const ErrorPage404 = () => (
  <div className="error-container">
    <div className="centered">
      <div className="centered-content">
        <div className="inner-content">
          <h3>Sadly, the requested page doesn't exist</h3>
          <p>The page you are looking for doesn't exist or has been moved.</p>
          <Link to="/" className="btn btn-primary">
            Take me back home
          </Link>
        </div>
      </div>
    </div>
  </div>
);
