import Request from '../helpers/request';
import { StatusDto } from '../typings';
import { NotificationSettingsDto, PushMessagesDto, SubscriptionDto, SubscriptionListDto } from '../typings/notifications';

export const getPushMessages = async (): Promise<PushMessagesDto> => {
  return await Request.getWithUserToken<PushMessagesDto>(`${process.env.REACT_APP_API_URL}/notifications/me/push/messages`);
};

export const getSubscriptions = async (): Promise<SubscriptionListDto> => {
  return await Request.getWithUserToken<SubscriptionListDto>(`${process.env.REACT_APP_API_URL}/notifications/me/push/subscriptions`);
};

export const addSubscription = async (data: Partial<SubscriptionDto>): Promise<SubscriptionDto> => {
  return await Request.postWithUserToken<SubscriptionDto>(`${process.env.REACT_APP_API_URL}/notifications/me/push/subscriptions`, {
    data,
  });
};

export const removeSubscription = async (id: string): Promise<StatusDto> => {
  return await Request.deleteWithUserToken<StatusDto>(`${process.env.REACT_APP_API_URL}/notifications/me/push/subscriptions/${id}`);
};

export const getSettings = async (): Promise<NotificationSettingsDto> => {
  return await Request.getWithUserToken<NotificationSettingsDto>(`${process.env.REACT_APP_API_URL}/notifications/me/settings`);
};

export const updateSettings = async (data: Partial<NotificationSettingsDto>): Promise<NotificationSettingsDto> => {
  return await Request.postWithUserToken<NotificationSettingsDto>(`${process.env.REACT_APP_API_URL}/notifications/me/settings`, {
    data,
  });
};
