const apiUrl = process.env.REACT_APP_API_URL;
const markettingUrl = process.env.REACT_APP_MARKETTING_URL;

export default {
  variant: process.env.REACT_APP_VARIANT || 'STAGING',
  api: {
    cards: `${apiUrl}/cards`,
    deposit: `${apiUrl}/deposit`,
    wallets: `${apiUrl}/wallet`,
    withdrawal: `${apiUrl}/withdrawal`,
    p2p: `${apiUrl}/p2p`,
  },
  web: {
    terms: `${markettingUrl}/terms`,
    privacy: `${markettingUrl}/privacy`,
  },
  fcm: {
    publicKey: process.env.REACT_APP_FCM_PUBLIC_KEY as string,
  },
  enabledCryptoDeposits: process.env.REACT_APP_ENABLE_CRYPTO_DEPOSITS as 'true' | 'false',
};
