import qs from 'qs';
import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { route } from '../../routes';
import * as userApi from '../../api/user';
import { handleRequestErrors } from '../../utils';
import { toast } from 'react-toastify';

export const OperationsHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { type, token }: any = useParams();
  const { response = 'ACCEPT' } = qs.parse(location.search, { ignoreQueryPrefix: true });

  switch (type) {
    case 'team-invite':
      useEffect(() => {
        userApi
          .respondToTeamInvite(response as any, token)
          .then(() => {
            if (response === 'REJECT') {
              toast.success('Invite rejected successfully');
            } else {
              // eslint-disable-next-line quotes
              toast.success("You've successfully accepted your invite. Please create an account to continue.");
            }

            navigate(route.authentication.signup, { replace: true });
          })
          .catch(handleRequestErrors);
      }, []);

      break;
    default:
      return <Navigate to={route.authentication.signin} replace state={{ from: location }} />;
  }

  return <img src={require('../../assets/loading.gif')} alt="" style={{ height: '20px' }} />;
};
