import React, { useState } from 'react';
import serializeForm from 'form-serialize';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Header } from '../components/DashboardHeader';
import { Sidebar } from '../components/DashboardSidebar';
import './styles.scss';
import { P2pSidebar } from './Sidebar';
import Gap from '../components/Gap';
import { useSelector } from 'react-redux';
import { ReducerStates } from '../../typings';
import * as p2pActions from '../../actions/p2p';
import { P2pApi } from '../../api/p2p';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { handleRequestErrors } from '../../utils';
import { Input } from '../components/html/Input';

export const P2pProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { p2p } = useSelector((state: ReducerStates) => state);
  const [isSaving, setIsSaving] = useState(false);

  return (
    <>
      <Helmet title="P2P Profile | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="p2p-page-wrap">
                  <P2pSidebar />

                  <div className="p2p-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Profile</h6>
                      </div>
                    </div>

                    <Gap v={2} className="hidden-768px" />

                    <div className="page-content-inner">
                      <div className="max-w-500x">
                        <form
                          onSubmit={async (ev) => {
                            ev.preventDefault();

                            const data = serializeForm(ev.target, { hash: true });

                            setIsSaving(true);

                            try {
                              const response = await P2pApi.updateProfile({
                                alias: data.alias,
                                availability: [],
                              });

                              dispatch(p2pActions.setProfile(response));

                              toast.success('Profile was updated successfully.');
                            } catch (err) {
                              handleRequestErrors(err);
                            } finally {
                              setIsSaving(false);
                            }
                          }}
                        >
                          <Input
                            type="text"
                            label="Alias"
                            placeholder="Ex. username"
                            name="alias"
                            min={3}
                            maxLength={40}
                            defaultValue={p2p.profile?.alias}
                          />
                          <small className="text-muted">Will be used in place of your full name.</small>
                          <Gap v={1} />

                          <Gap v={1} />

                          <button disabled={isSaving} type="submit" className="btn btn-primary btn-width-150px btn-full-width-mobile">
                            {isSaving ? 'Please wait...' : 'Update'}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
