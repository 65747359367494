import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/DashboardHeader';
import { Sidebar } from '../../components/DashboardSidebar';
import { ToggleSwitch } from '../../components/html/ToggleSwitch';
import { SettingsSidebar } from '../../components/SettingsSidebar';
import Gap from '../../components/Gap';
import '../settings.scss';

export const SystemSettings = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet title="Settings | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="settings-page-wrap">
                  <SettingsSidebar />

                  <div className="settings-page-content">
                    <div className="page-content-inner">
                      <div className="row">
                        <div className="col-auto" data-vertical_center>
                          <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                            Back
                          </a>
                        </div>

                        <div className="col" data-vertical_center>
                          <h6 className="p-0 m-0">System Settings</h6>
                        </div>
                      </div>

                      <Gap v={2} />

                      <div className="setting-radio-list">
                        <ul>
                          <li>
                            <div className="wrap">
                              <div className="row">
                                <div className="col fw-bold pe-4" data-vertical_center>
                                  <div>Auto-Fulfill Transactions</div>
                                  <small className="fw-normal">
                                    Transactions create via your developer token will not need approvals to be fulfilled
                                  </small>
                                </div>
                                <div className="col-auto text-end">
                                  <ToggleSwitch on={true} />
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
