import moment from 'moment';
import _ from 'lodash';
import Countdown, { zeroPad } from 'react-countdown';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import RcDropdown from 'rc-dropdown';
import { useNavigate } from 'react-router-dom';
import Menu, { Item as MenuItem } from 'rc-menu';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Header } from '../components/DashboardHeader';
import { Sidebar } from '../components/DashboardSidebar';
import './styles.scss';
import { P2pSidebar } from './Sidebar';
import Gap from '../components/Gap';
import { P2pTransactionDto, P2pTransactionStatusDto } from '../../typings/p2p';
import { ConditionalElement } from '../components/ConditionalElement';
import { P2pApi } from '../../api/p2p';
import { formatCurrency, handleRequestErrors } from '../../utils';
import { useSelector } from 'react-redux';
import { ReducerStates } from '../../typings';
import { Modal } from '../components/Modal';
import { Security2faDialog } from '../components/2FADialog';
import { useDispatch } from 'react-redux';
import * as p2pActions from '../../actions/p2p';
import { CopiableText } from '../components/CopiableText';
import { baseStore } from '../../store';

interface TxnRowProps {
  transaction: P2pTransactionDto;
  onUpdate(data: Partial<P2pTransactionDto>): void;
}

const TransactionInfoDialog = ({ transaction }: { transaction: P2pTransactionDto }) => {
  const { id, type, status, payment, transfer, customer, paymentAccounts = [], expiresAt } = transaction;

  const { rates } = baseStore.getState();

  const paymentCurrency = rates.currencies.find((curr) => curr.code === payment.currency);
  const transferCurrency = rates.currencies.find((curr) => curr.code === transfer.currency);

  const msLeftToExpiry = moment.utc(expiresAt, 'ddd MMM D Y HH:mm:s').diff(moment.utc(), 'milliseconds');

  return (
    <>
      <div className="head">
        <div className="illus">
          <img
            src={`https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/currencies/${payment.currency.toLocaleLowerCase()}.svg`}
            alt=""
          />
        </div>

        <h4>
          P2P Transaction{' '}
          {
            // prettier-ignore
            msLeftToExpiry > 0 && !['CANCELED', 'COMPLETED', 'DISPUTED'].includes(status) || ['PENDING_PAYMENT', 'PENDING_CONFIRMATION', 'DISPUTED'].includes(status) ? (
              <b>
                (<Countdown date={Date.now() + msLeftToExpiry} renderer={({ minutes, seconds }) => `${zeroPad(minutes)}:${zeroPad(seconds)}`} />
                )
              </b>
            ) : null
          }
        </h4>
      </div>

      <Gap v={1} />

      <div className="content">
        <div className="info-row">
          <div className="left">
            <div className="info-title">ID</div>
          </div>
          <div className="right">
            <div className="info-content">
              <CopiableText text={id} />
            </div>
          </div>
        </div>

        <div className="info-row">
          <div className="left">
            <div className="info-title">Status</div>
          </div>
          <div className="right">
            {status
              .split('_')
              .map((str, i) => (i === 0 ? _.capitalize(str.toLocaleLowerCase()) : str.toLowerCase()))
              .join(' ')}
          </div>
        </div>

        <div className="info-row">
          <div className="left">
            <div className="info-title">Type</div>
          </div>
          <div className="right">{type}</div>
        </div>

        <div className="info-row">
          <div className="left">
            <div className="info-title">Customer</div>
          </div>
          <div className="right">{customer.alias}</div>
        </div>

        <div className="info-row">
          <div className="left">
            <div className="info-title">{type === 'BUY' ? 'Customer pays you' : 'You pay customer'}</div>
          </div>
          <div className="right">
            <b>{formatCurrency(paymentCurrency?.type || 'FIAT', payment.amount - payment.feeAmount, payment.currency)}</b>
          </div>
        </div>

        <div className="info-row">
          <div className="left">
            <div className="info-title">{type === 'BUY' ? 'You pay customer' : 'Customer pays you'}</div>
          </div>
          <div className="right">
            <b>{formatCurrency(transferCurrency?.type || 'FIAT', transfer.amount - transfer.feeAmount, transfer.currency)}</b>
          </div>
        </div>

        <ConditionalElement
          condition={type === 'SELL' && paymentAccounts.length > 0}
          element={
            <div className="info-row block">
              <div className="left">
                <div className="info-title">Accounts</div>
              </div>

              <div className="right">
                <div className="info-content">
                  <Gap v={1} />

                  <ul className="accts-info">
                    {paymentAccounts.map((acct) => {
                      if (acct.bankName) {
                        return (
                          <li key={acct.id}>
                            <span className="wrap">
                              <span className="left">Account Name</span>
                              <span className="right">{acct.accountName}</span>
                            </span>

                            <span className="wrap">
                              <span className="left">Bank Name</span>
                              <span className="right">{acct.bankName}</span>
                            </span>

                            <span className="wrap">
                              <span className="left">AccountNumber</span>
                              <span className="right">
                                <CopiableText text={acct.accountNumber} />
                              </span>
                            </span>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </>
  );
};

const TransactionRow = (props: TxnRowProps) => {
  const dispatch = useDispatch();

  const {
    transaction: { id, status, type, payment, transfer, customer, expiresAt, createdAt },
    onUpdate,
  } = props;

  const { rates } = useSelector((state: ReducerStates) => state);

  const paymentCurrency = rates.currencies.find((curr) => curr.code === payment.currency);
  const transferCurrency = rates.currencies.find((curr) => curr.code === transfer.currency);

  const msLeftToExpiry = moment.utc(expiresAt, 'ddd MMM D Y HH:mm:s').diff(moment.utc(), 'milliseconds');

  const markReceived = () => {
    Modal.Confirm('Mark transaction as complete?', 'I hereby confirm that I have received my funds and this transaction is complete.').then(
      (value) => {
        if (value === true) {
          Modal.Open(
            <Security2faDialog
              operation="P2P_TRANSACTION_UPDATE"
              handleSecurity={async (security, callback) => {
                P2pApi.updateTransaction(id, 'RECEIVED', security)
                  .then((transaction) => {
                    Modal.CloseAll();
                    dispatch(p2pActions.addTransaction(transaction));
                    onUpdate(transaction);
                  })
                  .catch((err) => {
                    callback(null, err);
                    handleRequestErrors(err);
                  });
              }}
            />,
            {
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: 'modal-mobile-fullscreen',
                popup: 'max-w-350x',
              },
            },
          );
        }
      },
    );
  };

  const markPaymentMade = () => {
    Modal.Confirm(
      'Mark as paid?',
      'Please not that maliciously marking a transaction as paid when you have not made payment could result in a permanent suspension.',
    ).then((value) => {
      if (value === true) {
        Modal.Open(
          <Security2faDialog
            operation="P2P_TRANSACTION_UPDATE"
            handleSecurity={async (security, callback) => {
              P2pApi.updateTransaction(id, 'PAID', security)
                .then((transaction) => {
                  Modal.CloseAll();
                  dispatch(p2pActions.addTransaction(transaction));
                  onUpdate(transaction);
                })
                .catch((err) => {
                  callback(null, err);
                  handleRequestErrors(err);
                });
            }}
          />,
          {
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
              container: 'modal-mobile-fullscreen',
              popup: 'max-w-350x',
            },
          },
        );
      }
    });
  };

  const markTransactionCancel = () => {
    Modal.Confirm('Cancel transaction?', 'I hereby confirm that I have not made payment and this transaction is safe to be canceled.').then(
      (value) => {
        if (value === true) {
          Modal.Open(
            <Security2faDialog
              operation="P2P_TRANSACTION_UPDATE"
              handleSecurity={async (security, callback) => {
                P2pApi.updateTransaction(id, 'CANCEL', security)
                  .then((transaction) => {
                    Modal.CloseAll();
                    dispatch(p2pActions.addTransaction(transaction));
                    onUpdate(transaction);
                  })
                  .catch((err) => {
                    callback(null, err);
                    handleRequestErrors(err);
                  });
              }}
            />,
            {
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: 'modal-mobile-fullscreen',
                popup: 'max-w-350x',
              },
            },
          );
        }
      },
    );
  };

  const openDispute = async () => {
    const reason = await Modal.Prompt(
      'Create a dispute',
      'Please note that maliciously creating disputes could result in a temporary or permanent suspension of your account.',
      { inputType: 'textarea', required: true },
    );

    if (!reason) {
      Modal.Alert('', 'Please provide a reason for this dispute to enable us follow up.');
      return;
    }

    P2pApi.createDispute(id, reason as string).then(() =>
      Modal.Alert(
        'Dispute created',
        'Your dispute was created successfully. A representative from our support team will reach out to you soon. In the meantime, do see if you can reach a middle ground with your customer.',
      ),
    );
  };

  const showTxInfoDialog = () => {
    Modal.Open(<TransactionInfoDialog transaction={props.transaction} />, {
      customClass: {
        popup: 'p2p-transaction-info-dialog-popup',
        htmlContainer: 'p2p-transaction-info-dialog-container',
      },
    });
  };

  return (
    <tr onClick={() => showTxInfoDialog()}>
      <td>
        {status
          .split('_')
          .map((str, i) => (i === 0 ? _.capitalize(str.toLocaleLowerCase()) : str.toLowerCase()))
          .join(' ')}{' '}
        {
          // prettier-ignore
          msLeftToExpiry > 0 && !['CANCELED', 'COMPLETED', 'DISPUTED'].includes(status) || ['PENDING_PAYMENT', 'PENDING_CONFIRMATION', 'DISPUTED'].includes(status) ? (
            <b>
              (<Countdown date={Date.now() + msLeftToExpiry} renderer={({ minutes, seconds }) => `${zeroPad(minutes)}:${zeroPad(seconds)}`} />
              )
            </b>
          ) : null
        }
      </td>
      <td className="text-end">
        <b>{type}</b>
      </td>
      <td className="text-end">
        <span className="truncate-text">{customer.alias}</span>
      </td>
      <td className="text-end">
        {
          // prettier-ignore
          type === 'BUY' ? formatCurrency(paymentCurrency?.type || 'FIAT', (payment.amount - payment.feeAmount), payment.currency) : formatCurrency(transferCurrency?.type || 'FIAT', transfer.amount - transfer.feeAmount, transfer.currency)
        }
      </td>
      <td className="text-end">
        {
          // prettier-ignore
          type === 'BUY' ? formatCurrency(transferCurrency?.type || 'FIAT', transfer.amount - transfer.feeAmount, transfer.currency) : formatCurrency(paymentCurrency?.type || 'FIAT', payment.amount - payment.feeAmount, payment.currency)
        }
      </td>
      <td className="text-end">{moment.utc(createdAt, 'ddd MMM D Y HH:mm:s').format('MMM DD, yyyy hh:mm a')}</td>
      <td className="text-end" onClick={(ev) => ev.stopPropagation()}>
        {status === 'PENDING_CONFIRMATION' || (status === 'PENDING_PAYMENT' && type === 'SELL') ? (
          <RcDropdown
            trigger={['click']}
            overlay={
              <Menu>
                {type === 'BUY' && ['PENDING_CONFIRMATION'].includes(status) ? (
                  <>
                    <MenuItem key={`txn-${id}-pay-received`} onClick={() => markReceived()}>
                      Payment received
                    </MenuItem>

                    <MenuItem key={`txn-${id}-pay-dispute`} onClick={() => openDispute()}>
                      Dispute
                    </MenuItem>
                  </>
                ) : null}

                {type === 'SELL' && ['PENDING_PAYMENT'].includes(status) ? (
                  <MenuItem key={`txn-${id}-pay-made`} onClick={() => markPaymentMade()}>
                    Payment made
                  </MenuItem>
                ) : null}

                {type === 'SELL' && ['PENDING_PAYMENT', 'PENDING_CONFIRMATION'].includes(status) ? (
                  <MenuItem key={`txn-${id}-pay-cancel`} onClick={() => markTransactionCancel()}>
                    Cancel
                  </MenuItem>
                ) : null}
              </Menu>
            }
            alignPoint
          >
            <a style={{ display: 'inline-block', width: 30, height: 30, cursor: 'pointer', textAlign: 'center' }}>
              <BsThreeDotsVertical />
            </a>
          </RcDropdown>
        ) : null}
      </td>
    </tr>
  );
};

const TransactionRowLoading = () => {
  return (
    <>
      {Array(7)
        .fill(1)
        .map((value, index) => (
          <tr key={`p2p_txn_dummy_${value}_${index}`}>
            <td className="is-placeholder">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
          </tr>
        ))}
    </>
  );
};

export const P2pTransactions = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingPosition, setLoadingPosition] = useState<'LEFT' | 'RIGHT'>();
  const [page, setPage] = useState(1);
  const [size] = useState(20);
  const [filterStatus] = useState<P2pTransactionStatusDto>();
  const [totalItems, setTotalItems] = useState(0);
  const [transactions, setTransactions] = useState<P2pTransactionDto[]>([]);

  const pageTotal = size * page > totalItems ? totalItems : size * page;

  useEffect(() => {
    setLoading(true);

    if (loading) {
      return;
    }

    setTransactions([]);

    P2pApi.getTransactions(filterStatus, page, size)
      .then((res) => {
        setTransactions(res.records);
        setTotalItems(res.totalItems);
      })
      .catch(handleRequestErrors)
      .finally(() => {
        setLoading(false);
        setLoadingPosition(undefined);
      });
  }, [page, size]);

  return (
    <>
      <Helmet title="P2P Transactions | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="p2p-page-wrap">
                  <P2pSidebar />

                  <div className="p2p-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Transactions</h6>
                      </div>
                    </div>

                    <Gap v={2} className="hidden-768px" />

                    <div className="page-content-inner">
                      <div className="table-advanced">
                        <div className="table-wrapper table-wrapper-cards">
                          <table className="has-details">
                            <thead>
                              <tr>
                                <th>Status</th>
                                <th className="text-end">Type</th>
                                <th className="text-end">Customer</th>
                                <th className="text-end">Customer sends</th>
                                <th className="text-end">You send</th>
                                <th className="text-end">Date created</th>
                                <th></th>
                              </tr>
                            </thead>

                            <tbody>
                              <ConditionalElement condition={loading} element={<TransactionRowLoading />} />

                              {transactions.map((transaction) => (
                                <TransactionRow
                                  key={transaction.id}
                                  transaction={transaction}
                                  onUpdate={(data) =>
                                    setTransactions(
                                      transactions.map((txData) => {
                                        if (txData.id === transaction.id) {
                                          return {
                                            ...txData,
                                            ...data,
                                          };
                                        }

                                        return txData;
                                      }),
                                    )
                                  }
                                />
                              ))}
                            </tbody>
                          </table>

                          <ConditionalElement
                            condition={!loading && transactions.length === 0}
                            element={
                              <div className="no-result">
                                <h5>You don't have any transactions</h5>
                              </div>
                            }
                          />
                        </div>

                        <Gap v={1} />

                        <div className="table-pagination">
                          <div className="pagination-wrap">
                            <div className="item-total hidden">
                              <span className="color-4F4F4F">Rows per page: &nbsp;&nbsp;</span> 8
                            </div>

                            <ConditionalElement
                              condition={totalItems > size}
                              element={
                                <div className="item-nav">
                                  <span className="color-4F4F4F">
                                    {(page - 1) * size + 1} - {pageTotal} of {totalItems.toLocaleString('en')}
                                  </span>
                                  <Gap h={1} />

                                  <a
                                    className={`prev ${loading && loadingPosition === 'LEFT' ? 'loading' : ''}`}
                                    onClick={() => {
                                      if (loading) {
                                        return;
                                      }

                                      const prevPage = page - 1;

                                      if (prevPage <= 0) {
                                        return;
                                      }

                                      setPage(prevPage);
                                      setLoadingPosition('LEFT');
                                    }}
                                  />
                                  <a
                                    className={`next ${loading && loadingPosition === 'RIGHT' ? 'loading' : ''}`}
                                    onClick={() => {
                                      if (loading) {
                                        return;
                                      }

                                      const nextPage = page + 1;

                                      if (nextPage > Math.ceil(totalItems / size)) {
                                        return;
                                      }

                                      setPage(nextPage);
                                      setLoadingPosition('RIGHT');
                                    }}
                                  />
                                </div>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
