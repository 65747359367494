import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ReducerStates } from '../../typings';
import { route } from '../../routes';
import './styles.scss';

export const P2pSidebar = () => {
  const { p2p } = useSelector((state: ReducerStates) => state);

  return (
    <div className="p2p-navi">
      <div className="navi-wrap">
        <ul>
          <li>
            <NavLink to={route.p2p.home} className={({ isActive }) => `profile ${isActive ? 'current' : ''}`} end>
              Profile
            </NavLink>
          </li>
          {/* <li>
            <NavLink to={route.p2p.bot} className={({ isActive }) => `bot ${isActive ? 'current' : ''}`}>
              Bot
            </NavLink>
          </li> */}
          <li>
            <NavLink to={route.p2p.ads} className={({ isActive }) => `ads ${isActive ? 'current' : ''}`}>
              Ads
            </NavLink>
          </li>
          <li>
            <NavLink
              to={route.p2p.requests}
              className={({ isActive }) => `requests ${isActive ? 'current' : ''} ${p2p.requests?.length || 0 > 0 ? 'alert' : ''}`}
            >
              <span>Requests</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={route.p2p.transactions}
              className={({ isActive }) => `transactions ${isActive ? 'current' : ''} ${p2p.transactions?.length || 0 > 0 ? 'alert' : ''}`}
            >
              <span>Transactions</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={route.p2p.disputes}
              className={({ isActive }) => `disputes ${isActive ? 'current' : ''} ${p2p.disputes?.length || 0 > 0 ? 'alert' : ''}`}
            >
              <span>Disputes</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};
