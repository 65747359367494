import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/DashboardHeader';
import { Sidebar } from '../../components/DashboardSidebar';
import Gap from '../../components/Gap';
import { SettingsSidebar } from '../../components/SettingsSidebar';
import '../settings.scss';

export const ComplianceSettings = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet title="Settings | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="settings-page-wrap">
                  <SettingsSidebar />

                  <div className="settings-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Compliance Information</h6>
                      </div>
                    </div>

                    <Gap v={2} className="hidden-768px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
