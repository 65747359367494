import React, { useState } from 'react';
import Select from 'react-select';
import serializeForm from 'form-serialize';
import Gap from '../../components/Gap';
import { Input } from '../../components/html/Input';
import * as developerApi from '../../../api/developer';
import * as developerAction from '../../../actions/developer';
import { handleRequestErrors } from '../../../utils';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/html/Button';
import { baseStore } from '../../../store';
import { CopiableText } from '../../components/CopiableText';
import { Security2faDialog } from '../../components/2FADialog';
import config from '../../../config';
import { WebhookEventDto } from '../../../typings';
import { subscriptionEventsOptions } from './Webhooks';

const permOptions: any[] = [
  {
    value: 'dev.deposits.manage',
    label: 'Manage Deposits',
  },
  {
    value: 'dev.assets.manage',
    label: 'Manage Assets',
  },
  {
    value: 'dev.withdrawals.manage',
    label: 'Manage Withdrawals',
  },
  {
    value: 'dev.sub-addresses.manage',
    label: 'Manage Sub-Addresses',
  },
  {
    value: 'dev.hostpay.manage',
    label: 'Host+ Pay',
  },
  {
    value: 'dev.cards.manage',
    label: 'Virtual Cards',
  },
  {
    value: 'dev.webhooks.manage',
    label: 'Manage Webhooks',
  },
];

export const CreateKeyModal = () => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <h4>Create New Key</h4>

      <Gap v={0.5} />

      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          const form = ev.target as any;
          const { label, whiteListedIPs = '' } = serializeForm(form, { hash: true });

          Modal.Open(
            <Security2faDialog
              operation="DEVELOPER_KEY_ADD"
              handleSecurity={async (security, callback) => {
                developerApi
                  .createKey({
                    label,
                    permissions,
                    whiteListedIPs: whiteListedIPs.split(/\s*,\s*/).filter(Boolean),
                    security,
                  })
                  .then((key) => {
                    baseStore.dispatch(developerAction.addKey(key));
                    Modal.Alert(
                      'Developer Key Created',
                      <>
                        <p>
                          Please copy and save your <b>Client Secret</b> in a safe and secure place.
                        </p>
                        <p>
                          It will no longer be displayed once you close this modal.
                          <br />
                          Also, secrets cannot be retrieved if lost.
                        </p>
                        <p>
                          <CopiableText text={key.clientSecret} />
                        </p>
                      </>,
                    );
                  })
                  .catch((err) => {
                    callback(null, err);
                    handleRequestErrors(err);
                  })
                  .finally(() => setLoading(false));
              }}
            />,
            {
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: 'modal-mobile-fullscreen',
                popup: 'max-w-350x',
              },
            },
          );
        }}
      >
        <Input required label="Label" type="text" name="label" placeholder="Ex. Dev Server 1" gapbottom={1} />

        <Input
          required={config.variant === 'PRODUCTION'}
          label="IP Restriction"
          type="text"
          name="whiteListedIPs"
          placeholder="192.168.0.1, 192.168.0.2"
          gapbottom={1}
          info="If you have more than one IP addresses, please separate them with commas"
        />

        <div>
          <label htmlFor="select-permissions" className="mb-2">
            Assign Permissions
          </label>
          <Select
            isMulti
            id="select-permissions"
            name="permissions"
            options={permOptions}
            classNamePrefix="custom-select"
            onChange={(values: any) => setPermissions((values || []).map((v: any) => v.value))}
          />
          <small className="text-muted">
            Keys without permissions will be able to do basically nothing until you assign permissions to them
          </small>
          <Gap v={2} />
        </div>

        <div className="buttons">
          <Button disabled={loading} loading={loading} type="submit" className="btn btn-primary btn-width-150px" text="Create key" />

          <Gap h={1} />

          <Button disabled={loading} type="button" className="btn btn-grey" onClick={() => Modal.CloseAll()} text="Cancel" />
        </div>
      </form>
    </>
  );
};

export const CreateSubscription = () => {
  const [events, setEvents] = useState<WebhookEventDto[]>([]);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <h4>Add Webhook Subscription</h4>

      <Gap v={0.5} />

      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          const form = ev.target as any;
          const { url, secret } = serializeForm(form, { hash: true });

          Modal.Open(
            <Security2faDialog
              operation="WEBHOOK_SUBSCRIPTION_ADD"
              handleSecurity={async (security, callback) => {
                developerApi
                  .createWebhookSubscription({
                    url,
                    events,
                    secret,
                    security,
                  })
                  .then((subscription) => {
                    baseStore.dispatch(developerAction.addWebhookSubscription(subscription));
                    Modal.Alert(
                      'Subscription Created',
                      `Your webhook subscription for the events: ${events.join(',')} was created successfully!`,
                    );
                  })
                  .catch((err) => {
                    callback(null, err);
                    handleRequestErrors(err);
                  })
                  .finally(() => setLoading(false));
              }}
            />,
            {
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: 'modal-mobile-fullscreen',
                popup: 'max-w-350x',
              },
            },
          );
        }}
      >
        <Input required label="Webhook URL" type="url" name="url" placeholder="Ex. https://api.example.com/callback" gapbottom={1} />

        <div>
          <label htmlFor="select-events" className="mb-2">
            Select Events
          </label>
          <Select
            isMulti
            id="select-events"
            name="events"
            options={subscriptionEventsOptions}
            classNamePrefix="custom-select"
            onChange={(values: any) => setEvents((values || []).map((v: any) => v.value))}
          />
          <small className="text-muted">Webhook notifications will be sent to the URL you have provided for any of these events.</small>
          <Gap v={1} />
        </div>

        <Input
          label="Webhook Secret"
          type="text"
          name="secret"
          placeholder="Ex. Qv/AtL2C,fsyX*k"
          gapbottom={2}
          info="For every webhook request made to your URL, we'll send you your secret in your request header as <b>x-auth-secret</b>"
        />

        <div className="buttons">
          <Button disabled={loading} loading={loading} type="submit" className="btn btn-primary btn-width-150px" text="Subscribe" />

          <Gap h={1} />

          <Button disabled={loading} type="button" className="btn btn-grey" onClick={() => Modal.CloseAll()} text="Cancel" />
        </div>
      </form>
    </>
  );
};
