import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import serializeForm from 'form-serialize';
import Gap from '../components/Gap';
import { TextArea } from '../components/html/TextArea';
import { Sidebar } from '../components/OnboardingSidebar';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { BusinessKycDetailsDto, ProfileKycDetailsDto, ReducerStates } from '../../typings';
import * as kycApi from '../../api/kyc';
import { setBusinessKycInfo, setIndividualKycInfo } from '../../actions/kyc';
import { route } from '../../routes';
import { handleRequestErrors } from '../../utils';
import { Header } from '../components/DashboardHeader';

export const OnboardingTradingActs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, kyc } = useSelector((state: ReducerStates) => state);
  const [loading, setLoading] = useState(false);

  const isIndividual = user.type === 'INDIVIDUAL';
  const details = isIndividual ? kyc.individual?.trading : kyc.business?.trading;

  return (
    <>
      <Helmet title="Host Capital" />

      <div className="onboarding-wrapper">
        <Sidebar />

        <div className="onboarding-content-wrap">
          <Header isOnboarding onLeftMenuClick={() => {}} />

          <div className="content-inner">
            <div className="centered">
              <div className="centered-content">
                <Gap v={2} />

                <h3>Please describe purpose of exchange / transfer transactions</h3>
                <Gap v={1} />

                <form
                  onSubmit={(ev) => {
                    ev.preventDefault();

                    const formData = serializeForm(ev.target, { hash: true });
                    const { purposeOfExchange } = formData;

                    setLoading(true);
                    (isIndividual ? kycApi.updatePersonalInfo : kycApi.updateBusinessInfo)({
                      trading: {
                        purposeOfExchange,
                      },
                    })
                      .then((data) => {
                        // prettier-ignore
                        dispatch(isIndividual ? setIndividualKycInfo(data as ProfileKycDetailsDto) : setBusinessKycInfo(data as BusinessKycDetailsDto));
                        navigate(route.onboarding.termsOfService);
                      })
                      .catch(handleRequestErrors)
                      .finally(() => setLoading(false));
                  }}
                >
                  <TextArea
                    required
                    id="source-of-wealth"
                    name="purposeOfExchange"
                    label="Purpose of exchange"
                    placeholder="Example: Speculative trading, investing, cross-border remittances etc."
                    defaultValue={details?.purposeOfExchange}
                  />

                  <div className="button-set text-end mt-5">
                    <div className="d-inline-block">
                      <button
                        disabled={loading}
                        type="button"
                        className="btn btn-primary btn-outline"
                        onClick={() => navigate(route.onboarding.sourceOfFunds)}
                      >
                        Back
                      </button>

                      <Gap h={1} />

                      <button disabled={loading} type="submit" className="btn btn-primary">
                        {loading ? 'Please wait...' : 'Save & Continue'}
                      </button>
                    </div>
                  </div>
                </form>

                <Gap v={2} className="visible-768px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
