import { ListsAction, CountryDto, LocationData } from '../typings/lists';

export const setCountries = (countries: CountryDto[]): ListsAction => {
  return {
    type: 'SET_COUNTRIES',
    countries,
  };
};

export const setStates = (countryId: number, data: LocationData[]): ListsAction => {
  return {
    type: 'SET_STATES',
    countryId,
    data,
  };
};

export const setCities = (stateId: number, data: LocationData[]): ListsAction => {
  return {
    type: 'SET_CITIES',
    stateId,
    data,
  };
};
