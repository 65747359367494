import React from 'react';
import { DepositDialog } from './modules/components/DepositDialog';
import { ExchangeDialog } from './modules/components/ExchangeDialog';
import { Modal } from './modules/components/Modal';
import { route } from './routes';
import { baseStore } from './store';

export type ISearchResults = {
  id: string;
  title: string;
  description: string;
  location?: string;
  onClick?(): void;
};

export const searchResults: ISearchResults[] = [
  {
    id: 'result-1',
    title: 'Order History',
    description: 'View, filter your transaction history.',
    location: route.dashboard.orderHistory,
  },
  {
    id: 'result-2',
    title: 'Exhange',
    description: 'Exchange from fiat to crypto, crypto to fiat, crypto to crypto or fiat to fiat',
    onClick() {
      Modal.Open(<ExchangeDialog />, {
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'modal-mobile-fullscreen',
          popup: 'max-w-500x',
        },
      });
    },
  },
  {
    id: 'result-3',
    title: 'Deposit Fiat',
    description: 'Deposit fiat to your wallet via Bank transfer, card etc.',
    onClick() {
      const { wallets, kyc } = baseStore.getState();
      const kycData = kyc.business || kyc.individual;

      if (!kycData || (kycData && kycData.status !== 'VERIFIED')) {
        return Modal.Alert('Deposits Unavailable', 'You cannot make a deposit until you have been verified.');
      }

      const localCurrency = kyc.business?.currency || kyc.individual?.currency;
      const asset = wallets?.find((wallet) => wallet.currency.code === localCurrency?.code);

      if (!asset) {
        return;
      }

      Modal.Open(<DepositDialog assetId={asset?.id} currency={localCurrency!} />, {
        customClass: {
          container: 'modal-mobile-fullscreen',
          popup: 'deposit-dialog-popup',
          htmlContainer: 'deposit-dialog-container',
        },
      });
    },
  },
  {
    id: 'result-4',
    title: 'Account Settings',
    description: 'Update your personal account information',
    location: route.dashboard.settings,
  },
  {
    id: 'result-5',
    title: 'Security Settings',
    description: 'Update your security settings, add new authentication methods.',
    location: route.settings.security,
  },
  {
    id: 'result-6',
    title: 'Change Password',
    description: 'Update your account password',
    location: route.settings.password,
  },
  {
    id: 'result-7',
    title: 'Manage Bank Accounts',
    description: 'Manage your saved bank accounts.',
    location: route.settings.bankAccounts,
  },
  {
    id: 'result-8',
    title: 'Manage Crypto Addresses',
    description: 'Manage your saved crypto addresses.',
    location: route.settings.cryptoAddresses,
  },
  {
    id: 'result-9',
    title: 'Team Management',
    description: 'Manage your team members',
    location: route.settings.team,
  },
  {
    id: 'result-10',
    title: 'Notification Settings',
    description: 'Update your notification preferences',
    location: route.settings.notifications,
  },
];
