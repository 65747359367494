import { BusinessAction, BusinessDto } from '../typings/business';

export const setBusinessInfo = (business: BusinessDto): BusinessAction => ({
  type: 'SET_BUSINESS',
  business,
});

export const updateBusinessInfo = (business: Partial<BusinessDto>): BusinessAction => ({
  type: 'UPDATE_BUSINESS',
  business,
});
