import config from './config';

export const registerFirebaseSw = async (): Promise<ServiceWorkerRegistration | undefined> => {
  if ('serviceWorker' in navigator) {
    const sw = config.variant === 'PRODUCTION' ? './fcm-sw-prod.js' : './fcm-sw-dev.js';
    return await navigator.serviceWorker.register(sw);
  } else {
    return undefined;
  }
};
