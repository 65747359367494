import React, { useState } from 'react';
import Cleave from 'cleave.js/react';
import serializeForm from 'form-serialize';
import Gap from '../components/Gap';
import { Input } from '../components/html/Input';
import { Select2 } from '../components/html/Select';
import { Modal } from '../components/Modal';
import { handleRequestErrors } from '../../utils';
import * as kycApi from '../../api/kyc';
import { StakeholderKycDto } from '../../typings';

interface OnboardingModalProps<T = any> {
  onSuccess(data: T): void;
}

export const AddStakeholderModal = ({ onSuccess }: OnboardingModalProps<StakeholderKycDto>) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <h4>Add Stakeholder Details</h4>

      <Gap v={0.5} />

      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          const formData = serializeForm(ev.target, { hash: true });
          const { type, position, name, idType, idNumber, ownership, email, phoneNumber } = formData;

          setLoading(true);
          kycApi
            .addStakeHolder({
              type,
              position,
              name,
              idNumber: `${idType}::${idNumber}`,
              ownership,
              email,
              phoneNumber,
            })
            .then((stakeholder) => {
              onSuccess(stakeholder);
              Modal.CloseAll();
            })
            .catch((err) => {
              console.log(err);
              handleRequestErrors(err);
            })
            .finally(() => setLoading(false));
        }}
      >
        <Select2
          required
          name="type"
          options={[
            {
              label: 'Director',
              value: 'DIRECTOR',
            },
            {
              label: 'Authorized Representative',
              value: 'REPRESENTATIVE',
            },
            {
              label: 'Shareholder / Beneficiary',
              value: 'SHAREHOLDER',
            },
          ]}
          gapbottom={1}
        />

        <Input name="position" type="text" placeholder="Position (Optional)" gapbottom={1} />

        <Input required name="name" type="text" placeholder="Full Name" gapbottom={1} />

        <div className="row">
          <div className="col-3 pe-0">
            <Select2
              required
              name="idType"
              options={[
                {
                  label: 'NIN',
                  value: 'NIN',
                },
                {
                  label: 'BVN',
                  value: 'BVN',
                },
              ]}
            />
          </div>
          <div className="col-9">
            <Input required name="idNumber" type="text" placeholder="Identification No" />
          </div>
          <div className="col-12 pt-0 mt-0">
            <small className="text-muted">Required if one of majority stakeholder</small>
            <Gap v={1} />
          </div>
        </div>

        <div className="form-group">
          <Cleave
            id="input-text"
            name="ownership"
            className="form-control"
            placeholder="Percentage Ownership (Optional)"
            autoComplete="off"
            options={{
              numeral: true,
              stripLeadingZeroes: false,
              numeralThousandsGroupStyle: 'none',
            }}
          />
          <Gap v={1} />
        </div>

        <Input name="email" type="email" placeholder="Contact Email (Optional)" gapbottom={1} />

        <Input name="phoneNumber" type="tel" placeholder="Contact Phone (Optional)" gapbottom={1} />

        <div className="buttons">
          <button disabled={loading} type="submit" className="btn btn-primary btn-width-150px">
            {loading ? 'Please wait...' : 'Save'}
          </button>

          <Gap h={1} />

          <button type="button" className="btn btn-grey btn-width-100px" onClick={() => Modal.CloseAll()}>
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};
