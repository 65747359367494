import React, { useEffect, useState } from 'react';
import OtpInput from 'react18-input-otp';
import { BsChevronRight } from 'react-icons/bs';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import { baseStore } from '../../../store';
import * as userApi from '../../../api/user';
import * as userActions from '../../../actions/user';
import { handleRequestErrors } from '../../../utils';
import { Security2faTypes } from '../../../typings';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/html/Button';
import { toast } from 'react-toastify';
import { ConditionalElement } from '../../components/ConditionalElement';
import Gap from '../../components/Gap';
import checkTickIcon from '../../../assets/check-tick.svg';
import './security.scss';

interface TwoFASetupProps {
  type?: Security2faTypes;
}

const SetupDone = (props: TwoFASetupProps) => {
  const [title, setTitle] = useState('Two-Factor Authentication Verified!');
  // eslint-disable-next-line quotes
  const [message, setMessage] = useState("You've successfully setup two factor authentication");

  useEffect(() => {
    if (props.type === 'AUTHENTICATOR') {
      setTitle('Authenticator Verified!');
      // eslint-disable-next-line quotes
      setMessage("You've successfully enabled Authenticator security for your account.");
    } else if (props.type === 'SMS') {
      setTitle('Phone Number Verified!');
      // eslint-disable-next-line quotes
      setMessage("You've successfully enabled SMS security for your account.");
    } else if (props.type === 'PIN') {
      setTitle('PIN Set Successfully!');
      setMessage('You successfully enabled a security PIN for your account.');
    }
  }, [props.type]);

  return (
    <div className="text-center">
      <img src={checkTickIcon} alt="done" height={80} />
      <Gap v={1} />
      <h4 className="color-171717">{title}</h4>
      <p className="color-r7-g12-b66-a60">{message}</p>
      <button className="btn btn-primary full-width" onClick={() => Modal.CloseAll()}>
        Okay
      </button>
    </div>
  );
};

const AuthenticatorSetup = () => {
  const { user } = baseStore.getState();
  const [loading, setLoading] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [qrCode, setQrCode] = useState('');

  useEffect(() => {
    userApi
      .setup2Fa('AUTHENTICATOR')
      .then(({ data }) => setQrCode(data.barcodeImage))
      .catch(handleRequestErrors);
  }, []);

  const verifyAuthenticatorSetup = () => {
    setLoading(true);

    userApi
      .verify2Fa('AUTHENTICATOR', otpCode)
      .then(() => {
        setIsVerified(true);
        baseStore.dispatch(
          userActions.updateUser({
            security: {
              ...(user.security! || {}),
              authenticator: {
                set: true,
                enabled: true,
              },
            },
          }),
        );
      })
      .catch(handleRequestErrors)
      .finally(() => setLoading(false));
  };

  return isVerified ? (
    <SetupDone type="AUTHENTICATOR" />
  ) : (
    <div className="twofa-setup-1">
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          verifyAuthenticatorSetup();
        }}
      >
        <h4>Setup Authenticator</h4>
        <Gap v={1} />

        <div className="qr-display">
          <ConditionalElement condition={!!qrCode} element={<img src={qrCode} alt="QRC" />} />
        </div>

        <Gap v={2} />

        <span className="fw-bold">Verification code</span>
        <Gap v={0.5} />
        <OtpInput
          value={otpCode}
          numInputs={6}
          onChange={(otp: string) => setOtpCode(otp)}
          placeholder="******"
          shouldAutoFocus={true}
          containerStyle="otp-input-container"
          inputStyle="form-control"
          isInputNum={true}
        />

        <Gap v={1} />

        <Button loading={loading} disabled={otpCode.length < 6 || loading} className="btn btn-primary full-width" text="Verify" />
      </form>
    </div>
  );
};

const SmsSetup = () => {
  const { user } = baseStore.getState();
  const [otpCode, setOtpCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    userApi.setup2Fa('SMS').catch(handleRequestErrors);
  }, []);

  const verifySmsSetup = () => {
    setLoading(true);

    userApi
      .verify2Fa('SMS', otpCode)
      .then(() => {
        setIsVerified(true);
        baseStore.dispatch(
          userActions.updateUser({
            security: {
              ...(user.security! || {}),
              sms: {
                set: true,
                enabled: true,
              },
            },
          }),
        );
      })
      .catch(handleRequestErrors)
      .finally(() => setLoading(false));
  };

  return isVerified ? (
    <SetupDone type="SMS" />
  ) : (
    <div className="twofa-setup-1">
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          verifySmsSetup();
        }}
      >
        <h4>Setup SMS Security</h4>
        <Gap v={2} />

        <div className="note-type-1 caution">
          <span>
            An authentication code has been sent via SMS to your phone number: <b>{user.primaryPhone}</b>.
          </span>
        </div>

        <Gap v={2} />

        <span className="fw-bold">Verification code</span>
        <Gap v={0.5} />
        <OtpInput
          value={otpCode}
          numInputs={6}
          onChange={(otp: string) => setOtpCode(otp)}
          placeholder="******"
          shouldAutoFocus={true}
          containerStyle="otp-input-container"
          inputStyle="form-control"
          isInputNum={true}
        />

        <Gap v={1} />

        <Button loading={loading} className="btn btn-primary full-width" disabled={otpCode.length < 6 || loading} text="Verify" />
      </form>
    </div>
  );
};

const PinSetup = () => {
  const { user } = baseStore.getState();
  const [loading, setLoading] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [otpCode2, setOtpCode2] = useState('');
  const [isVerified, setIsVerified] = useState(false);

  const verifyPinSetup = () => {
    if (otpCode !== otpCode2) {
      return toast.error('Your PINs do not match. Please check your PIN and try again');
    }

    setLoading(true);
    userApi
      .setup2Fa('PIN', otpCode)
      .then(() => {
        setIsVerified(true);
        baseStore.dispatch(
          userActions.updateUser({
            security: {
              ...(user.security! || {}),
              pin: {
                set: true,
                enabled: true,
              },
            },
          }),
        );
      })
      .catch(handleRequestErrors)
      .finally(() => setLoading(false));
  };

  return isVerified ? (
    <SetupDone type="PIN" />
  ) : (
    <>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          verifyPinSetup();
        }}
      >
        <div className="twofa-setup-1">
          <h4>Setup Account PIN</h4>
          <Gap v={1} />

          <div className="note-type-1 caution">
            Setup a 4-digit PIN for your account. You can use this pin to verify your trades and authorized account updates.
          </div>

          <Gap v={2} />

          <span className="fw-bold" style={{ marginLeft: '5px' }}>
            Choose Your PIN
          </span>
          <Gap v={0.5} />
          <OtpInput
            value={otpCode}
            numInputs={4}
            onChange={(otp: string) => setOtpCode(otp)}
            placeholder="****"
            shouldAutoFocus={true}
            containerStyle="otp-input-container"
            inputStyle="form-control"
            isInputNum={true}
          />

          <Gap v={1} />

          <span className="fw-bold" style={{ marginLeft: '5px' }}>
            Enter PIN Again
          </span>
          <Gap v={0.5} />
          <OtpInput
            value={otpCode2}
            numInputs={4}
            onChange={(otp: string) => setOtpCode2(otp)}
            placeholder="****"
            shouldAutoFocus={true}
            containerStyle="otp-input-container"
            inputStyle="form-control"
            isInputNum={true}
          />
        </div>

        <Gap v={1} />

        <Button loading={loading} className="btn btn-primary full-width" disabled={otpCode.length < 4 || loading} text="Verify" />
      </form>
    </>
  );
};

export const TwoFASetup = (props: TwoFASetupProps) => {
  const [type, setType] = useState(props.type);

  switch (type) {
    case 'AUTHENTICATOR':
      return <AuthenticatorSetup />;
    case 'SMS':
      return <SmsSetup />;
    case 'PIN':
      return <PinSetup />;
    default:
      return (
        <div className="twofa-setup-1">
          <h4>Setup 2FA</h4>

          <ul className="chooser">
            <li onClick={() => setType('AUTHENTICATOR')}>
              Authenticator (Recommended) <BsChevronRight />
            </li>
            <li onClick={() => setType('SMS')}>
              Phone Number Verification <BsChevronRight />
            </li>
            <li onClick={() => setType('PIN')}>
              Security PIN <BsChevronRight />
            </li>
          </ul>
        </div>
      );
  }
};
