import React from 'react';
import { Modal } from '.';
import Gap from '../Gap';

interface AlertModalProps {
  title: string;
  message: string | JSX.Element;
  className?: string;
}

export const AlertModal = ({ title, message }: AlertModalProps) => (
  <>
    <div className="text-start">
      <h4 dangerouslySetInnerHTML={{ __html: title }} />

      <p className="margin-auto_center">{message}</p>

      <Gap v={1} />

      <button
        onClick={() => Modal.CloseAll()}
        className="btn btn-primary btn-width-150px"
        style={{ paddingLeft: '25px', paddingRight: '25px' }}
      >
        Okay
      </button>
    </div>
  </>
);
