/* eslint-disable new-cap */
/* eslint-disable valid-jsdoc */
import React from 'react';
import swal, { SweetAlertOptions } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AlertModal } from './Alert';
import { ConfirmModal } from './Confirm';
import { PromptModal, PromptModalOptions } from './Prompt';

export interface DialogButtons {
  accept?: {
    text: string;
    type?: 'normal' | 'delete';
  };
  cancel?: {
    text: string;
    type?: 'normal' | 'delete';
  };
}

const Swal = withReactContent(swal);

const Open = (view: JSX.Element, options?: SweetAlertOptions) => {
  const customClass: any = options?.customClass || {};

  return Swal.fire({
    html: view,
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: true,
    ...options,
    customClass: {
      ...customClass,
      container: 'modal-default ' + customClass.container || '',
    },
  });
};

const Alert = (title: string, message: string | JSX.Element): Promise<void> => {
  return new Promise((resolve) =>
    Open(<AlertModal title={title} message={message} />, {
      allowOutsideClick: true,
      allowEscapeKey: true,
      allowEnterKey: false,
      showCloseButton: true,
      didClose: () => resolve(),
      customClass: { popup: 'max-w-500x' },
    }),
  );
};

const Confirm = (
  title?: string,
  message?: string | JSX.Element | null,
  type: 'delete' | 'normal' = 'normal',
  options: SweetAlertOptions & { buttons?: DialogButtons } = {},
): Promise<boolean> => {
  const { buttons, ...swalOptions } = options;

  return new Promise((resolve) =>
    Open(<ConfirmModal title={title} message={message} type={type} buttons={buttons} onCancel={resolve} onAccept={resolve} />, {
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCloseButton: false,
      ...swalOptions,
      customClass: { popup: 'max-w-500x' },
    }),
  );
};

const Prompt = (title: string, message: string | JSX.Element, options?: PromptModalOptions) => {
  return new Promise((resolve) =>
    Open(<PromptModal title={title} message={message} options={options!} onCancel={resolve} onText={resolve} type="normal" />, {
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCloseButton: true,
      customClass: { popup: 'max-w-500x' },
    }),
  );
};

export const CloseAll = () => Swal.close();

export const Modal = {
  Open,
  CloseAll,
  Alert,
  Confirm,
  Prompt,
};
