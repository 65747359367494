import qrcode from 'qrcode';
import { AxiosError } from 'axios';
import BigNumber from 'bignumber.js';
import { toast } from 'react-toastify';
import { StableCoins, USD } from '../constants';
import { baseStore } from '../store';
import { CurrencyType } from '../typings';

export const handleRequestErrors = (error: AxiosError, defaultMessage?: string) => {
  let useMessage = '';
  const errorStatus = error.response?.status;

  if (errorStatus?.toString().startsWith('4')) {
    const errors = error.response?.data.errors;
    useMessage = error.response?.data.message || (errors && errors[0].message) || defaultMessage || 'An unexpected error occurred.';
  }

  if (errorStatus === 403 && error.response?.data.message.indexOf('expired') !== -1) {
    baseStore.dispatch({ type: 'RESET_STATES' });
  }

  toast.error(useMessage);
};

export const normaliseAmount = (decimals: number, value: number): number => {
  return Number(Math.floor(parseFloat(value + 'e' + decimals)) + 'e-' + decimals);
};

export const formatCurrency = (type: CurrencyType, amount: number, currency: string, decimals = 8): string => {
  decimals = decimals > 8 ? 8 : decimals;
  decimals = StableCoins.includes(currency) ? 2 : decimals;
  amount = normaliseAmount(decimals, Number(new BigNumber(amount).toFixed(decimals, BigNumber.ROUND_DOWN)));

  if (type === 'CRYPTO') {
    return `${amount} ${currency}`;
  } else {
    return amount.toLocaleString('en-US', { style: 'currency', currency: currency || USD });
  }
};

export const getQueryParams = (obj: { [x: string]: any }, ignoreEmpty = true): string => {
  const queries = [];

  for (const [k, v] of Object.entries(obj)) {
    if (ignoreEmpty && (v === null || v === undefined)) {
      continue;
    }

    queries.push(`${k}=${v}`);
  }

  return queries.length ? `?${queries.join('&')}` : '';
};

export const imageFileToDataUrl = (
  file: File,
  getResult: (err: string | Error | null, dataUrl: string | ArrayBuffer | null) => void,
): void => {
  const reader = new FileReader();

  reader.onload = (ev: ProgressEvent<FileReader>) => {
    if (ev.target) {
      const res = ev.target.result;

      if (!res) {
        return getResult('Unable to read image file.', null);
      } else {
        return getResult(null, res);
      }
    }
  };

  reader.readAsDataURL(file);
};

export const generateUniqueFileIdentifier = (file: File): string => {
  const relativePath = file.webkitRelativePath || file.name;
  const size = file.size;

  return size + '-' + relativePath.replace(/[^0-9a-zA-Z_-]/gim, '');
};

export const generateQrCode = (text: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    qrcode.toDataURL(text, (err, imageUri) => {
      if (err) {
        return reject(err);
      }

      resolve(imageUri);
    });
  });
};

export const cleanPhoneNumber = (phone: string): string => {
  return phone ? (phone.startsWith('+') ? phone : `+${phone}`) : '';
};
