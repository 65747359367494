import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { HiPlusSm } from 'react-icons/hi';
import { BiEditAlt } from 'react-icons/bi';
import { Header } from '../../components/DashboardHeader';
import { Sidebar } from '../../components/DashboardSidebar';
import { SettingsSidebar } from '../../components/SettingsSidebar';
import Gap from '../../components/Gap';
import './styles.scss';
import { AddTeamMemberModal, EditTeamMemberModal } from './Modals';
import { Modal } from '../../components/Modal';
import { useNavigate } from 'react-router-dom';
import { ConditionalElement } from '../../components/ConditionalElement';
import { TeamMemberDto } from '../../../typings';
import * as userApi from '../../../api/user';
import { handleRequestErrors } from '../../../utils';
import { Security2faDialog } from '../../components/2FADialog';

export const TeamSettings = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState<TeamMemberDto[]>([]);

  useEffect(() => {
    setLoading(true);

    userApi
      .getTeamMembers()
      .then(({ members }) => setMembers(members))
      .catch(handleRequestErrors)
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Helmet title="Settings | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="settings-page-wrap">
                  <SettingsSidebar />

                  <div className="settings-page-content">
                    <div className="page-content-inner">
                      <div className="row">
                        <div className="col-auto hidden-768px" data-vertical_center>
                          <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                            Back
                          </a>
                        </div>

                        <div className="col hidden-768px" data-vertical_center>
                          <h6 className="p-0 m-0">Team Management</h6>
                        </div>

                        <div className="col-auto">
                          <button
                            className="btn btn-primary btn-float-mobile"
                            onClick={() =>
                              Modal.Open(<AddTeamMemberModal onMemberAdded={(member) => setMembers([...members, member])} />, {
                                customClass: {
                                  container: 'modal-mobile-fullscreen',
                                  popup: 'max-w-400x',
                                },
                              })
                            }
                          >
                            <span className="text hidden-768px">Add team member</span>
                            <span className="icon visible-768px">
                              <HiPlusSm />
                            </span>
                          </button>
                        </div>
                      </div>

                      <Gap v={1} className="hidden-768px" />

                      <div className="team-settings-list">
                        <ul>
                          {members.map((member) => {
                            const { id, email, status, firstName, lastName } = member;

                            return (
                              <li key={id}>
                                <div className="row">
                                  <div className="col">
                                    <a>
                                      <small>
                                        <span>{email}</span>&nbsp;<b>&middot;</b>&nbsp;
                                        <b>
                                          <i>{status}</i>
                                        </b>
                                      </small>
                                      <Gap v={0.2} />
                                      <h6>
                                        {firstName} {lastName}
                                      </h6>
                                    </a>
                                  </div>
                                  <div className="col-auto" data-vertical_center>
                                    <div>
                                      <a
                                        className="edit btn"
                                        onClick={() => {
                                          Modal.Open(
                                            <EditTeamMemberModal
                                              member={member}
                                              onMemberUpdate={(updated) =>
                                                setMembers(members.map((mem) => (updated.id === mem.id ? updated : mem)))
                                              }
                                            />,
                                            {
                                              customClass: {
                                                container: 'modal-mobile-fullscreen',
                                                popup: 'max-w-400x',
                                              },
                                            },
                                          );
                                        }}
                                      >
                                        Edit&nbsp;&nbsp;
                                        <BiEditAlt />
                                      </a>
                                      &nbsp;&nbsp;
                                      <a
                                        className="remove btn btn-danger"
                                        onClick={() => {
                                          Modal.Open(
                                            <Security2faDialog
                                              operation="TEAM_MEMBER_REMOVAL"
                                              handleSecurity={async (security, callback) => {
                                                userApi
                                                  .removeTeamMember(id!, security)
                                                  .then(() => {
                                                    Modal.Alert(
                                                      'Removed Team Member',
                                                      `Successfully removed ${firstName} ${lastName} from your team.`,
                                                    );
                                                    setMembers(members.filter((mem) => mem.id !== id));
                                                  })
                                                  .catch((err) => {
                                                    callback(null, err);
                                                    handleRequestErrors(err);
                                                  });
                                              }}
                                            />,
                                            {
                                              allowOutsideClick: false,
                                              allowEscapeKey: false,
                                              customClass: {
                                                container: 'modal-mobile-fullscreen',
                                                popup: 'max-w-350x',
                                              },
                                            },
                                          );
                                        }}
                                      >
                                        Remove&nbsp;&nbsp;✕
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>

                        <ConditionalElement
                          condition={!loading && members.length === 0}
                          element={
                            <div className="no-result">
                              <h6>You haven't added any team member(s)</h6>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
