import React, { useEffect, useState } from 'react';
import serializeForm from 'form-serialize';
import { baseStore } from '../../../store';
import { WalletAsset, WithdrawalAccountDto } from '../../../typings';
import { Input } from '../../components/html/Input';
import { Select2 } from '../../components/html/Select';
import { Button } from '../../components/html/Button';
import { Modal } from '../../components/Modal';
import { Security2faDialog } from '../../components/2FADialog';
import { handleRequestErrors } from '../../../utils';
import * as withdrawalApi from '../../../api/withdrawal';
import Gap from '../../components/Gap';
import { ConditionalElement } from '../../components/ConditionalElement';
import config from '../../../config';

type AddAddressModalProps = {
  onAccountAdd?(account: WithdrawalAccountDto): void;
};

export const AddAddressModal = (props: AddAddressModalProps) => {
  const { wallets } = baseStore.getState();
  const { onAccountAdd = () => {} } = props;
  const [walletAsset, setWalletAsset] = useState<WalletAsset>();
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [networks, setNetworks] = useState<any[]>([]);
  const [address, setAddress] = useState('');
  const [addressErrorMessage, setAddressErrorMessage] = useState('');

  useEffect(() => {
    if (config.variant !== 'PRODUCTION') {
      return;
    }

    // prettier-ignore
    const addressRegex = walletAsset?.currency.network === selectedNetwork ? walletAsset?.currency.addressRegex : walletAsset?.currency.networks.find((ntwk) => ntwk.network === selectedNetwork)?.addressRegex;

    setAddressErrorMessage(
      address && addressRegex && !RegExp(addressRegex).test(address) ? 'The wallet address you have entered is invalid.' : '',
    );
  }, [walletAsset?.currency, selectedNetwork]);

  const saveDisabled = !!addressErrorMessage;

  return (
    <>
      <h4>Add Address</h4>
      <p>Add a new wallet address</p>

      <Gap v={0.5} />

      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          const form = ev.target as any;
          const { currency, address, network, memo, label } = serializeForm(form, { hash: true });

          Modal.Open(
            <Security2faDialog
              operation="CRYPTO_ADDRESS_ADD"
              handleSecurity={async (security, callback) => {
                try {
                  await withdrawalApi
                    .addAccount({
                      type: 'CRYPTO',
                      currency,
                      accountNumber: address,
                      accountName: label,
                      network,
                      memo,
                      security,
                    })
                    .then((account) => onAccountAdd(account));

                  Modal.CloseAll();
                } catch (err) {
                  callback(null, err);
                  handleRequestErrors(err);
                }
              }}
            />,
            {
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: 'modal-mobile-fullscreen',
                popup: 'max-w-350x',
              },
            },
          );
        }}
      >
        <Select2
          required
          label="Currency"
          options={wallets!
            .filter(({ currency }) => currency.type === 'CRYPTO')
            .map(({ currency }) => ({
              label: `${currency.name} (${currency.code})`,
              value: currency.code,
            }))}
          name="currency"
          placeholder="Select"
          onChange={(ev) => {
            const wAsset = wallets?.find((wallet) => wallet.currency.code === ev.target.value);

            if (wAsset) {
              setWalletAsset(wAsset);
              setNetworks([
                {
                  label: wAsset.currency.network,
                  value: wAsset.currency.network,
                },
                ...wAsset!.currency.networks?.map(({ network }) => ({
                  label: network,
                  value: network,
                })),
              ]);
            }
          }}
          gapbottom={1}
        />

        <Input
          required
          label="Address"
          gapbottom={1}
          placeholder="Ex. 0x71C7...01B5f6d8976F"
          name="address"
          onChange={(ev) => setAddress(ev.target.value)}
        />

        <Select2
          required
          label="Network"
          placeholder="Select a Network"
          options={networks}
          name="network"
          value={selectedNetwork}
          onChange={(ev) => setSelectedNetwork(ev.target.value)}
          gapbottom={1}
        />

        {
          // prettier-ignore
          walletAsset?.currency.networks.find((n) => n.network === selectedNetwork)?.requiresMemo || (walletAsset?.currency.network === selectedNetwork && walletAsset?.currency.requiresMemo) ? (
            <Input required type="text" label="Memo" placeholder="Ex. 123456" name="memo" gapbottom={1} />
          ) : null
        }

        <Input required type="text" label="Label" placeholder="Ex. My Binance Wallet" name="label" gapbottom={1} />

        <ConditionalElement
          condition={!!addressErrorMessage}
          element={<div className="color-fd0000 text-center">{addressErrorMessage}</div>}
        />

        <Gap v={1} />

        <div className="buttons">
          <Button disabled={saveDisabled} type="submit" className="btn btn-primary full-width" text="Save wallet" />
        </div>
      </form>
    </>
  );
};
