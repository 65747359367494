import { BusinessKycDetailsDto, KycAction, ProfileKycDetailsDto, StakeholderKycDto } from '../typings';

export const setIndividualKycInfo = (details: ProfileKycDetailsDto): KycAction => ({
  type: 'SET_INDIVIDUAL_KYC',
  details,
});

export const setBusinessKycInfo = (details: BusinessKycDetailsDto): KycAction => ({
  type: 'SET_BUSINESS_KYC',
  details,
});

export const addStakeholder = (stakeholder: StakeholderKycDto): KycAction => ({
  type: 'ADD_STAKEHOLDER',
  stakeholder,
});

export const removeStakeholder = (id: string): KycAction => ({
  type: 'REMOVE_STAKEHOLDER',
  id,
});
