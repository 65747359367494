import { CurrencyDefinition, ExchangeRate, RatesAction, RatesState } from '../typings';

const initialState: { currencies: CurrencyDefinition[]; conversions: ExchangeRate[] } = {
  currencies: [],
  conversions: [],
};

export default (state = initialState, action: RatesAction): RatesState => {
  switch (action.type) {
    case 'SET_CURRENCIES':
      return {
        ...state,
        currencies: action.currencies!,
      };
    case 'SET_CONVERSIONS':
      return {
        ...state,
        conversions: action.conversions!,
      };
    case 'ADD_CONVERSION':
      return {
        ...state,
        conversions: [
          ...(state.conversions || []).filter(
            (conv) => !(conv.fromCurrency === action.conversion?.fromCurrency && conv.toCurrency === action.conversion?.toCurrency),
          ),
          action.conversion!,
        ],
      };
    default:
      return state;
  }
};
