import _ from 'lodash';
import BigNumber from 'bignumber.js';
import copy from 'clipboard-copy';
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useEffect, useRef, useState } from 'react';
import serializeForm from 'form-serialize';
import Gap from '../components/Gap';
import { RiMastercardLine, RiVisaLine } from 'react-icons/ri';
import { Input, InputAmount } from '../components/html/Input';
import { Select2 } from '../components/html/Select';
import { ConditionalElement } from '../components/ConditionalElement';
import { Button } from '../components/html/Button';
import { baseStore } from '../../store';
import { CardDto, CardSchemeType, CardSupportedCurrencyDto, CardTransactionDto, CardTransactionType } from '../../typings/cards';
import { Note } from '../components/Note';
import { formatCurrency, handleRequestErrors } from '../../utils';
import { CurrencyDefinition, WalletAsset } from '../../typings';
import * as cardsApi from '../../api/cards';
import { Modal } from '../components/Modal';
import { Security2faDialog } from '../components/2FADialog';
import { CopiableText } from '../components/CopiableText';
import moment from 'moment';
import { AiTwotoneCopy } from 'react-icons/ai';

type CardOpsModalProps = {
  card: CardDto;
  onUpdate(cardId: string): void;
};

interface TransactionRowProps {
  txn: CardTransactionDto;
  openTransactionModal: (transaction: CardTransactionDto) => void;
}

const TransactionDetailsModal = ({ transaction }: { transaction: CardTransactionDto }) => {
  const getTxMeta = (name: string) => transaction.metadata.find((meta) => meta.name === name)?.value;

  const getMerchantInfo = () =>
    `${getTxMeta('merchantName')} (${[getTxMeta('merchantCity'), getTxMeta('merchantCountry')].filter(Boolean).join(', ')})`;

  const getStatusStyle = () => {
    if (transaction.status === 'SUCCESS') {
      return {
        color: '#00B102',
        backgroundColor: '#ECFFEC',
        border: '1px solid #ECFFEC',
        borderRadius: '5px',
        padding: '4px 8px',
        fontWeight: '500',
      };
    } else if (transaction.status === 'FAILED') {
      return {
        color: 'red',
        backgroundColor: 'lightcoral',
        border: '1px solid lightcoral',
        borderRadius: '5px',
        padding: '4px 8px',
        fontWeight: '500',
      };
    } else if (transaction.status === 'PENDING') {
      return {
        color: '#FF8E00',
        backgroundColor: '#FFF7EC',
        border: '1px solid #FFF7EC',
        borderRadius: '5px',
        padding: '4px 8px',
        fontWeight: '500',
      };
    }

    return {};
  };

  return (
    <div className="card-txn-container">
      <h4>Transaction Details</h4>
      <div className="txn-status">
        <p style={getStatusStyle()}>{_.capitalize(transaction.status)}</p>
      </div>

      <div>
        <div className="txn-det-item">
          <p className="det-item-lhs">Transaction ID</p>
          <p className="det-item-rhs">
            <CopiableText text={transaction.id} /> <AiTwotoneCopy />
          </p>
        </div>
        <div className="txn-det-item">
          <p className="det-item-lhs">Type</p>
          <p style={{ color: transaction.type === 'DEBIT' ? 'red' : '#00B102' }}>{transaction.type}</p>
        </div>
        <div className="txn-det-item">
          <p className="det-item-lhs">Currency</p>
          <p className="det-item-rhs">{transaction.currency}</p>
        </div>
        <div className="txn-det-item">
          <p className="det-item-lhs">Amount</p>
          <p className="det-item-rhs">{transaction.amount.toLocaleString('en')}</p>
        </div>
        <div className="txn-det-item">
          <p className="det-item-lhs">Fee</p>
          <p className="det-item-rhs">{transaction.feeAmount}</p>
        </div>
        {getTxMeta('merchantName') ? (
          <div className="txn-det-item">
            <p className="det-item-lhs">Merchant</p>
            <p className="det-item-rhs">{getMerchantInfo()}</p>
          </div>
        ) : null}
        <div className="txn-det-item">
          <p className="det-item-lhs">Transaction Date</p>
          <p className="det-item-rhs">{moment.utc(transaction.createdAt, 'ddd MMM D Y HH:mm:s').format('MMM D, Y hh:mm:s')}</p>
        </div>
      </div>
    </div>
  );
};

const TransactionRow = ({ txn, openTransactionModal }: TransactionRowProps) => {
  const getTxMeta = (name: string) => txn.metadata.find((meta) => meta.name === name)?.value;

  const getMerchantInfo = () =>
    `${getTxMeta('merchantName')} (${[getTxMeta('merchantCity'), getTxMeta('merchantCountry')].filter(Boolean).join(', ')})`;

  const transactionType = getTxMeta('transactionType');

  const isFundingTx = transactionType === 'FUNDING' || /funding/.test(txn.memo);
  const isReversalTx = transactionType === 'REVERSAL' || /reversal/.test(txn.memo);
  const isWithdrawalTx = transactionType === 'WITHDRAWAL' || /withdrawal/.test(txn.memo);
  const isChargeTx = transactionType === 'CHARGE' || /charge/.test(txn.memo);

  // const wasCharged = transactionType === 'CHARGE';
  const isPending = txn.status === 'PENDING';
  const wasDeclined = transactionType === 'DECLINED';
  const wasReversed = transactionType === 'REVERSAL';
  const wasRefunded = transactionType === 'REFUNDED';

  const handleClick = () => {
    openTransactionModal(txn);
  };

  return (
    <li
      key={txn.id}
      className={
        // prettier-ignore
        isPending && isWithdrawalTx ? 'tx-withdrawal-pending' :
          wasDeclined ? 'tx-declined' :
            txn.status === 'PENDING' ? 'tx-pending' :
              wasReversed || wasRefunded ? 'tx-reverserd-refunded' :
                txn.type === 'CREDIT' ? 'tx-in' : 'tx-out'
      }
      onClick={handleClick}
    >
      <div className="left">
        <div className="label">
          {
            // prettier-ignore
            transactionType === 'CARD_CREATION' ? 'Card Creation Fee' :
              isFundingTx ? 'Card Funding' :
                isReversalTx ? 'Card Reversal' :
                  isWithdrawalTx ? 'Card Withdrawal' :
                    isChargeTx || txn.type === 'DEBIT' ? getMerchantInfo() : ''
          }
        </div>
        <div className="value">{moment.utc(txn.createdAt, 'ddd MMM D Y HH:mm:s').format('MMM D, Y hh:mm')}</div>
      </div>

      <div className="right">
        <span className="currency">{txn.currency}</span>
        <span className="amount">{txn.amount.toLocaleString('en')}</span>
        <span>
          <img src={require('../../assets/arrow-right.png')} alt="" width={18} height={18} />
        </span>
      </div>
    </li>
  );
};

export const CreateCardModal = (props: { onCreated(card: CardDto): void }) => {
  const [working, setWorking] = useState(false);
  const [sectorId, setSectorId] = useState<string>();
  const [currency, setCurrency] = useState<string>('');
  const [scheme, setScheme] = useState<CardSchemeType>('MASTERCARD');
  const [fundCurrency, setFundCurrency] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [sectors, setSectors] = useState<Array<{ id: string; name: string }>>([]);
  const [currencies, setCurrencies] = useState<CardSupportedCurrencyDto[]>([]);
  const [, setRate] = useState<number | null>(null);
  const [creationFee, setCreationFee] = useState<number | null>(null);
  const [fundWallet, setFundWallet] = useState<WalletAsset | null>();

  const { user, wallets } = baseStore.getState();

  useEffect(() => {
    cardsApi.getCardSectors().then((response) => setSectors(response.sectors));
    cardsApi.getSupportedCurrencies().then((response) => setCurrencies(response.currencies));
  }, []);

  useEffect(() => {
    if (!(currency && fundCurrency && fundWallet)) {
      return;
    }

    const currencyDef = currencies.find((curr) => curr.code === currency);

    if (currency === fundCurrency) {
      const cFee = 1 * currencyDef?.creationFee!;

      setRate(1);
      setCreationFee(cFee);
      setErrorMessage(
        fundWallet.balance < cFee ? `You do not have sufficient funds in your ${fundCurrency} wallet to create a virtual card.` : '',
      );
      return;
    }

    setWorking(true);

    cardsApi
      .getExchangeRate(fundCurrency, currency)
      .then((conversion) => {
        const cFee = Number(
          new BigNumber(currencyDef?.creationFee! / conversion.rate).toFixed(fundWallet.currency.decimals!, BigNumber.ROUND_UP),
        );

        setRate(conversion.rate);
        setCreationFee(cFee);
        setErrorMessage(
          fundWallet.balance < cFee ? `You do not have sufficient funds in your ${fundCurrency} wallet to create a virtual card.` : '',
        );
      })
      .finally(() => setWorking(false));
  }, [currency, fundCurrency, fundWallet]);

  useEffect(() => {
    if (!fundCurrency) {
      setFundWallet(null);
      return;
    }

    const wallet = wallets?.find((w) => w.currency.code === fundCurrency);
    setFundWallet(wallet);
  }, [fundCurrency]);

  const creationDisabled = !!errorMessage;

  return (
    <>
      <h4>Create a card</h4>

      <Gap v={0.5} />

      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          const form = ev.target as any;
          const { label, firstName, lastName } = serializeForm(form, { hash: true });

          const data = {
            label,
            sectorId,
            currency,
            scheme,
            firstName,
            lastName,
            fundCurrency,
          };

          Modal.Open(
            <Security2faDialog
              operation="CARDS_CREATE"
              handleSecurity={async (security, callback) => {
                cardsApi
                  .createCard({
                    ...data,
                    security,
                  })
                  .then((card) => {
                    props.onCreated(card);
                    Modal.CloseAll();
                  })
                  .catch((err) => {
                    callback(null, err);
                    handleRequestErrors(err);
                  });
              }}
            />,
            {
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: 'modal-mobile-fullscreen',
                popup: 'max-w-350x',
              },
            },
          );
        }}
      >
        <Input type="text" label="Label" placeholder="Ex. Marketing expense card" name="label" gapbottom={1} />

        <div className="row">
          <div className="col">
            <Input
              required
              type="text"
              label="First name"
              placeholder="Ex. John"
              name="firstName"
              gapbottom={1}
              defaultValue={user?.firstName}
            />
          </div>

          <div className="col">
            <Input
              required
              type="text"
              label="Last name"
              placeholder="Ex. Doe"
              name="lastName"
              gapbottom={1}
              defaultValue={user?.lastName}
            />
          </div>
        </div>

        <Select2
          label="Sector (Optional)"
          placeholder="Select a sector"
          options={sectors.map(({ id: value, name: label }) => ({ label, value }))}
          name="sector"
          value={sectorId}
          onChange={(ev) => setSectorId(ev.target.value)}
          gapbottom={1}
        />

        <Select2
          required
          label="Currency"
          options={currencies.map(({ name: label, code: value }) => ({ label, value }))}
          name="currency"
          placeholder="Select"
          value={currency}
          onChange={(ev) => setCurrency(ev.target.value)}
          gapbottom={1}
          info="Select a currency for your card"
        />

        <Select2
          label="Scheme (Optional)"
          placeholder="Scheme"
          options={[
            { label: 'VISA', value: 'VISA' },
            { label: 'MASTERCARD', value: 'MASTERCARD' },
          ]}
          name="scheme"
          value={scheme}
          onChange={(ev) => setScheme(ev.target.value as CardSchemeType)}
          gapbottom={1}
        />

        <Select2
          required
          label="Fee wallet"
          placeholder="Select a wallet"
          options={wallets!
            .filter((wallet) => {
              const cardCurrency = currencies.find((curr) => curr.code === currency);
              return !!cardCurrency?.funding.find((fundCurr) => fundCurr.code === wallet.currency.code);
            })
            .map(({ currency }) => ({
              label: `${currency.name} (${currency.code})`,
              value: currency.code,
            }))}
          name="scheme"
          value={fundCurrency}
          onChange={(ev) => setFundCurrency(ev.target.value)}
          gapbottom={1}
          info="Select a wallet to pay your card creation fee from"
        />

        <ConditionalElement
          condition={!!(creationFee && fundWallet)}
          element={
            <Note>
              {errorMessage ? (
                <div className="color-fd0000 text-start">{errorMessage}</div>
              ) : (
                <div style={{ textAlign: 'left' }}>
                  You will be charged a{' '}
                  <b>
                    {formatCurrency(fundWallet?.currency.type!, creationFee!, fundWallet?.currency.code!, fundWallet?.currency.decimals)}
                  </b>{' '}
                  fee from your {fundWallet?.currency.code} wallet.
                </div>
              )}
            </Note>
          }
        />

        <Gap v={1} />

        <div className="buttons">
          <Button disabled={creationDisabled || working} type="submit" className="btn btn-primary full-width" text="Create card" />
        </div>
      </form>
    </>
  );
};

export const FundCardModal = (props: CardOpsModalProps) => {
  const [working] = useState(false);
  const [fundCurrency, setFundCurrency] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rate, setRate] = useState<number | null>(null);
  const [currencies, setCurrencies] = useState<CardSupportedCurrencyDto[]>([]);
  const [fundWallet, setFundWallet] = useState<WalletAsset | null>();
  const [cardCurrency, setCardCurrency] = useState<CurrencyDefinition>();
  const [cardAmount, setCardAmount] = useState(0);
  const [fundAmount, setFundAmount] = useState(0);

  const { card } = props;
  const { wallets, rates } = baseStore.getState();

  useEffect(() => {
    cardsApi.getSupportedCurrencies().then((response) => setCurrencies(response.currencies));
  }, []);

  useEffect(() => {
    if (!(fundCurrency && fundWallet)) {
      return;
    }

    if (card.currency === fundCurrency) {
      return setRate(1);
    }

    cardsApi.getExchangeRate(fundCurrency, card.currency).then((conversion) => setRate(conversion.rate));
  }, [fundCurrency, fundWallet]);

  useEffect(() => {
    if (!fundCurrency) {
      setFundWallet(null);
      return;
    }

    const wallet = wallets?.find((w) => w.currency.code === fundCurrency);
    setFundWallet(wallet);
  }, [fundCurrency]);

  useEffect(() => {
    const cardCurr = rates.currencies.find((curr) => curr.code === card.currency);
    setCardCurrency(cardCurr);
  }, [rates]);

  useEffect(() => {
    const amount = rate ? fundAmount * rate! : 0;
    setCardAmount(amount);
    setErrorMessage(fundAmount > fundWallet?.balance! ? 'You do not have sufficient funds for this transaction' : '');
  }, [fundAmount, fundCurrency, rate]);

  const fundingDisabled = !!errorMessage || !rate || !cardAmount || !fundCurrency || !fundAmount;

  return (
    <>
      <h4>Fund card</h4>
      <p>Add funds to your card from your wallet</p>

      <Gap v={0.5} />

      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          Modal.Open(
            <Security2faDialog
              operation="CARDS_FUND"
              handleSecurity={async (security, callback) => {
                cardsApi
                  .fundCard(card.id, fundAmount, fundCurrency, security)
                  .then((transaction) => {
                    props.onUpdate(card.id);

                    if (transaction.status === 'PENDING') {
                      Modal.Alert(
                        'Funding In Progress',
                        `Your card is being funded. It only takes a few seconds. You can check back later. Also, you will get a notification from us as soon as your card is funded.`,
                      );
                    } else {
                      Modal.CloseAll();
                    }
                  })
                  .catch((err) => {
                    callback(null, err);
                    handleRequestErrors(err);
                  });
              }}
            />,
            {
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: 'modal-mobile-fullscreen',
                popup: 'max-w-350x',
              },
            },
          );
        }}
      >
        <Select2
          required
          label="From wallet"
          placeholder="Select a wallet"
          options={wallets!
            .filter((wallet) => {
              const cardCurrency = currencies.find((curr) => curr.code === card.currency);
              return !!cardCurrency?.funding.find((fundCurr) => fundCurr.code === wallet.currency.code);
            })
            .map(({ currency }) => ({
              label: `${currency.name} (${currency.code})`,
              value: currency.code,
            }))}
          name="currency"
          value={fundCurrency}
          onChange={(ev) => setFundCurrency(ev.target.value)}
          gapbottom={1}
          info={
            // prettier-ignore
            fundWallet ? `Available balance: ${
              formatCurrency(fundWallet.currency.type!, fundWallet.balance || 0, fundWallet.currency.code, fundWallet.currency.decimals)
            }` : ''
          }
        />

        <InputAmount
          required
          disabled={!fundWallet}
          label="Funding amount"
          name="amount"
          currency={fundWallet?.currency}
          gapbottom={1}
          onChange={(ev) => setFundAmount(Number((ev.target as any).rawValue || 0))}
          info={fundWallet ? `The amount, in ${fundCurrency} that you'd like to fund your card with` : ''}
        />

        <ConditionalElement
          condition={!!(rate && fundWallet && cardCurrency)}
          element={
            <Note>
              {errorMessage ? (
                <div className="color-fd0000 text-start">{errorMessage}</div>
              ) : (
                <div style={{ textAlign: 'left' }}>
                  {
                    // prettier-ignore
                    rate?.toString().startsWith('0.0') ?

                      `${formatCurrency(cardCurrency?.type!, 1, card.currency, cardCurrency?.decimals)} = ${formatCurrency(fundWallet?.currency.type!, 1 / rate, fundWallet?.currency.code!, fundWallet?.currency.decimals )}` :

                      `${formatCurrency(fundWallet?.currency.type!, 1, fundWallet?.currency.code!, fundWallet?.currency.decimals)} = ${formatCurrency(cardCurrency?.type!, rate!, card.currency, cardCurrency?.decimals)}`
                  }
                  <br />
                  Your card will be funded with:{' '}
                  <b>{formatCurrency(cardCurrency?.type!, cardAmount, cardCurrency?.code!, cardCurrency?.decimals)}</b>
                </div>
              )}
            </Note>
          }
        />

        <Gap v={1} />
        <div className="buttons">
          <Button disabled={fundingDisabled || working} type="submit" className="btn btn-primary full-width" text="Continue" />
        </div>
      </form>
    </>
  );
};

export const WithdrawCardModal = (props: CardOpsModalProps) => {
  const [working] = useState(false);
  const [withdrawCurrency, setFundCurrency] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rate, setRate] = useState<number | null>(null);
  const [currencies, setCurrencies] = useState<CardSupportedCurrencyDto[]>([]);
  const [withdrawWallet, setFundWallet] = useState<WalletAsset | null>();
  const [cardCurrency, setCardCurrency] = useState<CurrencyDefinition>();
  const [cardAmount, setCardAmount] = useState(0);
  const [withdrawAmount, setFundAmount] = useState(0);

  const { card } = props;
  const { wallets, rates } = baseStore.getState();

  useEffect(() => {
    cardsApi.getSupportedCurrencies().then((response) => setCurrencies(response.currencies));
  }, []);

  useEffect(() => {
    if (!(withdrawCurrency && withdrawWallet)) {
      return;
    }

    if (card.currency === withdrawCurrency) {
      return setRate(1);
    }

    cardsApi.getExchangeRate(card.currency, withdrawCurrency).then((conversion) => setRate(conversion.rate));
  }, [withdrawCurrency, withdrawWallet]);

  useEffect(() => {
    if (!withdrawCurrency) {
      setFundWallet(null);
      return;
    }

    const wallet = wallets?.find((w) => w.currency.code === withdrawCurrency);
    setFundWallet(wallet);
  }, [withdrawCurrency]);

  useEffect(() => {
    const cardCurr = rates.currencies.find((curr) => curr.code === card.currency);
    setCardCurrency(cardCurr);
  }, [rates]);

  useEffect(() => {
    const amount = rate ? cardAmount * rate! : 0;
    setFundAmount(amount);
    setErrorMessage(cardAmount > card?.balance! ? 'You do not have sufficient funds for this transaction' : '');
  }, [cardAmount, withdrawCurrency, rate]);

  const withdrawDisabled = !!errorMessage || !rate || !cardAmount || !withdrawCurrency || !withdrawAmount;

  return (
    <>
      <h4>Card withdrawal</h4>
      <p>Withdraw funds from your card to your wallet</p>

      <Gap v={0.5} />

      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          Modal.Open(
            <Security2faDialog
              operation="CARDS_WITHDRAW"
              handleSecurity={async (security, callback) => {
                cardsApi
                  .withdrawCardFunds(card.id, cardAmount, withdrawCurrency, security)
                  .then(() => {
                    props.onUpdate(card.id);
                    Modal.CloseAll();
                  })
                  .catch((err) => {
                    callback(null, err);
                    handleRequestErrors(err);
                  });
              }}
            />,
            {
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: 'modal-mobile-fullscreen',
                popup: 'max-w-350x',
              },
            },
          );
        }}
      >
        <InputAmount
          required
          label="Withdrawal amount"
          name="amount"
          currency={cardCurrency}
          gapbottom={1}
          onChange={(ev) => setCardAmount(Number((ev.target as any).rawValue || 0))}
          info={`Available balance: ${
            // prettier-ignore
            cardCurrency ? formatCurrency(cardCurrency.type, card.balance || 0, cardCurrency.code, cardCurrency.decimals) : '$0.00'
          }`}
        />

        <Select2
          required
          label="To wallet"
          placeholder="Select a wallet"
          options={wallets!
            .filter((wallet) => {
              const cardCurrency = currencies.find((curr) => curr.code === card.currency);
              return !!cardCurrency?.withdraw.find((withdrawCurr) => withdrawCurr.code === wallet.currency.code);
            })
            .map(({ currency }) => ({
              label: `${currency.name} (${currency.code})`,
              value: currency.code,
            }))}
          name="currency"
          value={withdrawCurrency}
          onChange={(ev) => setFundCurrency(ev.target.value)}
          gapbottom={1}
        />

        <ConditionalElement
          condition={!!(rate && withdrawWallet && cardCurrency)}
          element={
            <Note>
              {errorMessage ? (
                <div className="color-fd0000 text-start">{errorMessage}</div>
              ) : (
                <div style={{ textAlign: 'left' }}>
                  {
                    // prettier-ignore
                    rate?.toString().startsWith('0.0') ?

                      `${formatCurrency(cardCurrency?.type!, 1, card.currency, cardCurrency?.decimals)} = ${formatCurrency(withdrawWallet?.currency.type!, 1 / rate, withdrawWallet?.currency.code!, withdrawWallet?.currency.decimals )}` :

                      `${formatCurrency(withdrawWallet?.currency.type!, 1, withdrawWallet?.currency.code!, withdrawWallet?.currency.decimals)} = ${formatCurrency(cardCurrency?.type!, rate!, card.currency, cardCurrency?.decimals)}`
                  }
                  <br />
                  {withdrawWallet ? (
                    <>
                      Your {withdrawWallet?.currency.code} wallet will be credited with:{' '}
                      <b>
                        {formatCurrency(
                          withdrawWallet?.currency.type!,
                          withdrawAmount,
                          withdrawWallet?.currency.code!,
                          withdrawWallet?.currency.decimals,
                        )}
                      </b>
                    </>
                  ) : null}
                </div>
              )}
            </Note>
          }
        />

        <Gap v={1} />
        <div className="buttons">
          <Button disabled={withdrawDisabled || working} type="submit" className="btn btn-primary full-width" text="Continue" />
        </div>
      </form>
    </>
  );
};

export const CardDetailsModel = (props: { card: CardDto }) => {
  const { card } = props;

  const cardHolder = `${card.holder.firstName} ${card.holder.lastName}`;
  const expiryText = `${card.expiry.slice(0, 2)}/${card.expiry.slice(-2)}`;

  return (
    <>
      <h4>{card.label || `${card.holder.firstName} ${card.holder.lastName}`}</h4>

      <Gap v={2} />

      <Slider
        dots={true}
        infinite={false}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={false}
        customPaging={(i) => <a>{i === 0 ? 'Front' : 'Back'}</a>}
      >
        <div className="card-samp_wrap">
          <div className="card-samp__front card-samp__part">
            {/* <img alt="" className="card-samp__front-square card-samp__square" src="https://image.ibb.co/cZeFjx/little_square.png" /> */}
            {card.scheme === 'MASTERCARD' ? (
              <RiMastercardLine className="card-samp__front-logo card-samp__logo" />
            ) : (
              <RiVisaLine className="card-samp__front-logo card-samp__logo" />
            )}

            <p
              className="card-samp_numer"
              onClick={() => {
                copy(card.pan);
                toast.info('Card number copied!');
              }}
            >
              •••• •••• •••• {card.pan.slice(-4)}
            </p>

            <div className="card-samp__space-75">
              <span className="card-samp__label">Card holder</span>
              <p
                className="card-samp__info"
                onClick={() => {
                  copy(cardHolder);
                  toast.info('Cardholder copied!');
                }}
              >
                {cardHolder}
              </p>
            </div>
            <div className="card-samp__space-25">
              <span className="card-samp__label">Expires</span>
              <p
                className="card-samp__info"
                onClick={() => {
                  copy(expiryText);
                  toast.info('Card expiry copied!');
                }}
              >
                {expiryText}
              </p>
            </div>
          </div>
        </div>

        <div className="card-samp_wrap">
          <div className="card-samp__back card-samp__part">
            <div className="card-samp__black-line"></div>
            <div className="card-samp__back-content">
              <div className="card-samp__secret">
                <p
                  className="card-samp__secret--last"
                  onClick={() => {
                    copy(card.cvv);
                    toast.info('Card cvv copied!');
                  }}
                >
                  {card.cvv}
                </p>
              </div>
              {/* <img alt="" className="card-samp__back-square card-samp__square" src="https://image.ibb.co/cZeFjx/little_square.png" /> */}
              {card.scheme === 'MASTERCARD' ? (
                <RiMastercardLine className="card-samp__back-logo card-samp__logo" />
              ) : (
                <RiVisaLine className="card-samp__back-logo card-samp__logo" />
              )}
            </div>
          </div>
        </div>
      </Slider>

      <Gap v={1} />

      <hr />

      <div>
        <h5>Billing address ({card.holder.country})</h5>
        <div className="row mt-3">
          <div className="col-auto">Street address</div>
          <div className="col text-end">{<CopiableText text={card.holder.address} />}</div>
        </div>
        <div className="row mt-1">
          <div className="col-auto">City</div>
          <div className="col text-end">{<CopiableText text={card.holder.city} />}</div>
        </div>
        <div className="row mt-1">
          <div className="col-auto">State</div>
          <div className="col text-end">{<CopiableText text={card.holder.state} />}</div>
        </div>
        <div className="row mt-1">
          <div className="col-auto">Zip code</div>
          <div className="col text-end">{<CopiableText text={card.holder.zipCode} />}</div>
        </div>
      </div>

      <Gap v={2} />
    </>
  );
};

export type CardsFilterData = {
  id?: string;
  status?: string;
  scheme?: string;
  currency?: string;
  firstName?: string;
  lastName?: string;
  balanceFrom?: number;
  balanceTo?: number;
  reference?: string;
  creationDateFrom?: Date;
  creationDateTo?: Date;
};

type FilterCardsModalProps = {
  filters: Partial<CardsFilterData>;
  onFilter(data: CardsFilterData): void;
};

export const FilterCardsModal = (props: FilterCardsModalProps) => {
  const { filters, onFilter } = props;
  const formRef = useRef(null);
  const [creationDateFrom, setCreationDateFrom] = useState<Date | null>();
  const [creationDateTo, setCreationDateTo] = useState<Date | null>();

  useEffect(() => {
    setCreationDateFrom(filters.creationDateFrom ?? null);
    setCreationDateTo(filters.creationDateTo ?? null);
  }, []);

  return (
    <div className="FilterCardsMoodal">
      <h4 className="title">Filter Cards</h4>
      <Gap v={0.5} />

      <form
        ref={formRef}
        onSubmit={(ev) => {
          ev.preventDefault();

          const form = ev.target as any;
          const { status, scheme, currency, firstName, lastName, balanceFrom, balanceTo } = serializeForm(form, { hash: true });

          Modal.CloseAll();

          onFilter({
            status,
            scheme,
            currency,
            firstName,
            lastName,
            balanceFrom,
            balanceTo,
            creationDateFrom: creationDateFrom ?? undefined,
            creationDateTo: creationDateTo ?? undefined,
          });
        }}
      ></form>
    </div>
  );
};

export const CardTransactionModal = ({ card }: { card: CardDto }) => {
  const [transactions, setTransactions] = useState<CardTransactionDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingPosition, setLoadingPosition] = useState<'LEFT' | 'RIGHT'>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterData, setFilterData] = useState<{ page: number; size: number; cardId: string; type?: CardTransactionType }>({
    page: 1,
    size: 20,
    cardId: card.id,
    type: undefined,
  });
  const [totalItems, setTotalItems] = useState(0);

  const pageTotal = filterData.size * filterData.page > totalItems ? totalItems : filterData.size * filterData.page;

  useEffect(() => {
    if (loading) {
      return;
    }

    setLoading(true);

    cardsApi
      .getCardTransactions(filterData)
      .then((res) => {
        setTransactions(res.records);
        setTotalItems(res.totalItems);
      })
      .catch(handleRequestErrors)
      .finally(() => {
        setLoading(false);
        setLoadingPosition(undefined);
      });
  }, [filterData]);

  const openTransactionModal = (transaction: CardTransactionDto) => {
    Modal.Open(<TransactionDetailsModal transaction={transaction} />, {
      customClass: {
        container: 'modal-mobile-fullscreen',
        popup: 'max-w-550x',
      },
    });
  };

  const getMerchantInfo = (txn: CardTransactionDto) =>
    `${txn.metadata.find((meta) => meta.name === 'merchantName')?.value} (${[
      txn.metadata.find((meta) => meta.name === 'merchantCity')?.value,
      txn.metadata.find((meta) => meta.name === 'merchantCountry')?.value,
    ]
      .filter(Boolean)
      .join(', ')})`;

  const filterTransactions = (txn: CardTransactionDto): boolean => {
    const lowerCaseSearchQuery = searchQuery.toLowerCase();
    const memoMatch = txn.memo.toLowerCase().includes(lowerCaseSearchQuery);
    const transactionTypeMatch = txn.metadata.some(
      (meta) => meta.name.toLowerCase() === 'transactiontype' && meta.value.toLowerCase().includes(lowerCaseSearchQuery),
    );

    // Extract merchantInfo and date for search
    const merchantInfo = getMerchantInfo(txn);
    const merchantInfoMatch = merchantInfo.toLowerCase().includes(lowerCaseSearchQuery);

    const dateMatch = moment
      .utc(txn.createdAt, 'ddd MMM D Y HH:mm:s')
      .format('MMM D, Y hh:mm')
      .toLowerCase()
      .includes(lowerCaseSearchQuery);

    return memoMatch || transactionTypeMatch || merchantInfoMatch || dateMatch;
  };

  return (
    <div className="card-txn-container">
      <h4>Card Transactions</h4>

      <div className="search-container-wrap">
        <div className="search-container">
          <img src={require('../../assets/search.png')} alt="" />
          <input type="text" placeholder="Search Transactions" value={searchQuery} onChange={(ev) => setSearchQuery(ev.target.value)} />
        </div>
      </div>

      <div className="card-txn-label">
        <label>
          <span
            onClick={() => {
              if (loading) {
                return;
              }

              setFilterData({
                ...filterData,
                page: 1,
                type: undefined,
              });
            }}
            className={!filterData.type ? 'active-filter' : ''}
          >
            All Transactions
          </span>
        </label>

        <label>
          <span
            onClick={() => {
              if (loading) {
                return;
              }

              setFilterData({
                ...filterData,
                page: 1,
                type: 'CREDIT',
              });
            }}
            className={filterData.type === 'CREDIT' ? 'active-filter' : ''}
          >
            Credits
          </span>
        </label>

        <label>
          <span
            onClick={() => {
              if (loading) {
                return;
              }

              setFilterData({
                ...filterData,
                page: 1,
                type: 'DEBIT',
              });
            }}
            className={filterData.type === 'DEBIT' ? 'active-filter' : ''}
          >
            Debits
          </span>
        </label>
      </div>

      <div className="card-txn-wrapper">
        {loading && (
          <div className="loading-wrap">
            <span className="spinner"></span>
          </div>
        )}

        {!loading && transactions.filter(filterTransactions).length === 0 && (
          <div className="loading-wrap">
            <span className="no-txn">
              <img src={require('../../assets/no-txn.png')} alt="" />
              <p>No Transaction Available</p>
            </span>
          </div>
        )}

        {!loading && transactions.filter(filterTransactions).length > 0 && (
          <div className="tab-content">
            <ul className="card-transactions">
              {transactions.filter(filterTransactions).map((txn) => (
                <TransactionRow key={txn.id} txn={txn} openTransactionModal={openTransactionModal} />
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="card-txn-pagination">
        <div className="pagination-wrap">
          <div className="item-total hidden">
            <span className="color-4F4F4F">Rows per page: &nbsp;&nbsp;</span> 8
          </div>

          <ConditionalElement
            condition={totalItems > filterData.size && !(loading && !loadingPosition)}
            element={
              <div className="item-nav">
                <span className="color-4F4F4F">
                  {(filterData.page - 1) * filterData.size + 1} - {pageTotal} of {totalItems.toLocaleString('en')}
                </span>
                <Gap h={1} />
                <a
                  className={`prev ${loadingPosition === 'LEFT' ? 'loading' : ''}`}
                  onClick={() => {
                    if (loading) {
                      return;
                    }

                    const prevPage = filterData.page - 1;

                    if (prevPage <= 0) {
                      return;
                    }

                    setFilterData({ ...filterData, page: prevPage });
                    setLoadingPosition('LEFT');
                  }}
                />
                <a
                  className={`next ${loadingPosition === 'RIGHT' ? 'loading' : ''}`}
                  onClick={() => {
                    if (loading) {
                      return;
                    }

                    const nextPage = filterData.page + 1;

                    if (nextPage > Math.ceil(totalItems / filterData.size)) {
                      return;
                    }

                    setFilterData({ ...filterData, page: nextPage });
                    setLoadingPosition('RIGHT');
                  }}
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};
