import * as fcm from 'firebase/messaging';
import { combineReducers } from 'redux';
import { messaging } from '../integrations/fcm';
import * as notificationsApi from '../api/notifications';
import user from './user';
import kyc from './kyc';
import lists from './lists';
import rates from './rates';
import wallets from './wallets';
import withdrawal from './withdrawals';
import developer from './developer';
import notification from './notification';
import business from './business';
import misc from './misc';
import p2p from './p2p';
import { loadState, removeState } from '../localstorage';
import { FCM_SUB_ID, LOCAL_APP_TOKEN } from '../constants';

export const appReducer = combineReducers({
  user,
  business,
  wallets,
  lists,
  kyc,
  rates,
  withdrawal,
  developer,
  notification,
  misc,
  p2p,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET_STATES') {
    const subId = loadState(FCM_SUB_ID);

    if (subId) {
      notificationsApi.removeSubscription(subId).catch(() => {});
    }

    removeState(FCM_SUB_ID);
    removeState(LOCAL_APP_TOKEN);
    fcm.deleteToken(messaging);

    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
