import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { CurrencyDefinition, CurrencyType } from '../../../typings';
import { baseStore } from '../../../store';
import { Button } from '../html/Button';
import { Security2faDialog } from '../2FADialog';
import * as walletApi from '../../../api/wallets';
import Gap from '../Gap';
import { Modal } from '../Modal';
import { setWalletAssets } from '../../../actions/wallets';
import { handleRequestErrors } from '../../../utils';

interface CreateAssetDialogProps {
  currency?: CurrencyDefinition;
}

export const CreateAssetDialog = (props: CreateAssetDialogProps) => {
  const { wallets, rates } = baseStore.getState();
  const [sourceCurrency, setSourceCurrency] = useState<{ label: string; value: string; type: CurrencyType }>();

  const sourceCurrencyOptions = rates?.currencies
    .filter(({ code }) => !wallets?.find((wallet) => wallet.currency.code === code))
    .map(({ name, code, type }) => ({ label: name, value: code, type }));

  useEffect(() => {
    if (!props.currency) {
      return;
    }

    setSourceCurrency(sourceCurrencyOptions?.find((option) => option.value === props?.currency?.code));
  }, [props.currency]);

  const createAsset = () =>
    Modal.Open(
      <Security2faDialog
        operation="ASSET_CREATE"
        handleSecurity={async (security, callback) => {
          walletApi
            .createWallet(sourceCurrency?.value!, sourceCurrency?.type!, security)
            .then(() => {
              Modal.Alert('Wallet Created', `Your ${sourceCurrency?.value} wallet asset was created successfully!`);
              setTimeout(() => walletApi.getWallets().then((res) => baseStore.dispatch(setWalletAssets(res.assets))), 1500);
            })
            .catch((err) => {
              callback(null, err);
              handleRequestErrors(err);
            });
        }}
      />,
      {
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'modal-mobile-fullscreen',
          popup: 'max-w-350x',
        },
      },
    );

  return (
    <>
      <Gap v={1} />
      <div className="head">
        <h4>Add Asset</h4>
      </div>

      <Gap v={1} />

      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          createAsset();
        }}
      >
        <div className="row">
          <div className="col-12">
            <label className="mb-2">
              Select Currency <span>*</span>
            </label>
          </div>

          <div className="col-12">
            <Select
              required
              name="sourceCurrency"
              options={[
                {
                  label: 'Fiat',
                  options: (sourceCurrencyOptions || []).filter((option) => option.type === 'FIAT'),
                },
                {
                  label: 'Crypto',
                  options: (sourceCurrencyOptions || []).filter((option) => option.type === 'CRYPTO'),
                },
              ]}
              onChange={(ev: any) => setSourceCurrency(ev)}
              value={sourceCurrency}
              classNamePrefix="custom-select"
            />
          </div>
        </div>

        <Gap v={1} />
        <Gap v={0.5} />

        <Button type="submit" className="btn btn-primary full-width" text="Continue" />

        <Gap v={1} />
      </form>
    </>
  );
};
