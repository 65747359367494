import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { USD } from '../../constants';
import { ReducerStates } from '../../typings';
import { formatCurrency } from '../../utils';
import { computeLocalBalance } from '../../utils/math';
import { AssetsList } from '../components/AssetsList';
import { Header } from '../components/DashboardHeader';
import { Sidebar } from '../components/DashboardSidebar';
import { OnboardingNote } from '../components/OnboardingNote';
import Gap from '../components/Gap';
import './styles.scss';
import { CryptoCards } from '../components/CryptoCards';

export const Assets = () => {
  const { wallets, rates, user } = useSelector((state: ReducerStates) => state);
  const currencyDef = rates.currencies.find((currency) => currency.code === (user.preferredCurrency || USD));

  const balance = computeLocalBalance(currencyDef!, wallets, rates.conversions);
  const balanceStr = formatCurrency(currencyDef?.type || 'FIAT', balance.value, currencyDef?.code || USD, currencyDef?.decimals || 2);

  return (
    <>
      <Helmet title="Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap assets-dashboard">
              <div className="content-inner">
                <Header withTicker={false} />

                <Gap v={2} />

                <div className="assets-page-wrap">
                  <OnboardingNote gapBottom={1} />

                  <div className="assets-balance-info">
                    <div className="t">Account Balance</div>
                    <div className="b">
                      <div className={`balance-num ${balanceStr.length >= 12 ? 'shrink-mobile' : ''}`}>{balanceStr}</div>

                      {/* <span>
                        ~
                        {formatCurrency(
                          btcCurrency?.type || 'FIAT',
                          btcBalance.value,
                          btcCurrency?.code || USD,
                          btcCurrency?.decimals || 2,
                        )}
                      </span> */}
                    </div>
                    <div className="a">{wallets.length} assets</div>
                  </div>

                  <CryptoCards />

                  <AssetsList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
