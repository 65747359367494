import { WithdrawalAction, WithdrawalState } from '../typings';

const defaultState: WithdrawalState = {
  accounts: [],
  transactions: [],
};

export default (state = defaultState, action: WithdrawalAction): WithdrawalState => {
  switch (action.type) {
    case 'SET_ACCOUNTS':
      const { accounts = [] } = action;

      return {
        ...state,
        accounts,
      };
    case 'ADD_ACCOUNT':
      return {
        ...state,
        accounts: [action.account!, ...state.accounts],
      };
    case 'REMOVE_ACCOUNT':
      return {
        ...state,
        accounts: state.accounts.filter((acct) => acct.id !== action.id),
      };
    default:
      return state;
  }
};
