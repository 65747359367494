import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { route } from '../../../routes';
import { GlobalSearchForm } from '../GlobalSearchForm';
import { ExchangeDialog } from '../ExchangeDialog';
import { Modal } from '../Modal';
import logoDash from '../../../assets/logo-dashboard.svg';
import 'rc-menu/assets/index.css';
import 'rc-dropdown/assets/index.css';
import Gap from '../Gap';
import './sidebar.scss';
// import { useSelector } from 'react-redux';
// import { ReducerStates } from '../../../typings';

export const Sidebar = () => {
  // const { p2p } = useSelector((state: ReducerStates) => state);

  return (
    <>
      <div className="dashboard-sidebar">
        <div className="text-white dashboard-nav-content-wrap">
          <div className="nav-logo hidden-1200px">
            <Link to="/" className="logo">
              <img src={logoDash} alt="logo" />
            </Link>
          </div>

          <div className="dash-nav-content">
            <div className="px-25x visible-1200px">
              <GlobalSearchForm />
            </div>

            <div className="px-25x">
              <Gap v={2} />

              <span
                className="btn btn-primary full-width btn-swap text-start visible-1200px"
                onClick={() => {
                  const $el = document.getElementsByClassName('dashboard-wrapper')[0];
                  const $scrollElem = document.getElementsByClassName('dashboard-content-wrap')[0];
                  $el && $el.classList.remove('left-sidebar-open', 'right-sidebar-open');
                  $scrollElem && $scrollElem.classList.remove('no-scroll');

                  Modal.Open(<ExchangeDialog />, {
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    customClass: {
                      container: 'modal-mobile-fullscreen',
                      popup: 'max-w-500x',
                    },
                  });
                }}
              >
                &nbsp;&nbsp;Exchange
              </span>

              <Gap v={2} />
            </div>

            <ul>
              <li>
                <NavLink className={({ isActive }) => `home ${isActive ? 'current' : ''}`} to={route.dashboard.home} end>
                  Home
                </NavLink>
              </li>

              <li>
                <NavLink className={({ isActive }) => `orders ${isActive ? 'current' : ''}`} to={route.dashboard.orderHistory}>
                  History
                </NavLink>
              </li>

              <li>
                <NavLink className={({ isActive }) => `cards ${isActive ? 'current' : ''}`} to={route.dashboard.cards}>
                  Cards
                </NavLink>
              </li>

              {/* <li>
                <NavLink
                  className={({ isActive }) =>
                    `p2p ${isActive ? 'current' : ''} ${
                      p2p.requests?.length || p2p.transactions?.length || p2p.disputes?.length || 0 > 0 ? 'alert' : ''
                    }`
                  }
                  to={route.p2p.home}
                >
                  <span>P2P</span>
                </NavLink>
              </li> */}

              <li>
                <NavLink className={({ isActive }) => `addresses ${isActive ? 'current' : ''}`} to={route.dashboard.subAddresses}>
                  Addresses
                </NavLink>
              </li>

              <li>
                <NavLink className={({ isActive }) => `settings ${isActive ? 'current' : ''}`} to={route.dashboard.settings}>
                  Settings
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
