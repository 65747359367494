import { DeveloperAction, DeveloperKeyDto, WebhookSubscriptionDto } from '../typings';

export const setKeys = (keys: DeveloperKeyDto[]): DeveloperAction => ({
  type: 'SET_KEYS',
  keys,
});

export const addKey = (key: DeveloperKeyDto): DeveloperAction => ({
  type: 'ADD_KEY',
  key,
});

export const removeKey = (id: string): DeveloperAction => ({
  type: 'REMOVE_KEY',
  id,
});

export const setWebhookSubscriptions = (subscriptions: WebhookSubscriptionDto[]): DeveloperAction => ({
  type: 'SET_WEBHOOK_SUBSCRIPTIONS',
  subscriptions,
});
export const addWebhookSubscription = (subscription: WebhookSubscriptionDto): DeveloperAction => ({
  type: 'ADD_WEBHOOK_SUBSCRIPTION',
  subscription,
});
export const removeWebhookSubscription = (id: string): DeveloperAction => ({
  type: 'REMOVE_WEBHOOK_SUBSCRIPTION',
  id,
});
