import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import serializeForm from 'form-serialize';
import { useNavigate } from 'react-router-dom';
import { TextArea } from '../components/html/TextArea';
import { Sidebar } from '../components/OnboardingSidebar';
import { route } from '../../routes';
import Gap from '../components/Gap';
import { handleRequestErrors } from '../../utils';
import { BusinessKycDetailsDto, ProfileKycDetailsDto, ReducerStates } from '../../typings';
import { setBusinessKycInfo, setIndividualKycInfo } from '../../actions/kyc';
import * as kycApi from '../../api/kyc';
import { Header } from '../components/DashboardHeader';

export const OnboardingFundsSource = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, kyc } = useSelector((state: ReducerStates) => state);
  const [loading, setLoading] = useState(false);

  const isIndividual = user.type === 'INDIVIDUAL';
  const details = isIndividual ? kyc.individual?.funds : kyc.business?.funds;

  return (
    <>
      <Helmet title="Host Capital" />

      <div className="onboarding-wrapper">
        <Sidebar />

        <div className="onboarding-content-wrap">
          <Header isOnboarding onLeftMenuClick={() => {}} />

          <div className="content-inner">
            <div className="centered">
              <div className="centered-content">
                <Gap v={2} />

                <h3>Source of Funds / Source of Wealth</h3>
                <Gap v={1} />

                <form
                  onSubmit={(ev) => {
                    ev.preventDefault();

                    const formData = serializeForm(ev.target, { hash: true });
                    const { sourceOfFunds, sourceOfWealth } = formData;

                    setLoading(true);
                    (isIndividual ? kycApi.updatePersonalInfo : kycApi.updateBusinessInfo)({
                      funds: {
                        sourceOfFunds,
                        sourceOfWealth,
                      },
                    })
                      .then((data) => {
                        // prettier-ignore
                        dispatch(isIndividual ? setIndividualKycInfo(data as ProfileKycDetailsDto) : setBusinessKycInfo(data as BusinessKycDetailsDto));
                        navigate(route.onboarding.tradingActivities);
                      })
                      .catch(handleRequestErrors)
                      .finally(() => setLoading(false));
                  }}
                >
                  <TextArea
                    required
                    id="source-of-funds"
                    name="sourceOfFunds"
                    label="Please describe source of funds (origin of the funds that will be used for this investment)"
                    placeholder="Example: Employment Income / Investment Income / Business Income / Savings / Inheritance, Trading etc."
                    defaultValue={details?.sourceOfFunds}
                    gapbottom={2}
                  />

                  <TextArea
                    required
                    id="source-of-wealth"
                    name="sourceOfWealth"
                    label="Please describe source of wealth (when and how was your wealth accumulated)"
                    placeholder="How did you accumulate your overall wealth and since when?"
                    defaultValue={details?.sourceOfWealth}
                  />

                  <div className="button-set text-end mt-5">
                    <div className="d-inline-block">
                      <button
                        disabled={loading}
                        type="button"
                        className="btn btn-primary btn-outline"
                        onClick={() => navigate(isIndividual ? route.onboarding.profile : route.onboarding.corporate)}
                      >
                        Back
                      </button>
                      <Gap h={1} />

                      <button disabled={loading} type="submit" className="btn btn-primary">
                        {loading ? 'Please wait...' : 'Save & Continue'}
                      </button>
                    </div>
                  </div>
                </form>

                <Gap v={2} className="visible-768px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
