import React from 'react';

interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  text: string | JSX.Element;
  loading?: boolean;
}

export const Button = (props: ButtonProps) => {
  const { text, loading, ...restProps } = props;

  return (
    <button disabled={loading} {...restProps}>
      {loading ? 'Please wait...' : text}
    </button>
  );
};
