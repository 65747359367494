/* eslint-disable new-cap */
import React from 'react';
import Cleave from 'cleave.js/react';
import { CloseAll, DialogButtons } from './index';
import { Input } from '../html/Input';
import { TextArea } from '../html/TextArea';
import Gap from '../Gap';

export interface PromptModalOptions {
  strict?: boolean;
  inputType?: 'text' | 'textarea' | 'number';
  required?: boolean;
  placeholder?: string;
  additionalInfo?: string;
}

interface PromptModalProps {
  type: 'normal' | 'delete';
  title: string;
  message: string | JSX.Element;
  buttons?: DialogButtons;
  onCancel(b?: boolean | null): void;
  onText(text?: string): void;
  options: PromptModalOptions;
}

const defaultOptions: PromptModalOptions = {
  strict: true,
  inputType: 'text',
  placeholder: 'Your input here',
  additionalInfo: '',
};

export const PromptModal = (props: PromptModalProps) => {
  const {
    type,
    title,
    message,
    onCancel,
    onText,
    options = defaultOptions,
    buttons = {
      accept: {
        text: '',
        type: 'normal',
      },
      cancel: {
        text: '',
        type: 'normal',
      },
    },
  } = props;
  const { strict, required, placeholder, inputType, additionalInfo } = options;

  return (
    <>
      <form
        className="text-left"
        onSubmit={(ev: React.FormEvent<HTMLFormElement> & { target: { text: { value: string } } }) => {
          ev.preventDefault();
          const text = ev.target.text.value.trim();

          if (strict === true) {
            if (text) {
              onText(text);
            }
          } else {
            onText(text);
          }

          CloseAll();
        }}
      >
        <h4>{title}</h4>

        <div className="form-group">
          <label>{message}</label>

          <Gap v={1} />

          {inputType === 'text' ? <Input required={required} type="text" placeholder={placeholder} name="text" /> : null}

          {inputType === 'number' ? (
            <Cleave
              required
              id="input-text"
              name="text"
              className="form-control"
              placeholder={placeholder}
              autoComplete="off"
              options={{
                numeral: true,
                stripLeadingZeroes: false,
                numeralThousandsGroupStyle: 'none',
              }}
            />
          ) : null}
          {inputType === 'textarea' ? <TextArea required={required} placeholder={placeholder} name="text" /> : null}
        </div>

        <div>{additionalInfo}</div>

        <Gap v={1} />

        <div className="row">
          {buttons.accept && (
            <div className="col-auto">
              <button
                type="submit"
                className={`btn ${(buttons.accept.type || type) === 'delete' ? 'btn-danger' : 'btn-primary'}`}
                style={{ paddingLeft: '25px', paddingRight: '25px' }}
              >
                {buttons.accept.text ? buttons.accept.text : 'Continue'}
              </button>
            </div>
          )}

          {buttons.cancel && (
            <div className="col-auto ps-0">
              <button
                type="button"
                className={`btn ${(buttons.cancel.type || type) === 'delete' ? 'btn-danger' : 'btn-grey'}`}
                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                onClick={() => {
                  CloseAll();
                  onCancel(false);
                }}
              >
                {buttons.cancel.text ? buttons.cancel.text : 'Cancel'}
              </button>
            </div>
          )}
        </div>
      </form>
    </>
  );
};
