import Request from '../helpers/request';
import { CityListDto, CountryListDto, StateListDto } from '../typings/lists';

export const getCountries = async (): Promise<CountryListDto> => {
  return await Request.get<CountryListDto>(`${process.env.REACT_APP_API_URL}/lists/location/countries`);
};

export const getStates = async (id: number): Promise<StateListDto> => {
  return await Request.getWithUserToken<StateListDto>(`${process.env.REACT_APP_API_URL}/lists/location/countries/${id}/states`);
};

export const getCities = async (id: number): Promise<CityListDto> => {
  return await Request.getWithUserToken<CityListDto>(`${process.env.REACT_APP_API_URL}/lists/location/states/${id}/cities`);
};
