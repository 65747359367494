import React from 'react';
import Gap from '../Gap';

interface TextAreaProps {
  label?: string;
  info?: string;
  gapbottom?: number;
}

export const TextArea = (
  props: TextAreaProps & React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
) => (
  <div className="form-group">
    {props.label && (
      <label htmlFor={props.id || ''} className="mb-2">
        {props.label} {props.required ? <span className="color-e25757">*</span> : null}
      </label>
    )}

    <textarea {...{ ...{ className: 'form-control' }, ...props }} />

    {props.info && <small className="text-muted">{props.info}</small>}

    {props.gapbottom ? <Gap v={props.gapbottom} /> : null}
  </div>
);
