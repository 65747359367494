import React, { useEffect, useState } from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { CurrencyDefinition, DepositChannelDto, DepositMethodDto, DepositMethodType } from '../../../typings';
import { Button } from '../html/Button';
import * as depositApi from '../../../api/deposit';
import * as walletsApi from '../../../api/wallets';
import { generateQrCode, handleRequestErrors } from '../../../utils';
import { CopiableText } from '../CopiableText';
import { Modal } from '../Modal';
import { AddBankAccountModal } from '../../Settings/BankAccounts/Modals';
import { ConditionalElement } from '../ConditionalElement';
import { Note } from '../Note';
import logoCopy from '../../../assets/copy.svg';
import Gap from '../Gap';
import './depositdialog.scss';

type DepositStages = 'FIAT_TERMS' | 'FIAT_METHODS' | 'CRYPTO_NETWORKS' | 'CRYPTO_ADDRESS' | 'FIAT_BANK_TRANSFER';

interface DepositDialogProps {
  stage?: DepositStages;
  assetId: string;
  currency: CurrencyDefinition;
  methodId?: DepositMethodType;
  updateStage?(stage: DepositStages, data?: any): void;
}

interface FiatDepositMethodProps extends DepositDialogProps {
  amount?: number;
  method?: DepositMethodDto;
  channel?: DepositChannelDto;
}

interface CryptoDepositAddressProps extends DepositDialogProps {
  address?: string;
  memo?: string;
  type?: string;
  network?: string;
}

// const FiatDepositAmount = (props: DepositDialogProps) => {
//   const [loading, setLoading] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const { currency } = props;

//   return (
//     <div className="amount-content">
//       <div className="head">
//         <h4>Deposit {currency.name}</h4>
//         <p>Please enter the amount you'll like to deposit</p>
//       </div>

//       <form
//         onSubmit={(ev) => {
//           ev.preventDefault();
//           setErrorMessage('');

//           const form = ev.target as any;
//           const rawValue = form.amount.rawValue;

//           if (!rawValue) {
//             return setErrorMessage('Please enter a valid deposit amount.');
//           }

//           const amount = Number(rawValue.split(' ')[1]);

//           setLoading(true);

//           depositApi
//             .prepare(props.assetId, amount, currency.code, props.methodId!)
//             .then(() => {
//               // updateStage('', {
//               //   amount,
//               //   deposit: res,
//               // });
//             })
//             .catch((err) => handleRequestErrors(err))
//             .finally(() => setLoading(false));
//         }}
//       >
//         <div className="content">
//           <div className="row">
//             <div className="col-12">
//               <label className="mb-2">
//                 Amount <span>*</span>
//               </label>
//             </div>

//             <div className="col-12">
//               <Cleave
//                 required
//                 id="input-text"
//                 name="amount"
//                 className="form-control"
//                 placeholder={`${currency.code} 0.00`.trim()}
//                 autoComplete="off"
//                 options={{
//                   prefix: `${currency.code} `,
//                   numeral: true,
//                   numeralDecimalScale: currency.decimals,
//                   stripLeadingZeroes: false,
//                   numeralThousandsGroupStyle: 'thousand',
//                 }}
//               />
//             </div>
//           </div>

//           <ConditionalElement
//             condition={!!errorMessage}
//             element={
//               <>
//                 <Gap v={0.5} />
//                 <div className="color-fd0000">{errorMessage}</div>
//               </>
//             }
//           />
//         </div>

//         <div className="buttons-area">
//           <Button
//             disabled={loading}
//             loading={loading}
//             className="btn btn-primary"
//             text="Continue"
//             style={{ width: '100%', maxWidth: '200px' }}
//           />
//         </div>
//       </form>
//     </div>
//   );
// };

const BankDepositMethod = (props: FiatDepositMethodProps) => {
  const { currency, method } = props;

  return (
    <div className="deposit-content">
      <div className="head">
        <h4>Deposit {currency.name}</h4>
      </div>

      <Gap v={1} />

      <div className="details">
        <div className="detail__item">
          <div className="detail__title">Bank Name:</div>
          <div className="detail__content">{method?.account.bankName}</div>
        </div>
        <div className="detail__item">
          <div className="detail__title">Account Name:</div>
          <div className="detail__content">{method?.account.accountName}</div>
        </div>
        <div className="detail__item">
          <div className="detail__title">Account Number:</div>
          <div className="detail__content copiable">
            <CopiableText text={method?.account.accountNumber || ''} />
            <img src={logoCopy} alt="" />
          </div>
        </div>
      </div>

      <div className="content">
        <p>As soon as we receive your transfer, your account will be credited.</p>
        <p>
          Should you have any questions in regards to your bank transfer, please contact us on{' '}
          <a href="mailto:help@hostcap.io">help@hostcap.io</a>
        </p>
      </div>

      <Gap v={2} />
    </div>
  );
};

const FiatDepositTerms = (props: DepositDialogProps) => {
  const { assetId, currency, updateStage = () => {} } = props;
  const [loading, setLoading] = useState(false);

  const nextStage = () => {
    setLoading(true);

    depositApi
      .getChannel(assetId, currency.code)
      .then((channel) => updateStage('FIAT_METHODS', { channel }))
      .catch(handleRequestErrors)
      .finally(() => setLoading(false));
  };

  return (
    <div className="terms-content">
      <div className="head">
        <h4>Deposit {currency.name}</h4>
      </div>

      <div className="content">
        <p>Please read the instructions carefully.</p>

        <p className="hidden">
          You need to{' '}
          <a
            className="cursor-pointer"
            onClick={() => {
              Modal.Open(<AddBankAccountModal type="DEPOSIT" />, {
                customClass: {
                  popup: 'max-w-400x',
                },
              });
            }}
          >
            <b>register the bank account</b>
          </a>{' '}
          you plan to use for making deposits.
        </p>

        <p>
          Please note that the transfer should come from a bank account in your legal name or legal entity name. We do not accept bank
          transfers from third parties.
          <br />
          If you use an unregistered bank account and/or an account that is not in your name, the transfer will be rejected and reversed to
          the originating account.
        </p>

        <p>
          Please nore that you <u>may</u> be required to provide supporting documents to prove the origin of your funds.
        </p>

        <ul style={{ paddingLeft: 15 }}>
          <li>Funds are processed immediately upon receipt</li>
          <li>You can deposit and withdraw in your local currency at any time</li>
          <li>Only bank transfers are accepted</li>
          <li>Minimum deposit is NGN 500,000</li>
          <li>Maximum deposit is NGN 100,000,000</li>
          <li>
            0 deposit fee. (HostCap charges no fee for bank transfers, but your bank and their intermediary banks may apply a processing
            fee)
          </li>
          <li>
            Should you have any questions in regards to your bank transfer, please contact us on{' '}
            <a href="mailto:help@hostcap.io">help@hostcap.io</a>
          </li>
        </ul>

        <Gap v={0.5} />
      </div>

      <div className="buttons-area">
        <Button
          loading={loading}
          disabled={loading}
          className="btn btn-primary"
          text="I Understand"
          style={{ width: '100%', maxWidth: '200px' }}
          onClick={nextStage}
        />
      </div>
    </div>
  );
};

const FiatSelectDepositMethod = (props: FiatDepositMethodProps) => {
  const { channel, updateStage = () => {} } = props;

  return (
    <div className="deposit-methods-chooser">
      <h4>Deposit Methods</h4>
      <p>Choose a deposit method to continue.</p>

      <Gap v={0.5} />

      {channel?.methods.length && channel?.methods.length > 0 ? (
        <ul>
          {channel?.methods.map((method) => (
            <li
              key={method.type}
              onClick={() => {
                switch (method.type) {
                  case 'BANK_TRANSFER':
                    return updateStage('FIAT_BANK_TRANSFER', {
                      method,
                    });
                }
              }}
            >
              {method.type.split('_').join(' ')}
              <BsChevronRight />
            </li>
          ))}
        </ul>
      ) : (
        <>
          <p>
            Please hold on while we create your dedicated deposit account. Typically this shouldn't take very long. Please check back in a
            few minutes.
          </p>
        </>
      )}

      <Gap v={0.5} />
    </div>
  );
};

const CryptoDepositNetwork = (props: DepositDialogProps) => {
  const { currency, assetId, updateStage = () => {} } = props;
  const [selectedNetw, setSelectedNetw] = useState<string>();
  const [networkOptions, setNetworkOptions] = useState<Array<{ name: string; code: string }>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const networks = [];

    if (currency.activities.includes('DEPOSIT')) {
      networks.push({
        name: currency.network,
        code: currency.network,
      });
    }

    currency.networks
      .filter((network) => network.activities.includes('DEPOSIT'))
      .forEach(({ network }) => {
        networks.push({
          name: network,
          code: network,
        });
      });

    setNetworkOptions(networks);
  }, [currency]);

  return (
    <div className="deposit-methods-chooser">
      <h4>Choose Network</h4>
      <p>Please confirm the network you're selecting is the same network you have chosen on the wallet you're sending from.</p>

      <Gap v={0.5} />

      <ul>
        {networkOptions.map(({ name, code }) => (
          <li
            key={code}
            onClick={() => {
              if (loading) {
                return;
              }

              setSelectedNetw(code);
              setLoading(true);

              walletsApi
                .getWalletAddress(assetId, code)
                .then(({ address, memo, type }) =>
                  updateStage('CRYPTO_ADDRESS', {
                    network: code,
                    address,
                    memo,
                    type,
                  }),
                )
                .finally(() => setLoading(false));
            }}
          >
            {name}
            {loading && selectedNetw === code ? (
              <img style={{ height: 16 }} src={require('../../../assets/loading-forever.gif')} alt="" />
            ) : (
              <BsChevronRight />
            )}
          </li>
        ))}
      </ul>

      <Gap v={0.5} />
    </div>
  );
};

const CryptoDeposit = (props: CryptoDepositAddressProps) => {
  const { currency, address, memo, network } = props;
  const [qrImage, setQrImage] = useState<string>();

  useEffect(() => {
    generateQrCode(address!).then((image) => setQrImage(image));
  }, [address]);

  return (
    <div className="deposit-content">
      <div className="head">
        <h4>Deposit {currency.code}</h4>
        <p>
          Please ensure you only send {currency.code} to this deposit address. Sending any other token to this address may result in a loss
          of the sent asset
        </p>
      </div>

      <Gap v={1} />

      <div className="details">
        <div className="qr-display">
          <ConditionalElement condition={!!qrImage} element={<img src={qrImage} alt="QRC" />} />
        </div>

        <Gap v={1} />

        <div className="detail__item">
          <div className="detail__title">Deposit Address ({network})</div>
          <div className="detail__content copiable">
            <CopiableText text={address || ''} />
            <img src={logoCopy} alt="" />
          </div>
        </div>

        {memo ? (
          <div className="detail__item">
            <div className="detail__title">Address Tag</div>
            <div className="detail__content copiable">
              <CopiableText text={memo || ''} />
              <img src={logoCopy} alt="" />
            </div>
          </div>
        ) : null}

        <Note>Your deposit will be automatically credited to your wallet as soon as it's confirmed.</Note>
      </div>

      <Gap v={2} />
    </div>
  );
};

export const DepositDialog = (props: DepositDialogProps) => {
  const { currency } = props;
  const [stage, setStage] = useState<DepositStages>(props.stage || currency.type === 'FIAT' ? 'FIAT_TERMS' : 'CRYPTO_NETWORKS');
  const [depositMethod, setDepositMethod] = useState<DepositMethodDto>();
  const [depositChannel, setDepositChannel] = useState<DepositChannelDto>();
  const [cryptoDepositData, setCryptoDepositData] = useState<CryptoDepositAddressProps>();

  switch (currency.type) {
    case 'FIAT': {
      if (stage === 'FIAT_TERMS') {
        return (
          <FiatDepositTerms
            {...props}
            updateStage={(stage, data) => {
              setDepositChannel(data.channel);
              setStage(stage);
            }}
          />
        );
      } else if (stage === 'FIAT_METHODS') {
        return (
          <FiatSelectDepositMethod
            {...props}
            channel={depositChannel}
            updateStage={(stage, data) => {
              setDepositMethod(data.method);
              setStage(stage);
            }}
          />
        );
      } else if (stage === 'FIAT_BANK_TRANSFER') {
        return <BankDepositMethod {...props} method={depositMethod} />;
      }

      break;
    }
    case 'CRYPTO': {
      if (stage === 'CRYPTO_NETWORKS') {
        return (
          <CryptoDepositNetwork
            {...props}
            updateStage={(stage, data) => {
              setCryptoDepositData(data);
              setStage(stage);
            }}
          />
        );
      } else if (stage === 'CRYPTO_ADDRESS') {
        return <CryptoDeposit {...props} {...cryptoDepositData} />;
      }
    }
  }

  return <></>;
};
