import { CurrencyDefinition, ExchangeRate, RatesAction } from '../typings';

export const setCurrencies = (currencies: CurrencyDefinition[]): RatesAction => ({
  type: 'SET_CURRENCIES',
  currencies,
});

export const addConversion = (conversion: ExchangeRate): RatesAction => ({
  type: 'ADD_CONVERSION',
  conversion,
});

export const setConversions = (conversions: ExchangeRate[]): RatesAction => ({
  type: 'SET_CONVERSIONS',
  conversions,
});
