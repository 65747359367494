import { P2pActions, P2pDisputeDto, P2pProfileDto, P2pRequestDto, P2pTransactionDto } from '../typings/p2p';

export const setProfile = (profile: P2pProfileDto): P2pActions => ({
  type: 'SET_PROFILE',
  profile,
});

export const setRequests = (requests: P2pRequestDto[]): P2pActions => ({
  type: 'SET_REQUESTS',
  requests,
});

export const setTransactions = (transactions: P2pTransactionDto[]): P2pActions => ({
  type: 'SET_TRANSACTIONS',
  transactions,
});

export const addTransaction = (transaction: P2pTransactionDto): P2pActions => ({
  type: 'ADD_TRANSACTION',
  transaction,
});

export const setDisputes = (disputes: P2pDisputeDto[]): P2pActions => ({
  type: 'SET_DISPUTES',
  disputes,
});
