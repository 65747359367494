import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import RcDropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import { useNavigate } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Header } from '../components/DashboardHeader';
import { Sidebar } from '../components/DashboardSidebar';
import './styles.scss';
import { P2pSidebar } from './Sidebar';
import Gap from '../components/Gap';
import { P2pDisputeDto, P2pDisputeStatusDto } from '../../typings/p2p';
import { P2pApi } from '../../api/p2p';
import { handleRequestErrors } from '../../utils';
import { ConditionalElement } from '../components/ConditionalElement';
import { Modal } from '../components/Modal';

interface DisputeRowProps {
  dispute: P2pDisputeDto;
  onUpdate(data: Partial<P2pDisputeDto>): void;
}

const DisputeRow = (props: DisputeRowProps) => {
  const {
    dispute: { id, status, reason, counterparty, creator, createdAt },
    onUpdate,
  } = props;

  const cancelDispute = async () => {
    const reason = await Modal.Prompt(
      'Cancel dispute',
      'Please note that opening and canceling too many disputes may result in a temporary account suspension',
      { inputType: 'textarea', placeholder: 'Cancelation reason?', required: true },
    );

    if (!reason) {
      Modal.Alert('', 'Please provide a reason for canceling this dispute.');
      return;
    }

    P2pApi.updateDispute(id, 'CANCEL', reason as string).then((dispute) => {
      Modal.Alert('Dispute canceled', 'Your dispute was canceled successfully.'), onUpdate(dispute);
    });
  };

  return (
    <tr>
      <td>
        <span className="truncate-text">{id}</span>
      </td>
      <td className="text-end">{status}</td>
      <td className="text-end">
        <span className="truncate-text">{reason}</span>
      </td>
      <td className="text-end">
        <b>{creator.alias}</b>
      </td>
      <td className="text-end">{moment.utc(createdAt, 'ddd MMM D Y HH:mm:s').format('MMM DD, yyyy hh:mm a')}</td>
      <td className="text-end" onClick={(ev) => ev.stopPropagation()}>
        {status === 'IN_PROGRESS' && counterparty.id !== creator.id ? (
          <RcDropdown
            trigger={['click']}
            overlay={
              <Menu>
                <MenuItem key={`txn-${id}-disp-close`} onClick={() => cancelDispute()}>
                  Cancel
                </MenuItem>
              </Menu>
            }
            alignPoint
          >
            <a style={{ display: 'inline-block', width: 30, height: 30, cursor: 'pointer', textAlign: 'center' }}>
              <BsThreeDotsVertical />
            </a>
          </RcDropdown>
        ) : null}
      </td>
    </tr>
  );
};

const DisputeRowLoading = () => {
  return (
    <>
      {Array(7)
        .fill(1)
        .map((value, index) => (
          <tr key={`p2p_txn_dummy_${value}_${index}`}>
            <td className="is-placeholder">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
          </tr>
        ))}
    </>
  );
};

export const P2pDisputes = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingPosition, setLoadingPosition] = useState<'LEFT' | 'RIGHT'>();
  const [page, setPage] = useState(1);
  const [size] = useState(20);
  const [filterStatus] = useState<P2pDisputeStatusDto>();
  const [totalItems, setTotalItems] = useState(0);
  const [disputes, setDisputes] = useState<P2pDisputeDto[]>([]);

  const pageTotal = size * page > totalItems ? totalItems : size * page;

  useEffect(() => {
    setLoading(true);

    if (loading) {
      return;
    }

    setDisputes([]);

    P2pApi.getDisputes(filterStatus, page, size)
      .then((res) => {
        setDisputes(res.records);
        setTotalItems(res.totalItems);
      })
      .catch(handleRequestErrors)
      .finally(() => {
        setLoading(false);
        setLoadingPosition(undefined);
      });
  }, [page, size]);

  return (
    <>
      <Helmet title="P2P Disputes | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="p2p-page-wrap">
                  <P2pSidebar />

                  <div className="p2p-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Disputes</h6>
                      </div>
                    </div>

                    <Gap v={2} className="hidden-768px" />

                    <div className="page-content-inner">
                      <div className="table-advanced">
                        <div className="table-wrapper table-wrapper-cards">
                          <table className="has-details">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th className="text-end">Status</th>
                                <th className="text-end">Reason</th>
                                <th className="text-end">Dispute by</th>
                                <th className="text-end">Date created</th>
                                <th></th>
                              </tr>
                            </thead>

                            <tbody>
                              <ConditionalElement condition={loading} element={<DisputeRowLoading />} />

                              {disputes.map((dispute) => (
                                <DisputeRow
                                  key={dispute.id}
                                  dispute={dispute}
                                  onUpdate={(data) =>
                                    setDisputes(
                                      disputes.map((dispData) => {
                                        if (dispData.id === dispute.id) {
                                          return {
                                            ...dispData,
                                            ...data,
                                          };
                                        }

                                        return dispData;
                                      }),
                                    )
                                  }
                                />
                              ))}
                            </tbody>
                          </table>

                          <ConditionalElement
                            condition={!loading && disputes.length === 0}
                            element={
                              <div className="no-result">
                                <h5>You don't have any disputes</h5>
                              </div>
                            }
                          />
                        </div>

                        <Gap v={1} />

                        <div className="table-pagination">
                          <div className="pagination-wrap">
                            <div className="item-total hidden">
                              <span className="color-4F4F4F">Rows per page: &nbsp;&nbsp;</span> 8
                            </div>

                            <ConditionalElement
                              condition={totalItems > size}
                              element={
                                <div className="item-nav">
                                  <span className="color-4F4F4F">
                                    {(page - 1) * size + 1} - {pageTotal} of {totalItems.toLocaleString('en')}
                                  </span>
                                  <Gap h={1} />

                                  <a
                                    className={`prev ${loading && loadingPosition === 'LEFT' ? 'loading' : ''}`}
                                    onClick={() => {
                                      if (loading) {
                                        return;
                                      }

                                      const prevPage = page - 1;

                                      if (prevPage <= 0) {
                                        return;
                                      }

                                      setPage(prevPage);
                                      setLoadingPosition('LEFT');
                                    }}
                                  />
                                  <a
                                    className={`next ${loading && loadingPosition === 'RIGHT' ? 'loading' : ''}`}
                                    onClick={() => {
                                      if (loading) {
                                        return;
                                      }

                                      const nextPage = page + 1;

                                      if (nextPage > Math.ceil(totalItems / size)) {
                                        return;
                                      }

                                      setPage(nextPage);
                                      setLoadingPosition('RIGHT');
                                    }}
                                  />
                                </div>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
