import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import RcDropdown from 'rc-dropdown';
// import { HiPlusSm } from 'react-icons/hi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import Menu, { Item as MenuItem } from 'rc-menu';
import { Header } from '../components/DashboardHeader';
import { Sidebar } from '../components/DashboardSidebar';
import { P2pSidebar } from './Sidebar';
import Gap from '../components/Gap';
import { ConditionalElement } from '../components/ConditionalElement';
import { P2pAdDto } from '../../typings/p2p';
import { P2pApi } from '../../api/p2p';
import { handleRequestErrors } from '../../utils';
import './styles.scss';
import { Modal } from '../components/Modal';

interface AdRowProps {
  ad: P2pAdDto;
  onUpdate(data: Partial<P2pAdDto>): void;
}

const AdRow = (props: AdRowProps) => {
  const { ad, onUpdate } = props;

  const updateAd = (id: string, data: Partial<P2pAdDto>) => {
    let msgTitle = 'Update this Ad';
    let msdDecription = 'Confirm this operation to continue.';

    if ((data.status as any) === 'CLOSE') {
      msgTitle = 'Close this Ad?';
      msdDecription = 'Please note that this action cannot be reversed. Continue?';
    }

    Modal.Confirm(msgTitle, msdDecription).then((value) => {
      if (value === true) {
        P2pApi.updateMyAd(id, data)
          .then((transaction) => {
            Modal.CloseAll();
            onUpdate(transaction);
          })
          .catch(handleRequestErrors);
      }
    });
  };

  return (
    <tr>
      <td>{ad.type}</td>
      <td className="text-end">
        <b>{ad.status}</b>
      </td>
      <td className="text-end">{ad.fromCurrency}</td>
      <td className="text-end">{ad.toCurrency}</td>
      <td className="text-end">{ad.rate.value.toFixed(2)}</td>
      <td className="text-end">
        <div className="d-inline-block text-start">
          Range: <b>{ad.limits.min.toLocaleString('en')}</b> - <b>{ad.limits.max.toLocaleString('en')}</b>
          <br />
          Total: <b>{ad.limits.total.toLocaleString('en')}</b>
        </div>
      </td>
      <td className="text-end">{ad.methods.map(({ id }) => id).join(', ')}</td>
      <td className="text-end">
        {ad.timeLimits.value} {ad.timeLimits.type.toLowerCase()}
      </td>
      <td className="text-end">{ad.requestAutoAccept ? 'Yes' : 'No'}</td>
      <td className="text-end" onClick={(ev) => ev.stopPropagation()}>
        <RcDropdown
          trigger={['click']}
          overlay={
            <Menu>
              {['ONLINE', 'OFFLINE', 'DRAFT'].includes(ad.status) ? (
                <MenuItem
                  key={`ad-${ad.id}-status`}
                  onClick={() => updateAd(ad.id, { status: ad.status === 'ONLINE' ? 'OFFLINE' : 'ONLINE' })}
                >
                  Set {ad.status === 'ONLINE' ? 'Offline' : 'Online'}
                </MenuItem>
              ) : null}

              {['ONLINE', 'OFFLINE'].includes(ad.status) ? (
                <MenuItem key={`ad-${ad.id}-close`} onClick={() => updateAd(ad.id, { status: 'CLOSE' as any })}>
                  Close Ad
                </MenuItem>
              ) : null}

              {/* {['ONLINE', 'OFFLINE', 'CLOSED'].includes(ad.status) ? (
                <MenuItem key={`ad-${ad.id}-transactions`} onClick={() => {}}>
                  Transactions
                </MenuItem>
              ) : null} */}

              {/* {['OFFLINE', 'DRAFT'].includes(ad.status) ? (
                <MenuItem key={`ad-${ad.id}-update`} onClick={() => {}}>
                  Update Ad
                </MenuItem>
              ) : null} */}

              {/* {ad.status === 'DRAFT' ? (
                <MenuItem key={`ad-${ad.id}-delete`} onClick={() => {}}>
                  Delete Ad
                </MenuItem>
              ) : null} */}
            </Menu>
          }
          alignPoint
        >
          <a style={{ display: 'inline-block', width: 30, height: 30, cursor: 'pointer', textAlign: 'center' }}>
            <BsThreeDotsVertical />
          </a>
        </RcDropdown>
      </td>
    </tr>
  );
};

const AdRowLoading = () => {
  return (
    <>
      {Array(7)
        .fill(1)
        .map((value, index) => (
          <tr key={`p2p_txn_dummy_${value}_${index}`}>
            <td className="is-placeholder">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
            <td className="is-placeholder text-end">
              <div className="custom-placeholder"></div>
            </td>
          </tr>
        ))}
    </>
  );
};

export const P2pAds = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingPosition, setLoadingPosition] = useState<'LEFT' | 'RIGHT'>();
  const [page, setPage] = useState(1);
  const [size] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [ads, setAds] = useState<P2pAdDto[]>([]);

  const pageTotal = size * page > totalItems ? totalItems : size * page;

  useEffect(() => {
    setLoading(true);

    if (loading) {
      return;
    }

    setAds([]);

    P2pApi.getMyAds(page, size)
      .then((res) => {
        setAds(res.records);
        setTotalItems(res.totalItems);
      })
      .catch(handleRequestErrors)
      .finally(() => {
        setLoading(false);
        setLoadingPosition(undefined);
      });
  }, [page, size]);

  return (
    <>
      <Helmet title="P2P Ads | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="p2p-page-wrap">
                  <P2pSidebar />

                  <div className="p2p-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Ads</h6>
                      </div>

                      {/* <div className="col-auto">
                        <button
                          className="btn btn-primary btn-float-mobile"
                          onClick={() => {
                            Modal.Open(<></>, {
                              customClass: {
                                container: 'modal-mobile-fullscreen',
                                popup: 'max-w-400x',
                              },
                            });
                          }}
                        >
                          <span className="text hidden-768px">Create Ad</span>
                          <span className="icon visible-768px">
                            <HiPlusSm />
                          </span>
                        </button>
                      </div> */}
                    </div>

                    <Gap v={2} className="hidden-768px" />

                    <div className="page-content-inner">
                      <div className="table-advanced">
                        <div className="table-wrapper table-wrapper-cards">
                          <table className="has-details">
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th className="text-end">Status</th>
                                <th className="text-end">From</th>
                                <th className="text-end">To</th>
                                <th className="text-end">Rate</th>
                                <th className="text-end">Limits</th>
                                <th className="text-end">Methods</th>
                                <th className="text-end">Time Limit</th>
                                <th className="text-end">Auto</th>
                                <th></th>
                              </tr>
                            </thead>

                            <tbody>
                              <ConditionalElement condition={loading} element={<AdRowLoading />} />

                              {ads.map((ad) => (
                                <AdRow
                                  key={ad.id}
                                  ad={ad}
                                  onUpdate={(data) =>
                                    setAds(
                                      ads.map((adData) => {
                                        if (adData.id === ad.id) {
                                          return {
                                            ...adData,
                                            ...data,
                                          };
                                        }

                                        return adData;
                                      }),
                                    )
                                  }
                                />
                              ))}
                            </tbody>
                          </table>

                          <ConditionalElement
                            condition={!loading && ads.length === 0}
                            element={
                              <div className="no-result">
                                <h5>You don't have any ads</h5>
                              </div>
                            }
                          />
                        </div>

                        <Gap v={1} />

                        <div className="table-pagination">
                          <div className="pagination-wrap">
                            <div className="item-total hidden">
                              <span className="color-4F4F4F">Rows per page: &nbsp;&nbsp;</span> 8
                            </div>

                            <ConditionalElement
                              condition={totalItems > size}
                              element={
                                <div className="item-nav">
                                  <span className="color-4F4F4F">
                                    {(page - 1) * size + 1} - {pageTotal} of {totalItems.toLocaleString('en')}
                                  </span>
                                  <Gap h={1} />

                                  <a
                                    className={`prev ${loading && loadingPosition === 'LEFT' ? 'loading' : ''}`}
                                    onClick={() => {
                                      if (loading) {
                                        return;
                                      }

                                      const prevPage = page - 1;

                                      if (prevPage <= 0) {
                                        return;
                                      }

                                      setPage(prevPage);
                                      setLoadingPosition('LEFT');
                                    }}
                                  />
                                  <a
                                    className={`next ${loading && loadingPosition === 'RIGHT' ? 'loading' : ''}`}
                                    onClick={() => {
                                      if (loading) {
                                        return;
                                      }

                                      const nextPage = page + 1;

                                      if (nextPage > Math.ceil(totalItems / size)) {
                                        return;
                                      }

                                      setPage(nextPage);
                                      setLoadingPosition('RIGHT');
                                    }}
                                  />
                                </div>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
