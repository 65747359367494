import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HiPlusSm } from 'react-icons/hi';
import { addAccount } from '../../../actions/withdrawal';
import { ReducerStates } from '../../../typings';
import { ConditionalElement } from '../../components/ConditionalElement';
import { Header } from '../../components/DashboardHeader';
import { Sidebar } from '../../components/DashboardSidebar';
import { removeAccount } from '../../../actions/withdrawal';
import Gap from '../../components/Gap';
import { Modal } from '../../components/Modal';
import { SettingsSidebar } from '../../components/SettingsSidebar';
import { AddAddressModal } from './Modals';
import * as withdrawalApi from '../../../api/withdrawal';
import { handleRequestErrors } from '../../../utils';
import { Security2faDialog } from '../../components/2FADialog';
import '../settings.scss';

export const CryptoAddressSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { withdrawal } = useSelector((state: ReducerStates) => state);

  useEffect(() => {
    withdrawalApi
      .getAccounts('CRYPTO')
      .then((data) =>
        data.accounts.forEach((account) => {
          dispatch(removeAccount(account.id!));
          dispatch(addAccount(account));
        }),
      )
      .catch((err) => handleRequestErrors(err));
  }, []);

  const cryptoAccounts = withdrawal.accounts.filter((account) => account.type === 'CRYPTO');

  return (
    <>
      <Helmet title="Settings | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="settings-page-wrap">
                  <SettingsSidebar />

                  <div className="settings-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Crypto Address Management</h6>
                      </div>

                      <div className="col-auto">
                        <button
                          className="btn btn-primary  btn-float-mobile"
                          onClick={() => {
                            Modal.Open(<AddAddressModal onAccountAdd={(account) => dispatch(addAccount(account))} />, {
                              customClass: {
                                container: 'modal-mobile-fullscreen',
                                popup: 'max-w-400x',
                              },
                            });
                          }}
                        >
                          <span className="text hidden-768px">Add Address</span>
                          <span className="icon visible-768px">
                            <HiPlusSm />
                          </span>
                        </button>
                      </div>
                    </div>

                    <Gap v={2} className="hidden-768px" />

                    <div className="table-basic">
                      <table>
                        <thead>
                          <tr>
                            <th>Label</th>
                            <th>Currency</th>
                            <th>Network</th>
                            <th>Address</th>
                            <th>Memo</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody>
                          {cryptoAccounts.map((account) => (
                            <tr key={account.id}>
                              <td>
                                <div className="fw-bold">{account.accountName}</div>
                              </td>
                              <td>{account.currency}</td>
                              <td>{account.network}</td>
                              <td>
                                <div>{account.accountNumber}</div>
                              </td>
                              <td>{account.memo || '-'}</td>
                              <td>
                                <span
                                  className="btn btn-danger delete"
                                  onClick={() => {
                                    Modal.Open(
                                      <Security2faDialog
                                        operation="WITHDRAWAL_ACCOUNT_REMOVE"
                                        handleSecurity={async (security, callback) => {
                                          try {
                                            await withdrawalApi
                                              .removeAccount(account.id!, security)
                                              .then(() => dispatch(removeAccount(account.id!)));

                                            Modal.CloseAll();
                                          } catch (err) {
                                            callback(null, err);
                                            handleRequestErrors(err);
                                          }
                                        }}
                                      />,
                                      {
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        customClass: {
                                          container: 'modal-mobile-fullscreen',
                                          popup: 'max-w-350x',
                                        },
                                      },
                                    );
                                  }}
                                >
                                  ✕
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <ConditionalElement
                        condition={cryptoAccounts.length === 0}
                        element={
                          <div className="no-result">
                            <h6>You don't have any saved addresses</h6>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
