import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import serializeForm from 'form-serialize';
import { useTranslation } from 'react-i18next';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import Gap from '../components/Gap';
import config from '../../config';
import * as authApi from '../../api/auth';
import { handleRequestErrors } from '../../utils';
import { UserDto } from '../../typings';
import { route } from '../../routes';
import { Select2 } from '../components/html/Select';
import { saveState } from '../../localstorage';
import { AccountTypeOptions, LOCAL_APP_TOKEN } from '../../constants';
import { useDispatch } from 'react-redux';
import { updateMiscSettings } from '../../actions/misc';
import { getExpiryFromNow } from '../../utils/math';
import logoPrimary from '../../assets/logo-primary.svg';
import checkTickIcon from '../../assets/check-tick.svg';
import loadingGif from '../../assets/loading.gif';
import './signup.scss';
import { PhoneCodeSelect } from '../components/html/PhoneCodeSelect';

interface SignupProps {
  data?: Partial<UserDto>;
  onSignup?(data: Partial<UserDto>): void;
}

export const SignupForm = ({ onSignup = () => {} }: SignupProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleGoogleSignup = (token: string) => {
    setLoading(true);

    authApi
      .authExternal(token, 'GMAIL')
      .then((response) => {
        if (response.redirect === 'ONBOARDING_0') {
          navigate(route.onboarding.type.replace(':token', response.token), { replace: true });
        } else {
          saveState(LOCAL_APP_TOKEN, {
            ...response,
            expiresIn: getExpiryFromNow(response.expiresIn),
          });
          navigate(route.dashboard.home, { replace: true });
          dispatch(updateMiscSettings({ token: response }));
        }
      })
      .catch((err) => handleRequestErrors(err))
      .finally(() => setLoading(false));
  };

  const googleSignup = useGoogleLogin({
    prompt: 'consent',
    onSuccess: ({ access_token: token }) => handleGoogleSignup(token),
    onError: (err) => console.log('SIGNUP_FAILED: ', err),
  });

  return (
    <div className="inner-content">
      <div className="signup-wrap">
        <div className="signup-box">
          <a href="" className="logo">
            <img src={logoPrimary} alt="logo" />
          </a>

          <Gap v={2} />

          <div className="heading">
            <h3>Create an Account</h3>
            <p>Create an account to get started</p>
          </div>

          <form
            onSubmit={(ev) => {
              ev.preventDefault();

              const form = ev.target as any;
              const { type, firstName, lastName, email, phoneCode, phone, password } = serializeForm(form, {
                hash: true,
              });

              const primaryPhone = phoneCode + phone.replace(/^0+/, '').replace(/\s/g, '');
              const data = {
                type,
                firstName,
                lastName,
                email,
                primaryPhone,
                password,
              };

              setLoading(true);
              authApi
                .signup(data)
                .then(() => {
                  onSignup(data);
                  form.reset();
                })
                .catch((err) => handleRequestErrors(err))
                .finally(() => setLoading(false));
            }}
          >
            <div className="form-group mb-2">
              <Select2 placeholder="Select account type" name="type" options={AccountTypeOptions} gapbottom={1} />
            </div>

            <div className="row mb-2">
              <div className="col">
                <div className="form-group">
                  <input required type="text" name="firstName" placeholder={t('text.firstname')} className="form-control" />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <input required type="text" name="lastName" placeholder={t('text.lastname')} className="form-control" />
                </div>
              </div>
              <Gap v={1} />
            </div>

            <div className="form-group mb-2">
              <input
                required
                type="text"
                name="email"
                placeholder={t('text.email')}
                className="form-control"
                minLength={5}
                maxLength={80}
              />
              <small className="text-muted">We will not send you unnecessary emails</small>
              <Gap v={1} />
            </div>

            <div className="row">
              <div className="col-4">
                <PhoneCodeSelect name="phoneCode" />
              </div>

              <div className="col-8 ps-0">
                <div className="form-group">
                  <Cleave
                    required
                    name="phone"
                    autoComplete="off"
                    id="input-pri-phone"
                    className="form-control"
                    placeholder="E.g 8051235678"
                    options={{
                      phone: true,
                      phoneRegionCode: 'any',
                    }}
                  />
                  <Gap v={1} />
                </div>
              </div>
            </div>

            <div className="form-group mb-2">
              <input required type="password" name="password" placeholder="Password" className="form-control" />
              <Gap v={1} />
            </div>

            <div>
              <label>
                <input required type="checkbox" />
                &nbsp; I agree to the <a href={config.web.terms}>terms</a> & <a href={config.web.privacy}>conditions</a>
              </label>
            </div>

            <button disabled={loading} type="submit" className="btn btn-primary mt-4 mb-2 btn-submit">
              {loading ? 'Please wait...' : 'Create Account'}
            </button>

            <Gap v={1} />
            <div className="text-uppercase text-center divider-text">
              <span>or</span>
            </div>
            <Gap v={1} />

            <div className="text-center">
              <a className="btn btn-google-login" onClick={() => googleSignup()}>
                Sign Up with Google
              </a>
            </div>

            <Gap v={2} />
            <div className="text-center">
              <span className="text-muted">Already have access?</span> <Link to={route.authentication.signin}>Sign In</Link>
            </div>
          </form>
        </div>

        <div className="illus">
          <h4>Trusted by 100+ clients</h4>
          <Gap v={1} />
          <img src={require('../../assets/person-1.png')} alt="" />
        </div>
      </div>
    </div>
  );
};

export const SignupConfirm = ({ data }: SignupProps) => {
  return (
    <div className="inner-content">
      <a href="" className="logo">
        <img src={logoPrimary} alt="logo" />
      </a>
      <Gap v={1} />

      <div className="signup-box">
        <img src={checkTickIcon} alt="check" />

        <Gap v={1} />

        <div className="heading">
          <h4>Account created successfully</h4>
          <div>We just sent a verification link to</div>
          <div className="fw-bold">{data!.email}</div>
        </div>
        <Gap v={2} />
      </div>
    </div>
  );
};

export const SignupEmailConfirm = () => {
  // eslint-disable-next-line no-unused-vars
  const { token }: any = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    authApi
      .emailConfirm(token)
      .then(() => setLoading(false))
      .catch((err) => handleRequestErrors(err));
  }, []);

  return (
    <div className="auth-signup-container auth-confirm">
      <div className="centered">
        <div className="centered-content full-width">
          <div className="inner-content">
            <a href="" className="logo">
              <img src={logoPrimary} alt="logo" />
            </a>
            <Gap v={1} />

            <div className="signup-box">
              {loading ? (
                <img src={loadingGif} alt="" style={{ height: '20px' }} />
              ) : (
                <>
                  <img src={checkTickIcon} alt="check" />

                  <Gap v={1} />

                  <div className="heading">
                    <h4>Email Verified</h4>
                    <div>
                      We've successfully verified your email.
                      <br /> Sign in to start using your account!
                    </div>
                    <Gap v={1} />
                    <Link className="btn btn-primary" to={route.authentication.signin}>
                      Sign In
                    </Link>
                  </div>
                  <Gap v={2} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Signup = () => {
  const { t } = useTranslation();

  const [signupConfirm, setSignupConfirm] = useState(false);
  const [data, setSignupData] = useState<Partial<UserDto>>({});

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string}>
      <Helmet title={t('text.signup') + ' | Host Capital'} />

      <div className={`auth-signup-container ${signupConfirm ? 'auth-confirm' : ''}`}>
        <div className="centered">
          <div className="centered-content full-width">
            {signupConfirm ? (
              <SignupConfirm data={data} />
            ) : (
              <SignupForm
                onSignup={(data) => {
                  setSignupData(data);
                  setSignupConfirm(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};
