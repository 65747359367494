import React, { useState } from 'react';
import Gap from '../../components/Gap';
import Cleave from 'cleave.js/react';
import serializeForm from 'form-serialize';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import { Input } from '../../components/html/Input';
import Select from 'react-select';
import { TeamMemberDto } from '../../../typings';
import * as userApi from '../../../api/user';
import { handleRequestErrors } from '../../../utils';
import { Button } from '../../components/html/Button';
import { Modal } from '../../components/Modal';
import { Security2faDialog } from '../../components/2FADialog';
import { PhoneCodeSelect } from '../../components/html/PhoneCodeSelect';

const permOptions: any[] = [
  {
    value: 'users.team.manage-team',
    label: 'Manage Team',
  },
  {
    value: 'users.team.perform-trades',
    label: 'Perform Trades',
  },
  {
    value: 'users.team.make-deposits',
    label: 'Initiate Deposits',
  },
  {
    value: 'users.team.make-withdrawals',
    label: 'Make Withdrawals',
  },
  {
    value: 'users.team.manage-kyc',
    label: 'Manage KYC/KYB',
  },
  {
    value: 'users.team.manage-bank-accounts',
    label: 'Manage Bank Accounts',
  },
  {
    value: 'users.team.manage-cards',
    label: 'Manage Virtual Cards',
  },
  {
    value: 'users.team.manage-crypto-addresses',
    label: 'Manage Crypto Addresses',
  },
  {
    value: 'users.team.manage-developer-keys',
    label: 'Manager API Keys',
  },
];

const MemberAdded = ({ member }: { member: TeamMemberDto }) => {
  return (
    <div className="text-center">
      <img src={require('../../../assets/team-members.svg')} alt="team" />
      <Gap v={0.5} />
      <h4>Invite Sent!</h4>
      <p>
        {member.firstName} {member.lastName} has been invited to your team.
      </p>
      <Gap v={1} />
      <button className="btn btn-primary light btn-width-150px">Okay</button>
    </div>
  );
};

type AddMemberProps = {
  onMemberAdded(member: TeamMemberDto): void;
};

const AddMember = (props: AddMemberProps) => {
  return (
    <>
      <h4>Add Team Member</h4>
      <p>Add a new team memeber to your account</p>
      <Gap v={0.5} />

      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          const form = ev.target as any;
          const { firstName, lastName, email, phoneCode, phone, position, permissions } = serializeForm(form, { hash: true });
          const phoneNumber = phoneCode + phone.replace(/^0+/, '').replace(/\s/g, '');

          Modal.Open(
            <Security2faDialog
              operation="TEAM_MEMBER_INVITE"
              handleSecurity={async (security, callback) => {
                userApi
                  .addTeamMember({
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    position,
                    permissions: (Array.isArray(permissions) ? permissions : [permissions]).filter(Boolean),
                    security,
                  })
                  .then((member) => {
                    Modal.CloseAll();
                    props.onMemberAdded(member);
                  })
                  .catch((err) => {
                    callback(null, err);
                    handleRequestErrors(err);
                  });
              }}
            />,
            {
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: 'modal-mobile-fullscreen',
                popup: 'max-w-350x',
              },
            },
          );
        }}
      >
        <div className="row mb-2">
          <div className="col">
            <Input required type="text" placeholder="Ex. Mary" label="First Name" name="firstName" />
          </div>

          <div className="col">
            <Input required type="text" placeholder="Ex. Jane" label="Last Name" name="lastName" />
          </div>
        </div>

        <Input required label="Email" type="email" name="email" placeholder="Ex. example@mail.com" gapbottom={1} />

        <div className="row">
          <div className="col-12">
            <label htmlFor="input-sec-phone" className="mb-2">
              Phone Number
            </label>
          </div>

          <div className="col-4">
            <PhoneCodeSelect name="phoneCode" />
          </div>

          <div className="col-8 ps-0">
            <div className="form-group">
              <Cleave
                required
                name="phone"
                autoComplete="off"
                id="input-pri-phone"
                className="form-control"
                placeholder="E.g 8051235678"
                options={{
                  phone: true,
                  phoneRegionCode: 'any',
                }}
              />
              <Gap v={1} />
            </div>
          </div>
        </div>

        <Input label="Position" type="text" name="position" placeholder="Ex. Chief Technology Officer" gapbottom={1} />

        <div>
          <label htmlFor="select-permissions" className="mb-2">
            Assign Permissions
          </label>
          <Select isMulti id="select-permissions" name="permissions" options={permOptions} classNamePrefix="custom-select" />
          <small className="text-muted">Users without permissions will be unable to do much until you assign permissions to them</small>
          <Gap v={2} />
        </div>

        <div className="buttons">
          <Button type="submit" className="btn btn-primary btn-width-150px" text="Save" />
          <Gap h={1} />
          <button type="button" className="btn btn-grey btn-width-100px" onClick={() => Modal.CloseAll()}>
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};

type EditMemberProps = {
  member: TeamMemberDto;
  onMemberUpdate?(member: TeamMemberDto): void;
};

export const EditTeamMemberModal = ({ member, onMemberUpdate = () => {} }: EditMemberProps) => {
  return (
    <>
      <h4>Update Team Member</h4>
      <Gap v={0.5} />

      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          const form = ev.target as any;
          const { position, permissions } = serializeForm(form, { hash: true });

          Modal.Open(
            <Security2faDialog
              operation="TEAM_MEMBER_UPDATE"
              handleSecurity={async (security, callback) => {
                userApi
                  .updateTeamMember(member.id!, {
                    position,
                    permissions: (Array.isArray(permissions) ? permissions : [permissions]).filter(Boolean),
                    security,
                  })
                  .then((member) => {
                    Modal.CloseAll();
                    onMemberUpdate(member);
                  })
                  .catch((err) => {
                    callback(null, err);
                    handleRequestErrors(err);
                  });
              }}
            />,
            {
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                container: 'modal-mobile-fullscreen',
                popup: 'max-w-350x',
              },
            },
          );
        }}
      >
        <Input
          defaultValue={member.position}
          label="Position"
          type="text"
          name="position"
          placeholder="Ex. Chief Technology Officer"
          gapbottom={1}
        />

        <div>
          <label htmlFor="select-permissions" className="mb-2">
            Assign Permissions
          </label>
          <Select
            isMulti
            id="select-permissions"
            name="permissions"
            options={permOptions}
            defaultValue={permOptions.filter((perm) => member.permissions?.includes(perm.value))}
            classNamePrefix="custom-select"
          />
          <small className="text-muted">Users without permissions will be unable to do much until you assign permissions to them</small>
          <Gap v={2} />
        </div>

        <div className="buttons">
          <Button type="submit" className="btn btn-primary btn-width-150px" text="Update" />
          <Gap h={1} />
          <button type="button" className="btn btn-grey btn-width-100px" onClick={() => Modal.CloseAll()}>
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};

interface AddTeamMemberModalProps {
  onMemberAdded?(member: TeamMemberDto): void;
}

export const AddTeamMemberModal = (props: AddTeamMemberModalProps) => {
  const { onMemberAdded = () => {} } = props;
  const [member, setMember] = useState<TeamMemberDto>();
  const [wasAdded, setWasAdded] = useState(false);

  return wasAdded ? (
    <MemberAdded member={member!} />
  ) : (
    <AddMember
      onMemberAdded={(member) => {
        setMember(member);
        setWasAdded(true);
        onMemberAdded(member);
      }}
    />
  );
};
