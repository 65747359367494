import React from 'react';
import filterIcon from '../../../assets/filter.svg';
import { Input } from '../html/Input';
import './styles.scss';

type SearchFilterConfigProps = {
  search: {
    name: string;
    placeholder: string;
    onChange(text?: string): void;
  };
  filter: {
    visible?: boolean;
    buttonText?: string;
    onClick(): void;
  };
};

export const SearchFilterConfig = (props: SearchFilterConfigProps) => {
  const { search, filter } = props;

  return (
    <div className="row search-filter-config">
      <div className="col-md">
        <div className="row">
          <div className="col">
            <Input
              type="text"
              className="search-input"
              placeholder={search.placeholder}
              name={search.name}
              autoComplete="off"
              onChange={(ev) => search.onChange(ev.target.value.trim() ?? undefined)}
            />
          </div>

          <div className="col-auto visible-768px">
            <button className="btn btn-outline-secondary filter-btn mobile" onClick={filter.onClick}>
              <img src={filterIcon} alt="filter" />
            </button>
          </div>
        </div>
      </div>

      {filter.visible ? (
        <div className="col-md-auto">
          <button className="btn btn-outline-secondary filter-btn hidden-768px" onClick={filter.onClick}>
            <img src={filterIcon} alt="filter" /> {filter.buttonText}
          </button>
        </div>
      ) : null}
    </div>
  );
};
