import React from 'react';
import Cleave from 'cleave.js/react';
import Gap from '../Gap';
import { CurrencyDefinition } from '../../../typings';
import { formatCurrency } from '../../../utils';

interface InputProps {
  label?: string;
  info?: string;
  currency?: CurrencyDefinition;
  gapbottom?: number;
}

interface InputWithButtonProps extends InputProps {
  inputClass?: string;
  buttonClass?: string;
  buttonClick?(ev: React.MouseEvent): void;
}

export const Input = (props: InputProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
  const { label, info, gapbottom, type, className, ...restProps } = props;

  return (
    <div className="form-group">
      {label && (
        <label className="mb-2" htmlFor={props.id || ''}>
          {label} {props.required ? <span>*</span> : null}
        </label>
      )}

      <input className={`form-control ${className}`} type={type || 'text'} {...restProps} />

      {info && <small className="text-muted">{info}</small>}

      {gapbottom ? <Gap v={gapbottom} /> : null}
    </div>
  );
};

export const InputAmount = (props: InputProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
  const { id, disabled, label, info, gapbottom, currency, name, onChange = () => {} } = props;

  return (
    <div className="form-group">
      {label && (
        <label className="mb-2" htmlFor={props.id || ''}>
          {label} {props.required ? <span>*</span> : null}
        </label>
      )}

      <Cleave
        key={`${currency?.code}-input-amount`}
        required
        disabled={disabled}
        id={id}
        name={name}
        className="form-control"
        placeholder={currency ? `${formatCurrency(currency?.type!, 0, currency?.code!, currency?.decimals)}` : '0.00'}
        autoComplete="off"
        options={{
          numeral: true,
          numeralDecimalScale: currency?.orderDecimals,
          stripLeadingZeroes: false,
          numeralThousandsGroupStyle: 'thousand',
        }}
        onChange={onChange}
      />

      {info && <small className="text-muted">{info}</small>}

      {gapbottom ? <Gap v={gapbottom} /> : null}
    </div>
  );
};

export const InputWithButton = (
  props: InputWithButtonProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
) => {
  const { placeholder, minLength, maxLength, disabled, inputClass, value, buttonClass, buttonClick, label, id, info } = props;

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={id || ''}>
          {label} {props.required ? <span>*</span> : null}
        </label>
      )}

      <div className="input-group">
        <input
          {...{
            ...{ className: `form-control ${inputClass || ''}`, type: 'text' },
            ...{ placeholder, minLength, maxLength, disabled, value },
          }}
        />
        <div className="input-group-append">
          <button className={buttonClass} onClick={buttonClick}>
            Copy
          </button>
        </div>
      </div>

      <Gap v={0.3} />

      {info && <small className="text-muted">{info}</small>}

      {props.gapbottom ? <Gap v={props.gapbottom} /> : null}
    </div>
  );
};
