import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { route } from '../../../routes';
import { Header } from '../../components/DashboardHeader';
import { Sidebar } from '../../components/DashboardSidebar';
import { Modal } from '../../components/Modal';
import { SettingsSidebar } from '../../components/SettingsSidebar';
import { TwoFASetup } from './modals';
import { useSelector } from 'react-redux';
import { ReducerStates } from '../../../typings';
import { Security2faDialog } from '../../components/2FADialog';
import * as userApi from '../../../api/user';
import * as userActions from '../../../actions/user';
import Gap from '../../components/Gap';
import '../settings.scss';
import './security.scss';
import { handleRequestErrors } from '../../../utils';
import { useDispatch } from 'react-redux';

export const SecuritySettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerStates) => state);

  return (
    <>
      <Helmet title="Settings | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="settings-page-wrap">
                  <SettingsSidebar />

                  <div className="settings-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Security</h6>
                      </div>
                    </div>

                    <Gap v={2} className="hidden-768px" />

                    <div className="page-content-inner">
                      <ul className="security-settings-list">
                        <li>
                          <div className="wrap">
                            <h6>Google/Authy Authenticator (Recommended)</h6>
                            <Gap v={1} />

                            <div>Protect your account and transactions.</div>

                            <Gap v={1} />

                            <button
                              className={`btn btn-default ${
                                user.security?.authenticator?.set! && user.security?.authenticator.enabled ? 'btn-danger' : 'btn-primary'
                              } btn-full-width-mobile`}
                              onClick={() => {
                                if (!user.security?.authenticator.set) {
                                  return Modal.Open(<TwoFASetup type="AUTHENTICATOR" />, {
                                    customClass: {
                                      container: 'modal-mobile-fullscreen',
                                      popup: 'max-w-400x',
                                    },
                                  });
                                }

                                Modal.Confirm(
                                  user.security?.authenticator.enabled ? 'Disable Authenticator' : 'Enable Authenticator',
                                  user.security?.authenticator.enabled ? (
                                    <>
                                      Are you sure you want disable your Authenticator security?
                                      <br />
                                      <br />
                                      Note: Disabling Authenticator security does not unset it, but you will no longer be able to use it to
                                      verify your account and transactions.
                                      <br />
                                      <br />
                                      Continue?
                                    </>
                                  ) : (
                                    <>
                                      Note: Authenticator security will now be available as an option to verify your account and
                                      transactions
                                    </>
                                  ),
                                ).then((b) => {
                                  if (b === true) {
                                    const enable = !user.security?.authenticator?.enabled;

                                    Modal.Open(
                                      <Security2faDialog
                                        operation="SECURITY_UPDATE"
                                        type="AUTHENTICATOR"
                                        handleSecurity={(security, callback) => {
                                          userApi
                                            .update2Fa('AUTHENTICATOR', {
                                              enable,
                                              security,
                                            })
                                            .then(() => {
                                              dispatch(
                                                userActions.updateUser({
                                                  security: {
                                                    ...(user.security! || {}),
                                                    authenticator: {
                                                      set: true,
                                                      enabled: enable,
                                                    },
                                                  },
                                                }),
                                              );
                                              Modal.Alert(
                                                `Authenticator Security ${enable ? 'Enabled' : 'Disabled'}`,
                                                `You have successfully ${
                                                  enable ? 'enabled' : 'disabled'
                                                } authenticator security for your account.`,
                                              );
                                            })
                                            .catch((err) => {
                                              callback(null, err);
                                              handleRequestErrors(err);
                                            });
                                        }}
                                      />,
                                      {
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        customClass: {
                                          container: 'modal-mobile-fullscreen',
                                          popup: 'max-w-350x',
                                        },
                                      },
                                    );
                                  }
                                });
                              }}
                            >
                              {
                                // prettier-ignore
                                user.security?.authenticator.set ? user.security?.authenticator.enabled ? 'Disable' : 'Enable' : 'Setup Authenticator'
                              }
                            </button>
                          </div>
                        </li>

                        <li>
                          <div className="wrap">
                            <h6>Phone Number Verification</h6>
                            <Gap v={1} />

                            <div>
                              Protect your account and transactions. Once verified, you will be able to receive OTP codes to verify your
                              transactions.
                            </div>

                            <Gap v={1} />

                            <button
                              className={`btn btn-default ${
                                user.security?.sms.set && user.security?.sms.enabled ? 'btn-danger' : 'btn-primary'
                              } btn-full-width-mobile`}
                              onClick={() => {
                                if (!user.security?.sms.set) {
                                  return Modal.Open(<TwoFASetup type="SMS" />, {
                                    customClass: {
                                      container: 'modal-mobile-fullscreen',
                                      popup: 'max-w-400x',
                                    },
                                  });
                                }

                                Modal.Confirm(
                                  user.security?.sms.enabled ? 'Disable SMS Security' : 'Enable SMS Security',
                                  user.security?.sms.enabled ? (
                                    <>
                                      Are you sure you want disable SMS PIN?
                                      <br />
                                      <br />
                                      Note: Disabling SMS security does not unset it, but you will no longer be able to use it to verify
                                      your account and transactions.
                                      <br />
                                      <br />
                                      Continue?
                                    </>
                                  ) : (
                                    <>Note: SMS security will now be available as an option to verify your account and transactions</>
                                  ),
                                ).then((b) => {
                                  if (b === true) {
                                    const enable = !user.security?.sms?.enabled;

                                    Modal.Open(
                                      <Security2faDialog
                                        type="SMS"
                                        operation="SECURITY_UPDATE"
                                        handleSecurity={(security, callback) => {
                                          userApi
                                            .update2Fa('SMS', {
                                              enable,
                                              security,
                                            })
                                            .then(() => {
                                              dispatch(
                                                userActions.updateUser({
                                                  security: {
                                                    ...(user.security! || {}),
                                                    sms: {
                                                      set: true,
                                                      enabled: enable,
                                                    },
                                                  },
                                                }),
                                              );
                                              Modal.Alert(
                                                `SMS Security ${enable ? 'Enabled' : 'Disabled'}`,
                                                `You have successfully ${enable ? 'enabled' : 'disabled'} SMS security for your account.`,
                                              );
                                            })
                                            .catch((err) => {
                                              callback(null, err);
                                              handleRequestErrors(err);
                                            });
                                        }}
                                      />,
                                      {
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        customClass: {
                                          container: 'modal-mobile-fullscreen',
                                          popup: 'max-w-350x',
                                        },
                                      },
                                    );
                                  }
                                });
                              }}
                            >
                              {user.security?.sms.set ? (user.security?.sms.enabled ? 'Disable' : 'Enable') : 'Verify Phone Number'}
                            </button>
                          </div>
                        </li>

                        <li>
                          <div className="wrap">
                            <h6>Security PIN</h6>
                            <Gap v={1} />

                            <div>Create 4-digit PIN to secure your account and transactions.</div>

                            <Gap v={1} />

                            <button
                              className={`btn btn-default ${
                                user.security?.pin.set && user.security?.pin.enabled ? 'btn-danger' : 'btn-primary'
                              } btn-full-width-mobile`}
                              onClick={() => {
                                if (!user.security?.pin.set) {
                                  return Modal.Open(<TwoFASetup type="PIN" />, {
                                    customClass: {
                                      container: 'modal-mobile-fullscreen',
                                      popup: 'max-w-400x',
                                    },
                                  });
                                }

                                Modal.Confirm(
                                  user.security?.pin.enabled ? 'Disable PIN Security' : 'Enable PIN Security',
                                  user.security?.pin.enabled ? (
                                    <>
                                      Are you sure you want disable your security PIN?
                                      <br />
                                      <br />
                                      Note: Disabling security PIN does not unset it, but you will no longer be able to use it to verify
                                      your account and transactions.
                                      <br />
                                      <br />
                                      Continue?
                                    </>
                                  ) : (
                                    <>
                                      Note: PIN security will now be available as an option to verify your account and transactions.
                                      <br />
                                      <br />
                                    </>
                                  ),
                                ).then((b) => {
                                  if (b === true) {
                                    const enable = !user.security?.pin?.enabled;

                                    Modal.Open(
                                      <Security2faDialog
                                        operation="SECURITY_UPDATE"
                                        type="PIN"
                                        handleSecurity={(security, callback) => {
                                          userApi
                                            .update2Fa('PIN', {
                                              enable,
                                              security,
                                            })
                                            .then(() => {
                                              dispatch(
                                                userActions.updateUser({
                                                  security: {
                                                    ...(user.security! || {}),
                                                    pin: {
                                                      set: true,
                                                      enabled: enable,
                                                    },
                                                  },
                                                }),
                                              );
                                              Modal.Alert(
                                                `Security PIN ${enable ? 'Enabled' : 'Disabled'}`,
                                                `You have successfully ${enable ? 'enabled' : 'disabled'} security PIN for your account.`,
                                              );
                                            })
                                            .catch((err) => {
                                              callback(null, err);
                                              handleRequestErrors(err);
                                            });
                                        }}
                                      />,
                                      {
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        customClass: {
                                          container: 'modal-mobile-fullscreen',
                                          popup: 'max-w-350x',
                                        },
                                      },
                                    );
                                  }
                                });
                              }}
                            >
                              {user.security?.pin.set ? (user.security?.pin.enabled ? 'Disable PIN' : 'Enable PIN') : 'Setup PIN'}
                            </button>
                          </div>
                        </li>

                        <li>
                          <div className="wrap">
                            <h6>Login Password</h6>
                            <Gap v={1} />

                            <div>
                              Login password is used to log in to your account. Note that this change only affects your personal account
                            </div>
                            <Gap v={1} />
                            <Link to={route.settings.password} className="btn btn-primary btn-default">
                              Change Password
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
