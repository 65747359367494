import React, { useEffect, useRef, useState } from 'react';
import serializeForm from 'form-serialize';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import Cleave from 'cleave.js/react';
import { Input } from '../html/Input';
import { Button } from '../html/Button';
import { baseStore } from '../../../store';
import { CheckBox } from '../html/Checkbox';
import { Select2 } from '../html/Select';
import { WalletAssetTransactionType } from '../../../typings';
import { Modal } from '../Modal';
import Gap from '../Gap';
import './styles.scss';

export type TransactionsFilterData = {
  reference?: string;
  currency?: string;
  type?: WalletAssetTransactionType;
  status?: string;
  fromDate?: Date;
  toDate?: Date;
  fromAmount?: number;
  toAmount?: number;
  includeSubTransactions?: boolean;
  onlySubTransactions?: boolean;
};

type FilterTransactionModalProps = {
  filters: Partial<TransactionsFilterData>;
  onFilter(data: TransactionsFilterData): void;
};

export const FilterTransactionModal = (props: FilterTransactionModalProps) => {
  const { filters, onFilter } = props;
  const formRef = useRef(null);
  const { wallets } = baseStore.getState();
  const [fromDate, setFromDate] = useState<Date | null>();
  const [toDate, setToDate] = useState<Date | null>();

  useEffect(() => {
    setFromDate(filters.fromDate ?? null);
    setToDate(filters.toDate ?? null);
  }, []);

  return (
    <div className="FilterTransactionMoodal">
      <h4 className="title">Filter Transactions</h4>
      <Gap v={0.5} />

      <form
        ref={formRef}
        onSubmit={(ev) => {
          ev.preventDefault();

          const form = ev.target as any;
          const {
            reference,
            currency,
            type,
            status,
            fromAmount,
            toAmount,
            includeSubTransactions,
            onlySubTransactions,
          } = serializeForm(form, { hash: true });

          Modal.CloseAll();
          onFilter({
            reference,
            currency,
            type,
            status,
            fromDate: fromDate ?? undefined,
            toDate: toDate ?? undefined,
            fromAmount,
            toAmount,
            includeSubTransactions,
            onlySubTransactions,
          });
        }}
      >
        <div className="row">
          <div className="col">
            <label htmlFor="sel-date-from">From date</label>
            <Gap v={0.5} />
            <DatePicker
              id="sel-date-from"
              className="form-control"
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              placeholderText="Eg. 2023-04-1"
              dateFormat="yyyy-MM-dd"
            />
            <Gap v={0.5} />
          </div>

          <div className="col">
            <label htmlFor="sel-date-to">To date</label>
            <Gap v={0.5} />
            <DatePicker
              id="sel-date-to"
              className="form-control"
              selected={toDate}
              onChange={(date) => setToDate(date)}
              placeholderText="Eg. 2023-04-10"
              dateFormat="yyyy-MM-dd"
            />
            <Gap v={0.5} />
          </div>
        </div>

        <Input
          id="input-reference"
          type="text"
          label="Reference"
          name="reference"
          placeholder="Eg. 187a8cff-d833-4aed-87a5-a7e04acfc213"
          defaultValue={filters.reference}
          gapbottom={0.5}
        />

        <label htmlFor="select-currency">Currency</label>
        <Gap v={0.5} />
        <Select
          id="select-currency"
          placeholder="Please Select"
          classNamePrefix="custom-select"
          options={[
            {
              label: 'Fiat',
              options: wallets
                ?.filter(({ type }) => type === 'FIAT')
                .map(({ currency }) => ({
                  label: `${currency.code} (${currency.name})`,
                  value: currency.code,
                })),
            },
            {
              label: 'Crypto',
              options: wallets
                ?.filter(({ type }) => type === 'CRYPTO')
                .map(({ currency }) => ({
                  label: `${currency.code} (${currency.name})`,
                  value: currency.code,
                })),
            },
          ]}
          defaultInputValue={filters.currency}
          name="currency"
        />
        <Gap v={0.5} />

        <div className="row">
          <div className="col">
            <Select2
              label="Type"
              placeholder="Please Select"
              options={[
                { label: 'CREDIT', value: 'CREDIT' },
                { label: 'DEBIT', value: 'DEBIT' },
              ]}
              defaultValue={filters.type}
              name="type"
              gapbottom={0.5}
            />
          </div>

          <div className="col">
            <Select2
              label="Status"
              placeholder="Please Select"
              options={[
                { label: 'PENDING', value: 'PENDING' },
                { label: 'IN_PROGRESS', value: 'IN_PROGRESS' },
                { label: 'SUCCESS', value: 'SUCCESS' },
                { label: 'FAILED', value: 'FAILED' },
              ]}
              defaultValue={filters.status}
              name="status"
              gapbottom={0.5}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label htmlFor="input-from-amount">From amount</label>
            <Gap v={0.5} />
            <Cleave
              id="input-from-amount"
              name="fromAmount"
              className="form-control"
              placeholder="0.00"
              autoComplete="off"
              value={filters.fromAmount}
              options={{
                numeral: true,
                numeralDecimalScale: 8,
                stripLeadingZeroes: false,
                numeralThousandsGroupStyle: 'none',
              }}
            />
          </div>

          <div className="col">
            <label htmlFor="input-tp-amount">To amount</label>
            <Gap v={0.5} />
            <Cleave
              id="input-to-amount"
              name="toAmount"
              className="form-control"
              placeholder="0.00"
              autoComplete="off"
              value={filters.toAmount}
              options={{
                numeral: true,
                numeralDecimalScale: 8,
                stripLeadingZeroes: false,
                numeralThousandsGroupStyle: 'none',
              }}
            />
          </div>
        </div>

        <Gap v={1} />
        <CheckBox
          id="include-sub-tx"
          name="includeSubTransactions"
          label="Include sub-transactions"
          value="true"
          defaultChecked={filters.includeSubTransactions}
        />

        <Gap v={0.5} />
        <CheckBox
          id="only-sub-tx"
          name="onlySubTransactions"
          label="Only sub-transactions"
          value="true"
          defaultChecked={filters.onlySubTransactions}
        />

        <Gap v={1} />

        <div className="buttons">
          <Button type="submit" className="btn btn-primary full-width" text="Filter Transactions" />
        </div>

        <div className="text-center mt-3">
          <a
            className="cursor-pointer"
            onClick={() => {
              (formRef.current as any).reset();
              Modal.CloseAll();
              onFilter({});
            }}
          >
            Clear Filters
          </a>
        </div>
      </form>
    </div>
  );
};
