import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NotificationSettingsDto, ReducerStates } from '../../../typings';
import { Header } from '../../components/DashboardHeader';
import { Sidebar } from '../../components/DashboardSidebar';
import Gap from '../../components/Gap';
import { ToggleSwitch } from '../../components/html/ToggleSwitch';
import { SettingsSidebar } from '../../components/SettingsSidebar';
import '../settings.scss';
import * as notificationApi from '../../../api/notifications';
import * as notificationAction from '../../../actions/notification';
import { useDispatch } from 'react-redux';
import { handleRequestErrors } from '../../../utils';

const notificationAlerts: Array<{ title: string; key: 'trades' | 'deposits' | 'withdrawals' | 'logins' }> = [
  {
    title: 'Trades',
    key: 'trades',
  },
  {
    title: 'Deposits',
    key: 'deposits',
  },
  {
    title: 'Withdrawals',
    key: 'withdrawals',
  },
  {
    title: 'Logins',
    key: 'logins',
  },
];

export const NotificationSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notification } = useSelector((state: ReducerStates) => state);

  useEffect(() => {
    notificationApi
      .getSettings()
      .then((settings) => dispatch(notificationAction.setSettings(settings)))
      .catch((err) => handleRequestErrors(err));
  }, []);

  const updateSettings = (settings: Partial<NotificationSettingsDto>) => {
    notificationApi
      .updateSettings(settings)
      .then((settings) => dispatch(notificationAction.setSettings(settings)))
      .catch((err) => handleRequestErrors(err));
  };

  return (
    <>
      <Helmet title="Settings | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="settings-page-wrap">
                  <SettingsSidebar />

                  <div className="settings-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Notification Settings</h6>
                      </div>
                    </div>

                    <Gap v={2} className="hidden-768px" />

                    <div className="page-content-inner">
                      {notificationAlerts.map(({ title, key }, index) => (
                        <div className="setting-radio-list" key={`${key}-${index}`}>
                          <div className="title">
                            <h5>{title}</h5>
                          </div>

                          <ul>
                            <li className="hidden">
                              <div className="wrap">
                                <div className="row">
                                  <div className="col fw-bold" data-vertical_center>
                                    SMS Notifications
                                  </div>
                                  <div className="col-auto text-end">
                                    <ToggleSwitch
                                      on={notification.settings[key]?.sms === true}
                                      onSwitch={(on) =>
                                        updateSettings({
                                          [key]: {
                                            ...notification.settings[key],
                                            sms: on,
                                          },
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="wrap">
                                <div className="row">
                                  <div className="col fw-bold" data-vertical_center>
                                    Email Alerts
                                  </div>
                                  <div className="col-auto text-end">
                                    <ToggleSwitch
                                      on={notification.settings[key]?.email === true}
                                      onSwitch={(on) =>
                                        updateSettings({
                                          [key]: {
                                            ...notification.settings[key],
                                            email: on,
                                          },
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="wrap">
                                <div className="row">
                                  <div className="col fw-bold" data-vertical_center>
                                    Push Notifications
                                  </div>
                                  <div className="col-auto text-end">
                                    <ToggleSwitch
                                      on={notification.settings[key]?.push === true}
                                      onSwitch={(on) =>
                                        updateSettings({
                                          [key]: {
                                            ...notification.settings[key],
                                            push: on,
                                          },
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
